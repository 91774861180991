import { Box, Tag } from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { Link as RemixLink } from "@remix-run/react"

import { useMutation, useQueryClient } from "@tanstack/react-query"
import { isAfter } from "date-fns"
import { motion } from "framer-motion"
import { mapProductsToRouteName } from "~/components/Filters/schemas"
import { endOfBusinessWeek } from "~/consts/dates"
import { GetUserListsByProduct } from "~/routes/__protected/api/lists"
import { cacheKeys } from "~/utils/cacheKeys"

export const TagNewSignalsList = ({
  list,
  product,
  field,
}: {
  list: GetUserListsByProduct[number]
  product: SpecterProducts
  field: "HasNewFundingHighlights" | "HasNewGrowthHighlights"
}): JSX.Element => {
  const queryClient = useQueryClient()

  const props = {
    HasNewFundingHighlights: {
      column: "newFundingSignalsLastViewedAt",
      countField: "_countNewFundingSignals",
      lastViewedAt: "newFundingSignalsLastViewedAt",
    },
    HasNewGrowthHighlights: {
      column: "newGrowthSignalsLastViewedAt",
      countField: "_countNewGrowthSignals",
      lastViewedAt: "newGrowthSignalsLastViewedAt",
    },
  } as const

  const { column, countField, lastViewedAt } = props[field]

  const lastViewedAtDate = list[lastViewedAt]
  const isNew =
    !(
      lastViewedAtDate &&
      isAfter(new Date(lastViewedAtDate), endOfBusinessWeek())
    ) && !!list[countField]

  const markedAsViewed = useMutation(async () => {
    await fetch(`/api/lists/${product}/${list.id}/mark-as-viewed`, {
      method: "POST",
      body: JSON.stringify({ column }),
    })

    await queryClient.invalidateQueries(cacheKeys.userLists({ product }))
  })

  const listItemNewSignalsLink = `/signals/${mapProductsToRouteName(
    product
  )}/feed?${new URLSearchParams({
    listId: list.id,
    query: JSON.stringify({ [field]: true }),
  }).toString()}`

  return (
    <Box position="relative" zIndex={1} display="inline-flex">
      {isNew && (
        <motion.div
          initial={{ scale: 0.8, opacity: 0.6 }}
          animate={{ scale: 1.6, opacity: 0 }}
          transition={{ duration: 1.6, repeat: Infinity }}
          style={{
            position: "absolute",
            inset: 0,
            zIndex: -1,
          }}
        >
          <Box h="full" bgColor="green.400" rounded="full" />
        </motion.div>
      )}
      <Tag
        as={RemixLink}
        to={listItemNewSignalsLink}
        onClick={() => markedAsViewed.mutate()}
        rounded="full"
        variant={isNew ? "solid" : "softOutline"}
        colorScheme={isNew ? "green" : "gray"}
      >
        {/* // TODO: Add similar to new Funding signals */}
        {list[countField] ?? "-"}
      </Tag>
    </Box>
  )
}
