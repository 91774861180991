import { SpecterProducts } from "@prisma/client"
import { ProductItem } from "~/components/SignalCard"
import { mapProductsToRouteName } from "~/components/Filters/schemas"

export const INTEGRATION_GUIDE_URL =
  "https://intercom.help/specter-labs/en/articles/10370245-new-integrations-setup-guide"

const INTEGRATIONS_WITH_LISTS = ["affinity", "hubspot", "attio"]

export const integrationSupportsLists = (integrationKey: string) => {
  return INTEGRATIONS_WITH_LISTS.includes(integrationKey)
}

const INTEGRATION_NAMES: Record<string, string> = {
  affinity: "Affinity",
  airtable: "Airtable",
  pipedrive: "Pipedrive",
  attio: "Attio",
  hubspot: "Hubspot",
  salesforce: "Salesforce",
}

export const getIntegrationName = (integrationKey: string) => {
  return INTEGRATION_NAMES[integrationKey] ?? ""
}

const INTEGRATION_LOGOS: Record<string, string> = {
  affinity: "/affinity-v2.png",
  airtable: "/airtable.png",
  pipedrive: "/pipedrive.png",
  attio: "/attio.png",
  hubspot: "/hubspot.png",
  salesforce: "/salesforce.png",
}

export const getIntegrationLogo = (integrationKey: string) => {
  return INTEGRATION_LOGOS[integrationKey] ?? ""
}

// Others are transparent bg
const INTEGRATION_COLORS: Record<string, string> = {
  airtable: "#036aef",
  hubspot: "#ff7b59",
  salesforce: "#00a1df",
}

export const getIntegrationColor = (integrationKey: string) => {
  return INTEGRATION_COLORS[integrationKey] ?? undefined
}

export const getDataSourceKey = (product?: SpecterProducts) => {
  switch (product) {
    case SpecterProducts.company:
      return "companyDatasource"
    case SpecterProducts.talent:
      return "talentDatasource"
    case SpecterProducts.stratintel:
      return "strategicDatasource"
  }
}

export const getDataMatchingKey = (product: SpecterProducts) => {
  switch (product) {
    case SpecterProducts.company:
      return "companyDataMatching"
    case SpecterProducts.talent:
      return "talentDataMatching"
    case SpecterProducts.stratintel:
      return "strategicDataMatching"
  }
}

export const getFieldMappingKey = (product?: SpecterProducts) => {
  switch (product) {
    case SpecterProducts.company:
      return "companyFieldMapping"
    case SpecterProducts.talent:
      return "talentFieldMapping"
    case SpecterProducts.stratintel:
      return "strategicFieldMapping"
  }
}

export const formatSignalsForIntegration = (
  signals: ProductItem<SpecterProducts>[],
  product: SpecterProducts
) => {
  return signals.map((signal) => ({
    ...signal,
    specter_source: "Specter",
    specter_url: `https://app.tryspecter.com/signals/${mapProductsToRouteName(
      product
    )}/feed/${signal.id}/f`,
  }))
}
