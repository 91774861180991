import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  UseDisclosureReturn,
  useToast,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { useFormik } from "formik"
import { HiPencil } from "react-icons/hi"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { z } from "zod"
import { toFormikValidationSchema } from "zod-formik-adapter"
import { cacheKeys } from "~/utils/cacheKeys"
import invariant from "~/utils/invariant"
import { useUserPermissions } from "~/utils/hooks/useUserPermissions"
import { GetShareableMembers } from "~/routes/__protected/api/team/share-members"
import { GetListDetailsById } from "~/utils/db/userLists"
import { MenuItem } from "~/components/MenuItem"

const formSchema = z.object({
  name: z.string(),
})

const validationSchema = toFormikValidationSchema(formSchema)

type FormValues = z.infer<typeof formSchema>

interface Props {
  listId: string
  product: SpecterProducts
}

export const EditList = ({ listId, product }: Props): JSX.Element => {
  const modal = useDisclosure()

  return (
    <>
      <MenuItem icon={HiPencil} onClick={modal.onOpen}>
        Edit
      </MenuItem>

      <Modal isOpen={modal.isOpen} onClose={modal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Edit List</ModalHeader>
          <EditListForm
            listId={listId}
            product={product}
            modalDisclosure={modal}
          />
        </ModalContent>
      </Modal>
    </>
  )
}

const EditListForm = ({
  listId,
  product,
  modalDisclosure,
}: Props & { modalDisclosure: UseDisclosureReturn }) => {
  const queryClient = useQueryClient()
  const permissions = useUserPermissions()
  const toast = useToast()

  const listQuery = useQuery<GetListDetailsById>(
    cacheKeys.userListDetails(listId),
    async () => {
      const req = await fetch(`/api/lists/${product}/${listId}/details`)

      invariant(req.ok)

      return await req.json()
    }
  )

  const updateMutation = useMutation<unknown, unknown, FormValues>(
    async (values) => {
      await fetch(`/api/lists/${product}/${listId}/details`, {
        method: "POST",
        body: JSON.stringify({
          ...values,
          isPublic: listQuery.data?.isPublic ?? false,
        }),
      })
    },
    {
      async onSuccess() {
        modalDisclosure.onClose()
        await queryClient.invalidateQueries(cacheKeys.userLists({ product }))
        await queryClient.invalidateQueries(cacheKeys.globalHubLists(product))

        toast({
          status: "success",
          title: "List edited successfully",
        })
      },
    }
  )
  useQuery(
    cacheKeys.shareMembers,
    async () => {
      const req = await fetch("/api/team/share-members")

      invariant(req.ok)

      const data: GetShareableMembers = await req.json()

      return data.filter((user) => user.userId !== permissions.data?.userId)
    },
    {
      enabled: permissions.isSuccess,
    }
  )

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      name: listQuery.data?.name ?? "",
      userId: listQuery.data?.userId ?? "",
    },
    onSubmit(values) {
      updateMutation.mutate(values)
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <ModalBody display="flex" flexDirection={"column"} gap={6}>
        <FormControl isInvalid={!!formik.errors.name}>
          <FormLabel htmlFor="name">List Name</FormLabel>
          <Input
            id="name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            placeholder={
              listQuery.isLoading ? "Loading..." : "Name the list..."
            }
          />
          <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
        </FormControl>
      </ModalBody>
      <ModalFooter gap={2}>
        <Button variant="outline" size="sm" onClick={modalDisclosure.onClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          size="sm"
          colorScheme="brand"
          isLoading={updateMutation.isLoading}
          disabled={!formik.isValid || updateMutation.isLoading}
        >
          Save Changes
        </Button>
      </ModalFooter>
    </form>
  )
}
