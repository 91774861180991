import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  Box,
  ModalCloseButton,
  Spinner,
  Center,
  Text,
  Button,
  Image,
  Flex,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { useQuery } from "@tanstack/react-query"
import { cacheKeys } from "~/utils/cacheKeys"
import { getProductEntityName } from "~/utils/getProductName"
import { ViewMode } from "~/utils/hooks/useViewMode"
import {
  formatSignalsForIntegration,
  getIntegrationColor,
  getIntegrationLogo,
  getIntegrationName,
} from "~/utils/integrationApp"
import invariant from "~/utils/invariant"
import { mapProductsToRouteName } from "../Filters/schemas"
import { IntegrationPush } from "../IntegrationPush"
import { ProductItem } from "../SignalCard"

interface Props {
  isOpen: boolean
  onClose: () => void
  integrationKey: string
  product: SpecterProducts
  signalIds: string[]
}

const Content = ({ product, integrationKey, onClose, signalIds }: Props) => {
  const signalQuery = useQuery<{
    items: ProductItem<typeof product>[]
  }>(cacheKeys.signalsById(signalIds), async () => {
    const req = await fetch(`/api/signals/${mapProductsToRouteName(product)}`, {
      method: "POST",
      body: JSON.stringify({
        ids: signalIds,
        view: ViewMode.Table,
      }),
    })

    invariant(req.ok)

    return req.json()
  })

  if (signalQuery.isLoading) {
    return (
      <Center h="calc(100vh - 300px)">
        <Box textAlign="center">
          <Spinner />
          <Text fontSize="md" fontWeight="medium" mb={2}>
            Fetching {getProductEntityName(product)}...
          </Text>
        </Box>
      </Center>
    )
  }

  if (!signalQuery.data || (signalQuery.data?.items.length ?? 0) <= 0) {
    return (
      <Center h="calc(100vh - 300px)">
        <Box textAlign="center">
          <Text fontSize="md" fontWeight="medium" mb={2}>
            Signal not found
          </Text>
          <Button onClick={onClose} size="sm" variant="outline">
            Close
          </Button>
        </Box>
      </Center>
    )
  }

  return (
    <IntegrationPush
      product={product}
      integrationKey={integrationKey}
      signals={formatSignalsForIntegration(signalQuery.data.items, product)}
      onComplete={onClose}
    />
  )
}

export const IntegrationPushSignalModal = ({
  isOpen,
  onClose,
  integrationKey,
  product,
  signalIds,
}: Props) => {
  const integrationName = getIntegrationName(integrationKey)
  const color = getIntegrationColor(integrationKey)

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW={800} p={1} rounded="2xl">
        <Box
          overflow="hidden"
          rounded="xl"
          borderWidth={1}
          borderColor="gray.100"
        >
          <ModalCloseButton color={color ? "white" : "inherit"} />
          <ModalHeader bgColor={color} borderBottomWidth={color ? 0 : 1}>
            <Flex alignItems="center" gap={2}>
              <Image
                w={6}
                src={getIntegrationLogo(integrationKey)}
                alt={integrationName}
              />
              <Text as="span" color={color ? "white" : "inherit"}>
                Add to {integrationName}
              </Text>
            </Flex>
          </ModalHeader>

          {isOpen && (
            <Content
              isOpen
              signalIds={signalIds}
              product={product}
              integrationKey={integrationKey}
              onClose={onClose}
            />
          )}
        </Box>
      </ModalContent>
    </Modal>
  )
}
