import { SpecterProducts } from "@prisma/client"
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { useUser } from "@clerk/remix"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import invariant from "~/utils/invariant"
import { cacheKeys } from "~/utils/cacheKeys"
import { HiUserRemove } from "react-icons/hi"
import { MenuItem } from "../MenuItem"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"

export function RemoveAccessButton({
  queryId,
  product,
}: {
  queryId: number
  product: SpecterProducts
}): JSX.Element {
  const modal = useDisclosure()
  const { user } = useUser()
  const queryClient = useQueryClient()
  const toast = useToast()
  const [searchParams] = useSafeSearchParams()

  const accessMutation = useMutation(
    async () => {
      invariant(user?.id)

      const req = await fetch(
        `/api/saved-searches/${queryId}/share/${user.id}`,
        {
          method: "DELETE",
        }
      )

      invariant(req.ok)
    },
    {
      async onSuccess() {
        await queryClient.refetchQueries(
          cacheKeys.userSavedSearches(product, searchParams.toString())
        )
        modal.onClose()

        toast({
          status: "success",
          title: "Access has been removed for this search",
        })
      },
    }
  )

  return (
    <>
      <MenuItem icon={HiUserRemove} onClick={modal.onOpen}>
        Remove Access
      </MenuItem>

      <Modal isOpen={modal.isOpen} onClose={modal.onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Remove Access</ModalHeader>
          <ModalBody>
            <Text fontSize="sm">
              Are you sure you want to remove your access to this search?
            </Text>
          </ModalBody>
          <ModalFooter gap={2}>
            <Button variant="outline" onClick={modal.onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="brand"
              onClick={() => accessMutation.mutate()}
              isLoading={accessMutation.isLoading}
              disabled={accessMutation.isLoading}
            >
              Yes, remove access
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
