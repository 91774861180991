import { SpecterProducts } from "@prisma/client"
import { GetUserPermissions } from "~/routes/__protected/api/user/permissions"
import { isOneOf } from "./isOneOf"
import { isNullish } from "./values"

const getDateOnly = (date: Date | string) => {
  const dateObj = new Date(date)
  return new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate())
}

export const isTrialExpired = (permissions: GetUserPermissions) => {
  const { endDateTrial, company, talent, stratintel } =
    permissions?.organization ?? {}

  return (
    !company &&
    !talent &&
    !stratintel &&
    !isNullish(endDateTrial) &&
    getDateOnly(endDateTrial) < getDateOnly(new Date())
  )
}

export const EXPLICIT_ACCESS_PRODUCTS = [
  SpecterProducts.company,
  SpecterProducts.talent,
  SpecterProducts.stratintel,
] as const

export function hasAccessToFeed(
  permissions: GetUserPermissions | null | undefined,
  product: SpecterProducts
) {
  if (isNullish(permissions)) return null

  if (!isOneOf(product, EXPLICIT_ACCESS_PRODUCTS)) return true

  return (
    permissions.organization?.[product] ||
    permissions.organization?.[`${product}Trial`]
  )
}
