import {
  Button,
  Flex,
  Spinner,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { useNavigation } from "@remix-run/react"
import * as R from "ramda"
import { useEffect, useMemo, useState } from "react"
import { HiNoSymbol, HiPlus } from "react-icons/hi2"
import { getOptionLabel } from "~/components"
import { FlexDivider } from "~/components/FlexDivider"
import { FlexOneLine } from "~/components/FlexOneLine"
import {
  CompanyOperator,
  COMPANY_OPERATORS_FIELDS,
  COMPANY_OPERATOR_LABELS,
  EXCLUDE_PREFIX,
  LanguageProficiency,
  LANGUAGE_PROFICIENCY_PROPS,
} from "~/consts/signals"
import { useProduct } from "~/utils/hooks/useProduct"
import { getProductSignalSearchParams } from "~/utils/hooks/useProductFilters"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"
import { isOneOf } from "~/utils/isOneOf"
import { PROGRAMMATIC_FILTERS } from "~/utils/progmaticFilters"
import {
  dateRangeSchema,
  getSignalFieldTitleString,
  GROWTH_PERIODS_LABELS,
  NUMERIC_OPERATORS,
} from "~/utils/signal"
import { formatDate } from "~/utils/string/format"
import { ValueOf } from "~/utils/types"
import { updateSearchParams } from "~/utils/updateSearchParams"
import { hasLength, isNullish } from "~/utils/values"
import { SignalFilters } from "./schemas"
import {
  isArrayFilter,
  isBooleanFilter,
  isCompanyOperatorsFilter,
  isCurrentTenureFilter,
  isDateFilter,
  isDateRangeFilter,
  isDateRollingRangeFilter,
  isGrowthFilter,
  isLanguagesFilter,
  isNumericFilter,
  isNumericFilterRevamped,
  isNumericRangeFilter,
  isSetFilter,
  isSingleValueFilter,
  isTextFilter,
} from "./utils"

type AppliedFilterItemProps<P extends SpecterProducts, Value> = {
  product: P
  field: string
  value: Value
  onChange?: (value: Value | null) => void
  onClickMoreItems?: () => void
  composedTitle?: string
}

export const AppliedFilterItem = <
  P extends SpecterProducts,
  Value extends ValueOf<SignalFilters[P]>
>({
  field,
  value,
  onChange,
  onClickMoreItems,
  composedTitle,
  product,
}: AppliedFilterItemProps<P, Value>) => {
  // This actually doesn't return a String in all cases
  const title = getSignalFieldTitleString(field)

  const isProgrammatic = PROGRAMMATIC_FILTERS.includes(field)

  if (
    field === "listId" ||
    field === "landscapeId" ||
    field === "landscapeColumnId"
  ) {
    return null
  }

  // Composed filters
  const isCompanyOperator =
    isOneOf(field, COMPANY_OPERATORS_FIELDS) && isCompanyOperatorsFilter(value)

  const isLanguages =
    product === "people" && field === "Languages" && isLanguagesFilter(value)

  if (isCompanyOperator || isLanguages) {
    const [operator, innerValue] = value
    const composedTitle = isCompanyOperator
      ? COMPANY_OPERATOR_LABELS[operator as CompanyOperator]
      : LANGUAGE_PROFICIENCY_PROPS[operator as LanguageProficiency].label

    const innerOnChange = (val: Value | null) => {
      if (isNullish(val)) {
        onChange?.(null as Value)
        return
      }

      onChange?.([operator, val] as Value)
    }

    return (
      <AppliedFilterItem<P, Value>
        field={field}
        value={innerValue as Value}
        onChange={innerOnChange}
        onClickMoreItems={onClickMoreItems}
        composedTitle={composedTitle}
        product={product}
      />
    )
  }

  if (field === "CurrentTenure" && isCurrentTenureFilter(value)) {
    const [min, max] = value

    const subtitle = min && max ? "between" : min ? "at least" : "at most"

    return (
      <AppliedFilterItemComponent
        field={field}
        title={title}
        subtitle={subtitle}
        items={[min, max].filter(Boolean) as string[]}
        {...(onChange && {
          onRemove: (index) => {
            if (min && max) {
              const newValue = [min, max] as (string | null)[]
              newValue.splice(index, 1, null)
              onChange(newValue as Value)
              return
            }
            onChange(null)
          },
        })}
        onClickMoreItems={onClickMoreItems}
      />
    )
  }

  // Basic filters

  if (isNumericFilterRevamped(value)) {
    const [operator, min, max] = value

    if (isNullish(min) && isNullish(max)) return <></>

    if (operator === "between") {
      if (isNullish(min) || isNullish(max)) return <></>

      return (
        <AppliedFilterItemComponent
          field={field}
          title={title}
          subtitle="between"
          items={[min, max].map(
            (val) =>
              `${val.toLocaleString("en-US")}${
                typeof title === "string" && title.includes("%") ? "%" : ""
              }`
          )}
          {...(onChange && {
            onRemove: (index) =>
              onChange(
                index === 0
                  ? ([NUMERIC_OPERATORS.LTE, max, null] as Value)
                  : ([NUMERIC_OPERATORS.GTE, min, null] as Value)
              ),
          })}
          onClickMoreItems={onClickMoreItems}
        />
      )
    }

    const numericValue = min

    return (
      <AppliedFilterItemComponent
        field={field}
        title={title}
        items={[
          `${getOperatorLabel(operator)} ${numericValue.toLocaleString(
            "en-US"
          )}${typeof title === "string" && title.includes("%") ? "%" : ""}`,
        ]}
        {...(onChange && { onRemove: () => onChange(null) })}
        onClickMoreItems={onClickMoreItems}
      />
    )
  }

  if (isNumericFilter(value)) {
    const [operator, numericValue] = value

    if (isNullish(numericValue)) return <></>

    const isDateField =
      field?.toLowerCase().includes("date") ||
      field?.toLowerCase().includes("founded")

    return (
      <AppliedFilterItemComponent
        field={field}
        title={title}
        subtitle={
          ["HotSignals", "HotCompanies"].includes(field)
            ? "- signal count"
            : undefined
        }
        items={[
          `${getOperatorLabel(operator)} ${
            isDateField ? numericValue : numericValue.toLocaleString("en-US")
          }${typeof title === "string" && title.includes("%") ? "%" : ""}`,
        ]}
        {...(onChange && { onRemove: () => onChange(null) })}
        onClickMoreItems={onClickMoreItems}
      />
    )
  }

  if (isNumericRangeFilter(value)) {
    const [start, end] = value

    if (isNullish(start) || isNullish(end)) return <></>

    const isDateField =
      field?.toLowerCase().includes("date") ||
      field?.toLowerCase().includes("founded")

    return (
      <AppliedFilterItemComponent
        field={field}
        title={title}
        subtitle="between"
        items={value.map(
          (val) =>
            `${isDateField ? val.toString() : val.toLocaleString("en-US")}${
              typeof title === "string" && title.includes("%") ? "%" : ""
            }`
        )}
        {...(onChange && {
          onRemove: (index) =>
            onChange(
              index === 0
                ? ([NUMERIC_OPERATORS.LTE, end] as Value)
                : ([NUMERIC_OPERATORS.GTE, start] as Value)
            ),
        })}
        onClickMoreItems={onClickMoreItems}
      />
    )
  }

  if (isTextFilter(value)) {
    const [operator, items] = value

    if (isNullish(items)) return <></>

    return (
      <AppliedFilterItemComponent
        field={field}
        title={title}
        subtitle={operator.replaceAll(".", " ")}
        items={items}
        {...(onChange && {
          onRemove: (index) =>
            onChange(
              items.length > 1
                ? ([operator, R.remove(index, 1, items)] as Value)
                : null
            ),
        })}
        onClickMoreItems={onClickMoreItems}
      />
    )
  }

  if (isDateFilter(value)) {
    const [start, end] = value

    return (
      <AppliedFilterItemComponent
        field={field}
        title={title}
        subtitle={start}
        items={[formatDate(new Date(end))]}
        {...(onChange && { onRemove: () => onChange(null) })}
        onClickMoreItems={onClickMoreItems}
        highlight={isProgrammatic}
      />
    )
  }

  if (isDateRangeFilter(value)) {
    const [start, end] = dateRangeSchema.parse(value)

    if (isNullish(start) && !isNullish(end)) {
      return (
        <AppliedFilterItemComponent
          field={field}
          title={title}
          subtitle={"before"}
          items={[formatDate(new Date(end))]}
          {...(onChange && { onRemove: () => onChange(null) })}
          onClickMoreItems={onClickMoreItems}
        />
      )
    }

    if (!isNullish(start) && isNullish(end)) {
      return (
        <AppliedFilterItemComponent
          field={field}
          title={title}
          subtitle={"after"}
          items={[formatDate(new Date(start))]}
          {...(onChange && { onRemove: () => onChange(null) })}
          onClickMoreItems={onClickMoreItems}
        />
      )
    }

    if (!isNullish(start) && !isNullish(end)) {
      return (
        <AppliedFilterItemComponent
          field={field}
          title={title}
          subtitle={"between"}
          items={[formatDate(new Date(start)), formatDate(new Date(end))]}
          {...(onChange && { onRemove: () => onChange(null) })}
          onClickMoreItems={onClickMoreItems}
        />
      )
    }

    return <></>
  }

  if (isDateRollingRangeFilter(value)) {
    const [start, end, invert] = value

    return (
      <AppliedFilterItemComponent
        field={field}
        title={title}
        subtitle={invert ? "in the last" : "before the last"}
        items={[`${start} ${start == 1 ? end.slice(0, -1) : end}`]} // remove the s from the timeframe, if value it's 1
        {...(onChange && { onRemove: () => onChange(null) })}
        onClickMoreItems={onClickMoreItems}
      />
    )
  }

  if (isSetFilter(value)) {
    const [operator, items] = value

    if (isNullish(items)) return <></>

    const includedItems = items.filter(
      (item) => !item.startsWith(EXCLUDE_PREFIX)
    )

    return (
      <AppliedFilterItemComponent
        field={field}
        title={title}
        subtitle={[
          composedTitle,
          includedItems.length > 1 ? operator : undefined,
        ]
          .filter(Boolean)
          .join(" - ")}
        items={items || []}
        {...(onChange && {
          onRemove: (index) => {
            const [operator, items] = value

            const retValue = R.remove(index, 1, items || [])

            if (!hasLength(retValue)) {
              // return null if it's just the operator left
              onChange(null as Value)
              return
            }

            onChange([operator, retValue] as Value)
          },
          onExclude: (index) => {
            const [operator, items] = value

            const retValue = items?.map((item, i) => {
              if (i !== index) return item

              const isExcluded = item.startsWith(EXCLUDE_PREFIX)

              return isExcluded
                ? item.replace(EXCLUDE_PREFIX, "")
                : `${EXCLUDE_PREFIX}${item}`
            })

            if (!hasLength(retValue)) {
              // return null if it's just the operator left
              onChange(null as Value)
              return
            }

            onChange([operator, retValue] as Value)
          },
        })}
        onClickMoreItems={onClickMoreItems}
      />
    )
  }

  if (field === "b2x" && isArrayFilter(value)) {
    return (
      <AppliedFilterItemComponent
        field={field}
        title={title}
        items={value}
        {...(onChange && {
          onRemove: (index) => onChange(R.remove(index, 1, value) as Value),
        })}
        onClickMoreItems={onClickMoreItems}
      />
    )
  }

  if (isArrayFilter(value)) {
    return (
      <AppliedFilterItemComponent
        field={field}
        title={title}
        items={value}
        {...(onChange && {
          onRemove: (index) => onChange(R.remove(index, 1, value) as Value),
          onExclude: (index) =>
            onChange(
              value?.map((item, i) => {
                if (i !== index) return item

                const isExcluded = item.startsWith(EXCLUDE_PREFIX)

                return isExcluded
                  ? item.replace(EXCLUDE_PREFIX, "")
                  : `${EXCLUDE_PREFIX}${item}`
              }) as Value
            ),
        })}
        onClickMoreItems={onClickMoreItems}
      />
    )
  }

  if (isSingleValueFilter(value)) {
    return (
      <AppliedFilterItemComponent
        field={field}
        title={title}
        subtitle={
          ["HotSignals", "HotCompanies"].includes(field)
            ? "in the last"
            : ["AverageTenure"].includes(field)
            ? "at least"
            : undefined
        }
        items={[value]}
        {...(onChange && { onRemove: () => onChange(null) })}
        onClickMoreItems={onClickMoreItems}
        highlight={isProgrammatic}
      />
    )
  }

  if (isBooleanFilter(value)) {
    return (
      <AppliedFilterItemComponent
        field={field}
        title={title}
        items={[value ? "Yes" : "No"]}
        {...(onChange && { onRemove: () => onChange(null) })}
        onClickMoreItems={onClickMoreItems}
        highlight={isProgrammatic}
      />
    )
  }

  if (isGrowthFilter(value)) {
    const [period, [operator, minNumber, maxNumber]] = value

    if (isNullish(minNumber)) return <></>

    if (operator === "between") {
      if (isNullish(maxNumber)) return <></>

      return (
        <AppliedFilterItemComponent
          field={field}
          title={title}
          subtitle={`- Past ${GROWTH_PERIODS_LABELS[period]}`}
          items={[
            `${getOperatorLabel("gte")} ${minNumber}`,
            `${getOperatorLabel("lte")} ${maxNumber}`,
          ]}
          {...(onChange && {
            onRemove: (index) => {
              if (index === 0) {
                onChange([period, ["lte", maxNumber, null]] as Value)
              } else {
                onChange([period, ["gte", minNumber, null]] as Value)
              }
            },
          })}
          onClickMoreItems={onClickMoreItems}
        />
      )
    }

    return (
      <AppliedFilterItemComponent
        field={field}
        title={title}
        subtitle={`- Past ${GROWTH_PERIODS_LABELS[period]}`}
        items={[
          `${getOperatorLabel(operator)} ${minNumber}${
            typeof title === "string" && title.includes("%") ? "%" : ""
          }`,
        ]}
        {...(onChange && {
          onRemove: () => onChange(null as Value),
        })}
        onClickMoreItems={onClickMoreItems}
      />
    )
  }

  return <></>
}

type AppliedFilterItemComponentProps = {
  field: string
  title: string
  subtitle?: string
  items: string[]
  onRemove?: (index: number) => void
  onExclude?: (index: number) => void
  onClickMoreItems?: () => void
  highlight?: boolean
}

const ITEMS_VISIBLE_COUNT = 2

export const AppliedFilterItemComponent = ({
  field,
  title,
  subtitle,
  items,
  onRemove,
  onExclude,
  onClickMoreItems,
  highlight = false,
}: AppliedFilterItemComponentProps): JSX.Element => {
  const itemsVisible = items.slice(0, ITEMS_VISIBLE_COUNT)

  return (
    <VStack alignItems="start" spacing={1}>
      <Text fontSize="xx-small" color="gray.500" whiteSpace="nowrap">
        {title}
        {subtitle && (
          <Text as="span" color="brand.400" fontWeight="semibold">
            {" "}
            {subtitle}
          </Text>
        )}
      </Text>
      <Flex gap={2} alignItems="center">
        {itemsVisible.map((item, index) => (
          <AppliedFilterItemTag
            key={item}
            field={field}
            item={item}
            {...(onRemove && { onRemove: () => onRemove(index) })}
            {...(onExclude && { onExclude: () => onExclude(index) })}
            highlight={highlight}
          />
        ))}
        {itemsVisible.length < items.length && (
          <Button
            variant="link"
            fontSize="xs"
            onClick={onClickMoreItems}
            minW="fit-content"
            whiteSpace="nowrap"
          >
            +{items.length - itemsVisible.length}
          </Button>
        )}
      </Flex>
    </VStack>
  )
}

type AppliedFilterItemTagProps = {
  field: string
  item: string
  onRemove?: () => void
  onExclude?: () => void
  highlight?: boolean
}

export const AppliedFilterItemTag = ({
  field,
  item,
  onRemove,
  onExclude,
  highlight = false,
}: AppliedFilterItemTagProps): JSX.Element => {
  const navigation = useNavigation()
  const [isRemoving, setIsRemoving] = useState(false)
  const [isExcluding, setIsExcluding] = useState(false)
  const isLoading = navigation.state === "loading"

  useEffect(() => {
    if (navigation.state === "idle") {
      setIsRemoving(false)
    }
  }, [navigation.state])

  const isExcluded = item.startsWith(EXCLUDE_PREFIX)

  const product = useProduct()

  return (
    <Tag
      size="sm"
      variant="outline"
      color={highlight ? "green.800" : "initial"}
      outline="1px solid"
      outlineColor={highlight ? "green.400" : "gray.300"}
      boxShadow="none"
      bgColor={highlight ? "green.50" : isExcluded ? "red.200" : "transparent"}
      opacity={isLoading ? 0.4 : 1}
      textDecoration={isExcluded ? "line-through" : "none"}
      maxW="300px"
      overflow="hidden"
      textOverflow="ellipsis"
    >
      <TagLabel display="inline-flex" alignItems="center">
        {getOptionLabel(item, field, product)}
      </TagLabel>
      {onExclude &&
        (isLoading && isExcluding ? (
          <Spinner size="xs" ml="0.75rem" color="gray.400" />
        ) : (
          <TagCloseButton
            as={isExcluded ? HiPlus : HiNoSymbol}
            boxSize={3.5}
            isDisabled={isLoading}
            pointerEvents={isLoading ? "none" : "all"}
            cursor="pointer"
            onClick={() => {
              if (!isLoading) {
                setIsExcluding(true)
                onExclude()
              }
            }}
          />
        ))}
      {onRemove && (
        <>
          {isLoading && isRemoving ? (
            <Spinner size="xs" ml="0.75rem" color="gray.400" />
          ) : (
            <TagCloseButton
              isDisabled={isLoading}
              pointerEvents={isLoading ? "none" : "all"}
              onClick={() => {
                if (!isLoading) {
                  setIsRemoving(true)
                  onRemove()
                }
              }}
            />
          )}
        </>
      )}
    </Tag>
  )
}

const getOperatorLabel = (option: string): string => {
  switch (option) {
    case "gte":
      return "≥"
    case "gt":
      return ">"
    case "equals":
      return "="
    case "lt":
      return "<"
    case "lte":
      return "≤"
    default:
      return option
  }
}

type Props<P extends SpecterProducts> = {
  product: P
  advancedFilters: ReturnType<typeof useDisclosure>
}
export const AppliedFilters = <
  P extends SpecterProducts,
  Value extends ValueOf<SignalFilters[P]>
>({
  product,
  advancedFilters,
}: Props<P>): JSX.Element => {
  const [searchParams, setSearchParams] = useSafeSearchParams()

  const filters = useMemo(
    () => getProductSignalSearchParams(product, searchParams),
    [product, searchParams]
  )

  const items = Object.entries(filters)

  const sortedItems = [
    ...items.filter(([key]) => PROGRAMMATIC_FILTERS.includes(key)),
    ...items.filter(([key]) => !PROGRAMMATIC_FILTERS.includes(key)),
  ].filter(Boolean) as [string, Value][]

  return (
    <FlexOneLine
      gap={2}
      defaultHeight={38}
      alignItems="center"
      overflowIndicator={(overflowingCount) => (
        <>
          {Object.keys(filters).length > overflowingCount && (
            <FlexDivider orientation="vertical" my={0} />
          )}
          <Button
            size="xs"
            variant="link"
            ml={2}
            onClick={advancedFilters.onOpen}
            alignSelf="center"
          >
            {`${overflowingCount} more filter${
              overflowingCount > 1 ? "s" : ""
            }`}
          </Button>
        </>
      )}
    >
      {sortedItems.map(([key, value], index) => {
        const handleChange = (val: Value | null) => {
          let newFilters: typeof filters

          if (val === null) {
            newFilters = R.omit([key], filters)
          } else {
            newFilters = R.assoc(key, val, filters)
          }

          const appliedFilters = R.reject(R.isEmpty, newFilters)

          const newSearchParams = updateSearchParams(searchParams, {
            query: appliedFilters,
          })

          setSearchParams(newSearchParams)
        }

        return (
          <Flex gap={2} key={key}>
            {index !== 0 && <FlexDivider orientation="vertical" my={0} />}
            <AppliedFilterItem
              product={product}
              field={key}
              value={value as Value}
              onChange={handleChange}
              onClickMoreItems={advancedFilters.onOpen}
            />
          </Flex>
        )
      })}
    </FlexOneLine>
  )
}
