import { CustomerFocus } from "@prisma/client"
import {
  getCustomerFocusColor,
  getCustomerFocusName,
} from "~/utils/companySignals"
import { isOneOf } from "~/utils/isOneOf"
import { ColoredTag } from "../ColoredTag"

export const CustomerFocusTag = ({
  b2x,
  isPrimary = true,
}: {
  b2x: CustomerFocus | null
  isPrimary?: boolean
}) => {
  if (b2x?.includes("_")) {
    const [primary, secondary] = b2x.split("_")
    return (
      <>
        {isOneOf(primary, Object.values(CustomerFocus)) && (
          <CustomerFocusTag b2x={primary} isPrimary />
        )}
        {isOneOf(secondary, Object.values(CustomerFocus)) && (
          <CustomerFocusTag b2x={secondary} isPrimary={false} />
        )}
      </>
    )
  }

  return (
    <ColoredTag color={isPrimary ? getCustomerFocusColor(b2x) : "gray"}>
      {getCustomerFocusName(b2x)}
    </ColoredTag>
  )
}
