import { useQuery } from "@tanstack/react-query"
import { cacheKeys } from "../cacheKeys"

export const useCompanyDeliveryMonth = () => {
  return useQuery<string>(
    cacheKeys.companyDeliveryMonth,
    async () => {
      const res = await fetch("/api/company/delivery-month")

      return await res.json()
    },
    {
      staleTime: 60 * 60 * 1000, // 1 hour
    }
  )
}
