import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { useRef } from "react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import invariant from "~/utils/invariant"
import { cacheKeys } from "~/utils/cacheKeys"
import { NotificationBadge } from "~/components/NotificationBadge"
import { HiOutlineTrash } from "react-icons/hi2"

interface Props {
  items: string[]
  listId: string
  product: SpecterProducts
  onSuccess: () => void
  isSelectionMenu?: boolean
}

export const ListRemoveModal = ({
  items,
  product,
  listId,
  onSuccess,
  isSelectionMenu,
}: Props): JSX.Element | null => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const cancelRef = useRef(null)
  const queryClient = useQueryClient()
  const toast = useToast()

  const removeMutation = useMutation(
    async () => {
      const req = await fetch(`/api/lists/${product}/${listId}/remove`, {
        method: "delete",
        body: JSON.stringify(items),
      })

      invariant(req.ok, "Failed to remove items from list")
    },
    {
      async onSuccess() {
        queryClient.resetQueries(cacheKeys.userList(listId))
        await Promise.allSettled(
          items.map((id) =>
            queryClient.invalidateQueries(cacheKeys.signalAddedToList(id))
          )
        )
        toast({
          status: "success",
          title: "List items removed successfully.",
        })

        onSuccess()
        onClose()
      },
    }
  )

  if (items.length <= 0) {
    return null
  }

  return (
    <>
      {isSelectionMenu ? (
        <Tooltip label="Remove signals from list" openDelay={300}>
          <IconButton
            w={"full"}
            aria-label="Remove signals from list"
            icon={<HiOutlineTrash />}
            disabled={items.length <= 0 || removeMutation.isLoading}
            isLoading={removeMutation.isLoading}
            onClick={onOpen}
            colorScheme="red"
          />
        </Tooltip>
      ) : (
        <Box position="relative">
          <NotificationBadge value={items.length} />
          <Button variant="outline" size="sm" fontSize="xs" onClick={onOpen}>
            Remove Selected
          </Button>
        </Box>
      )}

      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        leastDestructiveRef={cancelRef}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Remove signals from list</AlertDialogHeader>
          <AlertDialogBody>
            <Text>
              Are you sure you want to remove these signals from this list?
            </Text>
          </AlertDialogBody>
          <AlertDialogFooter gap={2}>
            <Button variant="outline" ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="brand"
              onClick={() => removeMutation.mutate()}
              isLoading={removeMutation.isLoading}
              disabled={removeMutation.isLoading}
            >
              Yes, remove items
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
