import {
  COMPANY_HIGHLIGHTS_PROPS,
  INVESTOR_HIGHLIGHTS_PROPS,
} from "~/consts/signals"
import { CompanySignalFeedItem } from "~/utils/db/queries/company/types"
import { GetInvestorByID } from "./db/getInvestorById"

export const sortCompanyHighlights = (
  highlights: CompanySignalFeedItem["highlights"],
  newHighlights: CompanySignalFeedItem["newHighlights"] = []
) => {
  const ordered = highlights?.sort((a, b) => {
    const groupA = COMPANY_HIGHLIGHTS_PROPS[a]
    const groupB = COMPANY_HIGHLIGHTS_PROPS[b]
    return (groupA?.order ?? 0) - (groupB?.order ?? 0)
  })

  if (!newHighlights || !ordered) return ordered

  return [
    ...ordered.filter((h) => newHighlights.includes(h)),
    ...ordered.filter((h) => !newHighlights.includes(h)),
  ]
}

export const sortInvestorHighlights = (
  highlights: GetInvestorByID["InvestorHighlights"]
) => {
  return highlights?.sort((a, b) => {
    const indexA = Object.keys(INVESTOR_HIGHLIGHTS_PROPS).indexOf(a?.highlight)
    const indexB = Object.keys(INVESTOR_HIGHLIGHTS_PROPS).indexOf(b?.highlight)

    return indexA - indexB
  })
}
