import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"
import { useQuery } from "@tanstack/react-query"
import invariant from "~/utils/invariant"
import { GetSavedSearchById } from "~/routes/__protected/api/saved-searches/$id"
import { cacheKeys } from "../cacheKeys"

interface Params {
  onSuccess?: (data: GetSavedSearchById) => void
}

export const useSavedSearchQuery = ({ onSuccess }: Params = {}) => {
  const [searchParams] = useSafeSearchParams()
  const searchId = searchParams.get("searchId")

  return useQuery(
    cacheKeys.userSavedSearch(searchId ?? ""),
    async () => {
      invariant(typeof searchId === "string")

      const req = await fetch(`/api/saved-searches/${searchId}`)

      invariant(req.ok, "Failed to load saved search")

      const data: GetSavedSearchById = await req.json()

      return data
    },
    {
      enabled: typeof searchId === "string",
      onSuccess,
    }
  )
}
