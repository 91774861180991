import {
  Box,
  Flex,
  Center,
  IconButton,
  Text,
  Icon,
  Tooltip,
  Button,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { useState } from "react"
import { HiChevronUp, HiHeart, HiLockClosed } from "react-icons/hi"
import { useIntegrationMeta } from "~/utils/hooks/useIntegrationMeta"
import {
  getDataMatchingKey,
  getDataSourceKey,
  getFieldMappingKey,
} from "~/utils/integrationApp"
import { ProductItem } from "../SignalCard"
import { DataSource } from "./DataSource"

type IntegrationSignal<P extends SpecterProducts> = ProductItem<P> & {
  specter_url: string
  specter_source: string
}

type Props<P extends SpecterProducts> = {
  product: P
  integrationKey: string
  signals: IntegrationSignal<P>[]
  onComplete: () => void
}

export const IntegrationPush = <P extends SpecterProducts>({
  product,
  integrationKey,
  signals,
  onComplete,
}: Props<P>) => {
  const [stepIndex, setStepIndex] = useState(0)
  const integrationMeta = useIntegrationMeta(integrationKey)

  const nextStep = () => setStepIndex((prev) => prev + 1)
  const prevStep = () => setStepIndex((prev) => Math.max(0, prev - 1))
  const dataSourceKey = getDataSourceKey(product)
  const defaultDataSource =
    (dataSourceKey && integrationMeta.data?.[dataSourceKey]) ?? ""
  const fieldMappingKey = getFieldMappingKey(product)
  const defaultFieldMapping =
    (fieldMappingKey && integrationMeta.data?.[fieldMappingKey]) ?? ""
  const dataMatchingKey = getDataMatchingKey(product)
  const defaultDataMatching = (dataMatchingKey &&
    integrationMeta.data?.[dataMatchingKey]) ?? {
    specterField: "",
    integrationField: "",
  }

  return (
    <Box pos="relative">
      {stepIndex > 0 && (
        <IconButton
          variant="outline"
          size="sm"
          pos="absolute"
          top={0}
          left={0}
          m={3}
          aria-label="Previous step"
          icon={<HiChevronUp />}
          onClick={prevStep}
          zIndex={1}
        />
      )}

      {integrationMeta.data?.configLocked && (
        <Box pos="absolute" top={0} right={0} m={3} zIndex={1}>
          <Tooltip label="Speak to a team Admin to unlock or update this configration.">
            <Flex
              bgColor="brand.50"
              borderWidth={1}
              borderColor="brand.100"
              rounded="full"
              alignItems="center"
              gap={1}
              color="brand.500"
              fontWeight="semibold"
              fontSize="xs"
              px={2}
              py={1}
              shadow="0 2px 6px rgba(59, 130, 246, 0.2)"
            >
              <HiLockClosed /> Configuration Locked
            </Flex>
          </Tooltip>
        </Box>
      )}

      <Box h="calc(100vh - 300px)" overflow="hidden">
        <Box
          h="full"
          overflow="visible"
          transition="transform 0.3s ease-in-out"
          transform={`translateY(-${stepIndex * 100}%)`}
        >
          <DataSource
            configLocked={integrationMeta.data?.configLocked ?? false}
            defaultDataSource={defaultDataSource}
            defaultFieldMapping={defaultFieldMapping}
            defaultDataMatching={defaultDataMatching}
            signals={signals}
            product={product}
            integrationKey={integrationKey}
            nextStep={nextStep}
          />

          <Flex h="full" flexDir="column">
            <Center flex={1}>
              <Box textAlign="center">
                <Icon as={HiHeart} color="brand.500" fontSize="4xl" />
                <Text fontWeight="medium" fontSize="xl">
                  All done!
                </Text>
                <Text fontSize="sm" color="gray.600" pb={6}>
                  Thank you for using Specter integrations.
                </Text>
                <Button
                  size="sm"
                  variant="solid"
                  colorScheme="brand"
                  onClick={onComplete}
                >
                  Done
                </Button>
              </Box>
            </Center>
          </Flex>
        </Box>
      </Box>
    </Box>
  )
}
