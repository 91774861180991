import { Flex } from "@chakra-ui/react"
import { SearchesAndListsProducts } from "~/components"
import { TagNewSignal } from "~/components/UserSearchesPage/TagNewSignal"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"
import { useViewMode } from "~/utils/hooks/useViewMode"
import { cleanProgmaticFiltersFromQuery } from "~/utils/progmaticFilters"
import { isNullish } from "~/utils/values"
import { CellRendererProps } from "../Table/cellRenderers"

import { Center, Icon, Link } from "@chakra-ui/react"
import { Link as RemixLink } from "@remix-run/react"
import { HiGlobeAlt } from "react-icons/hi"
import { HiOutlineMagnifyingGlass } from "react-icons/hi2"
import { generateURLFactory } from "./SavedSearchesTable"

import {
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { HiExternalLink } from "react-icons/hi"
import { HiEllipsisHorizontal } from "react-icons/hi2"
import {
  DeleteSavedSearch,
  EditSavedSearch,
  ShareShavedSearch,
} from "~/components"
import { Reassign } from "~/components/UserList/Reassign"
import { RemoveAccessButton } from "~/components/UserSearchesPage/RemoveAccessButton"
import { SavedSearchesIndex } from "~/routes/__protected/api/saved-searches"
import { ListType } from "~/routes/__protected/user/lists/$product"
import { cacheKeys } from "~/utils/cacheKeys"
import { useUserPermissions } from "~/utils/hooks/useUserPermissions"
import { PromoteGlobalHub } from "../UserList/PromoteGlobalHub"
import { DuplicateSavedSearch } from "./DuplicateSearch"
import { HideListOrSearch } from "./HideListOrSearch"

import {
  createSearchQuery,
  createSearchQueryAll,
  createSearchQueryNew,
  createSearchQueryNewHighlights,
} from "~/components/UserSearchesPage/utils"

const lastViewedAtColumn = {
  countAllSignals: "lastViewedAt",
  countNewSignals: "newHighlightsLastViewedAt",
  countNewFundingHighlights: "newFundingHighlightsLastViewedAt",
  countNewGrowthHighlights: "newGrowthHighlightsLastViewedAt",
} as const

const isNewField = {
  countNewSignals: "isNew",
  countNewFundingHighlights: "isNewFundingHighlights",
  countNewGrowthHighlights: "isNewGrowthHighlights",
} as const

function getCreateSearchQuery(
  columnId: string | undefined,
  product: SearchesAndListsProducts
) {
  switch (columnId) {
    case "countAllSignals":
      return createSearchQueryAll
    case "countNewSignals":
      return createSearchQueryNew(product)
    case "countNewGrowthHighlights":
      return createSearchQueryNewHighlights(product, "HasNewGrowthHighlights")
    case "countNewFundingHighlights":
      return createSearchQueryNewHighlights(product, "HasNewFundingHighlights")
    default:
      return createSearchQuery
  }
}

export function RenderTagNewSignalsSearch({
  data,
  value,
  column,
}: CellRendererProps<`saved-searches.${SearchesAndListsProducts}`>) {
  const [, setSearchParams] = useSafeSearchParams()
  const viewMode = useViewMode()

  const columnId = column?.getColId() as string | undefined
  if (isNullish(value) || isNullish(data) || !columnId) return <></>

  const {
    query,
    sort,
    id: searchId,
    type: product,
    hasOwnerAccess,
    isGlobalHub,
  } = data ?? {}

  const isNew = data[isNewField[columnId as keyof typeof isNewField]] ?? false
  const rootQuery =
    typeof query === "object" ? cleanProgmaticFiltersFromQuery(query ?? {}) : {}

  const generateURL = generateURLFactory({
    setSearchParams,
    product,
    viewMode,
    query: rootQuery,
    sort,
    searchId,
    ...(hasOwnerAccess && !isGlobalHub && { edit: true }),
  })

  const [to, onNavigate] = generateURL(
    getCreateSearchQuery(columnId, product as SearchesAndListsProducts)
  )

  return (
    <Flex alignItems="center" height="100%" justifyContent="center">
      <TagNewSignal
        key={`saved-search-query-id-${searchId}`}
        queryId={searchId}
        count={value}
        to={to}
        isNew={isNew}
        product={product}
        onClick={() => {
          // this will only be set if params are too big
          onNavigate()
        }}
        column={lastViewedAtColumn[columnId as keyof typeof lastViewedAtColumn]}
      />
    </Flex>
  )
}

export function RenderSearchName({
  data,
  value,
}: CellRendererProps<`saved-searches.${SearchesAndListsProducts}`>) {
  // const aiSearchDescription = useAi(`
  // Create a short description for these Saved Searches, based on the name of the search (given by the user) and the filters that have been applied.
  // Make it short (1 short sentence, max. 8 words) and complimentary to the Name, without including the name in the description, without verbs, without adjectives, just descriptive.
  // Search Name: ${data?.name}
  // Filters: ${JSON.stringify(data?.query)}`)

  // console.log(aiSearchDescription.data)

  const [, setSearchParams] = useSafeSearchParams()
  const viewMode = useViewMode()

  if (!data) return <></>

  const generateURL = generateURLFactory({
    setSearchParams,
    product: data.type,
    viewMode,
    query: data.query,
    sort: data.sort,
    searchId: data.id,
    ...(data.hasOwnerAccess && !data.isGlobalHub && { edit: true }),
  })

  const [to, onNavigate] = generateURL(
    getCreateSearchQuery("name", data.type as SearchesAndListsProducts)
  )

  const baseColor = data.isGlobalHub ? "yellow" : "gray"

  return (
    <Flex alignItems="center" height="100%" gap={3}>
      <Center
        w={8}
        h={8}
        bgColor={baseColor + ".50"}
        color={baseColor + ".500"}
        fontSize="md"
        rounded="lg"
        borderWidth={1}
        borderColor={baseColor + ".100"}
      >
        <Icon as={data.isGlobalHub ? HiGlobeAlt : HiOutlineMagnifyingGlass} />
      </Center>
      <Link
        as={RemixLink}
        to={to}
        onClick={() => {
          // this will only be set if params are too big
          onNavigate()
        }}
        fontWeight="semibold"
        fontSize="sm"
        color="gray.800"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        flexShrink={1}
        display="block"
      >
        {value}
      </Link>
      {/* <Text fontSize="xs" color="gray.400" lineHeight="normal">
        {aiSearchDescription.data}
      </Text> */}
    </Flex>
  )
}

export function SavedSearchActions<P extends SearchesAndListsProducts>({
  savedSearch,
  product,
}: {
  savedSearch: SavedSearchesIndex["items"][number]
  product: P
}) {
  const user = useUserPermissions()
  const queryClient = useQueryClient()
  const [searchParams, setSearchParams] = useSafeSearchParams()
  const viewMode = useViewMode()

  const generateURL = generateURLFactory({
    setSearchParams,
    product,
    viewMode,
    query: savedSearch.query,
    sort: savedSearch.sort,
    searchId: savedSearch.id,
    ...(savedSearch.hasOwnerAccess &&
      !savedSearch.isGlobalHub && { edit: true }),
  })

  const [to, onNavigate] = generateURL(getCreateSearchQuery("name", product))

  return (
    <>
      <Tooltip label="Open Search in Feed" openDelay={300}>
        <IconButton
          aria-label="Open Search in Feed"
          icon={<HiExternalLink />}
          as={RemixLink}
          to={to}
          onClick={(e) => {
            e.stopPropagation()
            onNavigate()
          }}
        />
      </Tooltip>
      <Popover placement="auto-end">
        <PopoverTrigger>
          <IconButton aria-label="More" icon={<HiEllipsisHorizontal />} />
        </PopoverTrigger>
        <Portal>
          <PopoverContent p={2} maxW={220}>
            {savedSearch.hasOwnerAccess && (
              <>
                <EditSavedSearch savedSearch={savedSearch} product={product} />
                <ShareShavedSearch
                  queryId={savedSearch.id}
                  onClose={() => {
                    queryClient.invalidateQueries(
                      cacheKeys.userSavedSearches(
                        product,
                        searchParams.toString()
                      )
                    )
                  }}
                />
              </>
            )}

            <HideListOrSearch id={savedSearch.id} type={ListType.savedSearch} />

            <DuplicateSavedSearch
              id={savedSearch.id.toString()}
              product={product}
            />

            {savedSearch.hasOwnerAccess && (
              <DeleteSavedSearch queryId={savedSearch.id} product={product} />
            )}

            {savedSearch.hasBeenShared && (
              <RemoveAccessButton queryId={savedSearch.id} product={product} />
            )}

            {savedSearch.hasOwnerAccess && user.data?.isAdmin && (
              <>
                <Text color="gray.400" pt={2} fontSize="xs">
                  Admin
                </Text>
                <PromoteGlobalHub
                  listType={ListType.savedSearch}
                  id={savedSearch.id}
                  isGlobalHub={savedSearch.isGlobalHub}
                  product={product}
                />
                <Reassign
                  listType={ListType.savedSearch}
                  id={savedSearch.id}
                  product={product}
                />
              </>
            )}
          </PopoverContent>
        </Portal>
      </Popover>
    </>
  )
}
