import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { ChangeEventHandler } from "react"
import { PiSortAscendingBold, PiSortDescendingBold } from "react-icons/pi"
import { SortDirection } from "~/utils/db/queries/types"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"
import { getSignalFieldTitleString } from "~/utils/signal"
import { updateSearchParams } from "~/utils/updateSearchParams"
import { ReactSelect } from "../ReactSelect"
import { signalSortFields } from "../Table/TableHeader"

interface Props<T extends readonly string[]> {
  fields: T
}

export function SignalSort<T extends readonly string[]>({
  fields,
}: Props<T>): JSX.Element {
  const [searchParams, setSearchParams] = useSafeSearchParams()

  const [field, direction] = getSortFromParams(searchParams)

  const changeSort = (newSortBy: string | null) => {
    const newSearchParams = updateSearchParams(searchParams, {
      sort: { ...(newSortBy ? { [newSortBy]: direction ?? "desc" } : {}) },
    })

    setSearchParams(newSearchParams)
  }

  const changeDirection: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!field) return
    const newSortDirection = e.target.checked ? "asc" : "desc"

    const newSearchParams = updateSearchParams(searchParams, {
      sort: {
        [field]: newSortDirection,
      },
    })

    setSearchParams(newSearchParams)
  }

  return (
    <VStack spacing={3} alignItems="stretch" p={4}>
      <Heading as="h2" size="xs">
        Sort Results
      </Heading>

      <FormControl>
        <ReactSelect
          placeholder="Sort by"
          isSearchable
          isClearable
          options={fields?.map((field) => ({
            label: getSignalFieldTitleString(field),
            value: field,
          }))}
          value={
            field
              ? { label: getSignalFieldTitleString(field), value: field }
              : null
          }
          onChange={(option) => {
            if (option?.value) {
              changeSort(option.value)
            } else {
              changeSort(null)
            }
          }}
        />
      </FormControl>

      <FormControl display="flex" alignItems="center" gap={2}>
        <FormLabel htmlFor="direction" m={0}>
          Descending
        </FormLabel>
        <Switch
          id="direction"
          onChange={changeDirection}
          isChecked={direction === "asc"}
        />
        <FormLabel htmlFor="direction" m={0}>
          Ascending
        </FormLabel>
      </FormControl>
    </VStack>
  )
}

type SignalSortMenuProps<P extends SpecterProducts> = {
  product: P
  onOpen?: () => void
  warningOnNoFilters?: boolean
}
export const SignalSortMenu = <P extends SpecterProducts>({
  product,
  onOpen,
  warningOnNoFilters,
}: SignalSortMenuProps<P>) => {
  const [searchParams] = useSafeSearchParams()

  const [field, direction] = getSortFromParams(searchParams)

  const sortOptions = signalSortFields(product) as string[]
  return (
    <Popover isLazy>
      <PopoverTrigger>
        <Button
          variant="dashed"
          leftIcon={
            direction === "desc" ? (
              <PiSortAscendingBold />
            ) : (
              <PiSortDescendingBold />
            )
          }
          onClick={onOpen}
          pos="relative"
        >
          Sort
          {field && (
            <Box
              boxSize={2}
              bgColor="brand.500"
              rounded="full"
              pos="absolute"
              top={0}
              right={0}
              transform="translate(25%, -25%)"
            />
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        width="sm"
        mr={3}
        ml={"200px"}
        _focus={{
          boxShadow: "none",
        }}
      >
        <SignalSort fields={sortOptions} />
        {warningOnNoFilters && (
          <Text fontSize="xs" color="orange.500" p={3} pt={0}>
            ⚠️ Sorting without filters may result in long loading times. Apply
            some filters before sorting.
          </Text>
        )}
      </PopoverContent>
    </Popover>
  )
}

export const getSortFromParams = (searchParams: URLSearchParams) => {
  return (Object.entries(JSON.parse(searchParams.get("sort") ?? "{}"))[0] ??
    []) as [string, SortDirection]
}
