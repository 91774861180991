import {
  Button,
  ButtonProps,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuList,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { Link } from "@remix-run/react"
import { useQuery } from "@tanstack/react-query"
import { HiChevronDown } from "react-icons/hi2"
import { FlexOneLine } from "~/components/FlexOneLine"
import { createSearchQuery } from "~/components/UserSearchesPage/utils"
import { cacheKeys } from "~/utils/cacheKeys"
import { useAnalytics } from "~/utils/hooks/useAnalytics"
import { useEnv } from "~/utils/hooks/useEnv"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"
import { useViewMode } from "~/utils/hooks/useViewMode"
import { MenuItem } from "../MenuItem"
import { generateURLFactory } from "../UserSearchesPage/SavedSearchesTable"
import {
  PRODUCT_SUGGESTED_SEARCHES,
  SuggestedSearchMenuItemSchema,
  SuggestedSearchSchema,
} from "./schemas"

type Props = {
  product: SpecterProducts
}

const buttonVariant: ButtonProps["variant"] = "outline"

export const SuggestedSearches = ({ product }: Props) => {
  const sections = PRODUCT_SUGGESTED_SEARCHES[product]
  const [searchParams, setSearchParams] = useSafeSearchParams()

  const analytics = useAnalytics()
  const env = useEnv()

  const viewMode = useViewMode()

  return (
    <FlexOneLine gap={1}>
      {Object.entries(sections).map(([section, searches]) => {
        if (typeof searches === "function") {
          return (
            <AsyncMenu
              key={section}
              section={section}
              fetchSearches={searches}
              product={product}
            />
          )
        }

        if (!Array.isArray(searches)) {
          const { query, sort, searchId } = searches as SuggestedSearchSchema

          const generateURL = generateURLFactory({
            searchParams,
            setSearchParams,
            product,
            viewMode,
            query,
            sort,
            searchId,
          })

          const [to, onNavigate] = generateURL(createSearchQuery)

          return (
            <Button
              key={section}
              as={Link}
              to={to}
              shadow="none"
              variant={buttonVariant}
              onClick={async () => {
                analytics.track("Used a Suggested Filter", {
                  product,
                  label: section,
                  searchURL: `${env.APP_URL}${to}`,
                  query,
                })

                // this will only be set if params are too big
                onNavigate()
              }}
            >
              {section}
            </Button>
          )
        }

        return (
          <SuggestedSearchesMenu
            key={section}
            section={section}
            searches={searches}
            product={product}
          />
        )
      })}
    </FlexOneLine>
  )
}

const AsyncMenu = ({
  section,
  fetchSearches,
  product,
}: {
  section: string
  fetchSearches: () => Promise<SuggestedSearchMenuItemSchema[]>
  product: SpecterProducts
}) => {
  const searchesDataQuery = useQuery<SuggestedSearchMenuItemSchema[]>(
    cacheKeys.suggestedSearchesMenu(section),
    fetchSearches
  )

  if (searchesDataQuery.isLoading || searchesDataQuery.isError) {
    return null
  }

  return (
    <SuggestedSearchesMenu
      section={section}
      searches={searchesDataQuery.data}
      product={product}
    />
  )
}

export const SuggestedSearchesMenu = ({
  section,
  searches,
  product,
}: {
  section: string
  searches: SuggestedSearchMenuItemSchema[]
  product: SpecterProducts
}) => {
  const viewMode = useViewMode()
  const [searchParams, setSearchParams] = useSafeSearchParams()
  const analytics = useAnalytics()
  const env = useEnv()

  return (
    <Menu key={section}>
      {({ isOpen }) => (
        <>
          <MenuButton as={Button} variant={buttonVariant} shadow="none">
            <Flex alignItems="center">
              {section}
              <Icon
                as={HiChevronDown}
                ml={2}
                transform={isOpen ? "rotateX(180deg)" : ""}
                transition="transform 0.2s"
              />
            </Flex>
          </MenuButton>

          <MenuList p={2}>
            {searches.map(({ label, query, sort, searchId }, i) => {
              const generateURL = generateURLFactory({
                searchParams,
                setSearchParams,
                product,
                viewMode,
                query,
                sort,
                searchId,
              })

              const [to, onNavigate] = generateURL(createSearchQuery)

              return (
                <MenuItem
                  key={i}
                  as={Link}
                  to={to}
                  onClick={async () => {
                    analytics.track("Used a Suggested Filter", {
                      product,
                      label,
                      searchURL: `${env.APP_URL}${to}`,
                      query,
                    })

                    // this will only be set if params are too big
                    onNavigate()
                  }}
                >
                  {label}
                </MenuItem>
              )
            })}
          </MenuList>
        </>
      )}
    </Menu>
  )
}
