import { Box, Flex, keyframes, Text, TextProps } from "@chakra-ui/react"
import { isAfter, sub } from "date-fns"
import numeral from "numeral"
import { StrategicSignalType } from "~/consts/strategicSignals"
import { TalentSignalStatus } from "~/consts/talentSignals"
import { OperatingStatus, OPERATING_STATUS_LABEL } from "~/utils/signal"
import { getColorFromSignalType } from "~/utils/strategicSignals"
import { getColorFromTalentSignalStatus } from "~/utils/talentSignals"
import { ColoredTag } from "../ColoredTag"

interface Props {
  label: string
  color: TextProps["color"]
  fontSize?: TextProps["fontSize"]
  noBlinking?: boolean
}

export const SignalStatusTalent = ({
  signalStatus,
}: {
  signalStatus: TalentSignalStatus
}): JSX.Element => {
  const color = getColorFromTalentSignalStatus(signalStatus)

  return <SignalStatus label={signalStatus} color={`${color}.300`} />
}

export const isRecentFunded = (lastFundingDate: Date | string) => {
  return lastFundingDate
    ? isAfter(
        new Date(lastFundingDate),
        sub(new Date(), {
          months: 9,
        })
      )
    : false
}

export const SignalStatusCompany = ({
  lastFundingType,
  lastFundingDate,
  lastFundingAmount,
}: {
  lastFundingType?: string | null
  lastFundingDate: Date | string | null
  lastFundingAmount: number | null
}): JSX.Element => {
  if (lastFundingDate !== null) {
    return (
      <Text
        as="h3"
        color="gray.500"
        fontSize="xs"
        display="flex"
        alignItems="baseline"
        gap={2}
        whiteSpace="nowrap"
      >
        {lastFundingType ? (
          <>
            <ColoredTag
              color="gray"
              verticalAlign="text-bottom"
              whiteSpace="nowrap"
            >
              {lastFundingType}
            </ColoredTag>
            {"–"}
          </>
        ) : (
          "Last Funding Amount: "
        )}
        <Text
          as="span"
          color="gray.800"
          fontSize="xs"
          fontWeight="bold"
          whiteSpace="nowrap"
        >
          {lastFundingAmount
            ? `$${numeral(lastFundingAmount).format("0,0a")}`
            : "N/A"}
        </Text>
      </Text>
    )
  }

  return <></>
}

export const SignalStatusStrategic = ({
  signalType,
}: {
  signalType: StrategicSignalType
}): JSX.Element => {
  const color = getColorFromSignalType(signalType)
  return <SignalStatus label={signalType} color={color} />
}

export const SignalStatus = ({
  label,
  color,
  fontSize = "xs",
  noBlinking,
}: Props): JSX.Element => {
  const animation = keyframes`
    from {
      transform: scale(0.5);
      opacity: 0.6
    }

    to {
      transform: scale(1.3);
      opacity: 0
    }
  `

  const slowlyBlink = `${animation} infinite 1.6s`

  return (
    <Flex gap={2} alignItems="center">
      {!noBlinking && (
        <Box
          width="fit-content"
          position="relative"
          _after={{
            content: '""',
            position: "absolute",
            w: 2,
            h: 2,
            rounded: "full",
            bgColor: color,
            inset: 0,
            margin: "auto",
          }}
        >
          <Box
            w={4}
            h={4}
            rounded="full"
            bgColor={color}
            animation={slowlyBlink}
          />
        </Box>
      )}

      <Text
        color={color}
        fontSize={fontSize}
        fontWeight="semibold"
        whiteSpace="nowrap"
      >
        {label}
      </Text>
    </Flex>
  )
}

// The logic for Last Funding Status is:
// 1. If there is highlights data, then use the highlights data to determine the status
// 2. If there is no highlights data, then use the lastFundingAmount, lastFundingDate, and totalFundingAmount to determine the status
//    a. If there is no lastFundingAmount, lastFundingDate, and totalFundingAmount, then the status is "No Funding"
//    b. If there is a lastFundingDate and it is recent, then the status is "Recent Funding"
//    c. If there is a lastFundingDate and it is not recent, then the status is "No Recent Funding"
export const LastFundingStatus = ({
  lastFundingAmount,
  lastFundingDate,
  totalFundingAmount,
  highlights,
}: {
  lastFundingAmount: number | null
  lastFundingDate: Date | string | null
  totalFundingAmount: number | null
  highlights?: string[] | null
}): JSX.Element => {
  // * Prioritize highlights data
  if (highlights?.includes("recent_funding")) {
    return <SignalStatus label="Recent Funding" color="yellow.500" />
  }

  if (highlights?.includes("no_recent_funding")) {
    return <SignalStatus label="No Recent Funding" color="green.300" />
  }

  if (highlights?.includes("no_funding")) {
    return <SignalStatus label="No Funding" color="purple.400" />
  }

  // * If there is no highlights data, then use the lastFundingAmount, lastFundingDate, and totalFundingAmount
  if (!lastFundingAmount && !lastFundingDate && !totalFundingAmount) {
    return <SignalStatus label="No Funding" color="purple.400" />
  }

  if (lastFundingDate) {
    if (isRecentFunded(lastFundingDate)) {
      return <SignalStatus label={"Recent Funding"} color={"yellow.500"} />
    }
  }

  return <SignalStatus label={"No Recent Funding"} color={"green.300"} />
}

export const OperatingStatusInfo = ({
  operatingStatus,
  noBlinking,
}: {
  operatingStatus: OperatingStatus
  noBlinking?: boolean
}): JSX.Element => {
  const colors: Record<OperatingStatus, string> = {
    acquired: "purple.400",
    active: "green.400",
    closed: "red.400",
    ipo: "blue.400",
  }

  return (
    <SignalStatus
      label={OPERATING_STATUS_LABEL[operatingStatus]}
      color={colors[operatingStatus]}
      noBlinking={noBlinking}
    />
  )
}
