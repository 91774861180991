import {
  Box,
  Center,
  Flex,
  FlexProps,
  Icon,
  Kbd,
  Spacer,
  Spinner,
  Tag,
} from "@chakra-ui/react"
import { useLocation } from "@remix-run/react"
import { Link } from "@remix-run/react"
import { ReactNode } from "react"
import { IconType } from "react-icons"
import { BETA_FEATURED_ROUTES, NEW_FEATURED_ROUTES } from "../Header"

export interface MenuItemProps extends FlexProps {
  children?: ReactNode
  to?: string
  onClick?: () => void
  activePath?: string | string[]
  isActive?: boolean
  icon?: IconType
  isLoading?: boolean
  isDisabled?: boolean
  hideActiveDot?: boolean
  shortcut?: () => ReactNode
}

export const MenuItem = ({
  children,
  to,
  icon,
  activePath,
  isActive: controlledIsActive,
  onClick,
  isLoading,
  isDisabled,
  hideActiveDot,
  shortcut,
  ...props
}: MenuItemProps): JSX.Element => {
  const location = useLocation()
  const isActive =
    controlledIsActive ||
    (activePath &&
      (activePath === "/"
        ? location.pathname === "/"
        : Array.isArray(activePath)
        ? activePath.some((path) => location.pathname.includes(path))
        : location.pathname.includes(activePath)))

  const isNew = NEW_FEATURED_ROUTES.includes(to ?? "")
  const isExternal = to?.includes("http")
  const isBeta = BETA_FEATURED_ROUTES.some((route) => to?.includes(route))

  return (
    <Flex
      {...(to && !isDisabled && { as: Link, to })}
      {...(isExternal && { target: "_blank" })}
      {...(isDisabled ? { pointerEvents: "none" } : { onClick })}
      fontWeight="medium"
      alignItems="center"
      color={isActive ? "brand.700" : isDisabled ? "gray.300" : "gray.600"}
      _hover={{ bgColor: "gray.100" }}
      bgColor={isActive ? "gray.100" : undefined}
      transition="all 0.3s"
      fontSize="sm"
      py={1.5}
      px={1.5}
      gap={2}
      cursor="pointer"
      rounded="lg"
      lineHeight={1.1}
      {...props}
    >
      {icon !== undefined && (
        <Center boxSize={4}>
          <Icon as={icon} fontSize="md" />
        </Center>
      )}

      {children}
      {isLoading && (
        <Spinner colorScheme={isActive ? "brand" : "gray"} boxSize={2.5} />
      )}
      <Spacer />
      {isNew && (
        <Tag colorScheme="brand" size="xs" my={-2}>
          New
        </Tag>
      )}
      {isBeta && (
        <Tag colorScheme="brand" size="xs" my={-2}>
          Beta
        </Tag>
      )}

      {shortcut && (
        <Kbd w="auto" px={0.5}>
          {shortcut()}
        </Kbd>
      )}

      {!hideActiveDot && isActive && (
        <Box boxSize={1} bgColor="brand.500" rounded="full" mr={3} />
      )}
    </Flex>
  )
}
