import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useFormik } from "formik"
import { z } from "zod"
import { toFormikValidationSchema } from "zod-formik-adapter"
import { CreateListByProduct } from "~/routes/__protected/api/lists/$product/create"
import { cacheKeys } from "~/utils/cacheKeys"
import { useAnalytics } from "~/utils/hooks/useAnalytics"
import invariant from "~/utils/invariant"

interface Props {
  isOpen: boolean
  onClose: () => void
  product: SpecterProducts
}

const createSchema = z.object({
  name: z.string(),
  product: z.nativeEnum(SpecterProducts),
})

const validationSchema = toFormikValidationSchema(createSchema)

type FormValues = z.infer<typeof createSchema>

export const ListCreateModal = ({
  isOpen,
  onClose,
  product,
}: Props): JSX.Element => {
  const queryClient = useQueryClient()
  const analytics = useAnalytics()

  const createMutation = useMutation<CreateListByProduct, unknown, FormValues>(
    async ({ product, name }) => {
      const req = await fetch(`/api/lists/${product}/create`, {
        method: "post",
        body: JSON.stringify({ name, isPublic: false }),
      })

      invariant(req.ok, "Failed to create new list")

      return await req.json()
    },
    {
      async onSuccess(data) {
        await queryClient.refetchQueries(cacheKeys.userLists({ product }))

        analytics.track("Created List", {
          product,
          listId: data.id,
          name: data.name,
        })
      },
      onSettled() {
        formik.resetForm()
        onClose()
      },
    }
  )

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      name: "",
      product,
    },
    onSubmit(values) {
      createMutation.mutate(values)
    },
  })

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <form onSubmit={formik.handleSubmit}>
          <ModalHeader>Create new List</ModalHeader>
          <ModalBody>
            <FormControl isInvalid={!!formik.errors.name}>
              <FormLabel htmlFor="name">List Name</FormLabel>
              <Input
                id="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                name="name"
              />
              <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter gap={2}>
            <Button variant="outline" onClick={onClose} size="sm">
              Cancel
            </Button>
            <Button
              size="sm"
              type="submit"
              colorScheme="brand"
              variant="solid"
              isLoading={createMutation.isLoading}
              disabled={createMutation.isLoading || !formik.isValid}
            >
              Create
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
