import { ReactElement, ReactNode } from "react"
import { isNullish } from "./values"

export const isEmpty = (node: ReactNode): boolean => {
  if (isNullish(node)) {
    return true
  }

  if (typeof node === "string") {
    return node.trim() === ""
  }

  if (Array.isArray(node)) {
    return node.every(isEmpty)
  }

  if (typeof node === "number") {
    return false
  }

  if (typeof node === "boolean") {
    return true
  }

  if ((node as ReactElement)?.props?.children) {
    return isEmpty((node as ReactElement).props.children)
  }

  return false
}
