import { useCallback, useEffect } from "react"

export const useScroll = ({
  onScrollEnd,
  onScroll,
  target,
}: {
  onScroll?: (pos: number) => void
  onScrollEnd?: (pos: number) => void
  target?: HTMLElement
} = {}) => {
  const getScrollPosition = useCallback(
    () =>
      (target
        ? target.scrollTop
        : window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop) || 0,
    [target]
  )

  useEffect(() => {
    const eventElement = target ?? document

    const handleScroll = () => {
      onScroll?.(getScrollPosition())
    }

    const handleScrollEnd = () => {
      onScrollEnd?.(getScrollPosition())
    }

    eventElement.addEventListener("scroll", handleScroll)
    eventElement.addEventListener("scrollend", handleScrollEnd)

    return () => {
      eventElement.removeEventListener("scroll", handleScroll)
      eventElement.removeEventListener("scrollend", handleScrollEnd)
    }
  }, [getScrollPosition, onScroll, onScrollEnd, target])
}
