import {
  Box,
  Button,
  Center,
  Spinner,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { useMutation } from "@tanstack/react-query"
import { useState } from "react"
import { HiOutlineMap } from "react-icons/hi"
import { useLandscapesEditable } from "~/utils/hooks/useLandscape"
import invariant from "~/utils/invariant"
import { ContextModal, ContextModalItem } from "../ContextModal"
import { MenuItem } from "../MenuItem"

interface Props {
  listId: string
  product: SpecterProducts
}

export const ListLandscapeImport = ({ listId, product }: Props) => {
  const modalDisclosure = useDisclosure()
  const [activeIndex, setActiveIndex] = useState(0)
  const toast = useToast()

  const landscapesQuery = useLandscapesEditable(product, modalDisclosure.isOpen)
  const selectedOption = landscapesQuery.data?.[activeIndex]

  const addMutation = useMutation({
    mutationFn: async () => {
      const columnId = selectedOption?.id

      invariant(columnId, "Column ID not found")

      const req = await fetch(`/api/landscapes/import/${columnId}`, {
        method: "POST",
        body: JSON.stringify({
          query: `{"listId":"${listId}"}`,
          sort: "asc",
        }),
      })

      if (!req.ok) {
        throw new Error("Failed to add list to landscape")
      }
    },
    onSuccess: () => {
      toast({
        title: "List added to landscape",
        description: "The list has been added to the landscape",
        status: "success",
      })
    },
  })

  return (
    <>
      <MenuItem icon={HiOutlineMap} onClick={modalDisclosure.onOpen}>
        Add to Landscape
      </MenuItem>

      <ContextModal
        isOpen={modalDisclosure.isOpen}
        onClose={modalDisclosure.onClose}
        title="Add to Landscape"
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        actionButton={() => (
          <>
            {selectedOption && (
              <Button
                colorScheme="brand"
                onClick={() => addMutation.mutate()}
                isLoading={addMutation.isLoading}
                isDisabled={addMutation.isLoading}
              >
                Add to Landscape
              </Button>
            )}
          </>
        )}
      >
        {landscapesQuery.isLoading && (
          <Center>
            <Spinner />
          </Center>
        )}

        {landscapesQuery.isSuccess && (
          <Box h="calc(100dvh - 400px)" overflow="auto" px={4} py={2}>
            {landscapesQuery.data?.map((column, index) => {
              return (
                <ContextModalItem key={column.id} index={index}>
                  {column.Landscape.name + " > " + column.name}
                </ContextModalItem>
              )
            })}
          </Box>
        )}
      </ContextModal>
    </>
  )
}
