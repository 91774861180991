import { Box } from "@chakra-ui/react"

export const IconLandscapeAdd = (props: any) => {
  return (
    <Box as="svg" viewBox="0 0 19.5 19.23" {...props}>
      <path
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
        d="M13.25,18.36l4.88-2.44c.38-.19.62-.58.62-1.01V2.44c0-.84-.88-1.38-1.63-1.01l-3.87,1.93c-.32.16-.69.16-1.01,0L7.25.87c-.32-.16-.69-.16-1.01,0L1.37,3.31c-.38.19-.62.58-.62,1.01v12.49c0,.84.88,1.38,1.63,1.01l3.87-1.93c.32-.16.69-.16,1.01,0l4.99,2.5c.32.16.69.16,1.01,0h0ZM9.75,6.12v7M13.25,9.62h-7"
      />
    </Box>
  )
}
