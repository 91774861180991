import { SpecterProducts } from "@prisma/client"
import * as R from "ramda"
import { TALENT_SIGNAL_STATUS } from "~/consts/talentSignals"
import { getProductSignalSearchParams } from "./hooks/useProductFilters"

export const getColorFromTalentSignalStatus = (status: string): string => {
  switch (status) {
    case TALENT_SIGNAL_STATUS.LIVE:
      return "green"
    case TALENT_SIGNAL_STATUS.STEALTH:
      return "purple"
    case TALENT_SIGNAL_STATUS.OUT_OF_STEALTH:
      return "orange"
    case TALENT_SIGNAL_STATUS.HR:
      return "pink"
    default:
      return "gray"
  }
}

export const countAppliedTalentSignalFilters = (
  searchParams: URLSearchParams
) => {
  const allFilters = getProductSignalSearchParams(
    SpecterProducts.talent,
    searchParams
  )
  const appliedFilters = R.reject(R.isEmpty, allFilters)

  return Object.keys(appliedFilters).length
}
