import { Avatar, Flex, Icon, Text } from "@chakra-ui/react"
import {
  HiGlobe,
  HiGlobeAlt,
  HiLockClosed,
  HiOutlineLockOpen,
} from "react-icons/hi"
import { getUserAvatarSrc } from "~/utils/getUserAvatarSrc"
import { BsBroadcastPin } from "react-icons/bs"
import { SavedSearchVisibility } from "~/routes/__protected/api/saved-searches"

export function VisibilityStatus({
  visibility,
}: {
  visibility: SavedSearchVisibility
}): JSX.Element {
  if (visibility.type == "globalHubAdmin") {
    return (
      <Flex gap={1}>
        <Icon as={HiGlobeAlt} color="gray.400" />
        <Text fontSize="xs">{visibility.status}</Text>
      </Flex>
    )
  }

  if (visibility.type == "globalHubUser") {
    return (
      <Flex gap={1}>
        <Icon as={BsBroadcastPin} color="gray.400" />
        <Text fontSize="xs">{visibility.status}</Text>
      </Flex>
    )
  }

  if (visibility.type == "teamShare") {
    return (
      <Flex gap={1}>
        <Icon as={HiGlobe} color="gray.400" />
        <Text fontSize="xs">{visibility.status}</Text>
      </Flex>
    )
  }

  if (visibility.type == "privateShare") {
    return (
      <Flex gap={1} alignItems="center">
        <Icon as={HiOutlineLockOpen} color="gray.400" />
        <Text fontSize="xs" mr={3}>
          Privately shared with:
        </Text>
        {visibility.sharedWith?.map((share) => (
          <Avatar
            ml={-3}
            size="xs"
            key={share.id}
            src={getUserAvatarSrc(share.avatar)}
            name={share.name}
          />
        ))}
      </Flex>
    )
  }

  return (
    <Flex gap={1}>
      <Icon as={HiLockClosed} color="gray.400" />
      <Text fontSize="xs">Private</Text>
    </Flex>
  )
}
