import { Avatar, Flex, Image, Tag, TagProps, Text } from "@chakra-ui/react"

import { Seniority } from "@prisma/client"
import { SignalStatusTalent, TalentSignalTitle } from "~/components"
import { SignalCard } from "~/components/SignalCard"
import {
  ButtonEmail,
  ButtonGithub,
  ButtonLinkedin,
  ButtonTwitter,
} from "~/components/SignalCard/Buttons"
import { CardBox } from "~/components/SignalCard/CardBox"
import { SignalIndustry } from "~/components/SignalIndustry"
import { TalentSignalEducationSmallCard } from "~/components/TalentSignalDetail/Education"
import { TalentSignalNewPositionSmallCard } from "~/components/TalentSignalDetail/NewPositionCard"
import { TalentSignalPastPositionsCardSmall } from "~/components/TalentSignalDetail/PastPositionsCard"
import {
  allTalentSignalStatus,
  allTalentSignalType,
} from "~/consts/talentSignals"
import { GetTalentSignalByID } from "~/utils/db/getTalentSignalByID"
import { getSignalLocation } from "~/utils/getSignalLocation"
import { isDateValid } from "~/utils/isDateValid"
import { isOneOf } from "~/utils/isOneOf"
import { getSignalDate } from "~/utils/signalDate"
import { formatSeniority } from "~/utils/string/formatters"

interface Props {
  signal: GetTalentSignalByID
  customLink?: string | null
  withReducedInfo?: boolean
}

export const TalentSignalCard = ({
  signal,
  customLink,
  withReducedInfo,
}: Props) => {
  return (
    <SignalCard
      customLink={customLink}
      withReducedInfo={withReducedInfo}
      product="talent"
      signal={signal}
      picture={
        <Avatar
          src={signal.ProfilePicture ?? undefined}
          boxSize={20}
          borderRadius="md"
          icon={<Image src="/specter-blank.jpg"></Image>}
          bg="white"
        />
      }
      title={signal.Name}
      subtitle={<TalentSignalTitle talentSignal={signal} />}
      description={
        isDateValid(signal.SignalDate) ? (
          <Text fontSize="xs" color="gray.400" as="span">
            <Text as="span" fontWeight="semibold">
              {getSignalDate(signal.SignalDate)}
            </Text>{" "}
            -{" "}
            {getSignalLocation({
              location: signal.Location,
              region: signal.Region,
            })}
          </Text>
        ) : null
      }
      postTitle={
        <>
          {isOneOf(signal.SignalType, allTalentSignalType) && (
            <Tag rounded="full">{signal.SignalType}</Tag>
          )}

          {signal.SignalScore && (
            <Tag rounded="full">Score: {signal.SignalScore}/10</Tag>
          )}

          {signal.IndustryOG && (
            <SignalIndustry industry={signal.IndustryOG} maxItems={2} />
          )}

          {signal.SerialFounder === "Yes" && (
            <Tag bgColor="#00A8A8" rounded="full" variant="solid" ml={1}>
              Serial Founder
            </Tag>
          )}
        </>
      }
      preButtons={
        <Flex>
          {isOneOf(signal.SignalStatus, allTalentSignalStatus) && (
            <SignalStatusTalent signalStatus={signal.SignalStatus} />
          )}

          {signal.OutOfStealthAdvantage !== null && (
            <Text
              pl={"8px"}
              color="gray.300"
              fontSize="xs"
              fontWeight="semibold"
              whiteSpace="nowrap"
            >
              ({signal.OutOfStealthAdvantage} ADV)
            </Text>
          )}
        </Flex>
      }
      actionButtons={
        <>
          <ButtonLinkedin linkedinUrl={signal.LinkedInURLTalent} />
          <ButtonTwitter
            twitterUrl={
              signal.TwitterURLTalent || signal?.personalDetail?.twitterUrl
            }
          />
          <ButtonGithub githubUrl={signal?.personalDetail?.githubUrl} />
          <ButtonEmail email={signal?.personalDetail?.email} />
        </>
      }
      cardBoxes={
        !withReducedInfo && (
          <>
            <CardBox value="Experience:">
              <Flex gap={2}>
                {signal.seniority && (
                  <Tag
                    colorScheme={getSeniorityColor(signal.seniority)}
                    rounded="full"
                    whiteSpace="nowrap"
                  >
                    {formatSeniority(signal.seniority)}
                  </Tag>
                )}
                {signal.YearsOfExperience && (
                  <Tag
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    rounded="full"
                  >
                    {signal.YearsOfExperience}
                  </Tag>
                )}
              </Flex>
            </CardBox>

            <TalentSignalNewPositionSmallCard talentSignal={signal} />
            <TalentSignalPastPositionsCardSmall talentSignal={signal} />
            {!withReducedInfo && (
              <TalentSignalEducationSmallCard talentSignal={signal} />
            )}
          </>
        )
      }
    />
  )
}

const SENIORITY_COLORS: Record<Seniority, TagProps["colorScheme"]> = {
  [Seniority.Entry]: "green",
  [Seniority.MidLevel]: "cyan",
  [Seniority.Director]: "linkedin",
  [Seniority.Executive]: "blue",
}

const SENIORITY_COLORS_RAW: Record<string, TagProps["colorScheme"]> = {
  Entry: "green",
  "Mid-Level": "cyan",
  Director: "linkedin",
  Executive: "blue",
}

export const getSeniorityColor = (
  seniority: string
): TagProps["colorScheme"] => {
  if (isOneOf(seniority, Object.values(Seniority))) {
    return SENIORITY_COLORS[seniority]
  }

  return SENIORITY_COLORS_RAW[seniority] ?? "red"
}
