import { IntegrationSyncFrequency, IntegrationSyncStatus } from "@prisma/client"
import { useQuery } from "@tanstack/react-query"
import { z } from "zod"
import { cacheKeys } from "../cacheKeys"

export const integrationMetaSchema = z.object({
  syncStatus: z.nativeEnum(IntegrationSyncStatus),
  configLocked: z.boolean(),
  companyDatasource: z.string().nullable(),
  talentDatasource: z.string().nullable(),
  strategicDatasource: z.string().nullable(),
  companyFieldMapping: z.any().nullable(),
  talentFieldMapping: z.any().nullable(),
  strategicFieldMapping: z.any().nullable(),
  integrationKey: z.string(),
  companySyncFrequency: z.nativeEnum(IntegrationSyncFrequency).nullable(),
  companySyncSource: z.string().nullable(),
  companyDataMatching: z.any().nullable(),
  talentDataMatching: z.any().nullable(),
  strategicDataMatching: z.any().nullable(),
})

export type IntegrationMeta = z.infer<typeof integrationMetaSchema>

export const useIntegrationMeta = (integrationKey?: string) => {
  return useQuery({
    enabled: integrationKey !== undefined,
    queryKey: cacheKeys.integrationMeta(integrationKey),
    queryFn: async () => {
      const req = await fetch(`/api/integrations/${integrationKey}/meta`)
      const res = await req.json()
      const data = integrationMetaSchema.parse(res)

      return data
    },
    refetchInterval(data) {
      return data?.syncStatus === IntegrationSyncStatus.RUNNING ? 4000 : 0
    },
  })
}
