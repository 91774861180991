import {
  Box,
  Text,
  Flex,
  Spinner,
  Button,
  Icon,
  ModalFooter,
  Center,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { useMutation } from "@tanstack/react-query"
import pluralize from "pluralize"
import { useEffect } from "react"
import { HiCheck } from "react-icons/hi"
import { HiExclamationTriangle } from "react-icons/hi2"
import { z } from "zod"
import { useAi } from "~/utils/hooks/useAi"
import { useIntegrationService } from "~/utils/hooks/useIntegrationService"
import { ReturnButton } from "../ReturnButton"
import { ProductItem } from "../SignalCard"

type Props<P extends SpecterProducts> = {
  product: P
  integrationKey: string
  matchedSignals: {
    signal: ProductItem<P>
    match: any
  }[]
  dataSource: string
  fieldMapping: Record<string, string>
  nextStep: () => void
}

export const Enrichment = <P extends SpecterProducts>({
  matchedSignals,
  integrationKey,
  dataSource,
  fieldMapping,
  nextStep,
}: Props<P>) => {
  const integrationService = useIntegrationService()

  const enrichmentMutation = useMutation(
    async () => {
      const res = await integrationService.post(`/enrich-records`, {
        signals: matchedSignals,
        dataSource,
        integrationKey,
        fieldMapping,
      })

      const result = z
        .object({
          errors: z.array(
            z.object({
              raw: z.any(),
            })
          ),
          results: z.array(z.any()),
        })
        .passthrough()
        .parse(res)

      return result
    },
    {
      // So we don't hammer the API
      retry: 0,
    }
  )

  useEffect(() => {
    setTimeout(() => {
      enrichmentMutation.mutate()
    }, 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hasErrors = (enrichmentMutation.data?.errors.length ?? 0) > 0
  const firstError = enrichmentMutation.data?.errors?.[0]?.raw
  const aiError = useAi(
    hasErrors
      ? `
You are an assistant that helps users troubleshoot configuration issues with their integration settings.
The user has attempted to set up a data integration on our website, mapping certain fields from their system to ours, but they received an error message from the integrations third party API. 
Given the error message and any additional context provided, overview what needs to be changed in the user's field mappings or configuration settings so that the integration will succeed.
Explain the error, keep it short, simple and to the point.

Suggest additinoally that the user changes the field configuration within the application they're integrating with.

Error Data: ${JSON.stringify(firstError)}
    `
      : undefined
  )

  return (
    <Flex h="full" flexDir="column">
      <Center
        flex={1}
        overflow="auto"
        alignItems={hasErrors ? "flex-start" : "center"}
      >
        <Box textAlign="center" py={18} maxW={480}>
          {enrichmentMutation.isIdle && (
            <>
              <Spinner />
              <Text fontWeight="medium" mb={4}>
                Starting enrichment...
              </Text>
            </>
          )}

          {enrichmentMutation.isLoading && (
            <>
              <Spinner />
              <Text fontWeight="medium">
                Enriching {pluralize("Record", matchedSignals.length, true)}...
              </Text>
            </>
          )}

          {enrichmentMutation.isSuccess && (
            <>
              <Icon
                as={hasErrors ? HiExclamationTriangle : HiCheck}
                color={hasErrors ? "orange.500" : "brand.500"}
                fontSize="4xl"
              />
              <Text fontWeight="medium">
                Enrichment complete{hasErrors ? " with errors" : ""}
              </Text>
              <Text color="gray.600" fontSize="sm">
                {enrichmentMutation.data.results.length}{" "}
                {pluralize("Record", enrichmentMutation.data.results.length)}{" "}
                enriched
              </Text>
              {hasErrors && (
                <>
                  <Text color="red.500" fontSize="sm" mb={2}>
                    {enrichmentMutation.data.errors.length}{" "}
                    {pluralize("Record", enrichmentMutation.data.errors.length)}{" "}
                    failed to enrich
                  </Text>
                  {aiError.isLoading && (
                    <Text fontSize="sm" color="gray.600">
                      <Spinner size="xs" /> Building AI powered summary...
                    </Text>
                  )}
                  <Text
                    whiteSpace="break-spaces"
                    mb={4}
                    fontSize="sm"
                    color="gray.600"
                  >
                    {aiError.data ??
                      (firstError &&
                      typeof firstError === "object" &&
                      "message" in firstError
                        ? firstError.message
                        : JSON.stringify(firstError))}
                  </Text>
                </>
              )}
              <Button
                mt={4}
                size="xs"
                variant="outline"
                onClick={() => enrichmentMutation.mutate()}
              >
                Rerun
              </Button>
            </>
          )}
        </Box>
      </Center>
      {enrichmentMutation.isSuccess && (
        <ModalFooter>
          <ReturnButton onClick={nextStep}>Next</ReturnButton>
        </ModalFooter>
      )}
    </Flex>
  )
}
