import {
  ModalFooter,
  Select,
  Spinner,
  Text,
  Box,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  Spacer,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useFormik } from "formik"
import pluralize from "pluralize"
import { z } from "zod"
import { toFormikValidationSchema } from "zod-formik-adapter"
import { cacheKeys } from "~/utils/cacheKeys"
import { getProductEntityName } from "~/utils/getProductName"
import { useIntegrationDataSources } from "~/utils/hooks/useIntegrations"
import { getDataSourceKey, getIntegrationName } from "~/utils/integrationApp"
import invariant from "~/utils/invariant"
import { ReturnButton } from "../ReturnButton"
import { ProductItem } from "../SignalCard"
import { DataMatching, DataMatchingType } from "./DataMatching"

type Props<P extends SpecterProducts> = {
  product: P
  integrationKey: string
  nextStep: () => void
  signals: ProductItem<P>[]
  defaultDataSource?: string
  configLocked: boolean
  defaultFieldMapping?: any
  defaultDataMatching?: DataMatchingType
}

const formSchema = z.object({
  dataSource: z.string({
    required_error: "Please select an Entity Type",
  }),
})

const validationSchema = toFormikValidationSchema(formSchema)

export const DataSource = <P extends SpecterProducts>({
  product,
  integrationKey,
  nextStep,
  signals,
  defaultDataSource,
  configLocked,
  defaultFieldMapping,
  defaultDataMatching,
}: Props<P>) => {
  const dataSourceQuery = useIntegrationDataSources(product, integrationKey)
  const queryClient = useQueryClient()
  const updateMetaMutation = useMutation({
    mutationFn: async (source: string) => {
      const dataSourceKey = getDataSourceKey(product)

      invariant(dataSourceKey)

      const req = await fetch(`/api/integrations/${integrationKey}/meta`, {
        method: "POST",
        body: JSON.stringify({
          [dataSourceKey]: source,
        }),
      })

      invariant(req.ok)
    },
    async onSuccess() {
      await queryClient.invalidateQueries({
        queryKey: cacheKeys.integrationMeta(integrationKey),
      })
      nextStep()
    },
  })

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      dataSource: defaultDataSource ?? "",
    },
    onSubmit: (values) => {
      updateMetaMutation.mutate(values.dataSource)
    },
  })

  return (
    <>
      <Flex
        as="form"
        h="full"
        flexDir="column"
        onSubmit={(e) => {
          e.preventDefault()
          formik.handleSubmit()
        }}
      >
        <Center flex={1}>
          <Box textAlign="center">
            <Text fontWeight="medium">
              Which {getIntegrationName(integrationKey)} Record type should be
              used?
            </Text>
            <Text fontSize="sm" color="gray.600" mb={4}>
              This is the record type that will be created/enriched within{" "}
              {getIntegrationName(integrationKey)}.
            </Text>
            {dataSourceQuery.isLoading && <Spinner />}
            {dataSourceQuery.isSuccess && (
              <FormControl isInvalid={!!formik.errors.dataSource}>
                <Select
                  isDisabled={configLocked}
                  value={formik.values.dataSource ?? ""}
                  name="dataSource"
                  onChange={formik.handleChange}
                  placeholder="Select an option..."
                >
                  {dataSourceQuery.data?.map((dataSource) => (
                    <option key={dataSource.value} value={dataSource.value}>
                      {dataSource.label}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{formik.errors.dataSource}</FormErrorMessage>
              </FormControl>
            )}
          </Box>
        </Center>
        <ModalFooter>
          <Text fontSize="xs" color="brand.500" fontWeight="semibold">
            {pluralize(getProductEntityName(product), signals.length, true)}{" "}
            selected
          </Text>
          <Spacer />
          <ReturnButton
            type="submit"
            isDisabled={!formik.isValid || updateMetaMutation.isLoading}
            isLoading={updateMetaMutation.isLoading}
          >
            Next
          </ReturnButton>
        </ModalFooter>
      </Flex>

      {updateMetaMutation.isSuccess && (
        <DataMatching
          product={product}
          integrationKey={integrationKey}
          signals={signals}
          nextStep={nextStep}
          configLocked={configLocked}
          defaultFieldMapping={defaultFieldMapping}
          dataSource={formik.values.dataSource}
          defaultDataMatching={defaultDataMatching}
        />
      )}
    </>
  )
}
