import { Tag } from "@chakra-ui/react"

import { TagProps } from "@chakra-ui/react"
import { ReactNode } from "react"

const sizeProps = (size: string) =>
  ({
    xs: { fontSize: "8px", outlineOffset: "none", px: 1, py: 0.5, minH: 0 },
    sm: { fontSize: "xx-small", outlineOffset: "none" },
    md: { fontSize: "xs", outlineOffset: "2px" },
    lg: { fontSize: "sm", outlineOffset: "4px" },
  }[size])

export const ColoredTag = ({
  color,
  children,
  size = "md",
  ...props
}: {
  color?: string
  children: ReactNode
  size?: "xs" | "sm" | "md" | "lg"
} & TagProps) => {
  const extraSizeProps = sizeProps(size)
  const [baseColor] = color?.split(".") ?? []

  return (
    <Tag
      borderRadius="full"
      variant="outline"
      color={baseColor + ".500"}
      borderWidth={1}
      borderColor={baseColor + ".200"}
      boxShadow="none"
      fontWeight="medium"
      overflow="hidden"
      whiteSpace="nowrap"
      bgColor={baseColor + ".50"}
      {...extraSizeProps}
      {...props}
    >
      {children}
    </Tag>
  )
}
