import { z } from "zod"
import {
  AVERAGE_TENURE_OPTIONS,
  COMPANY_SIZE_OPTIONS,
  EDUCATION_LEVELS,
  FUNDING_TYPE,
  GROWTH_STAGE_OPTIONS,
  PEOPLE_HIGHLIGHT_OPTIONS,
  SENIORITY_LEVELS,
} from "~/consts/signals"
import {
  companyOperatorsFilterSchema,
  getInnerSchemaAndOptions,
  numericFilterRevampedSchema,
  setFilterSchema,
  currentTenureFilterSchema,
  languagesFilterSchema,
} from "~/utils/signal"

export type PeopleDBFilters = z.infer<typeof peopleFiltersValidation>

const peopleOverviewFiltersSchema = z
  .object({
    Languages: languagesFilterSchema,
    Skills: setFilterSchema(),
    Highlights: setFilterSchema(z.enum(PEOPLE_HIGHLIGHT_OPTIONS)),
    Location: z.array(z.string()),
    Department: companyOperatorsFilterSchema(
      getInnerSchemaAndOptions("Department")
    ),
  })
  .partial()

const peopleExperienceCurrentCompany = z
  .object({
    CompanySize: z.array(z.nativeEnum(COMPANY_SIZE_OPTIONS)),
    CompanyHQLocation: z.array(z.string()),
    CompanyGrowthStage: z.array(z.enum(GROWTH_STAGE_OPTIONS)),
    CompanyLastFundingAmount: numericFilterRevampedSchema(),
  })
  .partial()

const peopleExperienceFiltersSchema = z
  .object({
    JobTitles: companyOperatorsFilterSchema(
      getInnerSchemaAndOptions("JobTitles")
    ),
    PastJobTitles: companyOperatorsFilterSchema(
      getInnerSchemaAndOptions("PastJobTitles")
    ),
    YearsOfExperience: numericFilterRevampedSchema(),
    SeniorityLevel: z.array(z.enum(SENIORITY_LEVELS)),
    CurrentTenure: currentTenureFilterSchema,
    AverageTenure: z.enum(AVERAGE_TENURE_OPTIONS),
    CompanyFundingType: z.array(z.nativeEnum(FUNDING_TYPE)),
  })
  .partial()

const peopleCompanyOperatorFiltersSchema = z
  .object({
    Companies: companyOperatorsFilterSchema(
      getInnerSchemaAndOptions("Companies")
    ),
    CompanyInvestors: companyOperatorsFilterSchema(
      getInnerSchemaAndOptions("CompanyInvestors")
    ),
    CompanyIndustries: companyOperatorsFilterSchema(
      getInnerSchemaAndOptions("CompanyIndustries")
    ),
  })
  .partial()

const peopleEducationFiltersSchema = z
  .object({
    Education: setFilterSchema(),
    EducationLocation: z.array(z.string()),
    GraduationYear: numericFilterRevampedSchema(),
    EducationLevel: z.array(z.enum(EDUCATION_LEVELS)),
    FieldOfStudy: setFilterSchema(),
  })
  .partial()

const peopleSocialFiltersSchema = z
  .object({
    Connections: numericFilterRevampedSchema(),
    Followers: numericFilterRevampedSchema(),
  })
  .partial()

export const peopleFiltersValidation = peopleOverviewFiltersSchema
  .merge(peopleExperienceFiltersSchema)
  .merge(peopleCompanyOperatorFiltersSchema)
  .merge(peopleExperienceCurrentCompany)
  .merge(peopleEducationFiltersSchema)
  .merge(peopleSocialFiltersSchema)

export const peopleAdvancedFiltersSchema = z.object({
  Overview: z.object({ Overview: peopleOverviewFiltersSchema }),
  Companies: z.object({
    Company: peopleCompanyOperatorFiltersSchema,
    "Current Company": peopleExperienceCurrentCompany,
  }),
  Experience: z.object({
    Experience: peopleExperienceFiltersSchema,
  }),
  Education: z.object({ Education: peopleEducationFiltersSchema }),
  Social: z.object({ Social: peopleSocialFiltersSchema }),
})

// Examples

// "AverageTenure": ["gte", 18, null], ✅
// "YearsOfExperience": ["gte", 10, null], ✅
// "Highlights": ["OR", ["serial_founder"]], ✅
// "GraduationYear": ["lte", 2015, null], ✅
// "Education": ["OR", [20075, 4098, 36459]], ✅
// "FieldOfStudy": ["OR", ["Computer Science"]],
// "CompanyLastFundingAmount": ["gte", 10000000, null], ✅
// "Department": ["Current", "OR", ["Engineering"]]
// "Companies": ["All", "OR", ["618106c3ee1af0b5ab8b668e"]]
// "CompanyFundingType": ["OR", ["Series A"]] ✅
// "JobTitles": ["Current", "OR", ["Senior Data Engineer"]]
// "PastJobTitles": ["Past", "OR", ["Senior Data Engineer"]]
// "CompanyInvestors": ["Current", "OR", ["inv_06177102947e5a662be3a6ab"]]
// "CompanyHQLocation": [
//         "148337312726515712:148618787703226367",
//         "[\"288511851128422400:288793326105133056\",\"289356276058554368:289637751035265024\",\"289919226011975680:290200700988686336\",\"290482175965396992:292171025825660928\",\"292452500802371584:293296925732503552\",\"293578400709214208:293859875685924864\",\"294422825639346176:294704300616056832\",\"294985775592767488:295267250569478144\",\"295830200522899456:296111675499610112\",\"296674625453031424:296956100429742080\",\"297237575406452736:297800525359874048\",\"298082000336584704:298363475313295360\",\"299770850196848640:300615275126980608\",\"301459700057112576:302022650010533888\",\"302304124987244544:302585599963955200\",\"302867074940665856:303148549917376512\"]"
//     ] ✅
