import { Flex, Grid, HStack, Link, Text } from "@chakra-ui/react"
import { HiBriefcase } from "react-icons/hi"
import { GetTalentSignalByID } from "~/utils/db/getTalentSignalByID"
import {
  pickKeyFromKeyValue,
  pickValueFromKeyValue,
} from "~/utils/keyValuePairs"

import { BoxIcon } from "~/components/BoxIcon"
import { Card } from "~/components/Card"
import { CompanyLogo } from "~/components/CompanyLogo"
import { FlexDivider } from "~/components/FlexDivider"
import { Link as RemixLink } from "@remix-run/react"
interface Props {
  talentSignal: GetTalentSignalByID
  limit?: number
}

export const TalentSignalPastPositionsCard = ({
  talentSignal,
  limit,
}: Props): JSX.Element | null => {
  if (talentSignal.PastPosition === null) return null

  return (
    <Card p={4} minW={300} maxW={400}>
      <Text as="h2" color="gray.800" fontSize="md" fontWeight="bold" mb={2}>
        Past Positions
      </Text>

      <Flex gap={2} direction="column">
        {talentSignal.PastPosition?.split("; ").map((position, index) =>
          limit && index >= limit ? null : (
            <HStack key={index} alignItems="stretch">
              {index === 0 && talentSignal.PastCompanyWebsite ? (
                <CompanyLogo domain={talentSignal.PastCompanyWebsite} />
              ) : (
                <BoxIcon icon={HiBriefcase} />
              )}

              <Flex
                direction="column"
                justifyContent="center"
                fontSize="sm"
                overflow="hidden"
              >
                {index === 0 && talentSignal.PastCompanyWebsite ? (
                  <Link
                    color="brand.500"
                    {...(talentSignal.PastCompany?.Company?.id
                      ? {
                          as: RemixLink,
                          to: `/signals/company/feed/${talentSignal.PastCompany.Company.id}/f`,
                        }
                      : {
                          href: talentSignal.PastCompanyWebsite ?? undefined,
                          target: "_blank",
                          to: "",
                        })}
                    fontSize="sm"
                    fontWeight="semibold"
                  >
                    {pickKeyFromKeyValue(position)}
                  </Link>
                ) : (
                  <Text fontWeight="semibold">
                    {pickKeyFromKeyValue(position)}
                  </Text>
                )}
                <Text
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                >
                  {pickValueFromKeyValue(position)}
                </Text>
              </Flex>
            </HStack>
          )
        )}
      </Flex>
    </Card>
  )
}

export const TalentSignalPastPositionsCardSmall = ({
  talentSignal,
}: Props): JSX.Element | null => {
  if (talentSignal.PastPosition === null) return null

  const [lastPosition, beforeLastPosition1, beforeLastPosition2] =
    talentSignal.PastPosition?.split("; ") ?? []
  return (
    <Card p={4} display="flex" flexDirection="column" gap={2} maxW="500px">
      <Text as="h2" color="gray.800" fontSize="sm" fontWeight="bold">
        Past Positions
      </Text>

      <Grid
        gap={2}
        templateColumns={
          beforeLastPosition1 || beforeLastPosition2
            ? "auto auto auto auto"
            : "auto 1fr"
        }
        justifyContent="start"
      >
        {talentSignal.PastCompanyWebsite ? (
          <CompanyLogo domain={talentSignal.PastCompanyWebsite} size={8} />
        ) : (
          <BoxIcon icon={HiBriefcase} size={8} />
        )}

        <Flex
          direction="column"
          justifyContent="center"
          fontSize="xs"
          overflow="hidden"
          flexShrink={1}
        >
          {talentSignal.PastCompanyWebsite ? (
            <Link
              color="brand.500"
              {...(talentSignal.PastCompany?.Company?.id
                ? {
                    as: RemixLink,
                    to: `/signals/company/feed/${talentSignal.PastCompany.Company.id}/f`,
                  }
                : {
                    href: talentSignal.PastCompanyWebsite ?? undefined,
                    target: "_blank",
                    to: "",
                  })}
              onClick={(e) => e.stopPropagation()}
              fontWeight="semibold"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            >
              {pickKeyFromKeyValue(lastPosition)}
            </Link>
          ) : (
            <Text
              fontWeight="semibold"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            >
              {pickKeyFromKeyValue(lastPosition)}
            </Text>
          )}
          <Text overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
            {pickValueFromKeyValue(lastPosition)}
          </Text>
        </Flex>
        {(beforeLastPosition1 || beforeLastPosition2) && (
          <>
            <FlexDivider orientation="vertical" />
            <Flex
              direction="column"
              justifyContent="center"
              fontSize="xs"
              overflow="hidden"
              flexShrink={5}
            >
              {beforeLastPosition1 && (
                <Text
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                >
                  <Text as="span" fontWeight="semibold">
                    {pickKeyFromKeyValue(beforeLastPosition1)}
                  </Text>
                  {" - "}
                  {pickValueFromKeyValue(beforeLastPosition1)}
                </Text>
              )}
              {beforeLastPosition2 && (
                <Text
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                >
                  <Text as="span" fontWeight="semibold">
                    {pickKeyFromKeyValue(beforeLastPosition2)}
                  </Text>
                  {" - "}
                  {pickValueFromKeyValue(beforeLastPosition2)}{" "}
                </Text>
              )}
            </Flex>
          </>
        )}
      </Grid>
    </Card>
  )
}
