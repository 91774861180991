import {
  Button,
  MenuItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { useUser } from "@clerk/remix"
import { SpecterProducts } from "@prisma/client"
import { useMutation } from "@tanstack/react-query"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"

import { useAnalytics } from "~/utils/hooks/useAnalytics"
import { useEnv } from "~/utils/hooks/useEnv"
import invariant from "~/utils/invariant"
import { relativeUrl } from "~/utils/string/url"
import { mapProductsToRouteName } from "../Filters/schemas"

interface Props {
  product: SpecterProducts
}

export const AffinitySearchExport = ({
  product,
}: Props): JSX.Element | null => {
  const toast = useToast()
  const user = useUser()
  const env = useEnv()
  const [searchParams] = useSafeSearchParams()
  const modal = useDisclosure()
  const analytics = useAnalytics()

  const affinityImportSearchMutation = useMutation<string>(
    async () => {
      invariant(user.user?.id)

      const query = searchParams.get("query")

      const req = await fetch(`/api/affinity/export-search`, {
        method: "POST",
        body: JSON.stringify({
          product,
          query,
        }),
      })

      invariant(req.ok)

      const searchURL = relativeUrl(
        `${env.APP_URL}/signals/${mapProductsToRouteName(product)}/feed`,
        searchParams.toString()
      )

      analytics.track(
        "Add to Affinity",
        {
          product,
          type: "search",
          searchURL,
          query,
        },
        { exclude: ["intercom"] }
      )

      return await req.json()
    },
    {
      onSuccess(url) {
        if (url) {
          const link = document.createElement("a")
          link.href = url
          link.click()
        }
      },
      onMutate() {
        modal.onClose()
        toast({
          status: "info",
          title: "Your export for Affinity is being created",
          description: "This can take some time",
        })
      },
    }
  )

  return (
    <>
      <MenuItem
        onClick={modal.onOpen}
        disabled={affinityImportSearchMutation.isLoading}
        minW="fit-content"
      >
        Export to Affinity
      </MenuItem>

      <Modal isOpen={modal.isOpen} onClose={modal.onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Export to Affinity</ModalHeader>
          <ModalBody>
            <Text fontSize="sm">
              Are you sure you want to create an export for Affinity?
            </Text>
            <Text fontSize="sm" mt={3}>
              Once the download completes,{" "}
              <strong>
                you will need to manually sign-in to Affinity and import the
                exported file.
              </strong>
            </Text>
          </ModalBody>
          <ModalFooter gap={2}>
            <Button variant="outline" onClick={modal.onClose}>
              Cancel
            </Button>

            <Button
              colorScheme="brand"
              onClick={() => affinityImportSearchMutation.mutate()}
              disabled={affinityImportSearchMutation.isLoading}
              isLoading={affinityImportSearchMutation.isLoading}
            >
              Create Export
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
