import { Flex, FlexProps } from "@chakra-ui/react"
import { ReactNode, useLayoutEffect, useRef, useState } from "react"
import useElementSize from "~/utils/hooks/useElementSize"
import { ClientSide } from "~/components/ClientSide"

interface Props extends FlexProps {
  defaultHeight?: number
  overflowIndicator?: ReactNode | ((overflowingItems: number) => ReactNode)
}

const ClientSideFlexOneLine = (props: Props) => {
  return (
    <ClientSide>
      <FlexOneLine {...props} />
    </ClientSide>
  )
}

const FlexOneLine = ({
  defaultHeight = 34,
  overflowIndicator,
  children,
  ...props
}: Props) => {
  const [flexHeight, setFlexHeight] = useState<number>(defaultHeight)

  const [flexRef, dimensions] = useElementSize()
  const overflowIndicatorRef = useRef<HTMLDivElement>(null)

  // The +1 is to account for rounding errors
  const totalWidth = dimensions?.width + 2

  const childrenWidth = Array.from(flexRef.current?.children ?? [])
    .filter((child) => {
      return (
        child instanceof HTMLElement &&
        child.dataset.attribute !== "more-indication"
      )
    })
    .map((child) => child.getBoundingClientRect().width)

  const flexGap = flexRef.current
    ? +getComputedStyle(flexRef.current).rowGap.replace("px", "")
    : 0

  const overflowWidth =
    overflowIndicatorRef.current?.getBoundingClientRect().width ?? 0

  let threshold = 0
  const overflowing = childrenWidth.findIndex((width, idx) => {
    const nextWidth = width + (idx === 0 ? 0 : flexGap)
    if (threshold + nextWidth + overflowWidth > totalWidth) {
      return true
    }

    threshold += nextWidth
    return false
  })

  const childrenWithMore = Array.isArray(children)
    ? [...children.flat(Infinity)]
    : [children]

  if (overflowIndicator && overflowing > 0) {
    const insertAt =
      threshold + flexGap + overflowWidth < totalWidth
        ? overflowing
        : overflowing - 1

    childrenWithMore.splice(
      insertAt,
      0,
      <Flex
        data-attribute="more-indication"
        key="more"
        alignSelf="stretch"
        ref={overflowIndicatorRef}
        height={`${flexHeight}px`}
      >
        {typeof overflowIndicator === "function"
          ? overflowIndicator(childrenWithMore.length - insertAt)
          : overflowIndicator}
      </Flex>
    )
  }

  useLayoutEffect(() => {
    if (flexRef.current) {
      if (flexRef.current.children.length) {
        const currentHeight =
          flexRef.current.children[0].getBoundingClientRect().height

        setFlexHeight(currentHeight)
      }
    }
  }, [flexRef])

  return (
    <Flex
      ref={flexRef}
      flexWrap="wrap"
      overflow="hidden"
      height={`${flexHeight + 2}px`}
      p={"1px"}
      {...props}
    >
      {childrenWithMore}
    </Flex>
  )
}

export { ClientSideFlexOneLine as FlexOneLine }
export type { Props as FlexOneLineProps }
