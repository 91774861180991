import { CustomerFocus } from "@prisma/client"
import { z } from "zod"
import { companySignalSortValidation } from "~/utils/db/sorting"
import { Pagination } from "~/utils/db/queries/types"
import { findQueryFromQueryId, findQueryFromSearchId } from "~/utils/db/queries"
import { normaliseToArray } from "~/utils/db/sql/utils"

import {
  ANNUAL_REVENUE_RANGES,
  COMPANY_HIGHLIGHTS_OPTIONS,
  COMPANY_SIZE_OPTIONS,
  FUNDING_TYPE,
  GROWTH_STAGE_OPTIONS,
  INDUSTRY_OPTIONS,
  SIGNAL_REGION_OPTIONS,
  SUB_INDUSTRY_OPTIONS,
} from "~/consts/signals"
import {
  booleanFilterSchema,
  dateFilterSchema,
  descriptionFilterSchema,
  growthFilterSchema,
  numericFilterSchema,
  NUMERIC_OPERATORS,
  OperatingStatusEnum,
  setFilterSchema,
  textFilterSchema,
} from "~/utils/signal"
import { signalDateFilterSchema } from "~/utils/signalDate"
import { hotSignalsSchema } from "./stratintel"

const companyCompanyFiltersSchemaObject = {
  Rank: numericFilterSchema({ defaultValue: NUMERIC_OPERATORS.LTE }),
  OperatingStatus: z.array(z.nativeEnum(OperatingStatusEnum)),
  Highlights: setFilterSchema(z.enum(COMPANY_HIGHLIGHTS_OPTIONS)),
  traction: textFilterSchema,
  FoundedDate: numericFilterSchema({
    defaultValue: NUMERIC_OPERATORS.GTE,
    extraConstraints: (z) => z.lte(new Date().getFullYear()),
  }),
  HQLocation: z.array(z.string()),
} as const

const companyCompanyFiltersSchema = z
  .object(companyCompanyFiltersSchemaObject)
  .partial()

const companyEmployeesFiltersSchema = z
  .object({
    EmployeeCount: numericFilterSchema(),
    "composed_growth.employee_count": growthFilterSchema(),
  })
  .partial()

const companyCompanySizeFiltersSchema = z
  .object({
    CompanySize: z.array(z.nativeEnum(COMPANY_SIZE_OPTIONS)),
  })
  .partial()

const companyTalentMovementFiltersSchema = z
  .object({
    NewHires: dateFilterSchema,
    RecentLeavers: dateFilterSchema,
    RecentPromotions: dateFilterSchema,
  })
  .partial()

const companyNameSearch = z
  .object({
    nameSearch: z.string().nullable(),
  })
  .partial()

const companyFundingFiltersSchema = z
  .object({
    TotalFundingAmount: numericFilterSchema({
      defaultValue: NUMERIC_OPERATORS.LTE,
    }),
    LastFundingType: z.array(z.nativeEnum(FUNDING_TYPE)),
    LastFundingAmount: numericFilterSchema(),
    LastFundingDate: dateFilterSchema,
    NumberOfFundingRounds: numericFilterSchema(),
    PostMoneyValuation: numericFilterSchema(),
  })
  .partial()

const companyInvestorsFiltersSchema = z
  .object({
    Investors: setFilterSchema(),
    NumberOfInvestors: numericFilterSchema(),
  })
  .partial()

const companyValuationFiltersSchema = z
  .object({
    AnnualRevenueEstimate: z.array(z.nativeEnum(ANNUAL_REVENUE_RANGES)),
    HotCompanies: hotSignalsSchema,
  })
  .partial()

const companyGrowthStageFiltersSchema = z
  .object({
    growthStage: z.array(z.enum(GROWTH_STAGE_OPTIONS)),
  })
  .partial()

const companyIndustryFiltersSchema = z
  .object({
    Industry: setFilterSchema(z.enum(INDUSTRY_OPTIONS)),
    SubIndustry: setFilterSchema(z.enum(SUB_INDUSTRY_OPTIONS)),
    Description: descriptionFilterSchema,
    b2x: z.array(z.nativeEnum(CustomerFocus)),
    tcp: textFilterSchema,
  })
  .partial()

const companyWebVisitsFiltersSchema = z
  .object({
    WebVisits: numericFilterSchema(),
    "composed_growth.web_visits": growthFilterSchema(),
  })
  .partial()

const companyWebsitePopularityRankFiltersSchema = z
  .object({
    WebsitePopularityRank: numericFilterSchema(),
    "composed_growth.popularity_rank.diff": growthFilterSchema(),
  })
  .partial()

const companyLinkedInFiltersSchema = z
  .object({
    LinkedInFollowers: numericFilterSchema(),
    "composed_growth.linkedin_followers": growthFilterSchema(),
  })
  .partial()

const companyTwitterFiltersSchema = z
  .object({
    TwitterFollowers: numericFilterSchema(),
    "composed_growth.twitter_followers": growthFilterSchema(),
  })
  .partial()

const companyInstagramFiltersSchema = z
  .object({
    InstagramFollowers: numericFilterSchema(),
    "composed_growth.instagram_followers": growthFilterSchema(),
  })
  .partial()

const companyAppDownloadsFiltersSchema = z
  .object({
    TotalAppDownloads: numericFilterSchema(),
    "composed_growth.total_app_downloads": growthFilterSchema([
      "1mo",
      "3mo",
      "6mo",
    ]),
  })
  .partial()

const companyiTunesFiltersSchema = z
  .object({
    iTunesReviews: numericFilterSchema(),
    "composed_growth.itunes_reviews": growthFilterSchema(),
  })
  .partial()

const companyGoogleFiltersSchema = z
  .object({
    GooglePlayReviews: numericFilterSchema(),
    "composed_growth.googleplay_reviews": growthFilterSchema(),
  })
  .partial()

const companyActiveTechnologiesFiltersSchema = z
  .object({
    ActiveTechnologies: setFilterSchema(),
  })
  .partial()

const companyAwardsFiltersSchema = z
  .object({
    Awards: setFilterSchema(),
    AwardsCount: numericFilterSchema(),
    certifications_mapped: setFilterSchema(),
  })
  .partial()

const companyG2FiltersSchema = z
  .object({
    G2ProductReviews: booleanFilterSchema,
    G2Medals: booleanFilterSchema,
    G2TotalReviews: numericFilterSchema(),
    G2Rating: numericFilterSchema(),
    // TODO: M and D don't want this for now, add when we have history on products
    // G2Products: textFilterSchema,
  })
  .partial()

const companyTrustpilotFiltersSchema = z
  .object({
    TrustpilotReviews: booleanFilterSchema,
    TrustpilotTotalReviews: numericFilterSchema(),
    TrustpilotRating: numericFilterSchema(),
  })
  .partial()

const subSection = companyAppDownloadsFiltersSchema.merge(
  companyActiveTechnologiesFiltersSchema
)

const companyNonFilterableFieldsSchema = z
  .object({
    new: z.boolean().optional(),
    NewHighlights: setFilterSchema(z.enum(COMPANY_HIGHLIGHTS_OPTIONS)),
    HasNewHighlights: z.boolean().optional(),
    HasNewFundingHighlights: z.boolean().optional(),
    HasNewGrowthHighlights: z.boolean().optional(),
  })
  .partial()

const companySocialFiltersSchema = companyLinkedInFiltersSchema
  .merge(companyTwitterFiltersSchema)
  .merge(companyInstagramFiltersSchema)
  .merge(companyiTunesFiltersSchema)
  .merge(companyGoogleFiltersSchema)
  .merge(subSection)

export const companyCRMFiltersSchema = z
  .object({
    LastActivityDate: signalDateFilterSchema.optional(),
    inCRM: z.boolean().optional(),
  })
  .partial()

const DEPRECATED_FIELDS = z
  .object({
    // * Add deprecated fields here:
    Tags: z.literal("DEPRECATED"),
    HQRegion: z.array(z.enum(SIGNAL_REGION_OPTIONS)),
  })
  .partial()

const companySignalListFilterSchema = z
  .object({
    listId: z.string().optional(),
    landscapeId: z.string().optional(),
    landscapeColumnId: z.string().optional(),
  })
  .partial()

type DateOperator = "after" | "equals" | "before"

const extra2 = companyCRMFiltersSchema
  .merge(companyNonFilterableFieldsSchema)
  .merge(companyAwardsFiltersSchema)
  .merge(companyG2FiltersSchema)
  .merge(companyTrustpilotFiltersSchema)
  .merge(companySocialFiltersSchema)
  .merge(companyTalentMovementFiltersSchema)
  .merge(DEPRECATED_FIELDS)

const extra = companyCompanySizeFiltersSchema
  .merge(companyInvestorsFiltersSchema)
  .merge(companyValuationFiltersSchema)
  .merge(companyEmployeesFiltersSchema)
  .merge(companyWebVisitsFiltersSchema)
  .merge(companyWebsitePopularityRankFiltersSchema)
  .merge(companyNameSearch)
  .merge(extra2)

export const companySignalFiltersValidation = companySignalListFilterSchema
  .merge(companyCompanyFiltersSchema)
  .merge(companyIndustryFiltersSchema)
  .merge(companyGrowthStageFiltersSchema)
  .merge(companyFundingFiltersSchema)
  .merge(extra)

// TODO: Fix these annoying linting errors
// @ts-ignore
export type CompanySignalFilters = z.infer<
  // @ts-ignore
  typeof companySignalFiltersValidation
>

// Supress the deep nesting for validation
// @ts-ignore
export const CompanyFiltersSchema = z
  .union([
    // @ts-ignore-next-line
    companySignalFiltersValidation,
    // @ts-ignore-next-line
    z.array(companySignalFiltersValidation),
  ])
  .default([])
  .transform((filter: CompanySignalFilters | CompanySignalFilters[]) => {
    return normaliseToArray(filter)
  })

export type CompanyFilters = z.infer<typeof CompanyFiltersSchema>

// @ts-ignore
export const CompaniesSchema = z
  .object({
    list_id: z.string().optional(), // Also appears in the filters.
    searchId: z.string().optional(),
    queryId: z.string().optional(),
    sort: companySignalSortValidation.optional(),
    searches: CompanyFiltersSchema,
    nameSearch: z.string().optional(),
    pagination: Pagination,
    ids: z.array(z.string()).optional(),
  })
  .transform(async (value) => {
    if (value.queryId !== undefined) {
      const queryIdQuery = await findQueryFromQueryId(Number(value.queryId))
      if (queryIdQuery !== undefined) {
        value.searches.push(...normaliseToArray(queryIdQuery))
      }
    }

    if (value.searchId !== undefined) {
      const searchQuery = await findQueryFromSearchId(Number(value.searchId))
      if (searchQuery !== undefined) {
        value.searches.push(...searchQuery.query)
      }
    }
    return value
  })

export type CompanySearch = z.infer<typeof CompaniesSchema>

export const getDateOperator = (operator: DateOperator) => {
  return {
    after: "gt",
    before: "lt",
    equals: "equals",
  }[operator]
}

export const companyAdvancedFiltersSchema = z.object({
  Overview: z.object({
    Overview: companyCompanyFiltersSchema,
  }),
  "Growth Stage": z.object({
    "Growth Stage": companyGrowthStageFiltersSchema,
  }),
  Industry: z.object({
    Industry: companyIndustryFiltersSchema,
  }),
  Financials: z.object({
    Funding: companyFundingFiltersSchema,
    Investors: companyInvestorsFiltersSchema,
    Financials: companyValuationFiltersSchema,
  }),
  Team: z.object({
    Employees: companyEmployeesFiltersSchema,
    "Company Size": companyCompanySizeFiltersSchema,
    "Notable Talent Movements": companyTalentMovementFiltersSchema,
  }),
  "Website Intelligence": z.object({
    "Web Visits": companyWebVisitsFiltersSchema,
    "Website Popularity Rank": companyWebsitePopularityRankFiltersSchema,
  }),
  "Social Engagement": z.object({
    LinkedIn: companyLinkedInFiltersSchema,
    Twitter: companyTwitterFiltersSchema,
    Instagram: companyInstagramFiltersSchema,
  }),
  "App Performance": z.object({
    "Total App Downloads": companyAppDownloadsFiltersSchema,
    iTunes: companyiTunesFiltersSchema,
    GooglePlay: companyGoogleFiltersSchema,
  }),
  Product: z.object({
    "G2 Data": companyG2FiltersSchema,
    "Trustpilot Data": companyTrustpilotFiltersSchema,
    "Active Technologies": companyActiveTechnologiesFiltersSchema,
  }),
  Awards: z.object({
    Awards: companyAwardsFiltersSchema,
  }),
  "My CRM": z.object({
    "My CRM": companyCRMFiltersSchema,
  }),
})
