import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  HStack,
  Image,
  Link,
  Tag,
  Text,
} from "@chakra-ui/react"
import { HiBriefcase, HiExternalLink } from "react-icons/hi"
import { GetTalentSignalByID } from "~/utils/db/getTalentSignalByID"
import {
  pickKeyFromKeyValue,
  pickValueFromKeyValue,
} from "~/utils/keyValuePairs"

import { BoxIcon } from "~/components/BoxIcon"
import { Card } from "~/components/Card"
import { CompanyLogo } from "~/components"
import { SignalIndustry } from "~/components/SignalIndustry"
import { TALENT_SIGNAL_STATUS } from "~/consts/talentSignals"
import { Link as RemixLink } from "@remix-run/react"

interface Props {
  talentSignal: GetTalentSignalByID
}

function Industry({ industry }: { industry: string }) {
  return (
    <>
      <Heading as="h2" size="xs" mb={2} w={"100%"}>
        Industry
      </Heading>

      <Flex gap={1} flexWrap="wrap" mb={4}>
        <SignalIndustry industry={industry} />
      </Flex>
    </>
  )
}

function Tags({ tags }: { tags: string[] }) {
  return (
    <>
      <Heading as="h2" size="xs" mb={2} w={"100%"}>
        Tags
      </Heading>

      <Flex gap={1} flexWrap="wrap" mb={4}>
        {tags.map((tag) => (
          <Tag key={tag} rounded="full">
            {tag}
          </Tag>
        ))}
      </Flex>
    </>
  )
}

export const TalentSignalNewPositionSmallCard = ({
  talentSignal,
}: Props): JSX.Element | null => {
  if (talentSignal.NewPosition === null) return null

  const newCompanyName = pickKeyFromKeyValue(talentSignal.NewPosition)
  const newPosition = pickValueFromKeyValue(talentSignal.NewPosition)
  const isStealth = talentSignal.SignalStatus === TALENT_SIGNAL_STATUS.STEALTH

  return (
    <Card
      p={4}
      display="flex"
      flexDirection="column"
      gap={2}
      maxW={250}
      minW={150}
    >
      <Text as="h2" color="gray.800" fontSize="sm" fontWeight="bold">
        New Position
      </Text>

      <HStack alignItems="center">
        {talentSignal.NewCompanyWebsite !== null ? (
          <CompanyLogo
            domain={
              talentSignal.NewCompanyDomain || talentSignal.NewCompanyWebsite
            }
            size={8}
          />
        ) : isStealth ? (
          <Box flex="none">
            <Image alt={newCompanyName} src="/stealth-logo.png" w={8} h={8} />
          </Box>
        ) : (
          <BoxIcon icon={HiBriefcase} size={8} />
        )}
        <Flex
          direction="column"
          justifyContent="center"
          fontSize="xs"
          overflow="hidden"
        >
          {talentSignal.NewCompanyWebsite !== null ? (
            <Link
              {...(talentSignal.NewCompany?.Company?.id
                ? {
                    as: RemixLink,
                    to: `/signals/company/feed/${talentSignal.NewCompany.Company.id}/f`,
                  }
                : {
                    href: talentSignal.NewCompanyWebsite ?? undefined,
                    target: "_blank",
                    to: "",
                  })}
              color="brand.500"
              fontWeight="semibold"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            >
              {newCompanyName}
            </Link>
          ) : (
            <Text
              fontWeight="semibold"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            >
              {newCompanyName}
            </Text>
          )}

          <Text overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
            {newPosition}
          </Text>
        </Flex>
      </HStack>
    </Card>
  )
}

export const TalentSignalNewPositionCard = ({
  talentSignal,
}: Props): JSX.Element | null => {
  if (talentSignal.NewPosition === null) return null

  const newCompanyName = pickKeyFromKeyValue(talentSignal.NewPosition)
  const newPosition = pickValueFromKeyValue(talentSignal.NewPosition)
  const isStealth = talentSignal.SignalStatus === TALENT_SIGNAL_STATUS.STEALTH

  return (
    <Card p={5}>
      <Heading as="h1" size="sm" mb={2}>
        New Position
      </Heading>

      <HStack alignItems="start">
        {talentSignal.NewCompanyWebsite !== null ? (
          <CompanyLogo
            domain={
              talentSignal.NewCompanyDomain || talentSignal.NewCompanyWebsite
            }
          />
        ) : isStealth ? (
          <Box flex="none">
            <Image alt={newCompanyName} src="/stealth-logo.png" w={10} h={10} />
          </Box>
        ) : (
          <BoxIcon icon={HiBriefcase} />
        )}

        <Box>
          {talentSignal.NewCompanyWebsite !== null ? (
            <Link
              {...(talentSignal.NewCompany?.Company?.id
                ? {
                    as: RemixLink,
                    to: `/signals/company/feed/${talentSignal.NewCompany.Company.id}/f`,
                  }
                : {
                    href: talentSignal.NewCompanyWebsite ?? undefined,
                    target: "_blank",
                    to: "",
                  })}
              color="brand.500"
              fontWeight="semibold"
              mb={1}
            >
              {newCompanyName}
            </Link>
          ) : (
            <Heading as="h2" size="xs" mb={1}>
              {newCompanyName}
            </Heading>
          )}

          <Text lineHeight={1} fontSize="sm" mb={1}>
            {newPosition}
          </Text>

          {talentSignal.FoundedDate && (
            <Text fontSize="xs" color="gray.500">
              Founded {talentSignal.FoundedDate}
            </Text>
          )}

          {talentSignal.NewCompanyTagline && (
            <Text fontSize="xs" color="gray.500">
              "{talentSignal.NewCompanyTagline}"
            </Text>
          )}

          <ButtonGroup spacing={1} mt={1}>
            {talentSignal.LinkedInURL && (
              <Button
                as="a"
                href={talentSignal.LinkedInURL}
                target="_blank"
                size="xs"
                variant="outline"
                rightIcon={<HiExternalLink />}
              >
                LinkedIn
              </Button>
            )}

            {talentSignal.NewCompanyWebsite && (
              <Button
                size="xs"
                variant="outline"
                as="a"
                target="_blank"
                href={talentSignal.NewCompanyWebsite}
                rightIcon={<HiExternalLink />}
              >
                Website
              </Button>
            )}
          </ButtonGroup>
        </Box>
      </HStack>

      <Flex pt={4} flexWrap="wrap" gap={1}>
        {talentSignal.IndustryOG && (
          <Industry industry={String(talentSignal.IndustryOG)} />
        )}

        {talentSignal.Tags && <Tags tags={talentSignal.Tags?.split(", ")} />}
      </Flex>
    </Card>
  )
}
