export const moveInArray = <T>(
  array: T[],
  fromIndex: number,
  toIndex: number
): T[] => {
  const newArray = [...array]

  const [removedItem] = newArray.splice(fromIndex, 1)
  newArray.splice(toIndex, 0, removedItem)

  return newArray
}
