import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react"
import debounce from "debounce"
import { useMemo } from "react"
import { HiOutlineSearch, HiOutlineX } from "react-icons/hi"
import { sanitizeDomain } from "~/utils/sanitizeDomain"

type Props = {
  defaultValue?: string | null
  onChange: (searchTerm: string) => void
  placeholder?: string
  onClear: () => void
}

export function SearchInput({
  defaultValue,
  onChange,
  placeholder = "Search Companies or Domains",
  onClear,
}: Props) {
  const debouncedOnChange = useMemo(() => debounce(onChange, 250), [onChange])

  return (
    <>
      <InputGroup flex="none" width="auto">
        <InputLeftElement color="gray.400">
          <HiOutlineSearch />
        </InputLeftElement>
        <Input
          type="text"
          placeholder={placeholder}
          defaultValue={defaultValue ?? ""}
          w="280px"
          onChange={(event) => {
            if (event.target.value.includes("http")) {
              debouncedOnChange(sanitizeDomain(event.target.value))
              return
            }
            debouncedOnChange(event.target.value)
          }}
        />
        <InputRightElement>
          <IconButton
            aria-label="Clear"
            icon={<HiOutlineX />}
            onClick={onClear}
            variant="ghost"
          />
        </InputRightElement>
      </InputGroup>
    </>
  )
}
