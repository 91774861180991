import { ButtonGroup, ButtonGroupProps } from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { ActionButtonProps, TableSocialButtons } from "./Buttons"

export const ButtonGroupTable = <P extends SpecterProducts>({
  product,
  signal,
  ...props
}: ActionButtonProps<P> & ButtonGroupProps) => {
  return (
    <ButtonGroup
      spacing={1}
      size="xs"
      variant="outline"
      alignItems="center"
      justifyContent="flex-end"
      mr="-20px" // This is to compensate unexpected extra space given to table cell by AgGrid
      {...props}
    >
      <TableSocialButtons product={product} signal={signal} />
    </ButtonGroup>
  )
}
