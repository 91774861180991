import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Divider,
  Heading,
  Link,
  Text,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { ReactNode } from "react"
import { mailto } from "~/utils/string/email"
import { Card } from "~/components/Card"
import { PRODUCT_SIGNAL_LABEL } from "../Feed"

export const PermissionsError = ({ product }: { product: SpecterProducts }) => {
  return (
    <>
      <Box p={1} rounded="lg" borderWidth={1} bgColor="white">
        <Card>
          <Heading as="h3" size="sm" mb={2}>
            It looks like you don't have access to{" "}
            {PRODUCT_SIGNAL_LABEL[product]} Signals yet &#128123;
          </Heading>

          <Text fontSize="sm">
            If you'd like to learn more, feel free to schedule a demo with our
            team.
          </Text>

          <Button
            variant="outline"
            mt={3}
            as="a"
            href="https://tryspecter.com/contact"
          >
            Schedule a demo
          </Button>

          <Divider my={4} borderColor="gray.200" />

          <Text fontSize="sm">
            If you're already subscribed to {PRODUCT_SIGNAL_LABEL[product]}{" "}
            Signals, please contact{" "}
            <Link
              isExternal
              href={mailto("team@tryspecter.com")}
              color="brand.500"
            >
              team@tryspecter.com
            </Link>{" "}
            and we'll activate your feed as soon as possible.
          </Text>
        </Card>
      </Box>
    </>
  )
}

export const FeedDataError = ({ message }: { message?: ReactNode }) => {
  return (
    <Alert status="error" m={4}>
      <AlertIcon />
      <AlertTitle>
        {message ?? "Something went wrong loading signals"}
      </AlertTitle>
    </Alert>
  )
}
