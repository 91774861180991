import { GrowthStage } from "@prisma/client"
import {
  companyGrowthBadgeTheme,
  GrowthBadgeTheme,
  GROWTH_STAGE_LABEL,
  strategicGrowthBadgeTheme,
} from "~/consts/signals"
import { isNullish } from "~/utils/values"
import { ColoredTag } from "../ColoredTag"

const GrowthBadge = ({
  growthStage,
  theme,
}: {
  growthStage: GrowthStage
  theme: GrowthBadgeTheme
}) => {
  const color = theme[growthStage]

  return (
    <ColoredTag color={color}>{GROWTH_STAGE_LABEL[growthStage]}</ColoredTag>
  )
}

export const StrategicGrowthBadge = ({
  growthStage,
}: {
  growthStage: GrowthStage
}) => {
  return (
    <GrowthBadge growthStage={growthStage} theme={strategicGrowthBadgeTheme} />
  )
}

export const CompanyGrowthBadge = ({
  growthStage,
}: {
  growthStage: GrowthStage | null
}) => {
  if (isNullish(growthStage)) {
    return null
  }

  return (
    <GrowthBadge growthStage={growthStage} theme={companyGrowthBadgeTheme} />
  )
}
