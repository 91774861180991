import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"

function useLocalStorageState() {
  const [state, setState] = useState<Record<string, string>>({})

  useEffect(() => {
    setState({ ...localStorage })
  }, [])

  const setWithLocalStorage = (key: string, nextState: string) => {
    if (nextState !== "") localStorage.setItem(key, nextState)
    else localStorage.removeItem(key)

    setState({ ...localStorage })
  }

  return [state, setWithLocalStorage] as const
}

type LocalStorage = {
  localStorage: Record<string, string>
  setLocalStorage: (key: string, value: string) => void
}
const LocalStorageContext = createContext<LocalStorage>({
  localStorage: {},
  setLocalStorage: () => {},
})

export const LocalStorageProvider = ({ children }: { children: ReactNode }) => {
  const [localStorage, setLocalStorage] = useLocalStorageState()

  return (
    <LocalStorageContext.Provider value={{ localStorage, setLocalStorage }}>
      {children}
    </LocalStorageContext.Provider>
  )
}

// * This hook behaves like a useState for localStorage,
// * so it'll re-render the component when the value changes
export function useLocalStorage<T>(key: string, defaultValue?: T) {
  const { localStorage, setLocalStorage } = useContext(LocalStorageContext)

  const value = tryCatch<T>(
    () => JSON.parse(localStorage[key]),
    (localStorage[key] ?? defaultValue) as T
  )

  return [
    value,
    (newValue: T) => {
      const stringValue =
        typeof newValue === "string" ? newValue : JSON.stringify(newValue)

      setLocalStorage(key, stringValue)
    },
  ] as const
}

function useLocalStorageStateRef() {
  const localStorageRef = useRef<Record<string, string>>(
    typeof window !== "undefined" ? { ...localStorage } : {}
  )

  const setWithLocalStorage = (key: string, nextState: string) => {
    if (nextState !== "") localStorage.setItem(key, nextState)
    else localStorage.removeItem(key)

    localStorageRef.current = { ...localStorage }
  }

  return [localStorageRef, setWithLocalStorage] as const
}

// * This hook behaves like a useRef for localStorage,
// * so it won't re-render the component when the value changes
// * it also returns a function to set the value
export function useLocalStorageRef<T>(key: string, defaultValue?: T) {
  const [localStorageRef, setLocalStorage] = useLocalStorageStateRef()

  const getValue = useCallback(() => {
    return tryCatch<T>(
      () => JSON.parse(localStorageRef.current[key]),
      (localStorageRef.current[key] ?? defaultValue) as T
    )
  }, [defaultValue, key, localStorageRef])

  return [
    // getter
    getValue,
    // setter
    (newValue: T) => {
      const stringValue =
        typeof newValue === "string" ? newValue : JSON.stringify(newValue)

      setLocalStorage(key, stringValue)
    },
    // cleaner
    () => setLocalStorage(key, ""),
  ] as const
}

const tryCatch = <T,>(func: () => T, coalesce: T): T => {
  try {
    return func()
  } catch (e) {
    return coalesce
  }
}
