import { Flex, Text, useTheme } from "@chakra-ui/react"
import { format, parse } from "date-fns"
import numeral from "numeral"
import { useCallback } from "react"
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts"
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent"
import { Unpacked } from "~/types/global"
import { theme as chakraTheme } from "~/utils/theme"

type HoverIntervals = { label: string; nBack: number }[]
type Values = { value: number; period: string }[]
interface Props {
  id: string
  reversed?: boolean
  values: Values
  yAxisValueFormat?: string
  label?: string
  color?: string
  hoverIntervals?: HoverIntervals
}

export const ChartSimplified = ({
  id,
  values,
  reversed = false,
  label = "‎",
  color,
  hoverIntervals = [
    { label: "1w", nBack: 1 },
    { label: "1mo", nBack: 4 },
  ],
}: Props): JSX.Element => {
  const theme = useTheme()

  const chartColor = color ?? theme.colors.blue[500]

  const gradientId = `simplifiedGrad-${id}`

  const valuesToShow = values.slice(
    hoverIntervals[hoverIntervals.length - 1].nBack
  )
  const renderTooltip = useCallback(
    (props: TooltipProps<ValueType, NameType>) => {
      const { active, payload } = props

      if (!active || !payload) {
        return null
      }

      const { period, value } = payload[0].payload

      const currentIndex = values.findIndex((v) => v.period === period)

      const valuesToRender = [
        { label: period, value },
        ...hoverIntervals.map(({ label, nBack }) => {
          const item = values[currentIndex - nBack] as Unpacked<Values>
          return { label, value: value - (item?.value ?? 0) }
        }),
      ]

      return (
        <Flex
          backgroundColor={theme.colors.gray[800]}
          borderRadius="5px"
          minWidth="75px"
          p={3}
          gap={3}
        >
          {valuesToRender.map(({ label, value }, idx) => (
            <Flex
              key={label}
              direction="column"
              alignItems="center"
              justifyContent="center"
              gap={1}
            >
              <Text fontSize="x-small" color={theme.colors.gray[300]}>
                {label}
              </Text>
              <Text
                fontSize="xs"
                color={idx === 0 ? theme.colors.white : theme.colors.green[500]}
              >
                {idx !== 0 && "+"}
                {numeral(value).format("0.[00]a")}
              </Text>
            </Flex>
          ))}
        </Flex>
      )
    },
    [hoverIntervals, theme, values]
  )

  return (
    <ResponsiveContainer width="100%" height={110}>
      <AreaChart data={valuesToShow}>
        <defs>
          <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={chartColor} stopOpacity={0.5} />
            <stop offset="100%" stopColor={chartColor} stopOpacity={0} />
          </linearGradient>
        </defs>
        <YAxis
          hide
          reversed={reversed}
          dataKey="value"
          allowDecimals={false}
          domain={([dataMin, dataMax]) => [
            Math.min(dataMin - (dataMax - dataMin), dataMin),
            dataMax,
          ]}
        />
        <XAxis
          dataKey="period"
          fontSize="12px"
          axisLine={false}
          tickLine={false}
          interval="preserveStartEnd"
          ticks={[
            valuesToShow[0]?.period,
            valuesToShow[valuesToShow.length - 1]?.period,
          ]}
          tickFormatter={(value) => value.slice(0, 3)}
        />
        <Area
          type="monotone"
          dataKey="value"
          name={label}
          stroke={chartColor}
          fill={`url(#${gradientId})`}
          connectNulls
          strokeWidth={2}
        />
        <Tooltip
          content={renderTooltip}
          wrapperStyle={{
            outline: "none",
          }}
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export const TooltipSimplifiedProps = <
  TValue extends ValueType,
  TName extends NameType
>(
  theme: typeof chakraTheme,
  yAxisValueFormat: string = "0.[00]a",
  label: string = ""
): TooltipProps<TValue, TName> => ({
  separator: label ? ": " : "",
  formatter: (value: TValue) =>
    numeral(value).format(yAxisValueFormat) as TValue,
  labelFormatter: (value: string) =>
    format(parse(value, "yyyy-MM-dd", new Date()), "MMM yyyy"),
  wrapperStyle: {
    outline: "none",
  },
  contentStyle: {
    border: "none",
    backgroundColor: theme.colors.gray[800],
    borderRadius: "5px",
    minWidth: "75px",
  },
  labelStyle: {
    fontSize: "x-small",
    color: theme.colors.gray[300],
  },
  itemStyle: {
    color: theme.colors.white,
    fontSize: "small",
  },
  cursor: {
    stroke: theme.colors.gray[400],
    strokeWidth: 1,
    strokeDasharray: "6 6",
  },
})
