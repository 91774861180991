import { IconButton, Image, Menu, MenuButton, MenuList } from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { useIntegrations } from "~/utils/hooks/useIntegrations"
import { AffinityImportSearch } from "./AffinityImportSearch"
import { AffinitySearchExport } from "./AffinitySearchExport"

interface Props {
  product: SpecterProducts
}

export const AffinityFeedMenu = ({ product }: Props) => {
  const integration = useIntegrations()

  if (
    (product !== SpecterProducts.talent &&
      product !== SpecterProducts.company &&
      product !== SpecterProducts.stratintel) ||
    (product === SpecterProducts.talent &&
      !integration.data?.integrations.hasAffinityTalentList) ||
    (product == SpecterProducts.stratintel &&
      !integration.data?.integrations.hasAffinityStrategicList) ||
    (product == SpecterProducts.company &&
      !integration.data?.integrations.hasAffinityCompanyList)
  ) {
    return null
  }

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Export Results"
        variant="outline"
        icon={<Image src="/affinity-logo.svg" w="10px" />}
      ></MenuButton>

      <MenuList
        color="gray.800"
        fontSize="sm"
        fontWeight="semibold"
        minWidth="fit-content"
      >
        <AffinityImportSearch product={product} />
        <AffinitySearchExport product={product} />
      </MenuList>
    </Menu>
  )
}
