type FontPropType = string
let canvas: HTMLCanvasElement

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana"), or the HTML element to get the font from.
 */
export const getTextWidth = (
  text: string,
  font: FontPropType | HTMLElement
): number => {
  // re-use canvas object for better performance
  canvas = canvas ?? document.createElement("canvas")

  const context = canvas.getContext("2d")

  if (!context) throw new Error("Could not get canvas context")

  context.font = font instanceof HTMLElement ? getCanvasFont(font) : font
  const metrics = context.measureText(text)
  return metrics.width
}

export const getCssStyle = (element: HTMLElement, prop: string) => {
  return window.getComputedStyle(element, null).getPropertyValue(prop)
}

export const getCanvasFont = (el: HTMLElement = document.body) => {
  const fontWeight = getCssStyle(el, "font-weight") || "normal"
  const fontSize = getCssStyle(el, "font-size") || "16px"
  const fontFamily = getCssStyle(el, "font-family") || "Times New Roman"

  return `${fontWeight} ${fontSize} ${fontFamily}`
}
