import { Select } from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { useUserLists } from "~/utils/hooks/useUserLists"

interface Props {
  product: SpecterProducts
  value: string
  onChange: (value: string) => void
}

export const ListFilterSelect = ({ product, value, onChange }: Props) => {
  const mappedProduct =
    product === SpecterProducts.fundingRounds
      ? SpecterProducts.company
      : product

  const listsQuery = useUserLists({ product: mappedProduct })

  return (
    <Select
      value={value ?? ""}
      onChange={(e) => onChange(e.currentTarget.value)}
      placeholder="Select a list..."
    >
      {listsQuery.data?.map((list) => {
        return (
          <option key={list.id} value={list.id}>
            {list.name}
          </option>
        )
      })}
    </Select>
  )
}
