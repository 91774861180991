import { Button, Flex, Heading, Spacer, Switch, Tag } from "@chakra-ui/react"
import {
  Location,
  useLocation,
  useNavigate,
  useNavigation,
  Link as RemixLink,
} from "@remix-run/react"
import { PiTableBold } from "react-icons/pi"
import { PiListBold } from "react-icons/pi"
import { PRODUCT_VIEW_MODES } from "~/routes/__protected/signals/$product"
import { getPeriodOfDate } from "~/utils/datetime"
import { useBgColor } from "~/utils/hooks/useBgColor"
import { useUserPermissions } from "~/utils/hooks/useUserPermissions"
import { ViewMode } from "~/utils/hooks/useViewMode"
import { useViewMode } from "~/utils/hooks/useViewMode"
import { isNullish } from "~/utils/values"
import { generateURLFactory } from "../UserSearchesPage/SavedSearchesTable"
import { SignalStatus } from "../SignalStatus"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"
import { useProduct } from "~/utils/hooks/useProduct"
import { createSearchQuery } from "../UserSearchesPage/utils"

type Route = string | { [key: string]: Route }

const PAGE_TITLES: Exclude<Route, string> = {
  "/": "Home",
  "/account": "Account Settings",
  "/import": "Import List",
  "/admin": {
    "/debug": "Debug",
    "/permissions": "Access Management",
    "/global-hub": "Global Hub",
    "/companies": "Add Companies",
  },
  "/signals": {
    "/strategic": "Investors",
    "/talent": "People",
    "/company": "Companies",
    "/investors": "Investors",
    "/funding-rounds": "Funding Rounds",
    "/acquisition": "Acquisitions",
    "/ipo": "IPOs",
    "/people": "People",
  },
  "/user": {
    "/lists": {
      "/company": "Company Lists",
      "/talent": "Talent Lists",
      "/strategic": "Strategic Lists",
    },
    "/searches": {
      "/company": "Company Searches",
      "/talent": "Talent Searches",
      "/strategic": "Strategic Searches",
    },
    "/landscapes": "Landscapes",
  },
  "/team": "Team Settings",
}

export const NEW_FEATURED_ROUTES: string[] = []
export const BETA_FEATURED_ROUTES: string[] = []

export const HEADER_HEIGHT = 50

const ViewModeToggle = () => {
  const [searchParams, setSearchParams] = useSafeSearchParams()
  const sort = searchParams.get("sort") ?? ""
  const searchId = searchParams.get("searchId")
  const viewMode = useViewMode()
  const product = useProduct()
  const generateURLFeed = generateURLFactory({
    searchParams,
    setSearchParams,
    product,
    viewMode: ViewMode.Feed,
    query: JSON.parse(searchParams.get("query") || "null"),
    sort,
    searchId: searchId ? Number(searchId) : undefined,
    search: searchParams.get("search"),
  })

  const generateURLTable = generateURLFactory({
    searchParams,
    setSearchParams,
    product,
    viewMode: ViewMode.Table,
    query: JSON.parse(searchParams.get("query") || "null"),
    sort,
    searchId: searchId ? Number(searchId) : undefined,
    search: searchParams.get("search"),
  })

  const [toFeed, onNavigateFeed] = generateURLFeed(createSearchQuery)
  const [toTable, onNavigateTable] = generateURLTable(createSearchQuery)

  return (
    <Flex
      rounded="lg"
      overflow="hidden"
      p={0.5}
      gap={0.5}
      bgColor="white"
      borderWidth={1}
      borderColor="gray.200"
      shadow="sm"
    >
      <Button
        leftIcon={<PiListBold />}
        variant={viewMode === "feed" ? "solid" : "ghost"}
        colorScheme={viewMode === "feed" ? "brand" : undefined}
        as={RemixLink}
        size="xs"
        to={toFeed}
        onClick={() => {
          onNavigateFeed()
        }}
      >
        Feed
      </Button>
      <Button
        leftIcon={<PiTableBold />}
        variant={viewMode === "table" ? "solid" : "ghost"}
        colorScheme={viewMode === "table" ? "brand" : undefined}
        as={RemixLink}
        size="xs"
        to={toTable}
        intercom-target="view-table"
        onClick={() => {
          onNavigateTable()
        }}
      >
        Table
      </Button>
    </Flex>
  )
}

export const Header = () => {
  const bgColor = useBgColor()
  const location = useLocation()
  const permissions = useUserPermissions()
  const firstName = permissions.data?.first_name
    ? `, ${permissions.data.first_name}`
    : ""
  const navigate = useNavigate()
  const product = useProduct({ enforce: false })

  const pageTitle =
    location.pathname === "/"
      ? `Good ${getPeriodOfDate(new Date())}${firstName}`
      : getPageTitle(location)

  const isNew = NEW_FEATURED_ROUTES.some((route) =>
    location.pathname?.includes(route)
  )

  const isFullScreen = location.pathname.endsWith("/f")

  const isTalentOrPeople =
    location.pathname.includes("/signals/talent") ||
    location.pathname.includes("/signals/people")

  const isStrategicOrInvestors =
    location.pathname.includes("/signals/strategic") ||
    location.pathname.includes("/signals/investors")

  const toggleLiveSignals = isTalentOrPeople
    ? {
        label: "Live Talent Signals",
        link: location.pathname.includes("/signals/talent")
          ? "/signals/people"
          : "/signals/talent",
        isChecked: location.pathname.includes("/signals/talent"),
      }
    : isStrategicOrInvestors
    ? {
        label: "Live Investors Signals",
        link: location.pathname.includes("/signals/strategic")
          ? "/signals/investors"
          : "/signals/strategic",
        isChecked: location.pathname.includes("/signals/strategic"),
      }
    : null

  const navigation = useNavigation()

  return (
    <Flex
      as="header"
      className="grid-area-header"
      position="sticky"
      top={0}
      transition="all 0.2s"
      alignItems="center"
      borderBottomWidth={1}
      borderColor="gray.100"
      px={6}
      h={`${HEADER_HEIGHT}px`}
      bg={bgColor}
      zIndex={200}
      borderTopRadius="xl"
      gap={2}
    >
      <Heading
        fontSize="sm"
        fontWeight="semibold"
        display="inline-flex"
        alignItems="center"
        gap={3}
      >
        {pageTitle}
        {isNew && pageTitle && <Tag colorScheme="brand">New</Tag>}
      </Heading>

      {toggleLiveSignals && (
        <>
          <Switch
            colorScheme="brand"
            isChecked={toggleLiveSignals.isChecked}
            onChange={() => navigate(toggleLiveSignals.link)}
            isDisabled={navigation.state === "loading"}
            {...(navigation.state === "loading" && {
              sx: {
                "& > span": {
                  cursor: "wait !important",
                },
              },
            })}
          />

          <SignalStatus label={toggleLiveSignals.label} color="brand.500" />
        </>
      )}

      <Spacer />

      {product &&
        location.pathname.includes("/signals") &&
        PRODUCT_VIEW_MODES[product].length > 1 &&
        !isFullScreen && <ViewModeToggle />}
    </Flex>
  )
}

export const getPageTitle = (location: Location): string => {
  const path = location.pathname

  if (path === "/") {
    return PAGE_TITLES["/"] as string
  }

  const pathParts = path.split("/").filter((part) => part !== "")

  return getFromTree(PAGE_TITLES, pathParts, "")
}

const getFromTree = (
  tree: Exclude<Route, string>,
  pathParts: string[],
  fallback: string
): string => {
  const [part, ...rest] = pathParts

  if (isNullish(part)) {
    return fallback
  }

  const nextTree = tree[`/${part}`]

  if (isNullish(nextTree)) {
    return fallback
  }

  if (typeof nextTree === "string") {
    return nextTree
  }

  const newFallback =
    typeof nextTree["/"] === "string" ? nextTree["/"] : fallback

  return getFromTree(nextTree, rest, newFallback)
}
