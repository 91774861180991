import {
  Menu,
  MenuButton,
  useDisclosure,
  MenuList,
  Image,
  Button,
} from "@chakra-ui/react"
import { TbCube } from "react-icons/tb"
import { IntegrationMeta } from "~/utils/hooks/useIntegrationMeta"
import { useIntegrations } from "~/utils/hooks/useIntegrations"
import { getIntegrationLogo, getIntegrationName } from "~/utils/integrationApp"
import { IntegrationPushSearchModal } from "../IntegrationPushSearchModal"
import { MenuItem } from "../MenuItem"

interface IntegrationActionProps {
  integration: IntegrationMeta
  singleAction: boolean
  MenuListction?: boolean
}

const IntegrationAction = ({
  integration,
  singleAction = false,
}: IntegrationActionProps) => {
  const modalState = useDisclosure()
  const integrationName = getIntegrationName(integration.integrationKey)

  return (
    <>
      {singleAction && (
        <Button
          variant="outline"
          leftIcon={
            <Image w={4} src={getIntegrationLogo(integration.integrationKey)} />
          }
          onClick={() => {
            modalState.onOpen()
          }}
        >
          Add to {integrationName}
        </Button>
      )}

      {!singleAction && (
        <MenuItem
          py={1}
          onClick={() => {
            modalState.onOpen()
          }}
        >
          Add to {integrationName}
        </MenuItem>
      )}

      <IntegrationPushSearchModal
        isOpen={modalState.isOpen}
        onClose={modalState.onClose}
        integrationKey={integration.integrationKey}
      />
    </>
  )
}

export const IntegrationSearchButton = () => {
  const integrations = useIntegrations()
  const connectedIntegrations = integrations.data?.integrations.integrationsMeta

  if ((connectedIntegrations?.length ?? 0) <= 0) {
    return null
  }

  if (connectedIntegrations && connectedIntegrations.length === 1) {
    return (
      <IntegrationAction singleAction integration={connectedIntegrations[0]} />
    )
  }

  return (
    <>
      <Menu>
        <MenuButton as={Button} leftIcon={<TbCube />} variant="outline">
          Add to CRM
        </MenuButton>

        <MenuList zIndex={999} p={2}>
          {connectedIntegrations?.map((item) => {
            return (
              <IntegrationAction
                key={item.integrationKey}
                integration={item}
                singleAction={false}
              />
            )
          })}
        </MenuList>
      </Menu>
    </>
  )
}
