import { Divider, Flex, FlexProps, Heading } from "@chakra-ui/react"
import { ReactNode } from "react"

type Props = Omit<FlexProps, "title"> & {
  title: ReactNode
  subtitle?: ReactNode
}

export const TabSubsectionTitle = ({ title, subtitle, ...props }: Props) => (
  <Flex alignItems="center" mb={4} gap={4} {...props}>
    <Heading
      as="h2"
      fontSize={{ base: "sm", lg: "md" }}
      w="fit-content"
      sx={{
        breakAfter: "avoid",
      }}
      display="inline-flex"
      gap={2}
      alignItems="center"
    >
      {title}
    </Heading>
    <Divider flex={1} />
    {subtitle}
  </Flex>
)
