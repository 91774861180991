import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { useRef } from "react"
import { HiTrash } from "react-icons/hi"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { cacheKeys } from "~/utils/cacheKeys"
import invariant from "~/utils/invariant"
import { SpecterProducts } from "@prisma/client"
import { MenuItem } from "../MenuItem"

interface Props {
  queryId: number
  product: SpecterProducts
}

export const DeleteSavedSearch = ({ queryId, product }: Props): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef(null)
  const toast = useToast()
  const queryClient = useQueryClient()

  const deleteMutation = useMutation(
    async () => {
      const res = await fetch(`/api/saved-searches/${queryId}/delete`, {
        method: "delete",
      })

      invariant(res.ok, "Failed to delete saved search")

      const data = await res.json()

      return data
    },
    {
      onSuccess() {
        queryClient.refetchQueries(cacheKeys.userSavedSearches(product))
        onClose()
        toast({
          status: "success",
          title: "Saved search deleted",
        })
      },
    }
  )

  return (
    <>
      <MenuItem icon={HiTrash} onClick={onOpen}>
        Delete
      </MenuItem>

      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontWeight="bold">
              Delete Saved Search
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this saved search?
            </AlertDialogBody>

            <AlertDialogFooter gap={2}>
              <Button
                onClick={onClose}
                ref={cancelRef}
                variant="outline"
                size="sm"
              >
                Cancel
              </Button>
              <Button
                onClick={() => deleteMutation.mutate()}
                isLoading={deleteMutation.isLoading}
                disabled={deleteMutation.isLoading}
                colorScheme="red"
                size="sm"
              >
                Yes, delete this search
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
