import {
  Box,
  Button,
  Flex,
  keyframes,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  Text,
  useTheme,
} from "@chakra-ui/react"

import { SpecterProducts } from "@prisma/client"
import { FormikProps } from "formik"
import * as R from "ramda"
import { createRef, useCallback, useRef, useState } from "react"
import { ZodFirstPartySchemaTypes, ZodObject } from "zod"
import { Card, FormikErrors, NEW_FEATURED_FILTER_SECTIONS } from "~/components"
import { DeprecatedFieldsAlert } from "~/components/DeprecatedFieldsAlert"
import { MenuItem } from "~/components/MenuItem"
import { getDeprecatedFields } from "~/utils/checkForBackwardsCompatibility"
import { useAnalytics } from "~/utils/hooks/useAnalytics"
import { useEnv } from "~/utils/hooks/useEnv"
import { useProductSignalFilters } from "~/utils/hooks/useProductFilters"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"
import { JSONSafeParse } from "~/utils/JSONSafeParse"
import { relativeUrl } from "~/utils/string/url"
import { updateSearchParams } from "~/utils/updateSearchParams"
import { useScroll } from "~/utils/useScroll"
import {
  FiltersSchema,
  mapProductsToRouteName,
  PRODUCT_FILTERS_SCHEMA,
  SignalFilters,
} from "../schemas"
import { Dot } from "./AdvancedFilterItem"
import { AdvancedFilterTab } from "./AdvancedFilterTab"

interface Props<P extends SpecterProducts> {
  product: P
  isOpen: boolean
  onClose: () => void
}

const HEADER_HEIGHT = 57
const PADDING_TOP = 16

export const AdvancedFiltersModal = <P extends SpecterProducts>({
  product,
  isOpen,
  onClose,
}: Props<P>): JSX.Element => {
  const schema = PRODUCT_FILTERS_SCHEMA[product] as FiltersSchema
  const formik = useProductSignalFilters({
    product,
    onSubmit() {
      onClose()
      formik.resetForm()
    },
  }) as FormikProps<SignalFilters[P]>
  const [searchParams] = useSafeSearchParams()

  const [currentTab, setCurrentTab] = useState<number | null>(null)

  const scrollRef = useRef<HTMLDivElement>(null)
  const suspendActiveTabChanging = useRef<boolean>(false)

  const N_TABS = Object.keys(schema.shape).length

  const tabRefs = useRef(
    Array.from({ length: N_TABS }).map(() => createRef<HTMLDivElement>())
  )

  const handleScroll = useCallback((_scrollPos: number) => {
    if (suspendActiveTabChanging.current) return

    // tabRefs.current.forEach(({ current: sec }) => {
    //   if (!sec) return

    //   const top = scrollPos ?? 0
    //   const offset = sec.offsetTop - 200
    //   const height = sec.offsetHeight
    //   const id = sec.querySelector("h2")?.innerText

    //   if (top >= offset && top < offset + height) {
    //     updateActiveLink(id)
    //   }
    // })
  }, [])

  const handleScrollEnd = useCallback(() => {
    suspendActiveTabChanging.current = false
  }, [])

  useScroll({
    target: scrollRef.current ?? undefined,
    onScroll: handleScroll,
    onScrollEnd: handleScrollEnd,
  })

  const scrollToTargetAdjusted = useCallback(
    (element: HTMLElement | null, instant?: boolean) => {
      if (!element) return

      const elementPosition = element.offsetTop - HEADER_HEIGHT - PADDING_TOP

      scrollRef.current?.scrollTo({
        top: elementPosition,
        behavior: instant ? "auto" : "smooth",
      })
    },
    []
  )

  const handleChangeTab = useCallback(
    (i: number, instant?: boolean) => {
      const tabRef = tabRefs.current[i]
      // const tabLinkRef = tabLinkRefs.current[i]

      suspendActiveTabChanging.current = true
      // updateActiveLink(tabLinkRef.current?.innerText)

      scrollToTargetAdjusted(tabRef.current, instant)
    },
    [scrollToTargetAdjusted]
  )

  // useEffect(() => {
  //   if (validTab === 0) return

  //   handleChangeTab(validTab, true)
  //   // * We want it to run once on mount
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  const initialFocusRef = useRef<HTMLButtonElement>(null)

  const handleCloseModal = () => {
    setCurrentTab(null)
    onClose()
  }

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement> | undefined) => {
    formik.handleSubmit(e)
  }

  const analytics = useAnalytics()
  const env = useEnv()
  const query = JSONSafeParse<Record<string, any>>(searchParams.get("query"))
  const deprecatedFields = getDeprecatedFields(product, query)

  const theme = useTheme()

  const animation = keyframes`
  0% {
    background-color: transparent;
  }
  50% {    
    background-color: ${theme.colors.brand[50]};
  }
  100% {    
    background-color: transparent;
  }
`

  const slowlyBlink = `1s ${animation} forwards 0.5s`

  return (
    <Modal
      onClose={handleCloseModal}
      isOpen={isOpen}
      id="drawer"
      size="5xl"
      isCentered
      initialFocusRef={initialFocusRef}
    >
      <ModalOverlay />
      <ModalContent rounded="2xl" p={1}>
        <Box
          overflow="hidden"
          rounded="xl"
          borderWidth={1}
          borderColor="gray.100"
        >
          <form onSubmit={handleSubmit}>
            <ModalCloseButton />

            {deprecatedFields.length > 0 && (
              <Box p={2} borderBottomWidth={1} borderBottomColor="gray.100">
                <DeprecatedFieldsAlert
                  deprecatedFields={deprecatedFields}
                  query={query}
                />
              </Box>
            )}

            <ModalBody
              display="flex"
              p={0}
              h="clamp(36rem, 68vh, 42rem)"
              overflow="auto"
            >
              <Flex
                direction="column"
                bgColor="gray.50"
                borderRightWidth={1}
                borderColor="gray.100"
                gap={1}
                p={2}
                minW="181px" // Only adding this width because of a flick when the biggest tab is selected
              >
                {Object.keys(schema.shape).map((key, index) => {
                  const tabKey = key as keyof typeof schema.shape

                  const isDirtySection = (
                    section: ZodObject<{
                      [itemKey: string]: ZodFirstPartySchemaTypes
                    }>
                  ) =>
                    Object.keys(section.shape).some(
                      (key) =>
                        formik.values[key as keyof typeof formik.values] !==
                        undefined
                    )

                  const isDirty = Object.values(
                    schema.shape[tabKey].shape
                  ).some((sectionSchema) => isDirtySection(sectionSchema))

                  const isNew = NEW_FEATURED_FILTER_SECTIONS.includes(key)

                  return (
                    <MenuItem
                      key={key}
                      isActive={(currentTab ?? 0) === index}
                      onClick={() => {
                        setCurrentTab(index)
                        handleChangeTab(index)
                      }}
                      mr={0.5}
                      hideActiveDot
                    >
                      <Text whiteSpace="nowrap">{key}</Text>
                      {isNew && (
                        <Tag colorScheme="brand" ml={2}>
                          New
                        </Tag>
                      )}
                      {isDirty && <Dot />}
                    </MenuItem>
                  )
                })}
              </Flex>
              <Flex direction="column" flexGrow={1}>
                <ModalHeader>Advanced Filters</ModalHeader>
                <Box overflow="auto" flexGrow={1} ref={scrollRef}>
                  <FormikErrors formik={formik} />

                  <Flex gap={4} direction="column" p={`${PADDING_TOP}px`}>
                    {Object.entries(schema.shape).map(([key, section], idx) => (
                      <Card
                        key={key}
                        as="section"
                        ref={tabRefs.current[idx]}
                        p={4}
                        display="flex"
                        flexDirection="column"
                        gap={4}
                        {...(currentTab === idx && { animation: slowlyBlink })}
                      >
                        {/* {idx !== 0 && <FlexDivider orientation="horizontal" />} */}
                        <AdvancedFilterTab
                          product={product}
                          formik={formik}
                          schema={section}
                        />
                      </Card>
                    ))}
                  </Flex>
                </Box>
                <ModalFooter gap={2}>
                  <Button
                    type="submit"
                    colorScheme="brand"
                    pointerEvents="all"
                    disabled={
                      !formik.dirty || Object.keys(formik.errors).length > 0
                    }
                    onClick={() => {
                      const newSearchParams = updateSearchParams(searchParams, {
                        query: formik.values,
                      })

                      const searchURL = relativeUrl(
                        `${env.APP_URL}/signals/${mapProductsToRouteName(
                          product
                        )}/feed`,
                        newSearchParams.toString()
                      )

                      analytics.track("Applied a Filter", {
                        menu: "Advanced Filters",
                        product,
                        searchURL,
                        query: formik.values,
                      })
                    }}
                  >
                    Apply Filters
                  </Button>
                  <Button
                    variant="outline"
                    borderColor="red.400"
                    color="red.400"
                    pointerEvents="all"
                    onClick={() => formik.setValues({})}
                    isDisabled={R.isEmpty(formik.values)}
                  >
                    Clear All
                  </Button>
                </ModalFooter>
              </Flex>
            </ModalBody>
          </form>
        </Box>
      </ModalContent>
    </Modal>
  )
}
