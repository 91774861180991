import { SpecterProducts } from "@prisma/client"
import * as R from "ramda"
import {
  StrategicSignalType,
  STRATEGIC_SIGNAL_TYPE,
} from "~/consts/strategicSignals"
import { getProductSignalSearchParams } from "./hooks/useProductFilters"

export const countAppliedStrategicSignalFilters = (
  searchParams: URLSearchParams
) => {
  const allFilters = getProductSignalSearchParams(
    SpecterProducts.stratintel,
    searchParams
  )
  const appliedFilters = R.reject(R.isEmpty, allFilters)

  return Object.keys(appliedFilters).length
}

export const getColorFromSignalType = (signalType: StrategicSignalType) => {
  switch (signalType) {
    case STRATEGIC_SIGNAL_TYPE.COMPANY:
      return "brand.600"
    case STRATEGIC_SIGNAL_TYPE.TALENT:
      return "warning"
    default:
      return "gray.300"
  }
}
