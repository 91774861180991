import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { useMutation } from "@tanstack/react-query"
import numeral from "numeral"
import { HiDownload } from "react-icons/hi"
import { mapProductsToRouteName } from "~/components/Filters/schemas"
import { SEARCH_EXPORT_LIMIT } from "~/consts/signals"
import { useAnalytics } from "~/utils/hooks/useAnalytics"
import { useEnv } from "~/utils/hooks/useEnv"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"
import { useUserPermissions } from "~/utils/hooks/useUserPermissions"
import invariant from "~/utils/invariant"
import { TrialModal } from "../TrialModal"

interface Props {
  product: SpecterProducts
}

export const SignalExport = ({ product }: Props): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const trailModal = useDisclosure()
  const toast = useToast()
  const analytics = useAnalytics()
  const env = useEnv()
  const [searchParams] = useSafeSearchParams()
  const permissionsQuery = useUserPermissions()

  const isTrial =
    permissionsQuery.data?.organization?.companyTrial ||
    permissionsQuery.data?.organization?.stratintelTrial ||
    permissionsQuery.data?.organization?.talentTrial

  const productRouteName = mapProductsToRouteName(product)

  const exportMutation = useMutation<string | null>(
    async () => {
      const req = await fetch(`/api/signals/${productRouteName}/export`, {
        method: "POST",
        body: JSON.stringify(Object.fromEntries(searchParams.entries())),
      })

      invariant(req.ok, "Failed to run your export request")

      const url = new URL(window.location.href)
      // If query is too big, search URL will not show the query params.
      const searchURL = `${env.APP_URL}/signals/${productRouteName}/feed${url.search}`

      analytics.track("Export", {
        product,
        type: "search",
        searchURL,
        query: searchParams.toString(),
      })

      const blob = await req.blob()

      const blobUrl = URL.createObjectURL(blob)

      return blobUrl
    },
    {
      onMutate() {
        onClose()
        toast({
          status: "info",
          title: "Your export is being created",
          description: "This may take a few minutes.",
        })
      },
      onSuccess(url) {
        if (url) {
          const link = document.createElement("a")
          link.href = url
          link.click()
        }
      },
    }
  )

  return (
    <>
      <Button
        variant="outline"
        leftIcon={<HiDownload />}
        disabled={exportMutation.isLoading}
        isLoading={exportMutation.isLoading}
        onClick={() => {
          if (isTrial) {
            trailModal.onOpen()
          } else {
            onOpen()
          }
        }}
        intercom-target="export"
      >
        Export
      </Button>

      <TrialModal
        product="Exports"
        isOpen={trailModal.isOpen}
        onClose={() => trailModal.onClose()}
      />

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Export Results</ModalHeader>
          <ModalBody>
            <Text fontSize="sm">
              {`Export is limited to the first ${numeral(
                SEARCH_EXPORT_LIMIT
              ).format("0,0")} results. If you'd like to
              export all results, please refine your search by including
              additional search filter(s).`}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Flex gap={2}>
              <Button
                variant="outline"
                onClick={onClose}
                disabled={exportMutation.isLoading}
              >
                Cancel
              </Button>
              <Button
                colorScheme="brand"
                onClick={() => exportMutation.mutate()}
                disabled={exportMutation.isLoading}
                isLoading={exportMutation.isLoading}
              >
                Start export
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
