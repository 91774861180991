import { Button, Text, useToast } from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { useMutation } from "@tanstack/react-query"
import debounce from "debounce"
import { FormikProps } from "formik"
import { singular } from "pluralize"
import { sentenceCase } from "sentence-case"
import { titleCase } from "title-case"
import { z, ZodFirstPartySchemaTypes } from "zod"
import { AsyncReactSelect, CompanyLogo, getOptionLabel } from "~/components"
import { EXCLUDE_PREFIX } from "~/consts/signals"
import {
  AsyncInputProperty,
  ASYNC_INPUT_FIELDS_TO_PROPERTY,
} from "~/routes/__protected/api/input-options.$product.$property"
import { cacheKeys } from "~/utils/cacheKeys"
import { ASYNC_INPUT_FIELD_PROPS, GetOptionsReturn } from "~/utils/db/options"
import { isOneOf } from "~/utils/isOneOf"
import { queryClient } from "~/utils/queryClient"

// These are the async properties that we want to cache the names for
export const ASYNC_PROPERTIES_WITH_NAMING = [
  "investors",
  "company",
  "source",
  "location",
  "education",
  "languages",
] satisfies AsyncInputProperty[]

export type AsyncPropertiesWithNaming =
  (typeof ASYNC_PROPERTIES_WITH_NAMING)[number]

interface Props<
  Schema extends ZodFirstPartySchemaTypes,
  Value extends z.infer<Schema>
> {
  field: string
  product: SpecterProducts
  onChange: (selected: string[]) => void
  onBlur: FormikProps<Value>["handleBlur"]
  value: Value
}

export function AutoCompleteSelect<
  Schema extends ZodFirstPartySchemaTypes,
  Value extends z.infer<Schema>
>({ field, product, onChange, value }: Props<Schema, Value>) {
  const asyncProperty = ASYNC_INPUT_FIELDS_TO_PROPERTY[field]

  const debouncedLoadOptions = debounce(
    (searchTerm: string, callback: (data: GetOptionsReturn) => any) => {
      fetch(`/api/input-options/${product}/${asyncProperty}?q=${searchTerm}`)
        .then((result) => result.json())
        .then((data: GetOptionsReturn) => callback(data))
    },
    250
  )

  const toast = useToast()

  const requestSpecterToTrackMutation = useMutation(
    async (inputValue: string) => {
      const res = await fetch(
        `/api/input-options/${product}/${asyncProperty}`,
        {
          method: "POST",
          body: JSON.stringify({ name: inputValue }),
          headers: { "Content-Type": "application/json" },
        }
      )

      if (!res.ok) {
        throw new Error("Failed to submit request")
      }
    },
    {
      onSuccess() {
        toast({
          status: "success",
          title: "Your request has been submitted",
        })
      },
      onError() {
        toast({
          status: "error",
          title: "Failed to submit request",
        })
      },
    }
  )

  const {
    acceptFreeText,
    hasOptionsByDefault,
    noOptionsMessage,
    placeholderText,
  } = ASYNC_INPUT_FIELD_PROPS[asyncProperty]

  // const SelectComponent = acceptFreeText
  //   ? AsyncCreatableReactSelect
  //   : AsyncReactSelect

  const SelectComponent = AsyncReactSelect

  return (
    <SelectComponent
      isMulti
      cacheOptions
      inputId={field}
      instanceId={"abc"}
      isClearable={true}
      placeholder={placeholderText ?? "All"}
      defaultOptions={hasOptionsByDefault}
      noOptionsMessage={({ inputValue }) =>
        inputValue.length ? (
          <>
            No results found for "{inputValue}"<br />
            {acceptFreeText &&
              requestSpecterToTrackMutation.status !== "success" && (
                <Button
                  variant="link"
                  color="brand.500"
                  onClick={() =>
                    requestSpecterToTrackMutation.mutate(inputValue)
                  }
                  isLoading={requestSpecterToTrackMutation.isLoading}
                >
                  Request Specter to track this{" "}
                  {singular(titleCase(sentenceCase(asyncProperty)))}.
                </Button>
              )}
          </>
        ) : (
          noOptionsMessage ?? "Type to see options..."
        )
      }
      loadOptions={debouncedLoadOptions}
      onChange={(selected) => {
        // We return the label as the Select widget is expecting to
        // return a string to match against
        onChange(selected.map((option: any) => option.value))

        if (isOneOf(asyncProperty, ASYNC_PROPERTIES_WITH_NAMING)) {
          queryClient.setQueryData(
            cacheKeys.inputOptions(asyncProperty),
            (options?: Record<string, GetOptionsReturn[number]>) => ({
              ...options,
              ...Object.fromEntries(
                selected.map((r) => [r.value.replace(EXCLUDE_PREFIX, ""), r])
              ),
            })
          )
        }
      }}
      value={
        value?.map((option: any) => ({
          label: getOptionLabel(option, field, product),
          value: option,
        })) ?? []
      }
      formatOptionLabel={(option) => {
        const prefix = ("logoUrl" in option || "domain" in option) && (
          <CompanyLogo
            domain={option.domain ?? null}
            source={option.logoUrl}
            size={6}
            mr={2}
          />
        )

        return (
          <>
            {prefix}
            {option.label}
            {option.domain && (
              <Text
                as="span"
                fontSize="xs"
                color="gray.400"
                ml={2}
                whiteSpace="pre"
              >
                {/* ·{"  "} */}
                {option.domain}
              </Text>
            )}
          </>
        )
      }}
    />
  )
}
