import { Image, Text, Tooltip } from "@chakra-ui/react"
import {
  AcquisitionType,
  FundingType,
  GrowthStage,
  InvestorHighlight,
  InvestorType,
  StrategicSourceType,
} from "@prisma/client"
import numeral from "numeral"
import { pick } from "ramda"
import { ReactNode } from "react"
import { IconType } from "react-icons"
import {
  PiCodeLight,
  PiGlobeLight,
  PiGraduationCapLight,
  PiRocketLaunchLight,
  PiTrendUpLight,
  PiTrophyLight,
  PiUsersLight,
} from "react-icons/pi"
import { News } from "~/components/CompanySignalDetailPage/Tabs/News"
import { CompanyTableItem } from "~/utils/db/queries/company/types"
import { JSONSafeParse } from "~/utils/JSONSafeParse"
import { formatDate } from "~/utils/string/format"
import {
  MappedTuple,
  TypedArrayMap,
  TypedObjectKeys,
  UnionToTuple,
} from "~/utils/types"

import {
  HiOutlineCodeBracket,
  HiOutlineCog,
  HiOutlineCurrencyDollar,
  HiOutlineLightBulb,
  HiOutlineMegaphone,
  HiOutlineUsers,
} from "react-icons/hi2"
import { PiGavelLight, PiStrategy } from "react-icons/pi"

export const SIGNAL_REGION = [
  "Africa",
  "Asia",
  "Canada",
  "Europe",
  "LATAM",
  "MENA",
  "Oceania",
  "Russia",
  "United States",
] as const

export const EXCLUDE_PREFIX = "<exclude>:"

export const SIGNAL_REGION_OPTIONS = [
  ...SIGNAL_REGION,
  ...(SIGNAL_REGION.map(
    (value) => `${EXCLUDE_PREFIX}${value}` as const
  ) as unknown as MappedTuple<typeof SIGNAL_REGION, typeof EXCLUDE_PREFIX>),
] as const

export const SIGNAL_REGION_FLAGS = {
  Africa: "🌍",
  Asia: "🌏",
  Canada: "🇨🇦",
  Europe: "🇪🇺",
  LATAM: "🌎",
  MENA: "🌍",
  Oceania: "🌏",
  Russia: "🇷🇺",
  "United States": "🇺🇸",
} as const

export const ANNUAL_REVENUE_RANGES = {
  L_1M: "<$1M",
  B_1M_2M: "$1M-$2M",
  B_2M_5M: "$2M-$5M",
  B_5M_10M: "$5M-$10M",
  B_10M_15M: "$10M-$15M",
  B_15M_25M: "$15M-$25M",
  B_25M_50M: "$25M-$50M",
  B_50M_75M: "$50M-$75M",
  B_75M_100M: "$75M-$100M",
  B_100M_200M: "$100M-$200M",
  B_200M_500M: "$200M-$500M",
  B_500M_1B: "$500M-$1B",
  M_1B: ">$1B",
} as const

export const INDUSTRY = [
  "Administrative Services",
  "Advertising",
  "Agriculture and Farming",
  "Biotechnology",
  "Blockchain and Crypto",
  "Clothing and Apparel",
  "Commerce and Shopping",
  "Community and Lifestyle",
  "Construction",
  "Consumer Products",
  "Content and Publishing",
  "Data and Analytics",
  "DeepTech",
  "Design",
  "Education",
  "Energy",
  "Events",
  "Finance",
  "Food",
  "Gaming",
  "Government and Military",
  "Hardware",
  "Health",
  "HR and Recruiting",
  "Information Technology",
  "Internet Services",
  "Legal",
  "Lending and Investments",
  "Manufacturing",
  "Media",
  "Mobile, Platforms, and Apps",
  "Music",
  "Professional Services",
  "Real Estate",
  "Sales and Marketing",
  "Security",
  "Software",
  "Sports",
  "Sustainability",
  "Telecommunication",
  "Transportation",
  "Travel and Tourism",
] as const

export const COMPANY_SUB_INDUSTRY = {
  "Administrative Services": [
    "Archiving Service",
    "Call Center",
    "Collection Agency",
    "College Recruiting",
    "Courier Service",
    "Customer Service",
    "Debt Collections",
    "Delivery",
    "Document Preparation",
    "Employee Benefits",
    "Extermination Service",
    "Facilities Support Services",
    "Housekeeping Service",
    "Human Resources",
    "Knowledge Management",
    "Office Administration",
    "Packaging Services",
    "Physical Security",
    "Project Management",
    "Service Industry",
    "Staffing Agency",
    "Technical Support",
    "Trade Shows",
    "Virtual Workforce",
  ] as const,
  Advertising: [
    "Ad Exchange",
    "Ad Network",
    "Ad Retargeting",
    "Ad Server",
    "Ad Targeting",
    "Advertising",
    "Advertising Platforms",
    "Affiliate Marketing",
    "Local Advertising",
    "Mobile Advertising",
    "Outdoor Advertising",
    "SEM",
    "Social Media Advertising",
    "Video Advertising",
  ] as const,
  "Agriculture and Farming": [
    "AgTech",
    "Agriculture",
    "Animal Feed",
    "Aquaculture",
    "Equestrian",
    "Farming",
    "Forestry",
    "Horticulture",
    "Hydroponics",
    "Livestock",
  ] as const,
  Biotechnology: [
    "Bioinformatics",
    "Biometrics",
    "Biopharma",
    "Biotechnology",
    "Genetics",
    "Life Science",
    "Neuroscience",
    "Quantified Self",
  ] as const,
  "Blockchain and Crypto": [
    "Bitcoin",
    "Blockchain",
    "Cryptocurrency",
    "Ethereum",
    "Virtual Currency",
  ] as const,
  "Clothing and Apparel": [
    "Fashion",
    "Laundry and Dry-cleaning",
    "Lingerie",
    "Shoes",
  ] as const,
  "Commerce and Shopping": [
    "Auctions",
    "Classifieds",
    "Collectibles",
    "Consumer Reviews",
    "Coupons",
    "E-Commerce",
    "E-Commerce Platforms",
    "Flash Sale",
    "Gift",
    "Gift Card",
    "Gift Exchange",
    "Gift Registry",
    "Group Buying",
    "Local Shopping",
    "Made to Order",
    "Marketplace",
    "Online Auctions",
    "Personalization",
    "Point of Sale",
    "Price Comparison",
    "Rental",
    "Retail",
    "Retail Technology",
    "Shopping",
    "Shopping Mall",
    "Social Shopping",
    "Sporting Goods",
    "Vending and Concessions",
    "Virtual Goods",
    "Wholesale",
  ] as const,
  "Community and Lifestyle": [
    "Adult",
    "Association",
    "Baby",
    "Cannabis",
    "Charity",
    "Children",
    "Communities",
    "Dating",
    "Elderly",
    "Family",
    "Funerals",
    "Generation Y",
    "Generation Z",
    "Homeless Shelter",
    "Humanitarian",
    "LGBT",
    "Leisure",
    "Lifestyle",
    "Men's",
    "Millennials",
    "Non Profit",
    "Online Forums",
    "Parenting",
    "Peer to Peer",
    "Pet",
    "Private Social Networking",
    "Professional Networking",
    "Q&A",
    "Religion",
    "Retirement",
    "Sex Industry",
    "Sex Tech",
    "Sharing Economy",
    "Social",
    "Social Entrepreneurship",
    "Social Impact",
    "Teenagers",
    "Underserved Children",
    "Virtual World",
    "Wedding",
    "Women's",
    "Young Adults",
  ] as const,
  Construction: [
    "Architecture",
    "Building Maintenance",
    "Building Material",
    "Construction",
    "Green Building",
    "Home Decor",
    "Home Improvement",
    "Home Renovation",
    "Home Services",
    "Interior Design",
    "Landscaping",
    "Property Development",
    "Smart Building",
    "Smart Cities",
    "Smart Home",
  ] as const,
  "Consumer Products": [
    "Beauty",
    "Comics",
    "Computer",
    "Consumer Electronics",
    "Consumer Goods",
    "Cosmetics",
    "DIY",
    "Drones",
    "Electronics",
    "Eyewear",
    "Fast-Moving Consumer Goods",
    "Flowers",
    "Furniture",
    "Google Glass",
    "Green Consumer Goods",
    "Handmade",
    "Jewelry",
    "Lingerie",
    "Mobile Devices",
    "Nintendo",
    "Playstation",
    "Roku",
    "Shoes",
    "Smart Home",
    "Tobacco",
    "Toys",
    "Wearables",
    "Windows Phone",
    "Xbox",
  ] as const,
  "Content and Publishing": [
    "Blogging Platforms",
    "Content Delivery Network",
    "Content Discovery",
    "Content Syndication",
    "Creative Agency",
    "DRM",
    "EBooks",
    "Journalism",
    "News",
    "Photo Editing",
    "Photo Sharing",
    "Photography",
    "Printing",
    "Publishing",
    "Social Bookmarking",
    "Video Editing",
    "Video Streaming",
  ] as const,
  "Data and Analytics": [
    "A/B Testing",
    "Analytics",
    "Application Performance Management",
    "Artificial Intelligence",
    "Big Data",
    "Bioinformatics",
    "Biometrics",
    "Business Intelligence",
    "Consumer Research",
    "Crowdsourcing",
    "Data Integration",
    "Data Mining",
    "Data Visualization",
    "Database",
    "Facial Recognition",
    "Geospatial",
    "Image Recognition",
    "Intelligent Systems",
    "Location Based Services",
    "Machine Learning",
    "Market Research",
    "Natural Language Processing",
    "Predictive Analytics",
    "Product Management",
    "Product Research",
    "Project Management",
    "Quantified Self",
    "Speech Recognition",
    "Test and Measurement",
    "Text Analytics",
    "Usability Testing",
  ] as const,
  DeepTech: [
    "Advanced Materials",
    "Aerospace",
    "Artificial Intelligence",
    "Bioinformatics",
    "Biometrics",
    "Biopharma",
    "Biotechnology",
    "Chemical",
    "Chemical Engineering",
    "Civil Engineering",
    "Embedded Systems",
    "Environmental Engineering",
    "Human Computer Interaction",
    "Industrial Automation",
    "Industrial Engineering",
    "Intelligent Systems",
    "Laser",
    "Life Science",
    "Machine Learning",
    "Marine Technology",
    "Mechanical Engineering",
    "Nanotechnology",
    "Natural Language Processing",
    "Neuroscience",
    "Nuclear",
    "Predictive Analytics",
    "Quantum Computing",
    "Robotics",
    "STEM Education",
    "Semiconductor",
    "Software Engineering",
  ] as const,
  Design: [
    "CAD",
    "Consumer Research",
    "Data Visualization",
    "Fashion",
    "Graphic Design",
    "Human Computer Interaction",
    "Industrial Design",
    "Interior Design",
    "Market Research",
    "Mechanical Design",
    "Product Design",
    "Product Research",
    "UX Design",
    "Usability Testing",
    "Web Design",
  ] as const,
  Education: [
    "Alumni",
    "Charter Schools",
    "College Recruiting",
    "Continuing Education",
    "Corporate Training",
    "E-Learning",
    "EdTech",
    "Education",
    "Edutainment",
    "Higher Education",
    "Language Learning",
    "MOOC",
    "Music Education",
    "Personal Development",
    "Primary Education",
    "STEM Education",
    "Secondary Education",
    "Skill Assessment",
    "Textbook",
    "Training",
    "Tutoring",
    "Universities",
    "Vocational Education",
  ] as const,
  Energy: [
    "Battery",
    "Biofuel",
    "Biomass Energy",
    "Clean Energy",
    "Electrical Distribution",
    "Energy",
    "Energy Efficiency",
    "Energy Management",
    "Energy Storage",
    "Fossil Fuels",
    "Fuel",
    "Fuel Cell",
    "Mineral",
    "Mining",
    "Mining Technology",
    "Natural Resources",
    "Oil and Gas",
    "Power Grid",
    "Precious Metals",
    "Renewable Energy",
    "Solar",
    "Timber",
    "Water",
    "Wind Energy",
  ] as const,
  Events: [
    "Concerts",
    "Event Management",
    "Event Promotion",
    "Events",
    "Nightclubs",
    "Nightlife",
    "Reservations",
    "Ticketing",
    "Wedding",
  ] as const,
  Finance: [
    "Accounting",
    "Angel Investment",
    "Asset Management",
    "Auto Insurance",
    "Banking",
    "Billing",
    "Bitcoin",
    "Commercial Insurance",
    "Commercial Lending",
    "Consumer Lending",
    "Credit",
    "Credit Bureau",
    "Credit Cards",
    "Crowdfunding",
    "Cryptocurrency",
    "Debit Cards",
    "Debt Collections",
    "FinTech",
    "Finance",
    "Financial Exchanges",
    "Financial Services",
    "Fraud Detection",
    "Funding Platform",
    "Gift Card",
    "Health Insurance",
    "Hedge Funds",
    "Impact Investing",
    "Incubators",
    "InsurTech",
    "Insurance",
    "Leasing",
    "Lending",
    "Life Insurance",
    "Micro Lending",
    "Mobile Payments",
    "Payments",
    "Personal Finance",
    "Prediction Markets",
    "Property Insurance",
    "Real Estate Investment",
    "Stock Exchanges",
    "Trading Platform",
    "Transaction Processing",
    "Venture Capital",
    "Virtual Currency",
    "Wealth Management",
  ] as const,
  Food: [
    "Bakery",
    "Brewing",
    "Cannabis",
    "Catering",
    "Coffee",
    "Confectionery",
    "Cooking",
    "Craft Beer",
    "Dietary Supplements",
    "Distillery",
    "Farmers Market",
    "Food Delivery",
    "Food Processing",
    "Food Trucks",
    "Food and Beverage",
    "Fruit",
    "Grocery",
    "Nutrition",
    "Organic Food",
    "Recipes",
    "Restaurants",
    "Seafood",
    "Snack Food",
    "Tea",
    "Tobacco",
    "Wine And Spirits",
    "Winery",
  ] as const,
  Gaming: [
    "Casual Games",
    "Console Games",
    "Contests",
    "Fantasy Sports",
    "Gambling",
    "Gamification",
    "Gaming",
    "MMO Games",
    "Online Games",
    "PC Games",
    "Serious Games",
    "Video Games",
    "eSports",
  ] as const,
  "Government and Military": [
    "CivicTech",
    "GovTech",
    "Government",
    "Law Enforcement",
    "Military",
    "National Security",
    "Politics",
    "Public Safety",
    "Social Assistance",
  ] as const,
  Hardware: [
    "3D Technology",
    "Application Specific Integrated Circuit (ASIC)",
    "Augmented Reality",
    "Cloud Infrastructure",
    "Communication Hardware",
    "Communications Infrastructure",
    "Computer",
    "Computer Vision",
    "Consumer Electronics",
    "DSP",
    "Data Center",
    "Data Center Automation",
    "Data Storage",
    "Drone Management",
    "Drones",
    "Electronic Design Automation (EDA)",
    "Electronics",
    "Embedded Systems",
    "Field-Programmable Gate Array (FPGA)",
    "Flash Storage",
    "GPS",
    "GPU",
    "Google Glass",
    "Hardware",
    "Industrial Design",
    "Laser",
    "Lighting",
    "Mechanical Design",
    "Mobile Devices",
    "NFC",
    "Network Hardware",
    "Nintendo",
    "Optical Communication",
    "Playstation",
    "Private Cloud",
    "RFID",
    "RISC",
    "Retail Technology",
    "Robotics",
    "Roku",
    "Satellite Communication",
    "Semiconductor",
    "Sensor",
    "Sex Tech",
    "Telecommunications",
    "Video Conferencing",
    "Virtual Reality",
    "Virtualization",
    "Wearables",
    "Windows Phone",
    "Wireless",
    "Xbox",
  ] as const,
  Health: [
    "Alternative Medicine",
    "Assisted Living",
    "Assistive Technology",
    "Biopharma",
    "Cannabis",
    "Child Care",
    "Clinical Trials",
    "Cosmetic Surgery",
    "Dental",
    "Diabetes",
    "Dietary Supplements",
    "Elder Care",
    "Electronic Health Record (EHR)",
    "Emergency Medicine",
    "Employee Benefits",
    "Fertility",
    "First Aid",
    "Funerals",
    "Genetics",
    "Health Care",
    "Health Diagnostics",
    "Home Health Care",
    "Hospital",
    "Medical",
    "Medical Device",
    "Nursing and Residential Care",
    "Nutraceutical",
    "Nutrition",
    "Outpatient Care",
    "Personal Health",
    "Pharmaceutical",
    "Psychology",
    "Rehabilitation",
    "Therapeutics",
    "Veterinary",
    "Wellness",
    "mHealth",
  ] as const,
  "HR and Recruiting": [
    "Career Planning",
    "College Recruiting",
    "Corporate Training",
    "Employee Benefits",
    "Employment",
    "Human Resources",
    "Knowledge Management",
    "Office Administration",
    "Recruiting",
    "Skill Assessment",
    "Social Recruiting",
    "Staffing Agency",
    "Talent Management",
    "Training",
    "Virtual Workforce",
  ] as const,
  "Information Technology": [
    "Business Information Systems",
    "CMS",
    "CRM",
    "CivicTech",
    "Cloud Data Services",
    "Cloud Management",
    "Cloud Security",
    "Contact Management",
    "Cyber Security",
    "Data Center",
    "Data Center Automation",
    "Data Integration",
    "Data Mining",
    "Data Visualization",
    "Document Management",
    "E-Signature",
    "Email",
    "GovTech",
    "IT Infrastructure",
    "IT Management",
    "Identity Management",
    "Information Services",
    "Information Technology",
    "Information and Communications Technology (ICT)",
    "Intrusion Detection",
    "Management Information Systems",
    "Messaging",
    "Military",
    "Network Security",
    "Penetration Testing",
    "Private Cloud",
    "Real Time",
    "Reputation",
    "Sales Automation",
    "Scheduling",
    "Social CRM",
    "Spam Filtering",
    "Technical Support",
    "Unified Communications",
    "Video Chat",
    "Video Conferencing",
    "Virtualization",
    "VoIP",
  ] as const,
  "Internet Services": [
    "Cloud Computing",
    "Cloud Data Services",
    "Cloud Infrastructure",
    "Cloud Management",
    "Cloud Storage",
    "Darknet",
    "Domain Registrar",
    "E-Commerce Platforms",
    "Ediscovery",
    "Email",
    "ISP",
    "Internet",
    "Internet of Things",
    "Location Based Services",
    "Messaging",
    "Music Streaming",
    "Online Forums",
    "Online Portals",
    "Private Cloud",
    "Product Search",
    "SEM",
    "SEO",
    "SMS",
    "Search Engine",
    "Semantic Search",
    "Semantic Web",
    "Social Media",
    "Social Media Management",
    "Social Network",
    "Unified Communications",
    "Vertical Search",
    "Video Chat",
    "Video Conferencing",
    "Visual Search",
    "VoIP",
    "Web Browsers",
    "Web Hosting",
  ] as const,
  Legal: [
    "Compliance",
    "Intellectual Property",
    "Law Enforcement",
    "Legal",
    "Legal Tech",
  ] as const,
  "Lending and Investments": [
    "Angel Investment",
    "Banking",
    "Commercial Lending",
    "Consumer Lending",
    "Credit",
    "Credit Cards",
    "Financial Exchanges",
    "Funding Platform",
    "Hedge Funds",
    "Impact Investing",
    "Incubators",
    "Micro Lending",
    "Stock Exchanges",
    "Trading Platform",
    "Venture Capital",
  ] as const,
  Manufacturing: [
    "3D Printing",
    "Advanced Materials",
    "Foundries",
    "Industrial",
    "Industrial Automation",
    "Industrial Engineering",
    "Industrial Manufacturing",
    "Machinery Manufacturing",
    "Manufacturing",
    "Paper Manufacturing",
    "Plastics and Rubber Manufacturing",
    "Textiles",
    "Wood Processing",
  ] as const,
  Media: [
    "Advice",
    "Animation",
    "Art",
    "Audio",
    "Audiobooks",
    "Blogging Platforms",
    "Broadcasting",
    "Celebrity",
    "Concerts",
    "Content",
    "Content Creators",
    "Content Discovery",
    "Content Syndication",
    "Creative Agency",
    "DRM",
    "Digital Entertainment",
    "Digital Media",
    "EBooks",
    "Edutainment",
    "Event Management",
    "Event Promotion",
    "Events",
    "Film",
    "Film Distribution",
    "Film Production",
    "Guides",
    "In-Flight Entertainment",
    "Independent Music",
    "Internet Radio",
    "Journalism",
    "Media and Entertainment",
    "Motion Capture",
    "Music",
    "Music Education",
    "Music Label",
    "Music Streaming",
    "Music Venues",
    "Musical Instruments",
    "News",
    "Nightclubs",
    "Nightlife",
    "Performing Arts",
    "Photo Editing",
    "Photo Sharing",
    "Photography",
    "Podcast",
    "Printing",
    "Publishing",
    "Reservations",
    "Social Media",
    "Social News",
    "TV",
    "TV Production",
    "Theatre",
    "Ticketing",
    "Video",
    "Video Editing",
    "Video Streaming",
    "Video on Demand",
    "Virtual World",
  ] as const,
  "Mobile, Platforms, and Apps": [
    "Android",
    "App Discovery",
    "Apps",
    "Consumer Applications",
    "Desktop Apps",
    "Enterprise Applications",
    "Facebook",
    "Google",
    "Google Glass",
    "Linux",
    "Mobile",
    "Mobile Apps",
    "Mobile Devices",
    "Mobile Payments",
    "Nintendo",
    "Operating Systems",
    "Playstation",
    "Reading Apps",
    "Roku",
    "Tizen",
    "Twitter",
    "Web Apps",
    "WebOS",
    "Windows",
    "Windows Phone",
    "Wireless",
    "Xbox",
    "iOS",
    "mHealth",
    "macOS",
  ] as const,
  Music: [
    "Audio",
    "Audiobooks",
    "Independent Music",
    "Internet Radio",
    "Music",
    "Music Education",
    "Music Label",
    "Music Streaming",
    "Musical Instruments",
    "Podcast",
  ] as const,
  "Professional Services": [
    "Accounting",
    "Business Development",
    "Career Planning",
    "Compliance",
    "Consulting",
    "Customer Service",
    "Employment",
    "Environmental Consulting",
    "Field Support",
    "Freelance",
    "Innovation Management",
    "Intellectual Property",
    "Legal",
    "Legal Tech",
    "Management Consulting",
    "Outsourcing",
    "Professional Networking",
    "Quality Assurance",
    "Recruiting",
    "Risk Management",
    "Social Recruiting",
    "Translation Service",
  ] as const,
  "Real Estate": [
    "Architecture",
    "Building Maintenance",
    "Building Material",
    "Commercial Real Estate",
    "Construction",
    "Coworking",
    "Facility Management",
    "Green Building",
    "Home Decor",
    "Home Improvement",
    "Home Renovation",
    "Home Services",
    "Home and Garden",
    "Interior Design",
    "Janitorial Service",
    "Landscaping",
    "Property Development",
    "Property Management",
    "Real Estate",
    "Real Estate Investment",
    "Rental Property",
    "Residential",
    "Self-Storage",
    "Smart Building",
    "Smart Cities",
    "Smart Home",
    "Timeshare",
    "Vacation Rental",
  ] as const,
  "Sales and Marketing": [
    "Advertising",
    "Affiliate Marketing",
    "App Discovery",
    "App Marketing",
    "Brand Marketing",
    "CRM",
    "Cause Marketing",
    "Content Marketing",
    "Digital Marketing",
    "Digital Signage",
    "Direct Marketing",
    "Direct Sales",
    "Email Marketing",
    "Lead Generation",
    "Lead Management",
    "Local",
    "Local Advertising",
    "Local Business",
    "Loyalty Programs",
    "Marketing",
    "Marketing Automation",
    "Mobile Advertising",
    "Multi-level Marketing",
    "Outdoor Advertising",
    "Personal Branding",
    "Public Relations",
    "SEM",
    "SEO",
    "Sales",
    "Sales Automation",
    "Social CRM",
    "Social Media Advertising",
    "Social Media Management",
    "Social Media Marketing",
    "Sponsorship",
    "Video Advertising",
  ] as const,
  Security: [
    "Cloud Security",
    "Corrections Facilities",
    "Cyber Security",
    "DRM",
    "E-Signature",
    "Fraud Detection",
    "Homeland Security",
    "Identity Management",
    "Intrusion Detection",
    "Law Enforcement",
    "Network Security",
    "Penetration Testing",
    "Physical Security",
    "Privacy",
    "Security",
  ] as const,
  Software: [
    "3D Technology",
    "Android",
    "App Discovery",
    "Application Performance Management",
    "Apps",
    "Artificial Intelligence",
    "Augmented Reality",
    "Billing",
    "Bitcoin",
    "Browser Extensions",
    "CAD",
    "CMS",
    "CRM",
    "Cloud Computing",
    "Cloud Management",
    "Computer Vision",
    "Consumer Applications",
    "Consumer Software",
    "Contact Management",
    "Cryptocurrency",
    "Data Center Automation",
    "Data Integration",
    "Data Storage",
    "Data Visualization",
    "Database",
    "Developer APIs",
    "Developer Platform",
    "Developer Tools",
    "Document Management",
    "Drone Management",
    "E-Learning",
    "EdTech",
    "Electronic Design Automation (EDA)",
    "Embedded Software",
    "Embedded Systems",
    "Enterprise Applications",
    "Enterprise Resource Planning (ERP)",
    "Enterprise Software",
    "Facial Recognition",
    "File Sharing",
    "IaaS",
    "Image Recognition",
    "Linux",
    "MOOC",
    "Machine Learning",
    "Marketing Automation",
    "Meeting Software",
    "Mobile Apps",
    "Mobile Payments",
    "Natural Language Processing",
    "Open Source",
    "Operating Systems",
    "PaaS",
    "Predictive Analytics",
    "Presentation Software",
    "Presentations",
    "Private Cloud",
    "Productivity Tools",
    "QR Codes",
    "Reading Apps",
    "Retail Technology",
    "Robotics",
    "SNS",
    "SaaS",
    "Sales Automation",
    "Scheduling",
    "Sex Tech",
    "Simulation",
    "Social CRM",
    "Software",
    "Software Engineering",
    "Speech Recognition",
    "Task Management",
    "Text Analytics",
    "Transaction Processing",
    "Video Conferencing",
    "Virtual Assistant",
    "Virtual Currency",
    "Virtual Desktop",
    "Virtual Goods",
    "Virtual Reality",
    "Virtual World",
    "Virtualization",
    "Web Apps",
    "Web Browsers",
    "Web Development",
    "iOS",
    "macOS",
  ] as const,
  Sports: [
    "American Football",
    "Baseball",
    "Basketball",
    "Boating",
    "Cricket",
    "Cycling",
    "Diving",
    "Fantasy Sports",
    "Fitness",
    "Golf",
    "Hockey",
    "Hunting",
    "Outdoors",
    "Racing",
    "Recreation",
    "Rugby",
    "Sailing",
    "Skiing",
    "Soccer",
    "Sporting Goods",
    "Sports",
    "Surfing",
    "Swimming",
    "Table Tennis",
    "Tennis",
    "Ultimate Frisbee",
    "Volley Ball",
    "eSports",
  ] as const,
  Sustainability: [
    "Biofuel",
    "Biomass Energy",
    "Clean Energy",
    "CleanTech",
    "Energy Efficiency",
    "Environmental Engineering",
    "Green Building",
    "Green Consumer Goods",
    "GreenTech",
    "Natural Resources",
    "Organic",
    "Pollution Control",
    "Recycling",
    "Renewable Energy",
    "Solar",
    "Sustainability",
    "Waste Management",
    "Water Purification",
    "Wind Energy",
  ] as const,
  Telecommunication: [
    "Email",
    "Meeting Software",
    "Messaging",
    "SMS",
    "Unified Communications",
    "Video Chat",
    "Video Conferencing",
    "VoIP",
    "Wired Telecommunications",
  ] as const,
  Transportation: [
    "Air Transportation",
    "Automotive",
    "Autonomous Vehicles",
    "Car Sharing",
    "Courier Service",
    "Delivery Service",
    "Electric Vehicle",
    "Ferry Service",
    "Fleet Management",
    "Food Delivery",
    "Freight Service",
    "GPS",
    "Geospatial",
    "Indoor Positioning",
    "Last Mile Transportation",
    "Limousine Service",
    "Location Based Services",
    "Logistics",
    "Mapping Services",
    "Marine Transportation",
    "Navigation",
    "Parking",
    "Ports and Harbors",
    "Procurement",
    "Public Transportation",
    "Railroad",
    "Recreational Vehicles",
    "Ride Sharing",
    "Same Day Delivery",
    "Shipping",
    "Shipping Broker",
    "Space Travel",
    "Supply Chain Management",
    "Taxi Service",
    "Transportation",
    "Warehousing",
    "Water Transportation",
  ] as const,
  "Travel and Tourism": [
    "Adventure Travel",
    "Amusement Park and Arcade",
    "Business Travel",
    "Casino",
    "Hospitality",
    "Hotel",
    "Museums and Historical Sites",
    "Parks",
    "Resorts",
    "Timeshare",
    "Tour Operator",
    "Tourism",
    "Travel",
    "Travel Accommodations",
    "Travel Agency",
    "Vacation Rental",
  ] as const,
}

export const COMPANY_SUB_INDUSTRY_FLAT: SubIndustryAll[] = Array.from(
  new Set(Object.values(COMPANY_SUB_INDUSTRY).flat())
)

export const INDUSTRY_OPTIONS = [
  ...INDUSTRY,
  ...INDUSTRY.map((value) => `${EXCLUDE_PREFIX}${value}` as const),
] as const

export const SUB_INDUSTRY_OPTIONS = [
  ...COMPANY_SUB_INDUSTRY_FLAT,
  ...COMPANY_SUB_INDUSTRY_FLAT.map(
    (value) => `${EXCLUDE_PREFIX}${value}` as const
  ),
] as [string, ...string[]]

type SubIndustryAll =
  (typeof COMPANY_SUB_INDUSTRY)[keyof typeof COMPANY_SUB_INDUSTRY][number]

export const FUNDING_TYPE: Record<FundingType, string> = {
  angel: "Angel",
  pre_seed: "Pre Seed",
  seed: "Seed",
  equity_crowdfunding: "Equity Crowdfunding",
  product_crowdfunding: "Product Crowdfunding",
  non_equity_assistance: "Non Equity Assistance",
  convertible_note: "Convertible Note",
  grant: "Grant",
  series_a: "Series A",
  series_b: "Series B",
  series_c: "Series C",
  series_d: "Series D",
  series_e: "Series E",
  series_f: "Series F",
  series_g: "Series G",
  series_h: "Series H",
  series_i: "Series I",
  series_j: "Series J",
  undisclosed: "Undisclosed",
  series_unknown: "Series Unknown",
  corporate_round: "Corporate Round",
  private_equity: "Private Equity",
  debt_financing: "Debt Financing",
  initial_coin_offering: "Initial Coin Offering",
  secondary_market: "Secondary Market",
  post_ipo_debt: "Post Ipo Debt",
  post_ipo_equity: "Post Ipo Equity",
  post_ipo_secondary: "Post Ipo Secondary",
} as const

export const ACQUISITION_TYPE: Record<AcquisitionType, string> = {
  acquisition: "Acquisition",
  merge: "Merger",
  lbo: "Leveraged Buyout",
  acquihire: "Acquihire",
  management_buyout: "Management Buyout",
}

export const CATEGORIES = {
  ADMINISTRATIVE_SERVICES: "Administrative Services",
  ADVERTISING: "Advertising",
  AGRICULTURE_AND_FARMING: "Agriculture and Farming",
  APPS: "Apps",
  ARTIFICIAL_INTELLIGENCE: "Artificial Intelligence",
  BIOTECHNOLOGY: "Biotechnology",
  CLOTHING_AND_APPAREL: "Clothing and Apparel",
  COMMERCE_AND_SHOPPING: "Commerce and Shopping",
  COMMUNITY_AND_LIFESTYLE: "Community and Lifestyle",
  CONSUMER_ELECTRONICS: "Consumer Electronics",
  CONSUMER_GOODS: "Consumer Goods",
  CONTENT_AND_PUBLISHING: "Content and Publishing",
  DATA_AND_ANALYTICS: "Data and Analytics",
  DESIGN: "Design",
  EDUCATION: "Education",
  ENERGY: "Energy",
  EVENTS: "Events",
  FINANCIAL_SERVICES: "Financial Services",
  FOOD_AND_BEVERAGE: "Food and Beverage",
  GAMING: "Gaming",
  GOVERNMENT_AND_MILITARY: "Government and Military",
  HARDWARE: "Hardware",
  HEALTH_CARE: "Health Care",
  INFORMATION_TECHNOLOGY: "Information Technology",
  INTERNET_SERVICES: "Internet Services",
  LENDING_AND_INVESTMENTS: "Lending and Investments",
  MANUFACTURING: "Manufacturing",
  MEDIA_AND_ENTERTAINMENT: "Media and Entertainment",
  MESSAGING_AND_TELECOMMUNICATIONS: "Messaging and Telecommunications",
  MOBILE: "Mobile",
  MUSIC_AND_AUDIO: "Music and Audio",
  NATURAL_RESOURCES: "Natural Resources",
  NAVIGATION_AND_MAPPING: "Navigation and Mapping",
  OTHER: "Other",
  PAYMENTS: "Payments",
  PLATFORMS: "Platforms",
  PRIVACY_AND_SECURITY: "Privacy and Security",
  PROFESSIONAL_SERVICES: "Professional Services",
  REAL_ESTATE: "Real Estate",
  SALES_AND_MARKETING: "Sales and Marketing",
  SCIENCE_AND_ENGINEERING: "Science and Engineering",
  SOFTWARE: "Software",
  SPORTS: "Sports",
  SUSTAINABILITY: "Sustainability",
  TRANSPORTATION: "Transportation",
  TRAVEL_AND_TOURISM: "Travel and Tourism",
  VIDEO: "Video",
} as const

export const ADMIN_EXPORT_LIMIT = 50000

export const SEARCH_EXPORT_LIMIT = 1000

export const ADD_MANY_TO_LIST_LIMIT = 1000

export const FEED_PAGE_SIZE = 25

export const COMPANY_SIZE_OPTIONS = {
  B_1_10: "1-10",
  B_11_50: "11-50",
  B_51_200: "51-200",
  B_201_500: "201-500",
  B_501_1000: "501-1000",
  B_1001_5000: "1001-5000",
  B_5001_10000: "5001-10000",
  M_10001: "10001+",
} as const

export type CompanySize =
  (typeof COMPANY_SIZE_OPTIONS)[keyof typeof COMPANY_SIZE_OPTIONS]

export const COMPANY_HIGHLIGHTS_KEYS = [
  "strong_headcount_growth",
  "headcount_surge",
  "strong_web_traffic_growth",
  "web_traffic_surge",
  "strong_social_growth",
  "social_surge",
  "strong_app_downloads_growth",
  "app_downloads_surge",
  "strong_app_reviews_growth",
  "app_reviews_surge",
  "top_tier_investors",
  "raised_last_month",
  "recent_funding",
  "no_recent_funding",
  "no_funding",
  "media_spotlight",
  "recent_news",
] as const

export const COMPANY_HIGHLIGHTS_OPTIONS = [
  ...COMPANY_HIGHLIGHTS_KEYS,
  ...COMPANY_HIGHLIGHTS_KEYS.map(
    (value) => `${EXCLUDE_PREFIX}${value}` as const
  ),
] as const

export const COMPANY_HIGHLIGHTS_PROPS: Record<
  string,
  {
    order: number
    label: string
    group: string
    secondLine?: (signal: CompanyTableItem) => ReactNode
  }
> = {
  strong_headcount_growth: {
    order: 3,
    label: "Strong Headcount Growth",
    group: "headcount",
    secondLine(signal) {
      return (
        <Text
          fontSize="xs"
          fontWeight="semibold"
          color={`${COMPANY_HIGHLIGHTS_GROUPS[this.group].colorScheme}.600`}
          whiteSpace="nowrap"
        >
          {numeral(signal.employeeMonthlyGrowth6).format("+0")}%
          <Text as="span" fontWeight="normal">
            {" "}
            employees (6 months)
          </Text>
        </Text>
      )
    },
  },
  headcount_surge: {
    order: 2,
    label: "Headcount Surge",
    group: "headcount",
    secondLine(signal) {
      return (
        <Text
          fontSize="xs"
          fontWeight="semibold"
          color={`${COMPANY_HIGHLIGHTS_GROUPS[this.group].colorScheme}.600`}
          whiteSpace="nowrap"
        >
          {numeral(signal.employeeMonthlyGrowth1).format("+0")}% employees (1
          month)
        </Text>
      )
    },
  },
  strong_web_traffic_growth: {
    order: 5,
    label: "Strong Web Traffic Growth",
    group: "webvisits",
    secondLine(signal) {
      return (
        <Text
          fontSize="xs"
          fontWeight="semibold"
          color={`${COMPANY_HIGHLIGHTS_GROUPS[this.group].colorScheme}.600`}
          whiteSpace="nowrap"
        >
          {numeral(signal.webVisitsMonthlyGrowth6).format("+0")}%
          <Text as="span" fontWeight="normal">
            {" "}
            web visits (6 months)
          </Text>
        </Text>
      )
    },
  },
  web_traffic_surge: {
    order: 4,
    label: "Web Traffic Surge",
    group: "webvisits",
    secondLine(signal) {
      return (
        <Text
          fontSize="xs"
          fontWeight="semibold"
          color={`${COMPANY_HIGHLIGHTS_GROUPS[this.group].colorScheme}.600`}
          whiteSpace="nowrap"
        >
          {numeral(signal.webVisitsMonthlyGrowth1).format("+0")}%
          <Text as="span" fontWeight="normal">
            {" "}
            web visits (1 month)
          </Text>
        </Text>
      )
    },
  },
  strong_social_growth: {
    order: 7,
    label: "Strong Social Growth",
    group: "social",
    secondLine(signal) {
      const totalSocialGrowth1 =
        signal.instagramFollowersMonthlyGrowth6 +
        signal.twitterFollowersMonthlyGrowth6 +
        signal.linkedinFollowersMonthlyGrowth6

      return (
        <Text
          fontSize="xs"
          fontWeight="semibold"
          color={`${COMPANY_HIGHLIGHTS_GROUPS[this.group].colorScheme}.600`}
          whiteSpace="nowrap"
        >
          {numeral(totalSocialGrowth1).format("+0,0a")}
          <Text as="span" fontWeight="normal">
            {" "}
            followers (6 months)
          </Text>
        </Text>
      )
    },
  },
  social_surge: {
    order: 6,
    label: "Social Surge",
    group: "social",
    secondLine(signal) {
      const totalSocialGrowth1 =
        signal.instagramFollowersMonthlyGrowth1 +
        signal.twitterFollowersMonthlyGrowth1 +
        signal.linkedinFollowersMonthlyGrowth1

      return (
        <Text
          fontSize="xs"
          fontWeight="semibold"
          color={`${COMPANY_HIGHLIGHTS_GROUPS[this.group].colorScheme}.600`}
          whiteSpace="nowrap"
        >
          {numeral(totalSocialGrowth1).format("+0,0a")}

          <Text as="span" fontWeight="normal">
            {" "}
            followers (1 month)
          </Text>
        </Text>
      )
    },
  },
  strong_app_downloads_growth: {
    order: 9,
    label: "Strong App Downloads Growth",
    group: "app_downloads",
    secondLine(signal) {
      return (
        <Text
          fontSize="xs"
          fontWeight="semibold"
          color={`${COMPANY_HIGHLIGHTS_GROUPS[this.group].colorScheme}.600`}
          whiteSpace="nowrap"
        >
          {numeral(signal.totalAppDownloadsMonthlyGrowth6).format("+0")}%
          <Text as="span" fontWeight="normal">
            {" "}
            downloads (6 months)
          </Text>
        </Text>
      )
    },
  },
  app_downloads_surge: {
    order: 8,
    label: "App Downloads Surge",
    group: "app_downloads",
    secondLine(signal) {
      return (
        <Text
          fontSize="xs"
          fontWeight="semibold"
          color={`${COMPANY_HIGHLIGHTS_GROUPS[this.group].colorScheme}.600`}
          whiteSpace="nowrap"
        >
          {numeral(signal.totalAppDownloadsMonthlyGrowth1).format("+0")}%
          <Text as="span" fontWeight="normal">
            {" "}
            downloads (1 month)
          </Text>
        </Text>
      )
    },
  },
  strong_app_reviews_growth: {
    order: 11,
    label: "Strong App Reviews Growth",
    group: "app_reviews",
    secondLine(signal) {
      const totalReviewsGrowth1 =
        signal.itunesReviewsMonthlyGrowth6 +
        signal.googlePlayReviewsMonthlyGrowth6

      return (
        <Text
          fontSize="xs"
          fontWeight="semibold"
          color={`${COMPANY_HIGHLIGHTS_GROUPS[this.group].colorScheme}.600`}
          whiteSpace="nowrap"
        >
          {numeral(totalReviewsGrowth1).format("+0,0a")}

          <Text as="span" fontWeight="normal">
            {" "}
            reviews (6 months)
          </Text>
        </Text>
      )
    },
  },
  app_reviews_surge: {
    order: 10,
    label: "App Reviews Surge",
    group: "app_reviews",
    secondLine(signal) {
      const totalReviewsGrowth1 =
        signal.itunesReviewsMonthlyGrowth1 +
        signal.googlePlayReviewsMonthlyGrowth1

      return (
        <Text
          fontSize="xs"
          fontWeight="semibold"
          color={`${COMPANY_HIGHLIGHTS_GROUPS[this.group].colorScheme}.600`}
          whiteSpace="nowrap"
        >
          {numeral(totalReviewsGrowth1).format("+0,0a")}

          <Text as="span" fontWeight="normal">
            {" "}
            reviews (1 month)
          </Text>
        </Text>
      )
    },
  },
  top_tier_investors: {
    order: 1,
    label: "Top Tier Investors",
    group: "investors",
    secondLine(signal) {
      const topTierInvestors = signal.investors?.filter((i) =>
        TOP_TIER_INVESTORS.includes(i)
      )

      if (!topTierInvestors?.length) return null
      const nInvestorsToShow = 1
      return (
        <Text
          fontSize="xs"
          fontWeight="normal"
          color={`${COMPANY_HIGHLIGHTS_GROUPS[this.group].colorScheme}.600`}
        >
          {topTierInvestors?.slice(0, nInvestorsToShow).join(", ")}
          {topTierInvestors.length > nInvestorsToShow &&
            `, +${topTierInvestors.length - nInvestorsToShow}`}
        </Text>
      )
    },
  },
  raised_last_month: {
    order: 12,
    label: "Raised Last Month",
    group: "funding",
  },
  recent_funding: {
    order: 13,
    label: "Recent Funding",
    group: "funding",
  },
  no_recent_funding: {
    order: 14,
    label: "No Recent Funding",
    group: "funding",
  },
  no_funding: {
    order: 14,
    label: "No Funding",
    group: "funding",
  },
  media_spotlight: {
    order: 15,
    label: "Media Spotlight",
    group: "news",
    secondLine(signal) {
      const publishers = [
        ...new Set(
          JSONSafeParse<News[]>(signal.news, [])
            .map((n) => n.publisher)
            .filter(Boolean)
        ),
      ] as string[]

      return (
        !!publishers?.length && (
          <Text
            fontSize="xs"
            fontWeight="normal"
            color={`${COMPANY_HIGHLIGHTS_GROUPS[this.group].colorScheme}.600`}
            display="inline-flex"
            alignItems="center"
            gap={1}
          >
            Featured in{"  "}
            {publishers?.map(
              (publisher, i) =>
                COMPANY_NEWS_NOTABLE_OUTLETS_LOGOS[publisher] && (
                  <Tooltip key={i} label={publisher}>
                    <Image
                      src={COMPANY_NEWS_NOTABLE_OUTLETS_LOGOS[publisher]}
                      boxSize={4}
                    />
                  </Tooltip>
                )
            )}
          </Text>
        )
      )
    },
  },
  recent_news: {
    order: 16,
    label: "Recent News",
    group: "news",
    secondLine(signal) {
      const lastNewsMention = JSONSafeParse<News[]>(signal.news, [])[0]?.date

      if (!lastNewsMention) return null
      return (
        <Text
          fontSize="xs"
          fontWeight="normal"
          color={`${COMPANY_HIGHLIGHTS_GROUPS[this.group].colorScheme}.600`}
        >
          Latest News at {formatDate(new Date(lastNewsMention))}
        </Text>
      )
    },
  },
}

export const COMPANY_HIGHLIGHTS_RENAMING: Record<string, string> = {
  strong_headcount: "strong_headcount_growth",
  // headcount_surge:"headcount_surge",
  strong_webvisits: "strong_web_traffic_growth",
  webvisits_surge: "web_traffic_surge",
  strong_social: "strong_social_growth",
  // social_surge:"social_surge",
  strong_app_downloads: "strong_app_downloads_growth",
  // app_downloads_surge:"app_downloads_surge",
  strong_app_reviews: "strong_app_reviews_growth",
  // app_reviews_surge:"app_reviews_surge",
  // top_tier_investors:"top_tier_investors",
  // raised_last_month:"raised_last_month",
  // recent_funding:"recent_funding",
  // no_recent_funding:"no_recent_funding",
  // no_funding:"no_funding",
  notable_outlet_news: "media_spotlight",
  // recent_news:"recent_news"
}

export const COMPANY_HIGHLIGHTS_GROUPS: Record<
  string,
  {
    colorScheme: string
    tab: number
    hide?: boolean
  }
> = {
  headcount: { colorScheme: "green", tab: 2 },
  webvisits: { colorScheme: "blue", tab: 3 },
  social: { colorScheme: "pink", tab: 5 },
  app_downloads: { colorScheme: "orange", tab: 4 },
  app_reviews: { colorScheme: "orange", tab: 4 },
  investors: { colorScheme: "yellow", tab: 1 },
  funding: { colorScheme: "yellow", tab: 1, hide: true },
  news: { colorScheme: "gray", tab: 7 },
}

// Source: https://docs.google.com/spreadsheets/d/11IbkwZZxdQK7Gq5DtrLkeWSZmi1Z6ms_bvVet4G3ZFs/edit#gid=1961313982
export const COMPANY_NEWS_NOTABLE_OUTLETS_LOGOS: Record<string, string> = {
  "Arctic Startup":
    "https://specter-company-logo.s3.amazonaws.com/arcticstartup.com.jpeg",
  ArcticStartup:
    "https://specter-company-logo.s3.amazonaws.com/arcticstartup.com.jpeg",
  Axios: "https://specter-company-logo.s3.amazonaws.com/axios.com.jpg",
  "axios.com": "https://specter-company-logo.s3.amazonaws.com/axios.com.jpg",
  Benzinga: "https://specter-company-logo.s3.amazonaws.com/benzinga.com.png",
  Bloomberg:
    "https://specter-company-logo.s3.amazonaws.com/bloomberglive.com.jpeg",
  "Bloomberg Adria":
    "https://specter-company-logo.s3.amazonaws.com/bloomberglive.com.jpeg",
  "Bloomberg Law":
    "https://specter-company-logo.s3.amazonaws.com/bloomberglive.com.jpeg",
  "Bloomberg Law News":
    "https://specter-company-logo.s3.amazonaws.com/bloomberglive.com.jpeg",
  "Bloomberg Línea":
    "https://specter-company-logo.s3.amazonaws.com/bloomberglive.com.jpeg",
  "Bloomberg Linea Brasil":
    "https://specter-company-logo.s3.amazonaws.com/bloomberglive.com.jpeg",
  "Bloomberg Moontalks TV":
    "https://specter-company-logo.s3.amazonaws.com/bloomberglive.com.jpeg",
  "Bloomberg Philanthropies":
    "https://specter-company-logo.s3.amazonaws.com/bloomberglive.com.jpeg",
  "Bloomberg Tax":
    "https://specter-company-logo.s3.amazonaws.com/bloomberglive.com.jpeg",
  "Bloomberg View":
    "https://specter-company-logo.s3.amazonaws.com/bloomberglive.com.jpeg",
  "Bloomberg.com":
    "https://specter-company-logo.s3.amazonaws.com/bloomberglive.com.jpeg",
  "bloomberglaw.com":
    "https://specter-company-logo.s3.amazonaws.com/bloomberglive.com.jpeg",
  BloombergNEF:
    "https://specter-company-logo.s3.amazonaws.com/bloomberglive.com.jpeg",
  "bloombergtax.com":
    "https://specter-company-logo.s3.amazonaws.com/bloomberglive.com.jpeg",
  "Business Insider":
    "https://specter-company-logo.s3.amazonaws.com/businessinsider.com.png",
  "Business Insider (tech)":
    "https://specter-company-logo.s3.amazonaws.com/businessinsider.com.png",
  "Business Insider Africa":
    "https://specter-company-logo.s3.amazonaws.com/businessinsider.com.png",
  "Business Insider Australia":
    "https://specter-company-logo.s3.amazonaws.com/businessinsider.com.png",
  "Business Insider India":
    "https://specter-company-logo.s3.amazonaws.com/businessinsider.com.png",
  "Business Insider México | Noticias pensadas para ti":
    "https://specter-company-logo.s3.amazonaws.com/businessinsider.com.png",
  "Business Insider Singapore":
    "https://specter-company-logo.s3.amazonaws.com/businessinsider.com.png",
  "Business Insider South Africa":
    "https://specter-company-logo.s3.amazonaws.com/businessinsider.com.png",
  CNBC: "https://specter-company-logo.s3.amazonaws.com/cnbc.com.jpeg",
  "CNBC Africa": "https://specter-company-logo.s3.amazonaws.com/cnbc.com.jpeg",
  "CNBC Indonesia":
    "https://specter-company-logo.s3.amazonaws.com/cnbc.com.jpeg",
  CNN: "https://specter-company-logo.s3.amazonaws.com/cnn.com.png",
  "CNN Brasil": "https://specter-company-logo.s3.amazonaws.com/cnn.com.png",
  "CNN Money Tech": "https://specter-company-logo.s3.amazonaws.com/cnn.com.png",
  "CNN Philippines":
    "https://specter-company-logo.s3.amazonaws.com/cnn.com.png",
  "CNN Underscored":
    "https://specter-company-logo.s3.amazonaws.com/cnn.com.png",
  CNNMoney: "https://specter-company-logo.s3.amazonaws.com/cnn.com.png",
  "Coin Telegraph":
    "https://specter-company-logo.s3.amazonaws.com/cointelegraph.com.jpeg",
  CoinDesk: "https://specter-company-logo.s3.amazonaws.com/coindesk.com.png",
  Coindesk: "https://specter-company-logo.s3.amazonaws.com/coindesk.com.png",
  "CoinDesk: Bitcoin, Ethereum, Crypto News and Price Data":
    "https://specter-company-logo.s3.amazonaws.com/coindesk.com.png",
  "coindesk.com":
    "https://specter-company-logo.s3.amazonaws.com/coindesk.com.png",
  Cointelegraph:
    "https://specter-company-logo.s3.amazonaws.com/cointelegraph.com.jpeg",
  "Cointelegraph Brasil":
    "https://specter-company-logo.s3.amazonaws.com/cointelegraph.com.jpeg",
  DealStreetAsia:
    "https://specter-company-logo.s3.amazonaws.com/dealstreetasia.com.jpeg",
  Engadget: "https://specter-company-logo.s3.amazonaws.com/engadget.com.jpeg",
  Entrepreneur:
    "https://specter-company-logo.s3.amazonaws.com/entrepreneur.com.jpeg",
  "ETtech.com": "https://economictimes.indiatimes.com/icons/etfavicon.ico",
  "EU Startups":
    "https://www.eu-startups.com/wp-content/themes/Newspaperimages/favicon.ico",
  "EU-Startups":
    "https://www.eu-startups.com/wp-content/themes/Newspaperimages/favicon.ico",
  "Fast Company": "https://logo.clearbit.com/board.fastcompany.com",
  "Financial Times":
    "https://specter-company-logo.s3.amazonaws.com/ft.com.jpeg",
  Forbes: "https://specter-company-logo.s3.amazonaws.com/forbes.vn.jpeg",
  "Forbes Africa":
    "https://specter-company-logo.s3.amazonaws.com/forbes.vn.jpeg",
  "Forbes Argentina":
    "https://specter-company-logo.s3.amazonaws.com/forbes.vn.jpeg",
  "Forbes Brasil":
    "https://specter-company-logo.s3.amazonaws.com/forbes.vn.jpeg",
  "Forbes Centroamérica":
    "https://specter-company-logo.s3.amazonaws.com/forbes.vn.jpeg",
  "Forbes Chile":
    "https://specter-company-logo.s3.amazonaws.com/forbes.vn.jpeg",
  "Forbes Colombia":
    "https://specter-company-logo.s3.amazonaws.com/forbes.vn.jpeg",
  "Forbes España":
    "https://specter-company-logo.s3.amazonaws.com/forbes.vn.jpeg",
  "Forbes France":
    "https://specter-company-logo.s3.amazonaws.com/forbes.vn.jpeg",
  "Forbes India":
    "https://specter-company-logo.s3.amazonaws.com/forbes.vn.jpeg",
  "Forbes Italia":
    "https://specter-company-logo.s3.amazonaws.com/forbes.vn.jpeg",
  "Forbes ME": "https://specter-company-logo.s3.amazonaws.com/forbes.vn.jpeg",
  "Forbes México":
    "https://specter-company-logo.s3.amazonaws.com/forbes.vn.jpeg",
  "Forbes Portugal":
    "https://specter-company-logo.s3.amazonaws.com/forbes.vn.jpeg",
  "Forbes.com": "https://specter-company-logo.s3.amazonaws.com/forbes.vn.jpeg",
  "Forbes.pl": "https://specter-company-logo.s3.amazonaws.com/forbes.vn.jpeg",
  "Forbes.ru": "https://specter-company-logo.s3.amazonaws.com/forbes.vn.jpeg",
  "Forbes.ua": "https://specter-company-logo.s3.amazonaws.com/forbes.vn.jpeg",
  Fortune: "https://specter-company-logo.s3.amazonaws.com/fortune.com.jpeg",
  "Fox Business":
    "https://specter-company-logo.s3.amazonaws.com/foxbusiness.com.jpeg",
  "Fox News":
    "https://specter-company-logo.s3.amazonaws.com/foxbusiness.com.jpeg",
  "Fox News - Tech":
    "https://specter-company-logo.s3.amazonaws.com/foxbusiness.com.jpeg",
  Gizmodo: "https://specter-company-logo.s3.amazonaws.com/gizmodo.com.jpeg",
  "Inc. Magazine": "https://specter-company-logo.s3.amazonaws.com/inc.com.jpeg",
  "Inc.com": "https://specter-company-logo.s3.amazonaws.com/inc.com.jpeg",
  Inc42: "https://specter-company-logo.s3.amazonaws.com/inc42.com.jpeg",
  "Inc42 Media": "https://specter-company-logo.s3.amazonaws.com/inc42.com.jpeg",
  MarketWatch: "https://mw4.wsj.net/mw5/content/logos/favicon.ico",
  Marketwatch: "https://mw4.wsj.net/mw5/content/logos/favicon.ico",
  "marketwatch.com": "https://mw4.wsj.net/mw5/content/logos/favicon.ico",
  Mashable: "https://mashable.com/favicons/favicon-32x32.png",
  NoCamels: "https://specter-company-logo.s3.amazonaws.com/nocamels.com.jpeg",
  Sifted: "https://specter-company-logo.s3.amazonaws.com/sifted.eu.jpeg",
  "Silicon Canals":
    "https://specter-company-logo.s3.amazonaws.com/siliconcanals.com.jpeg",
  SiliconCanals:
    "https://www.coindesk.com/pf/resources/favicons/production/favicon-32x32.png?d=332",
  "Startupticker-Ch":
    "https://specter-company-logo.s3.amazonaws.com/startupticker.ch.jpeg",
  "Startupticker.ch":
    "https://specter-company-logo.s3.amazonaws.com/startupticker.ch.jpeg",
  StrictlyVC:
    "https://strictlyvc.com/wp-content/uploads/2023/10/cropped-SVC-icon.png?w=32",
  "Tech In Africa":
    "https://www.techinafrica.com/wp-content/uploads/2017/11/cropped-icon-tech-africa-32x32.png",
  "Tech in Africa":
    "https://www.techinafrica.com/wp-content/uploads/2017/11/cropped-icon-tech-africa-32x32.png",
  "Tech in Asia":
    "https://specter-company-logo.s3.amazonaws.com/techinasia.com.png",
  "Tech.eu": "https://specter-company-logo.s3.amazonaws.com/tech.eu.jpeg",
  TechCrunch:
    "https://specter-company-logo.s3.amazonaws.com/techcrunch.com.jpeg",
  TechInAsia:
    "https://specter-company-logo.s3.amazonaws.com/techinasia.com.png",
  "The Block": "https://www.theblock.co/favicon.ico",
  "The Block Crypto": "https://www.theblock.co/favicon.ico",
  "The Economic Times":
    "https://economictimes.indiatimes.com/icons/etfavicon.ico",
  "The Guardian":
    "https://specter-company-logo.s3.amazonaws.com/theguardian.com.jpeg",
  "The Information":
    "https://specter-company-logo.s3.amazonaws.com/theinformation.com.jpeg",
  "The Next Web":
    "https://specter-company-logo.s3.amazonaws.com/thenextweb.com.png",
  "The Verge":
    "https://specter-company-logo.s3.amazonaws.com/theverge.com.jpeg",
  "Venture Beat":
    "https://specter-company-logo.s3.amazonaws.com/venturebeat.com.jpeg",
  VentureBeat:
    "https://specter-company-logo.s3.amazonaws.com/venturebeat.com.jpeg",
  "Wall Street Journal": "https://logo.clearbit.com/ceocouncil.wsj.com",
  "Washington Post":
    "https://specter-company-logo.s3.amazonaws.com/washingtonpost.com.jpeg",
  Wired: "https://specter-company-logo.s3.amazonaws.com/wired.com.jpeg",
  WIRED: "https://specter-company-logo.s3.amazonaws.com/wired.com.jpeg",
  "Wired News": "https://specter-company-logo.s3.amazonaws.com/wired.com.jpeg",
  "Wired UK": "https://specter-company-logo.s3.amazonaws.com/wired.com.jpeg",
  "WIRED UK": "https://specter-company-logo.s3.amazonaws.com/wired.com.jpeg",
  WSJ: "https://logo.clearbit.com/ceocouncil.wsj.com",
  "WSJ Blogs": "https://logo.clearbit.com/ceocouncil.wsj.com",
  "WSJ Pro - Venture Capital": "https://logo.clearbit.com/ceocouncil.wsj.com",
}

export const TOP_TIER_INVESTORS = [
  "Fidelity",
  "Summit Partners",
  "TA Associates",
  "RA Capital Management",
  "TCV",
  "Perceptive Advisors",
  "Cisco",
  "Janus Henderson Investors",
  "The Carlyle Group",
  "OrbiMed",
  "Adage Capital Management",
  "Audax Private Equity",
  "TPG",
  "Goldman Sachs",
  "Vivo Capital",
  "NXT Capital",
  "HarbourVest Partners",
  "Venrock",
  "General Atlantic",
  "Rock Springs Capital",
  "Apax Partners",
  "Warburg Pincus",
  "3i Group",
  "Redmile Group",
  "Bain Capital",
  "Intel Capital",
  "Silver Lake",
  "GIC",
  "Accel-KKR",
  "Meritech Capital Partners",
  "Logos Capital",
  "Tikehau Capital",
  "LDC",
  "Surveyor Capital",
  "Adams Street Partners",
  "Scotiabank",
  "Oxford Finance LLC",
  "Abingworth",
  "FTV Capital",
  "Sutter Hill Ventures",
  "Frazier Healthcare Partners",
  "Biotechnology Value Fund",
  "EcoR1 Capital",
  "T. Rowe Price",
  "Trident Capital",
  "RTW Investments",
  "Betaworks",
  "Neuberger Berman",
  "Polaris Partners",
  "H.I.G. Capital",
  "SoftBank",
  "RBC Capital Markets",
  "Edison Partners",
  "JMI Equity",
  "Credit Suisse",
  "Canaccord Genuity Group",
  "Boxer Capital",
  "Omnes Capital",
  "TriplePoint Capital",
  "Trinity Capital",
  "Horizon Technology Finance",
  "MidCap Financial",
  "Stanford University",
  "Invus",
  "Western Technology Investment",
  "GE Capital",
  "Kleiner Perkins",
  "NewSpring",
  "Andera Partners",
  "Foundation Capital",
  "Alibaba Group",
  "Intel",
  "Paradigm Capital",
  "Sequoia Capital",
  "Allen & Company",
  "Uncork Capital",
  "Apollo",
  "Felicis",
  "TD Securities",
  "New Enterprise Associates",
  "Samsara BioCapital",
  "Sofinnova Investments",
  "Bank of Montreal",
  "Comcast Ventures",
  "TPG Growth",
  "SVA",
  "Alta Partners",
  "Greenspring Associates",
  "JP Morgan",
  "Highland Capital Partners",
  "Trinity Ventures",
  "Advent International",
  "Novo Holdings",
  "Armentum Partners",
  "Invesco",
  "Bain Capital Ventures",
  "Aisling Capital",
  "Bessemer Venture Partners",
  "Insight Partners",
  "Google Ventures",
  "U.S. Venture Partners",
  "JPMorgan Partners",
  "Venrock Healthcare Capital Partners",
  "Franklin Templeton",
  "Qualcomm Ventures",
  "Riverside Company",
  "Sapphire Ventures",
  "Accel",
  "Revolution",
  "Fonds de solidarité FTQ",
  "Omega Funds",
  "MassVentures",
  "Pamlico Capital",
  "Benchmark",
  "Kennet Partners",
  "Relay Ventures",
  "Foresite Capital",
  "Mitsui & Co",
  "BDC Venture Capital",
  "Ardian",
  "Versant Ventures",
  "Greylock",
  "Bain Capital Life Sciences",
  "General Catalyst",
  "Andreessen Horowitz",
  "Raymond James",
  "Index Ventures",
  "European Bank for Reconstruction and Development",
  "JAFCO",
  "U.S. Department of Defense",
  "Eight Roads Ventures",
  "Mayfield Fund",
  "F-Prime Capital",
  "Boston Millennia Partners",
  "5AM Ventures",
  "Rocket Internet",
  "Atlas Venture",
  "DCM Ventures",
  "Crosslink Capital",
  "IVP",
  "LNR Partners",
  "ABRY Partners",
  "Floodgate",
  "Norwest Equity Partners (NEP)",
  "HSBC",
  "Lightspeed Venture Partners",
  "Spectrum Equity",
  "Ampersand Capital Partners",
  "Novartis Venture Fund",
  "Bill & Melinda Gates Foundation",
  "DCVC",
  "Flagship Pioneering",
  "RC Capital",
  "Lerer Hippeau",
  "BloombergNEF",
  "Ronald Conway",
  "Greycroft",
  "Actis",
  "Madrona",
  "Casdin Capital",
  "ACME Capital",
  "Sofinnova Partners",
  "Thrive Capital",
  "MPM Capital",
  "Safeguard Scientifics",
  "Blackstone Group",
  "Khosla Ventures",
  "Investor Growth Capital Limited",
  "National Bank of Canada",
  "Paul Buchheit",
  "Great Hill Partners",
  "Y Combinator",
  "Eurazeo",
  "Sierra Ventures",
  "CRV",
  "SoftBank Capital",
  "VantagePoint Capital Partners",
  "Global Brain Corporation",
  "Battery Ventures",
  "Seventure Partners",
  "Wellcome Trust",
  "Microsoft",
  "Correlation Ventures",
  "Salesforce Ventures",
  "Scale Venture Partners",
  "NorthStar Capital",
  "Vertex Ventures",
  "MMV Capital Partners",
  "Temasek Holdings",
  "Social Capital",
  "Kohlberg Kravis Roberts",
  "ARCH Venture Partners",
  "Focus Ventures",
  "Espresso Capital",
  "Fidelity Ventures",
  "Next47",
  "Merrill Lynch",
  "Forbion Capital Partners",
  "CIT Group",
  "Kreos Capital",
  "Paladin Capital Group",
  "Business Growth Fund",
  "Austin Ventures",
  "MIT Media Lab",
  "Mitsubishi UFJ Capital",
  "Gimv",
  "KfW",
  "Stephens Group",
  "ABS Capital Partners",
  "Shasta Ventures",
  "NGP Capital",
  "Slow Ventures",
  "SR One",
  "Eniac Ventures",
  "Livingbridge",
  "Gravitas Securities",
  "Ally Bridge Group",
  "SV Health Investors",
  "Kapor Capital",
  "Molten Ventures",
  "InterWest Partners",
  "Hillhouse Investment",
  "Square 1 Bank",
  "Google",
  "Baseline Ventures",
  "Sigma Partners",
  "Baillie Gifford",
  "SMBC Venture Capital",
  "Nextech Invest",
  "Motorola Solutions Venture Capital",
  "Updata Partners",
  "Inovia Capital",
  "Ridge Ventures",
  "Tiger Global Management",
  "Madison Dearborn Partners",
  "Samsung Ventures",
  "Bay City Capital",
  "Webb Investment Network",
  "Future Fifty",
  "Storm Ventures",
  "August Capital",
  "CITIC Securities",
  "Eastward Capital Partners",
  "Icon Ventures",
  "JAFCO Asia",
  "Constellation Technology Ventures",
  "Avidity Partners",
  "GGV Capital",
  "CVC Capital Partners",
  "Founders Fund",
  "Ignition Partners",
  "Essex Woodlands Healthcare Partners",
  "EDBI",
  "Emergence",
  "Farallon Capital Management",
  "Norwest Venture Partners",
  "StartX (Stanford-StartX Fund)",
  "Hg",
  "Techstars Ventures",
  "Jerusalem Venture Partners (JVP)",
  "Lighter Capital",
  "Morgenthaler Ventures",
  "Tenaya Capital",
  "Viking Global Investors",
  "Matrix",
  "Collaborative Fund",
  "Ascent Venture Partners",
  "Dragoneer Investment Group",
  "Maveron",
  "SharesPost Investment Management",
  "Medtronic",
  "SaaS Capital",
  "AME Cloud Ventures",
  "Upfront Ventures",
  "GE Ventures",
  "Canaan Partners",
  "Cross Creek",
  "Cisco Investments",
  "Cormark Securities Inc.",
  "Alexandria Venture Investments",
  "Singtel Innov8",
  "Earlybird Venture Capital",
  "Service Provider Capital",
  "Integral Capital Partners",
  "Northgate Capital",
  "Domain Associates",
  "Oaktree Capital Management",
  "Chase Capital Partners",
  "Sands Capital Ventures",
  "Signatures Capital",
  "Amadeus Capital Partners",
  "Menlo Ventures",
  "Bonfire Ventures",
  "Spark Capital",
  "BMO Capital Markets",
  "Pegasus Tech Ventures",
  "HV Capital",
  "Union Square Ventures",
  "David Tisch",
  "Comerica Incorporated",
  "Walden International",
  "OurCrowd",
  "Soleus Capital",
  "Mohr Davidow Ventures",
  "Tianxing Capital",
  "Reid Hoffman",
  "Longworth Venture Partners",
  "Vintage Investment Partners",
  "EQT life science",
  "Novo Ventures",
  "Harrison Metal",
  "Redpoint",
  "Saratoga Partners",
  "FirstMark",
  "Initialized Capital",
  "Mitsui Global Investment",
  "Globespan Capital Partners",
  "CICC",
  "Compound",
  "Bregal Sagemount",
  "Qualcomm",
  "Cipio Partners",
  "Caisse de Depot et Placement du Quebec",
  "Scottish Equity Partners",
  "CIBC World Markets",
  "EPIC Ventures",
  "MaRS Investment Accelerator Fund",
  "Chrys Capital",
  "Innovacom",
  "Investcorp",
  "Pritzker Group Venture Capital",
  "New Leaf Venture Partners",
  "Longitude Capital",
  "Dell Technologies Capital",
  "Columbia Capital",
  "Clearlake Capital Group",
  "Advanced Technology Ventures",
  "Hyde Park Venture Partners",
  "New York Angels",
  "Freestyle Capital",
  "Elaia",
  "MUFG Bank",
  "Mizuho Capital",
  "Balderton Capital",
  "Bay Partners",
  "Kodiak Venture Partners",
  "Bridge Bank",
  "Johnson & Johnson Development Corporation",
  "Omidyar Network",
  "Lead Edge Capital",
  "Graycliff Partners",
  "Rho Capital Partners",
  "ITOCHU Corporation",
  "Business Development Bank of Canada",
  "Lehman Holdings",
  "Providence Equity Partners",
  "Third Rock Ventures",
  "Headline",
  "Gary Vaynerchuk",
  "CSC Upshot",
  "ArrowMark Partners",
  "Ben Franklin Technology Partners of Southeastern Pennsylvania",
  "Ontario Teachers' Pension Plan",
  "Horizons Ventures",
  "Morgan Stanley Expansion Capital",
  "Comdisco Ventures",
  "Keith Rabois",
  "National Bank Financial",
  "Amicus Capital",
  "CRG",
  "Audrey Capital",
  "Tuesday Capital",
  "Baird Capital",
  "First Round Capital",
  "Bantam Group",
  "Accomplice",
  "Pitango VC",
  "St. Paul Venture Capital",
  "Stone Point Capital",
  "360 Capital",
  "Zelkova Ventures",
  "Brainchild Holdings",
  "Wellington Partners",
  "HLM Venture Partners",
  "Viola Ventures",
  "BNP Paribas",
  "Connecticut Innovations",
  "Harmony Partners",
  "Social Leverage",
  "Francisco Partners",
  "G Squared",
  "Fortress Investment Group",
  "Frontier Growth",
  "Visa",
  "BEST Funds",
  "Muzinich & Co",
  "Celgene",
  "Nexus Venture Partners",
  "Industry Ventures",
  "Deutsche Telekom Strategic Investments",
  "Founder Collective",
  "Roche Venture Fund",
  "RRE Ventures",
  "Shervin Pishevar",
  "Alloy Ventures",
  "Marc Benioff",
  "Great Oaks Venture Capital",
  "Pequot Capital",
  "Baidu",
  "CID Group",
  "Norwest Mezzanine Partners",
  "ClearSky",
  "Hearst Ventures",
  "Morgan Stanley Dean Witter",
  "TELUS Ventures",
  "Grotech Ventures",
  "Giza Venture Capital",
  "Voyager Capital",
  "Fabrice Grinda",
  "CMEA Capital",
  "Ventech",
  "OUP (Osage University Partners)",
  "Naval Ravikant",
  "Avalon Ventures",
  "Rho Ventures",
  "TSVC",
  "Noro-Moseley Partners",
  "Vulcan Capital",
  "GE Equity",
  "Hummer Winblad Venture Partners",
  "Citi Ventures",
  "Lilly Asia Ventures",
  "Alliance of Angels",
  "Morado Ventures",
  "CyberAgent Capital",
  "Lowercase Capital",
  "LaunchCapital",
  "GS Growth",
  "Blumberg Capital",
  "Creandum",
  "Auren Hoffman",
  "Sevin Rosen Funds",
  "Chiratae Ventures",
  "Pinnacle Ventures",
  "Thomvest Ventures",
  "BETPLAY138 | Waspada Betpay138 Situs Maling",
  "Shenzhen Capital Group",
  "Act Venture Capital",
  "Warner Media",
  "Scott Banister",
  "Marc Bell Ventures",
  "Pelion Venture Partners",
  "Idealab",
  "Dave Morin",
  "BDMI",
  "GrowthWorks Capital",
  "Atlantic Bridge",
  "Foundry Group",
  "Lightbank",
  "Kinnevik",
  "BlueRun Ventures",
  "Viola Credit",
  "Saints Capital",
  "SeedInvest",
  "Pfizer Venture Investments",
  "Brook Venture Partners",
  "Three Arch Partners",
  "QueensBridge Venture Partners",
  "L Catterton",
  "Techammer",
  "Vast Ventures",
  "British International Investment",
  "Wilson Sonsini Goodrich & Rosati",
  "NUMA",
  "Sumitomo Mitsui Banking Corporation",
  "Cybele Holdings",
  "Barclays Corporate Banking",
  "Agility Capital",
  "J.P. Morgan Securities Inc.",
  "Coatue",
  "Criteria Venture Tech",
  "QuestMark Partners",
  "ODYSSEE VENTURE",
  "Founders Circle Capital",
  "Entrepreneur Venture",
  "IBB Ventures",
  "Star Ventures",
  "ICONIQ Growth",
  "Beringea",
  "Nissay Capital",
  "Oxford Bioscience Partners",
  "Split Rock Partners",
  "Claret Capital Partners",
  "HBM Healthcare Investments AG",
  "Ulu Ventures",
  "Crédit Agricole",
  "Social Starts",
  "Mountain Partners",
  "Northwood Ventures",
  "Lauder Partners",
  "DST Global",
  "CARB-X",
  "Maverick Ventures",
  "Deerfield Capital Management",
  "CDH Investments",
  "Export Development Canada",
  "OpenView",
  "BNP Paribas Private Equity",
  "DFJ Growth",
  "Granite Ventures",
  "Lux Capital",
  "Amgen Ventures",
  "Worldview Technology Partners",
  "Verizon Ventures",
  "Genesis Partners",
  "Novak Biddle Venture Partners",
  "IRIS",
  "Mobius Venture Capital",
  "NTT DOCOMO Ventures",
  "Bloomberg Beta",
  "Vertex Ventures Israel",
  "BancBoston Ventures",
  "Village Ventures",
  "Crosspoint Venture Partners",
  "Core Capital Partners",
  "China Development Industrial Bank (CDIB)",
  "J & W Seligman",
  "Azure Capital Partners",
  "Swisscom Ventures",
  "TeleSoft Partners",
  "Thomas Weisel Partners",
  "Celtic House Venture Partners",
  "Scott Belsky",
  "Flybridge",
  "ComVentures",
  "WILCO",
  "Haystack",
  "InnoVen Capital",
  "Sequoia Capital Israel",
  "H.I.G. Growth Partners",
  "Atomico",
  "Labrador Ventures",
  "Camden Partners",
  "WestView Capital Partners",
  "Version One Ventures",
  "Acton Capital",
  "InvestMichigan",
  "Susquehanna Growth Equity",
  "TransCosmos",
  "Commonwealth Capital Ventures",
  "American Express Ventures",
  "Crescendo Ventures",
  "Prism Venture Management",
  "b2venture (formerly btov Partners)",
  "Delphi Ventures",
  "Quotidian Ventures",
  "CMEA Ventures",
  "Glynn Capital Management",
  "Lilly Ventures",
  "Flight Ventures",
  "First Analysis",
  "Mitchell Kapor",
  "SAIF Partners",
  "Telstra Ventures",
  "Sorenson Capital",
  "Alexis Ohanian",
  "RBC Venture Partners",
  "Farzad Nazem",
  "Incubate Fund",
  "Advent Venture Partners",
  "Amerindo Investment Advisors",
  "Boulder Ventures",
  "Regal Funds Management",
  "Promus Ventures",
  "Advancit Capital",
  "Red Swan Ventures",
  "Hinge Capital",
  "Clarus Ventures",
  "Access Industries",
  "Sprout Group",
  "HealthCare Ventures",
  "Northzone",
  "Mizuho Bank",
  "Rembrandt Venture Partners",
  "DNX Ventures",
  "WPP Ventures",
  "Homebrew",
  "Transmedia Capital",
  "Virginia Venture Partners",
  "Winklevoss Capital",
  "Level Equity Management",
  "Artis Ventures (AV)",
  "Ascension Ventures",
  "LocalGlobe",
  "Evergreen Venture Partners",
  "Palomar Ventures",
  "Draper Associates",
  "83North",
  "Fortune Venture Capital",
  "Formation 8",
  "Gilde Healthcare",
  "HealthCap",
  "Silverton Partners",
  "Activate Venture Partners",
  "JK&B Capital",
  "Joshua Schachter",
  "Microsoft Accelerator",
  "Skyline Ventures",
  "Band of Angels",
  "Poalim Equity",
  "GP Bullhound",
  "SixThirty",
  "Founders' Co-op",
  "Primary Venture Partners",
  "Vayner/RSE",
  "Mission Ventures",
  "Wayne Chang",
  "Dave McClure",
  "AllegisCyber",
  "TL Ventures",
  "VenGrowth Asset Management",
  "ABS Ventures",
  "Foresight Group",
  "Harbert Growth Partners",
  "Excel Venture Management",
  "Pontifax",
  "ONSET Ventures",
  "CapitalG",
  "Alsop Louie Partners",
  "Scout Ventures",
  "Karista",
  "DN Capital",
  "Nordic Capital",
  "Esther Dyson",
  "Gemini Israel Ventures",
  "Grape Arbor VC",
  "Cota Capital",
  "AngelPad",
  "Catapult Ventures",
  "Learn Capital",
  "NewSchools Venture Fund",
  "Third Point Ventures",
  "Triangle Peak Partners",
  "BAM Ventures",
  "El Dorado Ventures",
  "Globis Capital Partners",
  "Naxicap Partners",
  "Cowboy Ventures",
  "TVM Capital",
  "HIGHLINEvc",
  "Lee Linden",
  "MI Ventures",
  "Point Nine",
  "Mark Cuban",
  "TDF Ventures",
  "Real Ventures",
  "Morningside Group",
  "Kaiser Permanente Ventures",
  "Vaekstfonden",
  "Intersouth Partners",
  "Ludlow Ventures",
  "Graph Ventures",
  "Innovation Endeavors",
  "XG Ventures",
  "Technology Partners",
  "Streamlined Ventures",
  "ProQuest Investments",
  "Saarbruecker 21",
  "Steamboat Ventures",
  "Burrill & Company",
  "Translink Capital",
  "Mellon Ventures",
  "Rustic Canyon Partners",
  "RiverVest",
  "Birchmere Ventures",
  "Seed Capital",
  "Daniel Curran",
  "Auriga Partners",
  "Valhalla Partners",
  "Cultivation Capital",
  "DHVC",
  "Georgian",
  "Rothenberg Ventures",
  "Digital Garage (TSE: 4819)",
  "Mumbai Angels",
  "SGH CAPITAL",
  "Clark Landry",
  "Newfund",
  "Apex Venture Partners",
  "Javelin Venture Partners",
  "Knight Enterprise Fund",
  "Oreilly AlphaTech Ventures",
  "VTF Capital",
  "Industrifonden",
  "Max Levchin",
  "WI Harper Group",
  "EIT Digital Accelerator",
  "EvoNexus",
  "TA Ventures",
  "Nippon Venture Capital",
  "NextView Ventures",
  "Enterprise Partners",
  "Siparex Groupe",
  "OVP Venture Partners",
  "Asset Management Ventures (AMV)",
  "CVP Management",
  "Louis Beryl",
  "Microsoft Accelerator Bangalore",
  "Chang Corporation",
  "Keiretsu Forum",
  "Project A Ventures",
  "Inveready",
  "Bullpen Capital",
  "Helion Venture Partners",
  "Alven",
  "XAnge",
  "Forerunner Ventures",
  "Innovation Network Corporation of Japan",
  "Jump Capital",
  "Resolute Ventures",
  "Crédit Mutuel Equity",
  "The Draper Richards Kaplan Foundation",
  "OCA Ventures",
  "Charlie Songhurst",
  "IA Ventures",
  "Chicago Ventures",
  "Founder Friendly Labs",
  "Hard Yaka",
]

export const SOURCE_TYPE_OPTIONS = [
  ...Object.keys(StrategicSourceType),
  ...Object.keys(StrategicSourceType).map(
    (value) => `${EXCLUDE_PREFIX}${value}` as const
  ),
] as const

export const INVESTOR_HIGHLIGHTS_PROPS: Record<
  InvestorHighlight,
  {
    label: string
    colorScheme: string
    tab: number
    secondLine?: (signal: CompanyTableItem) => ReactNode
  }
> = {
  high_exit_ratio: {
    label: "High Exit Ratio",
    colorScheme: "green",
    tab: 0,
  },
  high_investment_activity: {
    label: "High Investment Activity",
    colorScheme: "blue",
    tab: 0,
  },
  lead_investor_spotlight: {
    label: "Lead Investor Spotlight",
    colorScheme: "orange",
    tab: 0,
  },
  raised_new_fund: {
    label: "Raised New Fund",
    colorScheme: "purple",
    tab: 0,
  },
  recent_investment: {
    label: "Recent Investment",
    colorScheme: "teal",
    tab: 0,
  },
}

export const INVESTOR_HIGHLIGHTS_OPTIONS = [
  ...(Object.keys(
    INVESTOR_HIGHLIGHTS_PROPS
  ) as UnionToTuple<InvestorHighlight>),
  ...(
    Object.keys(INVESTOR_HIGHLIGHTS_PROPS) as UnionToTuple<InvestorHighlight>
  ).map((value) => `${EXCLUDE_PREFIX}${value}` as const),
] as const

export const INVESTOR_TYPE_OPTIONS = [
  ...(Object.keys(InvestorType) as UnionToTuple<InvestorType>),
  ...(Object.keys(InvestorType) as UnionToTuple<InvestorType>).map(
    (value) => `${EXCLUDE_PREFIX}${value}` as const
  ),
] as const

export const FUNDING_TYPE_OPTIONS = [
  ...(Object.keys(FundingType) as UnionToTuple<FundingType>),
  ...(Object.keys(FundingType) as UnionToTuple<FundingType>).map(
    (value) => `${EXCLUDE_PREFIX}${value}` as const
  ),
] as const

// GROWTH STAGE

export const GROWTH_STAGE_LABEL = {
  [GrowthStage.no_funding]: "Bootstrapped",
  [GrowthStage.bootstrapped]: "Bootstrapped",
  [GrowthStage.pre_launch]: "Pre-Launch",
  [GrowthStage.seed]: "Pre-seed / Seed",
  [GrowthStage.early]: "Early Stage",
  [GrowthStage.growing]: "Growth Stage",
  [GrowthStage.late]: "Late Stage",
  [GrowthStage.exit]: "Exit Stage",
} as const

export type GrowthBadgeTheme = Partial<Record<GrowthStage, string>>

export const companyGrowthBadgeTheme = {
  [GrowthStage.no_funding]: "yellow.600",
  [GrowthStage.seed]: "orange.500",
  [GrowthStage.early]: "green.500",
  [GrowthStage.growing]: "green.700",
  [GrowthStage.late]: "green.900",
  [GrowthStage.exit]: "blue.500",
} as const satisfies GrowthBadgeTheme

export const strategicGrowthBadgeTheme = {
  [GrowthStage.pre_launch]: "purple.500",
  [GrowthStage.bootstrapped]: "yellow.600",
  [GrowthStage.early]: "green.500",
  [GrowthStage.growing]: "green.700",
  [GrowthStage.late]: "green.900",
} as const satisfies GrowthBadgeTheme

const companyGrowthStageKeys = TypedObjectKeys(companyGrowthBadgeTheme)
const strategicGrowthStageKeys = TypedObjectKeys(strategicGrowthBadgeTheme)

export const CompanyGrowthStageEnum = pick(companyGrowthStageKeys, GrowthStage)
export const StratintelGrowthStageEnum = pick(
  strategicGrowthStageKeys,
  GrowthStage
)

export const GROWTH_STAGE_OPTIONS = [
  ...(TypedObjectKeys(CompanyGrowthStageEnum) as UnionToTuple<
    keyof typeof CompanyGrowthStageEnum
  >),
  ...TypedArrayMap(
    TypedObjectKeys(CompanyGrowthStageEnum),
    (value) => `${EXCLUDE_PREFIX}${value}` as const
  ),
] as const

export type PeopleHighlight = keyof typeof PEOPLE_HIGHLIGHTS_PROPS

type HighlightProps = {
  label: string
  secondLine: string
  icon: IconType
  colorScheme: string
}

export const PEOPLE_HIGHLIGHTS_PROPS = {
  fortune_500_experience: {
    label: "Fortune 500 Experience",
    secondLine: "has worked at a Fortune 500 company",
    icon: PiTrophyLight,
    colorScheme: "orange",
  },
  hyper_connector: {
    label: "Hyper Connector",
    secondLine: "has over 10K LinkedIn followers",
    icon: PiUsersLight,
    colorScheme: "orange",
  },
  influencer: {
    label: "Influencer",
    secondLine: "has over 20K LinkedIn + Twitter followers",
    icon: PiUsersLight,
    colorScheme: "orange",
  },
  prior_exit: {
    label: "Prior Exit",
    secondLine: "was a founder or C-level of a company that got acquired",
    icon: PiTrendUpLight,
    colorScheme: "purple",
  },
  prior_ipo: {
    label: "Prior IPO",
    secondLine: "was a founder or C-level at a company that IPOed",
    icon: PiTrendUpLight,
    colorScheme: "purple",
  },
  prior_vc_backed_experience: {
    label: "Prior VC Backed Experience",
    secondLine: "has worked at a VC-funded company",
    icon: PiTrendUpLight,
    colorScheme: "purple",
  },
  prior_vc_backed_founder: {
    label: "Prior VC Backed Founder",
    secondLine: "has been a founder or C-level at a VC-funded company",
    icon: PiRocketLaunchLight,
    colorScheme: "purple",
  },
  serial_founder: {
    label: "Serial Founder",
    secondLine: "has founded 2+ companies",
    icon: PiRocketLaunchLight,
    colorScheme: "purple",
  },
  major_tech_experience: {
    label: "Major Tech Experience",
    secondLine: "has worked at a Top 50 tech company",
    icon: PiCodeLight,
    colorScheme: "blue",
  },
  polyglot: {
    label: "Polyglot",
    secondLine: "speaks 4+ languages",
    icon: PiGlobeLight,
    colorScheme: "blue",
  },
  strong_academic_background: {
    label: "Strong Academic Background",
    secondLine: "has a PhD, or significant academic experience",
    icon: PiGraduationCapLight,
    colorScheme: "blue",
  },
  strong_technical_background: {
    label: "Strong Technical Background",
    secondLine: "engineer with 10+ years of experience",
    icon: PiCodeLight,
    colorScheme: "blue",
  },
  top_university: {
    label: "Top University",
    secondLine: "has attended a top 50 university",
    icon: PiGraduationCapLight,
    colorScheme: "blue",
  },
  vc_backed_experience: {
    label: "VC Backed Experience",
    secondLine: "has worked at a VC-funded company",
    icon: PiTrendUpLight,
    colorScheme: "purple",
  },
  vc_backed_founder: {
    label: "VC Backed Founder",
    secondLine: "is a founder or C-level at a VC-funded company",
    icon: PiRocketLaunchLight,
    colorScheme: "purple",
  },
} as const satisfies Record<string, HighlightProps>

const PEOPLE_HIGHLIGHTS_KEYS = Object.keys(
  PEOPLE_HIGHLIGHTS_PROPS
) as UnionToTuple<PeopleHighlight>

export const PEOPLE_HIGHLIGHT_OPTIONS = [
  ...PEOPLE_HIGHLIGHTS_KEYS,
  ...PEOPLE_HIGHLIGHTS_KEYS.map(
    (value) => `${EXCLUDE_PREFIX}${value}` as const
  ),
] as const

export const LANGUAGE_PROFICIENCY_OPTIONS = [
  "All",
  "Elementary Proficiency",
  "Limited Working Proficiency",
  "Professional Working Proficiency",
  "Full Professional Proficiency",
  "Native Or Bilingual Proficiency",
] as const

export type LanguageProficiency = (typeof LANGUAGE_PROFICIENCY_OPTIONS)[number]

export const LANGUAGE_PROFICIENCY_PROPS: Record<
  LanguageProficiency,
  { label: string; colorScheme: string; valueLabel?: string }
> = {
  All: {
    label: "Any Proficiency Level",
    colorScheme: "gray",
  },
  "Elementary Proficiency": {
    label: "Elementary Proficiency",
    colorScheme: "yellow",
  },
  "Limited Working Proficiency": {
    label: "Limited Working Proficiency",
    colorScheme: "green",
    valueLabel: "Limited Working",
  },
  "Professional Working Proficiency": {
    label: "Professional Working Proficiency",
    colorScheme: "blue",
    valueLabel: "Professional Working",
  },
  "Full Professional Proficiency": {
    label: "Full Professional Proficiency",
    colorScheme: "purple",
    valueLabel: "Full Professional",
  },
  "Native Or Bilingual Proficiency": {
    label: "Native or Biligual",
    colorScheme: "red",
  },
}

export const EDUCATION_LEVELS = [
  "Secondary Education",
  "Bachelor's Degree",
  "Master's Degree",
  "Doctoral Degree",
] as const

export const SENIORITY_LEVELS = [
  "Entry Level",
  "Mid Level",
  "Senior Level",
  "Executive Level",
] as const

export const COMPANY_OPERATORS = [
  "All",
  "Current",
  "Past",
  "CurrentRecent",
] as const

export type CompanyOperator = (typeof COMPANY_OPERATORS)[number]

export const COMPANY_OPERATOR_LABELS = {
  All: "Current + Past",
  Current: "Current Only",
  Past: "Past Only",
  CurrentRecent: "Current + Recent",
} as const satisfies Record<CompanyOperator, string>

export const PEOPLE_DEPARTMENTS = [
  "BD & Marketing",
  "Engineering",
  "Finance",
  "Human Resources",
  "Legal & Compliance",
  "Operations",
  "Product & Research",
  "Strategy & Corporate Development",
] as const

export type PeopleDepartment = (typeof PEOPLE_DEPARTMENTS)[number]

export const COMPANY_OPERATORS_FIELDS = [
  "Department",
  "JobTitles",
  "PastJobTitles",
  "Companies",
  "CompanyIndustries",
  "CompanyInvestors",
] as const

export type CompanyOperatorsField = (typeof COMPANY_OPERATORS_FIELDS)[number]

export const DEPARTMENT_ICONS: Record<PeopleDepartment, IconType> = {
  "BD & Marketing": HiOutlineMegaphone,
  Engineering: HiOutlineCodeBracket,
  Finance: HiOutlineCurrencyDollar,
  "Human Resources": HiOutlineUsers,
  "Legal & Compliance": PiGavelLight,
  Operations: HiOutlineCog,
  "Product & Research": HiOutlineLightBulb,
  "Strategy & Corporate Development": PiStrategy,
}

export const CURRENT_TENURE_OPTIONS = [
  "3 months",
  "6 months",
  "1 year",
  "1.5 years",
  "2 years",
  "3 years",
  "5 years",
  "7 years",
  "10 years",
  "15 years",
  "20 years",
  "25 years",
] as const

export const AVERAGE_TENURE_OPTIONS = [
  "6 months",
  "1 year",
  "1.5 years",
  "2 years",
  "3 years",
  "5 years",
  "7 years",
  "10 years",
] as const
