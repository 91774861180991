import { Button, useDisclosure } from "@chakra-ui/react"
import { ScreenshotModal } from "~/components/ScreenshotModal"

interface Props {
  screenshot: string
}

export const ScreenshotButton = ({ screenshot }: Props): JSX.Element => {
  const modal = useDisclosure()

  return (
    <>
      <Button
        size="xs"
        variant="outline"
        onClick={(e) => {
          e.stopPropagation()
          modal.onOpen()
        }}
      >
        View Screenshot
      </Button>

      <ScreenshotModal
        screenshot={screenshot}
        isOpen={modal.isOpen}
        onClose={modal.onClose}
      />
    </>
  )
}
