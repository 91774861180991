import { AspectRatio, useTheme } from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { format, sub } from "date-fns"
import numeral from "numeral"
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { useCompanyDeliveryMonth } from "~/utils/hooks/useCompanyDeliveryMonth"
import { theme } from "~/utils/theme"
import { TooltipSimplifiedProps } from "../ChartSimplified"

export interface ChartOverTimeProps {
  reversed?: boolean
  baseValue: number
  month1Growth: number | null
  month2Growth: number | null
  month3Growth: number | null
  month4Growth: number | null
  month5Growth: number | null
  month6Growth: number | null
  yAxisValueFormat?: string
  valueCalculation?: (
    baseValue: number,
    growthValue: number | null
  ) => number | null
  label?: string
  product?: SpecterProducts
}

export const valuePercentageCalculation = (
  baseValue: number,
  growthValue: number | null
) => {
  if (growthValue === null) {
    return null
  }

  return Math.ceil(baseValue / (1 + growthValue / 100))
}

export const valueAbsCalculation = (
  baseValue: number,
  growthValue: number | null
) => {
  if (growthValue === null) {
    return null
  }

  return baseValue - growthValue
}

export const GRAPH_COLORS: Partial<Record<SpecterProducts, string>> = {
  [SpecterProducts.company]: theme.colors.brand[500],
  [SpecterProducts.talent]: "#FB552F",
  [SpecterProducts.stratintel]: "#00C82C",
}

export const ChartOverTime = ({
  baseValue,
  reversed = false,
  month1Growth,
  month2Growth,
  month3Growth,
  month4Growth,
  month5Growth,
  month6Growth,
  yAxisValueFormat = "0.[00]a",
  label = "Value",
  product = SpecterProducts.company,
  valueCalculation = valuePercentageCalculation,
}: ChartOverTimeProps): JSX.Element => {
  const theme = useTheme()

  const chartColor = GRAPH_COLORS[product] ?? theme.colors.blue[500]

  const { data: deliveryMonth } = useCompanyDeliveryMonth()

  if (!deliveryMonth) return <></>

  const currentMonth = new Date(deliveryMonth)

  return (
    <AspectRatio ratio={2} maxH={300} overflow="hidden">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={300}
          data={[
            {
              value: valueCalculation(baseValue, month6Growth),
              period: format(sub(currentMonth, { months: 6 }), "MMM"),
            },
            {
              value: valueCalculation(baseValue, month5Growth),
              period: format(sub(currentMonth, { months: 5 }), "MMM"),
            },
            {
              value: valueCalculation(baseValue, month4Growth),
              period: format(sub(currentMonth, { months: 4 }), "MMM"),
            },
            {
              value: valueCalculation(baseValue, month3Growth),
              period: format(sub(currentMonth, { months: 3 }), "MMM"),
            },
            {
              value: valueCalculation(baseValue, month2Growth),
              period: format(sub(currentMonth, { months: 2 }), "MMM"),
            },
            {
              value: valueCalculation(baseValue, month1Growth),
              period: format(sub(currentMonth, { months: 1 }), "MMM"),
            },
            {
              value: baseValue,
              period: format(currentMonth, "MMM"),
            },
          ]}
        >
          <defs>
            <linearGradient id="overTimeGrad" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={chartColor} stopOpacity={0.5} />
              <stop offset="100%" stopColor={chartColor} stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis
            reversed={reversed}
            dataKey="value"
            fontSize="12px"
            width={80}
            allowDecimals={false}
            tickFormatter={(value) => numeral(value).format(yAxisValueFormat)}
            interval="preserveStartEnd"
            domain={[
              (dataMin: number) => Math.min(0, dataMin),
              (dataMax: number) => {
                if (dataMax < 50) {
                  return Math.ceil(dataMax / 10) * 10
                }

                if (dataMax < 500) {
                  return Math.ceil(dataMax / 100) * 100
                }

                if (dataMax < 5000) {
                  return Math.ceil(dataMax / 1000) * 1000
                }

                if (dataMax < 50000) {
                  return Math.ceil(dataMax / 10000) * 10000
                }

                if (dataMax < 500000) {
                  return Math.ceil(dataMax / 100000) * 100000
                }

                if (dataMax < 5000000) {
                  return Math.ceil(dataMax / 1000000) * 1000000
                }

                if (dataMax < 50000000) {
                  return Math.ceil(dataMax / 10000000) * 10000000
                }

                return dataMax
              },
            ]}
          />
          <XAxis dataKey="period" fontSize="12px" />
          <Area
            type="monotone"
            dataKey="value"
            name={label}
            stroke={chartColor}
            fill="url(#overTimeGrad)"
            connectNulls
            strokeWidth={2}
          />
          <Tooltip
            {...TooltipSimplifiedProps(theme, yAxisValueFormat, label)}
          />
        </AreaChart>
      </ResponsiveContainer>
    </AspectRatio>
  )
}
