import {
  Box,
  Button,
  Select,
  Center,
  Flex,
  Text,
  Spinner,
  ModalFooter,
  Icon,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { ProductItem } from "../SignalCard"
import pluralize from "pluralize"
import { getIntegrationName } from "~/utils/integrationApp"
import { useState } from "react"
import { useMutation } from "@tanstack/react-query"
import { z } from "zod"
import { ReturnButton } from "../ReturnButton"
import { HiCheck } from "react-icons/hi"
import { HiExclamationTriangle } from "react-icons/hi2"
import invariant from "~/utils/invariant"
import { useAi } from "~/utils/hooks/useAi"
import { useIntegrationService } from "~/utils/hooks/useIntegrationService"
import { useIntegrationLists } from "~/utils/hooks/useIntegrations"

type Props<P extends SpecterProducts> = {
  integrationKey: string
  matchedSignals: {
    signal: ProductItem<P>
    match: any
  }[]
  dataSource: string
  nextStep: () => void
}

export const AddToList = <P extends SpecterProducts>({
  integrationKey,
  matchedSignals,
  dataSource,
  nextStep,
}: Props<P>) => {
  const [isAddingToList, setIsAddingToList] = useState(false)
  const [selectedListId, setSelectedListId] = useState<string | null>(null)
  const integrationName = getIntegrationName(integrationKey)
  const integrationService = useIntegrationService()
  const listsQuery = useIntegrationLists(integrationKey, isAddingToList)

  const selectedList = listsQuery.data?.find(
    (list) => list.value === selectedListId
  )

  const addToListMutation = useMutation({
    mutationFn: async () => {
      invariant(selectedList, "No list selected")

      const req = await integrationService.post(`/add-to-list`, {
        integrationKey,
        listId: selectedList.value,
        records: matchedSignals.map(({ match }) => match),
        dataSource,
      })

      const result = z
        .object({
          errors: z.array(
            z.object({
              raw: z.any(),
            })
          ),
          results: z.array(z.any()),
        })
        .parse(req)

      return result
    },
    retry: 0,
  })

  const addToList = () => {
    nextStep()
    if (selectedListId) {
      addToListMutation.mutate()
    }
  }

  const skipStep = () => {
    setIsAddingToList(false)
    nextStep()
  }

  const hasErrors = (addToListMutation.data?.errors?.length ?? 0) > 0
  const firstError = addToListMutation.data?.errors?.[0]?.raw
  const aiError = useAi(
    hasErrors
      ? `
You are an assistant that helps users troubleshoot configuration issues with their integration settings.
The user has attempted to add ${pluralize(
          "this",
          matchedSignals.length
        )} ${pluralize(
          "Record",
          matchedSignals.length
        )} to a ${integrationName} list, but they received an error message from the integrations third party API. 
Given the error message and any additional context provided, overview what needs to be changed in the user's configuration settings so that the integration will succeed.

Explain the error, keep it short, simple and to the point.

Error Data: ${JSON.stringify(firstError)}
    `
      : undefined
  )

  return (
    <>
      <Flex h="full" flexDir="column">
        <Center flex={1}>
          <Box>
            <Text mb={4} fontWeight="medium">
              Would you like to add {pluralize("this", matchedSignals.length)}{" "}
              {pluralize("Record", matchedSignals.length)} to a{" "}
              {integrationName} list?
            </Text>
            <Flex gap={2} justifyContent="center">
              <Button variant="outline" size="sm" onClick={skipStep}>
                Skip
              </Button>
              <Button
                variant="solid"
                colorScheme="brand"
                size="sm"
                onClick={() => {
                  setIsAddingToList(true)
                  nextStep()
                }}
              >
                Yes, Add to List
              </Button>
            </Flex>
          </Box>
        </Center>
      </Flex>

      {isAddingToList && (
        <>
          <Flex h="full" flexDir="column">
            <Center flex={1}>
              <Box>
                <Text mb={4} fontWeight="medium">
                  Which {integrationName} list would you like to add{" "}
                  {pluralize("this", matchedSignals.length)}{" "}
                  {pluralize("Record", matchedSignals.length)} to?
                </Text>
                {listsQuery.isLoading && <Spinner />}
                {listsQuery.isSuccess && (
                  <Select
                    value={selectedListId ?? ""}
                    placeholder="Select an option..."
                    onChange={(e) => setSelectedListId(e.target.value)}
                  >
                    {listsQuery.data?.map((list) => (
                      <option key={list.value} value={list.value}>
                        {list.label}
                      </option>
                    ))}
                  </Select>
                )}
              </Box>
            </Center>
            <ModalFooter>
              <ReturnButton onClick={addToList}>Next</ReturnButton>
            </ModalFooter>
          </Flex>

          {selectedListId && (
            <Flex h="full" flexDir="column">
              <Center flex={1} alignItems={hasErrors ? "flex-start" : "center"}>
                <Box textAlign="center" maxW={480} py={18}>
                  {addToListMutation.isLoading && (
                    <>
                      <Spinner />
                      <Text mb={4} fontWeight="medium">
                        Adding {pluralize("Record", matchedSignals.length)} to "
                        {selectedList?.label}"
                      </Text>
                    </>
                  )}

                  {addToListMutation.isSuccess && (
                    <>
                      <Icon
                        as={hasErrors ? HiExclamationTriangle : HiCheck}
                        color={hasErrors ? "orange.500" : "brand.500"}
                        fontSize="4xl"
                      />
                      <Text fontWeight="medium">
                        Adding to list complete{hasErrors ? " with errors" : ""}
                      </Text>
                      <Text color="gray.600" fontSize="sm">
                        {addToListMutation.data.results.length}{" "}
                        {pluralize(
                          "Record",
                          addToListMutation.data.results.length
                        )}{" "}
                        added to list
                      </Text>
                      {hasErrors && (
                        <>
                          <Text color="red.500" fontSize="sm" mb={2}>
                            {addToListMutation.data.errors.length}{" "}
                            {pluralize(
                              "Record",
                              addToListMutation.data.errors.length
                            )}{" "}
                            failed to be added to list
                          </Text>
                          {aiError.isLoading && (
                            <Text fontSize="sm" color="gray.600">
                              <Spinner size="xs" /> Building AI powered
                              summary...
                            </Text>
                          )}
                          <Text
                            whiteSpace="break-spaces"
                            mb={4}
                            fontSize="sm"
                            color="gray.600"
                          >
                            {aiError.data ??
                              (firstError &&
                              typeof firstError === "object" &&
                              "message" in firstError
                                ? firstError.message
                                : JSON.stringify(firstError))}
                          </Text>
                        </>
                      )}
                      <Button
                        mt={4}
                        size="xs"
                        variant="outline"
                        onClick={() => addToListMutation.mutate()}
                      >
                        Rerun
                      </Button>
                    </>
                  )}
                </Box>
              </Center>
              <ModalFooter>
                <ReturnButton
                  onClick={nextStep}
                  isDisabled={addToListMutation.isLoading}
                >
                  Next
                </ReturnButton>
              </ModalFooter>
            </Flex>
          )}
        </>
      )}
    </>
  )
}
