import {
  add,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
  sub,
} from "date-fns"
import { zonedTimeToUtc } from "date-fns-tz"

export const lastWeek = () => {
  const now = new Date()
  return sub(
    new Date(
      Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        0,
        0,
        0
      )
    ),
    {
      weeks: 1,
    }
  )
}

export const startOfBusinessWeek = () =>
  zonedTimeToUtc(
    startOfWeek(lastWeek(), {
      weekStartsOn: 6,
    }),
    "utc"
  )

export const endOfBusinessWeek = () =>
  zonedTimeToUtc(
    endOfWeek(lastWeek(), {
      weekStartsOn: 6,
    }),
    "utc"
  )

export function endOfNextBusinessWeek() {
  return zonedTimeToUtc(
    endOfWeek(add(endOfBusinessWeek(), { days: 6 }), { weekStartsOn: 6 }),
    "utc"
  )
}

export const startOfBusinessMonth = () =>
  zonedTimeToUtc(startOfMonth(new Date()), "utc")

export const endOfBusinessMonth = () =>
  zonedTimeToUtc(endOfMonth(new Date()), "utc")
