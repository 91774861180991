import { Button, Flex, Text, Center } from "@chakra-ui/react"
import { Link } from "@remix-run/react"
import { HEADER_HEIGHT } from "~/components/Header"

function PeopleDBComingSoon() {
  return (
    <Center h={`calc(100vh - ${HEADER_HEIGHT}px)`}>
      <Flex direction="column" gap={1} align="center">
        <video
          src="/specter-ghost.mp4"
          autoPlay
          loop
          muted
          style={{
            height: "30vh",
            objectFit: "contain",
          }}
        />
        <Text as="h2" fontWeight="semibold" fontSize="xl" mt={3} mb={1}>
          Specter People is almost here!
        </Text>
        <Text fontSize="sm" color="gray.500" textAlign="center">
          We're{" "}
          <Text as="strong" fontWeight="semibold" color="brand.500">
            launching this January
          </Text>
          , and you'll be among the first to explore it. <br /> Stay tuned for
          updates as we bring powerful people insights your way!
        </Text>
        <Button
          variant="outline"
          w="fit-content"
          my={8}
          as={Link}
          to={`/signals/talent/feed`}
        >
          Return to Talent Signals
        </Button>
      </Flex>
    </Center>
  )
}

export default PeopleDBComingSoon
