import { Flex, Tag, Text } from "@chakra-ui/react"

import { SpecterProducts } from "@prisma/client"
import { Link as RemixLink } from "@remix-run/react"

import { CompanyLogo } from "~/components"
import { CardBox, SignalCard, SignalCardProps } from "~/components/SignalCard"

import { parseISO } from "date-fns"
import numeral from "numeral"
import { GetFundingRoundsEntry } from "~/utils/db/fundingRoundsDB"
import { isDateValid } from "~/utils/isDateValid"
import { formatDate } from "~/utils/string/format"

interface Props {
  signal: GetFundingRoundsEntry
  addedToList?: boolean
  signalCardProps?: Partial<SignalCardProps<"fundingRounds">>
}

export const FundingRoundSignalCard = ({
  signal,
  signalCardProps,
}: Props): JSX.Element => {
  const companyURL = `/signals/company/feed/${signal.company.id}/f?tab=1`

  return (
    <SignalCard
      {...signalCardProps}
      customLink={companyURL}
      product={SpecterProducts.fundingRounds}
      signal={signal}
      picture={
        signal.company.CompanyData ? (
          <CompanyLogo
            domain={String(signal.company.CompanyData.Domain)}
            size={16}
          />
        ) : null
      }
      title={signal.name}
      // subtitle={`Raised amount: ${signal.}` }
      description={
        <>
          {isDateValid(signal.announcedOn) &&
            `Announced on ${formatDate(
              parseISO(signal.announcedOn as unknown as string)
            )}`}
          <br />
          Post Money Valuation:{" "}
          <Text as="span" color="gray.600" fontSize="sm" fontWeight="semibold">
            {signal.postMoneyValuation
              ? numeral(signal.postMoneyValuation).format("$0,0")
              : "-"}
          </Text>
        </>
      }
      // postDescription={"postDescription"}
      postTitle={[
        signal.investors?.map(({ investor }) => (
          <Tag
            zIndex={1}
            key={investor.id}
            mr={1}
            as={RemixLink}
            to={`/signals/investors/feed/${investor.id}/f`}
            _hover={{ bg: "brand.100" }}
            transition="background-color 0.2s"
          >
            {investor.name}
          </Tag>
        )),
      ].filter(Boolean)}
      postActionMenu={
        <Flex direction="column" justifyContent="center" flexGrow={1}>
          {signal.raisedAmount && (
            <CardBox
              title="Money Raised"
              value={numeral(signal.raisedAmount).format("$0,0")}
            ></CardBox>
          )}
        </Flex>
      }
      // actionButtons={
      //   <>
      //     <ButtonWebsite websiteUrl={signal.domain} />

      //     <ButtonCrunchbase
      //       crunchbaseUrl={ensureAbsoluteLink(signal.crunchbaseUrl)}
      //     />
      //     <ButtonLinkedin
      //       linkedinUrl={ensureAbsoluteLink(signal.linkedinUrl)}
      //     />
      //     <ButtonTwitter twitterUrl={ensureAbsoluteLink(signal.twitterUrl)} />
      //   </>
      // }
      // cardBoxes={
      //   <>
      //     {signal.raisedAmount && (
      //       <CardBox
      //         title="Raised amount"
      //         value={numeral(signal.raisedAmount).format("$0,0")}
      //       ></CardBox>
      //     )}
      //     {signal.postMoneyValuation && (
      //       <CardBox
      //         title="Post Money Valuation"
      //         value={numeral(signal.postMoneyValuation).format("$0,0")}
      //       ></CardBox>
      //     )}
      //   </>
      // }
      // postActionMenu={
      //   !!signal.highlights?.filter(
      //     (h) =>
      //       COMPANY_HIGHLIGHTS_PROPS[h] !== undefined &&
      //       !COMPANY_HIGHLIGHTS_GROUPS[COMPANY_HIGHLIGHTS_PROPS[h].group].hide
      //   )?.length && (
      //     <Flex direction="column" gap={1}>
      //       {[
      //         ...new Set(
      //           sortCompanyHighlights(
      //             signal.highlights.filter(
      //               (h) => COMPANY_HIGHLIGHTS_PROPS[h] !== undefined
      //             )
      //           )?.map((highlight) => COMPANY_HIGHLIGHTS_PROPS[highlight].group)
      //         ),
      //       ]
      //         .slice(0, 5)
      //         .map((group) => {
      //           const highlight = sortCompanyHighlights(
      //             signal.highlights?.filter(
      //               (h) => COMPANY_HIGHLIGHTS_PROPS[h] !== undefined
      //             ) ?? []
      //           )?.find((h) => group === COMPANY_HIGHLIGHTS_PROPS[h].group)

      //           if (!highlight) return null

      //           const { label } = COMPANY_HIGHLIGHTS_PROPS[highlight]

      //           const { colorScheme, tab, hide } =
      //             COMPANY_HIGHLIGHTS_GROUPS[group]

      //           if (hide) return null

      //           const isNew =
      //             signal.newHighlights &&
      //             signal.newHighlights.includes(highlight)

      //           return (
      //             <Card
      //               key={highlight}
      //               p={1}
      //               px={2}
      //               position="relative"
      //               bgColor={`${colorScheme}.50`}
      //               borderColor={`${colorScheme}.100`}
      //               fontWeight="bold"
      //               to={`${detailURL}${queryAppendChar}tab=${tab}`}
      //             >
      //               {isNew && (
      //                 <NotificationBadge
      //                   value="New"
      //                   color={`${colorScheme}.400`}
      //                 />
      //               )}

      //               <Text
      //                 as="h2"
      //                 color={`${colorScheme}.500`}
      //                 fontSize="x-small"
      //                 whiteSpace="nowrap"
      //                 flexGrow={1}
      //               >
      //                 {label.replace(/Growth/g, "")}
      //               </Text>
      //             </Card>
      //           )
      //         })}
      //     </Flex>
      //   )
      // }
    />
  )
}
