import { URLSearchParamsInit } from "../hooks/useSafeSearchParams"

/**
 * Builds a relative URL as new URL() doesn't allow a partial URL
 */
export function relativeUrl(
  pathname: string,
  queryParams: URLSearchParamsInit
): string {
  return `${pathname}?${new URLSearchParams(queryParams)}`
}

/**
 * Appends a query param to a relative URL
 */
export function appendQueryParam(
  relativeUrl: string,
  queryParams: [string, string][]
): string {
  let formatRelativeUrl = relativeUrl

  if (!formatRelativeUrl.startsWith("/")) {
    formatRelativeUrl = `/${formatRelativeUrl}`
  }

  // Note, we pad with our domain because new URL expects a FQDN
  const url = new URL(`https://app.tryspecter.com${formatRelativeUrl}`)

  queryParams.forEach((param) => url.searchParams.append(...param))

  return `${url.pathname}?${url.searchParams.toString()}`
}

/**
 * Given a URL like string strip "http" and "www." from the front of it.
 */
export function stripProtocol(s: string): string {
  let output = s

  if (output.startsWith("http://")) {
    output = output.slice(7)
  }

  if (output.startsWith("https://")) {
    output = output.slice(8)
  }

  if (output.startsWith("www.")) {
    output = output.slice(4)
  }

  return output
}

/**
 * Given a URL like string remove the trailing slash (/).
 */
export function removeTrailingSlash(url: string): string {
  if (url.endsWith("/")) {
    return url.slice(0, -1) // Remove the trailing slash
  }
  return url
}

/**
 * Given a possibly relative URL convert it to an absolute URL. This mostly
 * involves prepend "https" if the protocol isn't set.
 */
export function ensureAbsoluteLink(s: string | null): string {
  if (!s) return ""

  if (!s.startsWith("http")) {
    return "https://" + s
  }

  return s
}

/**
 * Given a possibly relative URL prepend "https", this is to make relative
 * URLs absolute.
 */
export function normalizeItunesAppUrl(app_url: string | null): string {
  if (!app_url) return ""

  if (!isNaN(parseInt(app_url))) {
    return `https://itunes.apple.com/us/app/${app_url}`
  }
  return ensureAbsoluteLink(app_url)
}
