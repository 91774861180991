import {
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  PlacementWithLogical,
  Portal,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { FocusEventHandler, useState } from "react"
import { HiChevronRight } from "react-icons/hi"
import {
  CustomMultiValue,
  CustomMultiValueRemove,
  getOptionLabel,
  ReactSelect,
} from "~/components"
import { EXCLUDE_PREFIX } from "~/consts/signals"
import useElementSize from "~/utils/hooks/useElementSize"
import { useProduct } from "~/utils/hooks/useProduct"

export function ComposedMenuSelect<T extends string>({
  field,
  menuOptions,
  subMenuOptions,
  value,
  onChange,
  onBlur,
  placement = { menu: "start", subMenu: "right-start" },
  allowExclude,
}: {
  field: string
  menuOptions: readonly T[]
  subMenuOptions: Record<T, readonly string[]>
  value: readonly string[]
  onChange: (value: readonly string[]) => void
  onBlur: FocusEventHandler<HTMLInputElement>
  placement?: { menu: PlacementWithLogical; subMenu: PlacementWithLogical }
  allowExclude?: boolean
}) {
  const product = useProduct()
  const [subMenuOpen, setSubMenuOpen] = useState<T | null>(null)

  const selectOptions = (
    Array.from(new Set(Object.values(subMenuOptions).flat())) as string[]
  )
    .filter(
      (option) =>
        // Don't show excluded values in select options
        !option.startsWith(EXCLUDE_PREFIX) &&
        // When "<exclude>:<value>" is selected, don't show "<value>" in the options
        !value?.some((v: string) => v.replace(EXCLUDE_PREFIX, "") === option)
    )
    .map((option: string) => ({
      value: option,
      label: getOptionLabel(option, field, product),
    }))

  // ! If we prefer to group the sub-options by option, we can use this instead
  // const groupedSelectOptions = ObjectMap(
  //   subMenuOptions,
  //   ([itemKey, subItems]) => ({
  //     label: menuOptions[itemKey],
  //     options: subItems.map((subItem) => ({
  //       label: subItem,
  //       value: subItem,
  //     })),
  //   })
  // )

  const openedSubMenuSelectedOptions = subMenuOpen
    ? value.filter((subItem) => subMenuOptions[subMenuOpen].includes(subItem))
    : []

  const [menuListRef] = useElementSize()
  const [buttonRef, { width }] = useElementSize<HTMLButtonElement>()

  const menuDisclosure = useDisclosure()

  const [text, setText] = useState("")

  return (
    <Flex direction="column">
      <ReactSelect
        inputId={field}
        instanceId={field}
        menuIsOpen={text.length > 0}
        isMulti
        isSearchable
        inputValue={text}
        onInputChange={(value, { action }) => {
          if (action === "set-value") return

          setText(value)
        }}
        options={selectOptions}
        value={value.map((subItem) => ({
          value: subItem,
          label: getOptionLabel(subItem, field, product),
        }))}
        onChange={(value) => {
          setText("")
          onChange(value.map((v) => v.value))
        }}
        onBlur={onBlur}
        onMenuOpen={menuDisclosure.onOpen}
        onMenuClose={() => text.length > 0 && menuDisclosure.onClose()}
        closeMenuOnSelect={false}
        {...(allowExclude && {
          components: {
            MultiValueRemove: CustomMultiValueRemove,
            MultiValue: CustomMultiValue,
          },
        })}
      />
      <Menu
        placement={placement.menu}
        preventOverflow
        closeOnSelect={false}
        onClose={() => {
          setSubMenuOpen(null)
          menuDisclosure.onClose()
        }}
        isOpen={menuDisclosure.isOpen && text.length === 0}
      >
        <MenuButton ref={buttonRef} width="full" p={0}></MenuButton>
        <Portal>
          <MenuList
            maxH="350px"
            overflowY="auto"
            ref={menuListRef}
            width={width}
            zIndex={99999}
          >
            {/* Sub Menu */}
            <Menu
              isOpen={subMenuOpen !== null}
              placement={placement.subMenu}
              preventOverflow={false}
              closeOnSelect={false}
            >
              <MenuButton
                as={MenuItem}
                p={0}
                position="absolute"
                top={0}
                left={0}
                right={0}
              ></MenuButton>
              <MenuList
                h={`${menuListRef.current?.offsetHeight}px`}
                overflowY="auto"
              >
                {subMenuOpen && (
                  <MenuOptionGroup
                    title={subMenuOpen}
                    type="checkbox"
                    value={openedSubMenuSelectedOptions}
                  >
                    {subMenuOptions[subMenuOpen].map((subItem) => (
                      <MenuItemOption
                        key={subItem}
                        value={subItem}
                        onClick={() => onChange(toggleElement(value, subItem))}
                        fontSize="sm"
                      >
                        {subItem}
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                )}
              </MenuList>
            </Menu>
            {/* Main Menu Options */}
            {menuOptions.map((option) => {
              const subMenuOptionsSelected = value.filter((subItem) =>
                subMenuOptions[option].includes(subItem)
              )

              return (
                <MenuItem
                  key={option}
                  onClick={() =>
                    setSubMenuOpen((prev) => (prev === option ? null : option))
                  }
                  bg={option === subMenuOpen ? "gray.100" : "transparent"}
                >
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text
                      fontSize="sm"
                      display="inline"
                      mr="auto"
                      textAlign="center"
                      _after={{
                        content: '""',
                        display: "inline-block",
                        width: 1,
                        height: 1,
                        ml: 2,
                        mb: "2px",
                        bg:
                          subMenuOptionsSelected.length > 0
                            ? "red.400"
                            : "transparent",
                        borderRadius: "full",
                      }}
                    >
                      {option}{" "}
                      <Text as="span" color="gray.500" fontSize="xs">
                        ({subMenuOptions[option].length})
                      </Text>
                    </Text>
                    <Icon as={HiChevronRight} color="gray.400" />
                  </Flex>
                </MenuItem>
              )
            })}
          </MenuList>
        </Portal>
      </Menu>
    </Flex>
  )
}

const toggleElement = <T,>(arr: readonly T[], item: T) =>
  arr.includes(item) ? arr.filter((i) => i !== item) : [...arr, item]
