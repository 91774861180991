import { Link, Text } from "@chakra-ui/react"
import { Link as RemixLink } from "@remix-run/react"
import {
  TALENT_SIGNAL_STATUS,
  TALENT_SIGNAL_TYPE,
} from "~/consts/talentSignals"
import { GetTalentSignalByID } from "~/utils/db/getTalentSignalByID"
import {
  pickKeyFromKeyValue,
  pickValueFromKeyValue,
} from "~/utils/keyValuePairs"

interface Props {
  talentSignal: GetTalentSignalByID
}

export const TalentSignalTitle = ({
  talentSignal,
}: Props): JSX.Element | null => {
  // const location = useLocation()
  // const isEmbed = location.pathname.includes("/embed")

  const {
    SignalStatus,
    PastPosition,
    NewPosition,
    SignalType,
    PastCompanyWebsite,
    NewCompanyWebsite,
    PastCompany,
    NewCompany,
  } = talentSignal

  const pastCompanyName = PastPosition
    ? pickKeyFromKeyValue(PastPosition)
    : "unknown"
  const newCompanyName = NewPosition
    ? pickKeyFromKeyValue(NewPosition)
    : "unknown"
  const newPosition = NewPosition
    ? pickValueFromKeyValue(NewPosition)
    : "unknown"

  const PastCompanyLink = () => {
    return (
      <Link
        color="brand.500"
        onClick={(e) => e.stopPropagation()}
        position="relative"
        zIndex={1}
        {...(PastCompany?.Company?.id
          ? {
              as: RemixLink,
              to: `/signals/company/feed/${PastCompany.Company.id}/f`,
              // target: isEmbed ? "_blank" : undefined,
              target: "_blank",
            }
          : {
              href: PastCompanyWebsite ?? undefined,
              target: "_blank",
              to: "",
            })}
      >
        {pastCompanyName}
      </Link>
    )
  }

  const NewCompanyLink = () => {
    return (
      <Link
        color="brand.500"
        {...(NewCompany?.Company?.id
          ? {
              as: RemixLink,
              to: `/signals/company/feed/${NewCompany.Company.id}/f`,
              // target: isEmbed ? "_blank" : undefined,
              target: "_blank",
            }
          : {
              href: NewCompanyWebsite ?? undefined,
              target: "_blank",
              to: "",
            })}
        position="relative"
        zIndex={1}
        {...(!NewCompanyWebsite && {
          cursor: "text",
          textDecoration: "none !important",
        })}
        onClick={(e) => e.stopPropagation()}
      >
        {newCompanyName}
      </Link>
    )
  }

  if (
    SignalStatus === TALENT_SIGNAL_STATUS.LIVE &&
    (SignalType === TALENT_SIGNAL_TYPE.NEW_COMPANY ||
      SignalType === TALENT_SIGNAL_TYPE.NEW_FUND)
  ) {
    return (
      <>
        Left <PastCompanyLink /> and started a {SignalType}: <NewCompanyLink />{" "}
        as "{newPosition}"
      </>
    )
  }

  if (
    SignalStatus === TALENT_SIGNAL_STATUS.STEALTH &&
    (SignalType === TALENT_SIGNAL_TYPE.NEW_COMPANY ||
      SignalType === TALENT_SIGNAL_TYPE.NEW_FUND)
  ) {
    return (
      <>
        Left <PastCompanyLink /> and started a {SignalType} in{" "}
        <Text as="span" color="brand.500">
          Stealth
        </Text>
      </>
    )
  }

  if (
    SignalStatus === TALENT_SIGNAL_STATUS.OUT_OF_STEALTH &&
    (SignalType === TALENT_SIGNAL_TYPE.NEW_COMPANY ||
      SignalType === TALENT_SIGNAL_TYPE.NEW_FUND)
  ) {
    return (
      <>
        Comes Out of Stealth and announces a {SignalType}: <NewCompanyLink /> as
        "{newPosition}"
      </>
    )
  }

  if (
    SignalStatus === TALENT_SIGNAL_STATUS.LIVE &&
    SignalType === TALENT_SIGNAL_TYPE.NEW_ROLE
  ) {
    return (
      <>
        Left <PastCompanyLink /> and started a New Role at <NewCompanyLink /> as
        "{newPosition}"
      </>
    )
  }

  if (
    SignalStatus === TALENT_SIGNAL_STATUS.STEALTH &&
    SignalType === TALENT_SIGNAL_TYPE.NEW_ROLE
  ) {
    return (
      <>
        Left <PastCompanyLink /> and started a New Role in{" "}
        <Text as="span" color="brand.500">
          Stealth
        </Text>
      </>
    )
  }

  if (
    SignalStatus === TALENT_SIGNAL_STATUS.OUT_OF_STEALTH &&
    SignalType === TALENT_SIGNAL_TYPE.NEW_ROLE
  ) {
    return (
      <>
        Comes Out of Stealth and announces a New Role at <NewCompanyLink /> as "
        {newPosition}"
      </>
    )
  }

  if (
    SignalStatus === TALENT_SIGNAL_STATUS.HR &&
    SignalType === TALENT_SIGNAL_TYPE.SEARCHING
  ) {
    return (
      <>
        Left <PastCompanyLink /> and might be Searching a new job opportunity
      </>
    )
  }

  if (
    SignalStatus === TALENT_SIGNAL_STATUS.HR &&
    SignalType === TALENT_SIGNAL_TYPE.PROMOTION
  ) {
    return (
      <>
        Got a Promotion at <NewCompanyLink /> as "{newPosition}"
      </>
    )
  }

  if (
    SignalStatus === TALENT_SIGNAL_STATUS.LIVE &&
    SignalType === TALENT_SIGNAL_TYPE.INVESTMENT
  ) {
    return (
      <>
        Made an Investment in <NewCompanyLink />
      </>
    )
  }

  if (
    SignalStatus === TALENT_SIGNAL_STATUS.STEALTH &&
    SignalType === TALENT_SIGNAL_TYPE.INVESTMENT
  ) {
    return <>Made an Investment in a Stealth company</>
  }

  if (
    SignalStatus === TALENT_SIGNAL_STATUS.OUT_OF_STEALTH &&
    SignalType === TALENT_SIGNAL_TYPE.INVESTMENT
  ) {
    return (
      <>
        Comes Out of Stealth and announces an Investment in <NewCompanyLink />
      </>
    )
  }

  return null
}
