import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { useFormik } from "formik"
import { HiUser } from "react-icons/hi"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { z } from "zod"
import { toFormikValidationSchema } from "zod-formik-adapter"
import { cacheKeys } from "~/utils/cacheKeys"
import invariant from "~/utils/invariant"
import { useUserPermissions } from "~/utils/hooks/useUserPermissions"
import { ReactSelect } from "~/components"
import { GetShareableMembers } from "~/routes/__protected/api/team/share-members"
import { LoadingLogo } from "~/components/Logo/loading"
import { ListType } from "~/routes/__protected/user/lists/$product"
import { MenuItem } from "~/components/MenuItem"

interface FormProps {
  listType: ListType
  onClose: () => void
  id: string | number
  product: SpecterProducts
}

const formSchema = z.object({
  newUserId: z.string(),
})

const validationSchema = toFormikValidationSchema(formSchema)

const ReassignForm = ({
  listType,
  onClose,
  id,
  product,
}: FormProps): JSX.Element => {
  const queryClient = useQueryClient()
  const permissions = useUserPermissions()
  const toast = useToast()

  const updateMutation = useMutation<unknown, unknown, string>(
    async (newUserId) => {
      const reassignListUrl =
        listType == ListType.userList
          ? `/api/lists/${product}/${id}/reassign/${newUserId}`
          : `/api/saved-searches/${id}/reassign/${newUserId}`

      await fetch(reassignListUrl, {
        method: "POST",
      })
    },
    {
      async onSuccess() {
        onClose()

        if (listType == ListType.userList) {
          await queryClient.invalidateQueries(cacheKeys.userLists({ product }))
        }

        if (listType == ListType.savedSearch) {
          await queryClient.invalidateQueries(
            cacheKeys.userSavedSearches(product)
          )
        }

        const title =
          listType == ListType.userList
            ? "List reassigned successfully"
            : "Saved Search reassigned successfully"

        toast({
          status: "success",
          title,
        })
      },
    }
  )

  const teamMembersQuery = useQuery(
    cacheKeys.shareMembers,
    async () => {
      const req = await fetch("/api/team/share-members")

      invariant(req.ok)

      const data: GetShareableMembers = await req.json()

      return data.filter((user) => user.userId !== permissions.data?.userId)
    },
    {
      enabled: permissions.isSuccess,
    }
  )

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      newUserId: "",
    },
    onSubmit(values) {
      if (values.newUserId) {
        updateMutation.mutate(values.newUserId)
      }
    },
  })

  const selectedUser = teamMembersQuery.data?.find(
    (user) => user.userId === formik.values.newUserId
  )

  if (permissions.isLoading && !permissions.data) {
    return <LoadingLogo />
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <ModalBody display="flex" flexDirection={"column"} gap={6}>
        <FormControl isInvalid={!!formik.errors.newUserId}>
          <FormLabel htmlFor="name">List Owner</FormLabel>
          <ReactSelect
            isSearchable
            options={[
              {
                label: `You (${permissions.data?.email})`,
                value: permissions.data?.userId || "",
              },
              ...(teamMembersQuery.data?.map((user) => ({
                label: `${user.first_name} ${user.last_name} (${user.email})`,
                value: user.userId,
              })) ?? []),
            ]}
            value={
              selectedUser
                ? {
                    label: `${selectedUser.first_name} ${selectedUser.last_name} (${selectedUser.email})`,
                    value: formik.values.newUserId,
                  }
                : permissions.data?.userId === formik.values.newUserId
                ? {
                    label: `You (${permissions.data?.email})`,
                    value: formik.values.newUserId,
                  }
                : null
            }
            onChange={(option) => {
              if (option?.value) {
                formik.setFieldValue("newUserId", option.value)
              }
            }}
          />
          <FormErrorMessage>{formik.errors.newUserId}</FormErrorMessage>
        </FormControl>
      </ModalBody>
      <ModalFooter gap={2}>
        <Button variant="outline" size="sm" onClick={onClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          size="sm"
          colorScheme="brand"
          isLoading={updateMutation.isLoading}
          disabled={!formik.isValid || updateMutation.isLoading}
        >
          Save Changes
        </Button>
      </ModalFooter>
    </form>
  )
}

interface Props {
  listType: ListType
  id: string | number
  product: SpecterProducts
}

export function Reassign({ listType, id, product }: Props): JSX.Element {
  const modal = useDisclosure()

  const reassignListBody = (
    <p>
      Reassigning this list will remove it from your account and assign it to
      another user. The list will be <i>reset</i>, it will no longer be public,
      nor will it be shared with the new owners' team. This is so that they can
      decide what to do with it.
    </p>
  )

  const reassignSavedSearchBody = (
    <p>
      Reassigning this Saved Search will remove it from your account and assign
      it to another user. The search will be <i>reset</i>, it will no longer be
      public, nor will it be shared with the new owners' team. This is so that
      they can decide what to do with it.
    </p>
  )

  return (
    <>
      <MenuItem icon={HiUser} onClick={modal.onOpen}>
        Reassign
      </MenuItem>

      <Modal isOpen={modal.isOpen} onClose={modal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Reassign</ModalHeader>
          <ModalBody>
            {listType == ListType.userList
              ? reassignListBody
              : reassignSavedSearchBody}
          </ModalBody>
          <ReassignForm
            listType={listType}
            onClose={modal.onClose}
            id={id}
            product={product}
          />
        </ModalContent>
      </Modal>
    </>
  )
}
