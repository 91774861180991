import {
  Box,
  Button,
  Icon,
  Center,
  Flex,
  ModalFooter,
  Select,
  Text,
  IconButton,
  Spinner,
  Tooltip,
  PopoverBody,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useFormik } from "formik"
import pluralize from "pluralize"
import { useMemo } from "react"
import { HiArrowRight, HiInformationCircle, HiX } from "react-icons/hi"
import { HiExclamationTriangle } from "react-icons/hi2"
import { titleCase } from "title-case"
import { z } from "zod"
import { cacheKeys } from "~/utils/cacheKeys"
import { getProductEntityName } from "~/utils/getProductName"
import { getSignalFields } from "~/utils/getSignalFields"
import { getValueAtPathToString } from "~/utils/getValueAtPathToString"
import { useIntegrationFields } from "~/utils/hooks/useIntegrations"
import { getFieldMappingKey, getIntegrationName } from "~/utils/integrationApp"
import invariant from "~/utils/invariant"
import { ReturnButton } from "../ReturnButton"
import { ProductItem } from "../SignalCard"
import { Enrichment } from "./Enrichment"

type Props<P extends SpecterProducts> = {
  product: P
  integrationKey: string
  matchedSignals: {
    signal: ProductItem<P>
    match: any
  }[]
  dataSource: string
  nextStep: () => void
  configLocked: boolean
  defaultFieldMapping?: any
}

export const FieldMapping = <P extends SpecterProducts>({
  product,
  integrationKey,
  matchedSignals,
  dataSource,
  nextStep,
  configLocked,
  defaultFieldMapping,
}: Props<P>) => {
  const queryClient = useQueryClient()
  const fieldsQuery = useIntegrationFields(product, integrationKey, dataSource)

  const updateMetaMutation = useMutation({
    mutationFn: async (mapping: Record<string, string>) => {
      const fieldMappingKey = getFieldMappingKey(product)

      invariant(fieldMappingKey, "Field mapping key not found")

      await fetch(`/api/integrations/${integrationKey}/meta`, {
        method: "POST",
        body: JSON.stringify({
          [fieldMappingKey]: mapping,
        }),
      })
    },
    async onSuccess() {
      await queryClient.invalidateQueries({
        queryKey: cacheKeys.integrationMeta(integrationKey),
      })
    },
  })

  const hasValidValue = (signal: ProductItem<P>, path: string) => {
    const value = path.split(".").reduce((obj: any, key) => {
      if (key === "[0]") {
        return Array.isArray(obj) ? obj[0] : obj
      }
      return obj?.[key]
    }, signal)

    return value !== undefined && value !== null
  }

  // Sort initial field mapping by value
  const initialValues = useMemo(() => {
    const parsedFieldMapping = z
      .record(z.string(), z.string())
      .safeParse(defaultFieldMapping)

    if (!parsedFieldMapping.success) return []

    const entries = Object.entries(parsedFieldMapping.data)
    return entries.sort(([, a], [, b]) => a.localeCompare(b))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    async onSubmit(values) {
      await updateMetaMutation.mutateAsync(Object.fromEntries(values))
      nextStep()
    },
  })

  const fieldMapping = formik.values
  const availableFields =
    fieldsQuery.data?.filter((field) => {
      return !fieldMapping.some(([key]) => key === field.value)
    }) ?? []

  const [firstSignal] = matchedSignals
  const internalFields = getSignalFields(firstSignal?.signal ?? {})
  const showEnrichmentStep = Object.keys(fieldMapping).length > 0
  const showEnrichment = updateMetaMutation.isSuccess

  const skipEnrichment = () => {
    nextStep()
    if (showEnrichmentStep && showEnrichment) {
      nextStep()
    }
  }

  return (
    <>
      <Flex
        h="full"
        flexDir="column"
        as="form"
        onSubmit={(e) => {
          e.preventDefault()
          formik.handleSubmit()
        }}
      >
        <Center flex={1} overflow="auto">
          <Box textAlign="center">
            <Text mb={4} fontWeight="medium" maxW={360} mx="auto">
              Which fields should be sent to{" "}
              {getIntegrationName(integrationKey)} when enriching{" "}
              {pluralize("this", matchedSignals.length)}{" "}
              {pluralize("Record", matchedSignals.length)}?
            </Text>

            {fieldsQuery.isLoading && <Spinner />}

            {fieldMapping.map(([key, value], i) => {
              const selectedField = fieldsQuery.data?.find(
                (field) => field.value === key
              )

              const hasBlankValues = matchedSignals.some(
                ({ signal }) => !hasValidValue(signal, value)
              )
              const valuePreview = getValueAtPathToString(
                firstSignal?.signal,
                value
              )

              return (
                <Flex key={i} alignItems="center" gap={2} mb={2}>
                  {hasBlankValues && (
                    <Tooltip
                      label={`Some selected ${pluralize(
                        getProductEntityName(product).toLowerCase()
                      )} are missing a value for this field.`}
                    >
                      <Box>
                        <Icon as={HiExclamationTriangle} color="orange.500" />
                      </Box>
                    </Tooltip>
                  )}
                  <Popover>
                    <PopoverTrigger>
                      <Box>
                        <Icon as={HiInformationCircle} color="gray.400" />
                      </Box>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverBody textAlign="left">
                        <Text fontSize="xx-small">
                          <Text as="span" fontWeight="semibold">
                            Type:
                          </Text>{" "}
                          {titleCase(typeof valuePreview)}
                        </Text>
                        <Text fontSize="xx-small">
                          <Text as="span" fontWeight="semibold">
                            Example:
                          </Text>{" "}
                          "{valuePreview}"
                        </Text>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                  <Select
                    w="300px"
                    flex="none"
                    isDisabled={configLocked}
                    placeholder="Select an option..."
                    value={value}
                    onChange={(e) => {
                      const update = [...fieldMapping]
                      update[i] = [key, e.target.value]
                      formik.setValues(update)
                    }}
                  >
                    {internalFields.map((field, i) => {
                      return (
                        <option value={field.value} key={i}>
                          {field.label}
                        </option>
                      )
                    })}
                  </Select>
                  <Icon as={HiArrowRight} />
                  <Select
                    w="300px"
                    flex="none"
                    isDisabled={configLocked}
                    placeholder="Select an option..."
                    value={key}
                    onChange={(e) => {
                      const update = [...fieldMapping]
                      update[i] = [e.target.value, value]
                      formik.setValues(update)
                    }}
                  >
                    {[selectedField, ...availableFields].map((field, i) => {
                      if (!field) return null

                      return (
                        <option value={field.value} key={i}>
                          {field.label}
                        </option>
                      )
                    })}
                  </Select>
                  <IconButton
                    size="sm"
                    variant="outline"
                    icon={<Icon as={HiX} />}
                    onClick={() => {
                      formik.setValues(fieldMapping.filter(([k]) => k !== key))
                    }}
                    aria-label="Remove field"
                  />
                </Flex>
              )
            })}

            {availableFields.length > 0 && (
              <Button
                size="sm"
                mt={4}
                variant="outline"
                onClick={() => {
                  formik.setValues([
                    ...fieldMapping,
                    [availableFields[0].value, internalFields[0]?.value ?? ""],
                  ])
                }}
                isDisabled={configLocked}
              >
                Add Field
              </Button>
            )}
          </Box>
        </Center>

        <ModalFooter gap={2}>
          <Button variant="outline" size="sm" onClick={skipEnrichment}>
            Skip Enrichment
          </Button>
          <ReturnButton
            type="submit"
            isDisabled={
              Object.keys(fieldMapping).length <= 0 || formik.isSubmitting
            }
            isLoading={formik.isSubmitting}
          >
            Next
          </ReturnButton>
        </ModalFooter>
      </Flex>

      {showEnrichmentStep && showEnrichment && (
        <Enrichment
          product={product}
          integrationKey={integrationKey}
          matchedSignals={matchedSignals}
          dataSource={dataSource}
          fieldMapping={Object.fromEntries(fieldMapping)}
          nextStep={nextStep}
        />
      )}
    </>
  )
}
