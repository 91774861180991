import { Box, BoxProps } from "@chakra-ui/react"
import { Link as RemixLink, useLocation } from "@remix-run/react"
import { forwardRef, ReactNode } from "react"
import { useBgColor } from "~/utils/hooks/useBgColor"

export interface CardProps extends BoxProps {
  children?: ReactNode
  suppressHover?: boolean
  to?: string
}

export const Card = forwardRef<HTMLDivElement, CardProps>(
  ({ children, suppressHover, to, ...props }, ref) => {
    const bgColor = useBgColor()
    const location = useLocation()
    const isEmbed = location.pathname.includes("/embed")

    return (
      <Box
        ref={ref}
        rounded="xl"
        borderWidth={1}
        borderColor="gray.100"
        p={{ base: 3, lg: 5 }}
        as="article"
        bgColor={bgColor}
        pos="relative"
        zIndex={1}
        {...(suppressHover
          ? {}
          : {
              _hover: { boxShadow: "card" },
              transition: "box-shadow 0.2s",
            })}
        {...props}
      >
        {to && (
          <Box
            as={RemixLink}
            to={to}
            target={isEmbed ? "_blank" : undefined}
            pos="absolute"
            inset={0}
            zIndex={0}
          />
        )}
        {children}
      </Box>
    )
  }
)

Card.displayName = "Card"
