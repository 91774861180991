import React, { useEffect, useState } from "react"

interface Props {
  children: React.ReactNode
}

export const ClientSide = ({ children }: Props): JSX.Element | null => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  if (!mounted) {
    return null
  }

  return <>{children}</>
}
