import { z } from "zod"

import {
  INDUSTRY_OPTIONS,
  SIGNAL_REGION_OPTIONS,
  SOURCE_TYPE_OPTIONS,
  StratintelGrowthStageEnum,
} from "~/consts/signals"
import {
  andOrSchema,
  dateFilterSchema,
  numericFilterSchema,
  NUMERIC_OPERATORS,
  setFilterSchema,
  textFilterSchema,
} from "~/utils/signal"
import { signalDateFilterSchema } from "~/utils/signalDate"

const HOT_SIGNALS_TIMEFRAMES = {
  "90d": "90 days",
  "180d": "180 days",
  "360d": "360 days",
  "720d": "720 days",
}

export const hotSignalsSchema = z.union([
  z.nativeEnum(HOT_SIGNALS_TIMEFRAMES),
  numericFilterSchema({ omitBetween: true }),
])

const SOURCE_TYPE_SCHEMA = z.array(
  z.enum(SOURCE_TYPE_OPTIONS as [string, ...string[]])
)

export const strategicSignalFiltersSchema = z
  .object({
    SignalDate: signalDateFilterSchema,
    SignalScore: numericFilterSchema(),
    SourceType: andOrSchema(SOURCE_TYPE_SCHEMA),
    SignalSource: setFilterSchema(),
    SignalType: z.array(z.enum(["Company", "Talent"])),
    MultipleSources: z.boolean(),
    HotSignals: hotSignalsSchema,
    HQLocation: z.array(z.string()),
  })
  .partial()

export const strategicCompanyFiltersSchema = z
  .object({
    Company: z.array(z.string()),
    Website: textFilterSchema,
    Description: textFilterSchema,
    Industry: setFilterSchema(z.enum(INDUSTRY_OPTIONS)),
    Founded: numericFilterSchema({
      defaultValue: NUMERIC_OPERATORS.GTE,
      extraConstraints: (z) => z.lte(2999),
    }),
  })
  .partial()

export const strategicFundingFiltersSchema = z
  .object({
    TotalFundingAmount: numericFilterSchema({
      defaultValue: NUMERIC_OPERATORS.LTE,
    }),
    LastFundingDate: dateFilterSchema,
    LastFundingAmount: numericFilterSchema(),
    Investors: textFilterSchema,
  })
  .partial()

export const strategicGrowthStageFiltersSchema = z
  .object({
    growthStage: z.array(z.nativeEnum(StratintelGrowthStageEnum)),
  })
  .partial()

const DEPRECATED_FIELDS = z
  .object({
    // * Add deprecated fields here:
    HQRegion: z.array(z.enum(SIGNAL_REGION_OPTIONS)),
  })
  .partial()

export const strategicSignalFiltersValidation = strategicSignalFiltersSchema
  .merge(strategicGrowthStageFiltersSchema)
  .merge(strategicCompanyFiltersSchema)
  .merge(strategicFundingFiltersSchema)
  .merge(DEPRECATED_FIELDS)

export type StrategicSignalFilters = z.infer<
  typeof strategicSignalFiltersValidation
>

export const strategicAdvancedFiltersSchema = z.object({
  Signal: z.object({
    Signal: strategicSignalFiltersSchema,
  }),
  "Growth Stage": z.object({
    "Growth Stage": strategicGrowthStageFiltersSchema,
  }),
  Company: z.object({
    Company: strategicCompanyFiltersSchema,
  }),
  Funding: z.object({
    Funding: strategicFundingFiltersSchema,
  }),
})

export const strategicSignalQuickFiltersSchema = z
  .object({
    SignalDate: signalDateFilterSchema,
    SourceType: andOrSchema(SOURCE_TYPE_SCHEMA),
    SignalType: z.array(z.enum(["Company", "Talent"])),
    SignalScore: numericFilterSchema(),
    SignalSource: setFilterSchema(),
    growthStage: z.array(z.nativeEnum(StratintelGrowthStageEnum)),
    HQLocation: z.array(z.string()),
    Industry: setFilterSchema(z.enum(INDUSTRY_OPTIONS)),
    HotSignals: hotSignalsSchema,
  })
  .partial()

export type StrategicSignalQuickFilters = z.infer<
  typeof strategicSignalQuickFiltersSchema
>
