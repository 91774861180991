import { SpecterProducts } from "@prisma/client"
import { z, ZodTypeAny } from "zod"
import { PRODUCT_SIGNAL_FILTERS_VALIDATION } from "~/components/Filters/schemas"
import {
  COMPANY_HIGHLIGHTS_RENAMING,
  INDUSTRY_OPTIONS,
  SUB_INDUSTRY_OPTIONS,
} from "~/consts/signals"
import { andOrSchema, textFilterSchema } from "./signal"

export const getDeprecatedFields = (
  product: SpecterProducts,
  query: unknown
) => {
  const signalFiltersValidation = PRODUCT_SIGNAL_FILTERS_VALIDATION[product]
  const parsed = signalFiltersValidation.safeParse(query)

  if (parsed.success) {
    return []
  }

  return parsed.error.issues.map((issue) => {
    const path = issue.path[0]
    return path
  })
}

export const transformQueryBackCompat = <T extends Record<string, any>>(
  product: SpecterProducts,
  query: T
) => {
  const newQuery = { ...query }
  const signalFiltersValidation = PRODUCT_SIGNAL_FILTERS_VALIDATION[product]
  const parsed = signalFiltersValidation.safeParse(query)

  if (parsed.success) {
    return { newQuery, hasUpdated: false, hasFailedValidation: false }
  }

  let hasUpdated = false

  parsed.error.issues.forEach((issue) => {
    const filter = issue.path[0]

    const transform = BACK_COMPAT_TRANSFORMS[product][filter]

    // if there's no transforms for this filter, we can't do anything
    if (!transform) {
      return
    }

    const value = query[filter]

    const transformed = transform.safeParse(value)

    if (!transformed.success) {
      console.log(`Failed to transform ${product} ${filter} ${value}`)
      return
    }

    hasUpdated = true
    newQuery[filter as keyof T] = transformed.data
    console.log({ value, transformed: query[filter] })
  })

  return { newQuery, hasUpdated, hasFailedValidation: true }
}

const BACK_COMPAT_TRANSFORMS: Record<
  SpecterProducts,
  Record<string, ZodTypeAny>
> = {
  [SpecterProducts.company]: {
    Industry: z.array(z.enum(INDUSTRY_OPTIONS)).transform((val) => ["OR", val]),
    SubIndustry: z
      .array(z.enum(SUB_INDUSTRY_OPTIONS))
      .transform((val) => ["OR", val]),
    Highlights: andOrSchema(
      z
        .array(
          z.string().transform((val) => COMPANY_HIGHLIGHTS_RENAMING[val] ?? val)
        )
        .nullable()
    ),
    Investors: textFilterSchema.transform((val) => ["OR", val[1]]),
  },
  [SpecterProducts.talent]: {
    Industry: z.array(z.enum(INDUSTRY_OPTIONS)).transform((val) => ["OR", val]),
  },
  [SpecterProducts.stratintel]: {
    Industry: z.array(z.enum(INDUSTRY_OPTIONS)).transform((val) => ["OR", val]),
    SignalSource: textFilterSchema.transform((val) => ["OR", val[1]]),
  },
  [SpecterProducts.investors]: {},
  [SpecterProducts.fundingRounds]: {},
  [SpecterProducts.acquisition]: {},
  [SpecterProducts.ipo]: {},
  [SpecterProducts.people]: {},
}
