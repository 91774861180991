import { SimpleGrid, Text } from "@chakra-ui/react"
import { Card } from "~/components/Card"
import { Tab } from "~/components/DetailPage/FullScreen"
import { DetailValueCard } from "../DetailValueCard"

export const Patents: Tab<"company">["TabContent"] = ({ signal }) => {
  if (signal.patentsCount === null && signal.trademarksCount === null) {
    return (
      <Card>
        <Text fontSize="xs" color="gray.400">
          We don't have any patents or trademark data for this company at this
          time.
        </Text>
      </Card>
    )
  }

  return (
    <SimpleGrid templateColumns="1fr 1fr" gap={4}>
      <DetailValueCard label="Number of Patents" value={signal.patentsCount} />
      <DetailValueCard
        label="Number of Trademarks"
        value={signal.trademarksCount}
      />
    </SimpleGrid>
  )
}
