import {
  Avatar,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  SimpleGrid,
  Tag,
  Text,
  Tooltip,
} from "@chakra-ui/react"

import { SpecterProducts } from "@prisma/client"
import { Fragment } from "react"
import { HiBriefcase, HiLibrary } from "react-icons/hi"
import { SignalCard } from "~/components/SignalCard"
import {
  ButtonEmail,
  ButtonLinkedin,
  ButtonTwitter,
} from "~/components/SignalCard/Buttons"

import { Education, Person, Position } from "~/utils/db/peopleDBSchemas"
import { getSignalLocation } from "~/utils/getSignalLocation"
import { useCompanyDeliveryMonth } from "~/utils/hooks/useCompanyDeliveryMonth"
import { BoxIcon } from "../BoxIcon"
import { Card } from "../Card"
import { CompanyLogo } from "../CompanyLogo"
import { FlexDivider } from "../FlexDivider"
import { CompanyRankBadge } from "../TagCompanyRank"

import { Link as RemixLink } from "@remix-run/react"
import { HiOutlineShieldCheck } from "react-icons/hi2"
import { sentenceCase } from "sentence-case"
import { titleCase } from "title-case"
import { PeopleHighlight, PEOPLE_HIGHLIGHTS_PROPS } from "~/consts/signals"
import { ensureAbsoluteLink } from "~/utils/string/url"
import { isNullish } from "~/utils/values"

interface Props {
  signal: Person
  customLink?: string | null
  withReducedInfo?: boolean
}

export const NUM_INDUSTRIES_TO_SHOW = 2
export const NUM_SKILLS_TO_SHOW = 2
export const NUM_HIGHLIGHTS_TO_SHOW = 2

export const PeopleSignalCard = ({
  signal,
  customLink,
  withReducedInfo,
}: Props) => {
  const { data: currentMonth } = useCompanyDeliveryMonth()
  const detailURL = `./${signal.id}${location.search}`

  const croppedHeadline =
    signal.headline &&
    signal.headline?.slice(0, 100) +
      (signal.headline?.length > 100 ? "..." : "")

  const currentPositions = signal.experience?.filter((p) => p.is_current)
  const pastPositions = signal.experience?.filter((p) => !p.is_current)

  return (
    <SignalCard
      customLink={customLink}
      withReducedInfo={withReducedInfo}
      product={SpecterProducts.people}
      signal={signal}
      picture={
        <>
          {/* <Tooltip
            label={signal.is_hiring ? "Hiring" : "Open to work"}
            isDisabled={!(signal.is_hiring || signal.is_open_to_work)}
          > */}
          <Avatar
            src={signal.profile_image_url ?? undefined}
            boxSize={20}
            borderRadius="md"
            icon={
              <Image src="https://i.postimg.cc/q7BB33R3/pacman-1.png"></Image>
            }
            bg="white"
            position="relative"
          >
            {/* {(signal.is_hiring || signal.is_open_to_work) && (
                <Box
                  overflow="hidden"
                  position="absolute"
                  inset="0"
                  _before={{
                    content: '""',
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    right: "50%",
                    left: "-25%",
                    background: `linear-gradient(transparent, ${
                      signal.is_hiring ? "purple" : "green"
                    }, transparent)`,
                    // animation: `${rotatingAnim} linear infinite 3s`,
                    transformOrigin: "right",
                    transform: "rotate(-45deg)",
                  }}
                  clipPath={clipPathFrame({ borderRadius: 6, borderWidth: 10 })}
                />
              )} */}
          </Avatar>
          {/* </Tooltip> */}
        </>
      }
      topLeftBadge={
        !!signal.rank && (
          <CompanyRankBadge rank={signal.rank} month={currentMonth} />
        )
      }
      title={signal.full_name}
      subtitle={
        <Text
          fontSize="sm"
          color="gray.600"
          fontWeight="medium"
          as="span"
          maxW="100px"
        >
          {croppedHeadline}
        </Text>
      }
      description={
        <Text fontSize="xs" color="gray.400" as="span">
          {getSignalLocation({
            location: signal.location,
            region: signal.region,
          })}
        </Text>
        // isDateValid(signal.SignalDate) ? (
        //   <Text fontSize="xs" color="gray.400" as="span">
        //     <Text as="span" fontWeight="semibold">
        //       {getSignalDate(signal.SignalDate)}
        //     </Text>{" "}
        //     -{" "}
        //     {getSignalLocation({
        //       location: signal.Location,
        //       region: signal.Region,
        //     })}
        //   </Text>
        // ) : null
      }
      postTitle={
        <>
          <TagListWithOverflow
            items={signal.highlights}
            maxNItems={NUM_HIGHLIGHTS_TO_SHOW}
            overflowTo={detailURL}
            renderFlexDivider
            formatValue={(highlight) =>
              PEOPLE_HIGHLIGHTS_PROPS[highlight as PeopleHighlight]?.label ??
              titleCase(sentenceCase(highlight))
            }
            RenderTag={(highlight) => (
              <Tag
                key={highlight}
                display="inline-flex"
                gap={1}
                alignItems="center"
                colorScheme="orange"
              >
                {PEOPLE_HIGHLIGHTS_PROPS[highlight as PeopleHighlight]?.label ??
                  titleCase(sentenceCase(highlight))}
              </Tag>
            )}
          />
          <TagListWithOverflow
            items={signal.skills}
            maxNItems={NUM_SKILLS_TO_SHOW}
            overflowTo={detailURL}
            // renderFlexDivider
            formatValue={(skill) => skill.name}
            RenderTag={(skill) => (
              <Tag
                key={skill.name}
                display="inline-flex"
                gap={1}
                alignItems="center"
              >
                {skill.name}
                {skill.passed_skill_assessment && (
                  <Tooltip label="Passed skill assessment" fontSize="xs">
                    <Icon as={HiOutlineShieldCheck} />
                  </Tooltip>
                )}
              </Tag>
            )}
          />
          {/* <TagListWithOverflow
            items={signal.industry?.split(", ")}
            maxNItems={NUM_INDUSTRIES_TO_SHOW}
            overflowTo={detailURL}
            RenderTag={(industry) => <TagIndustry label={industry} />}
          /> */}
        </>
      }
      actionButtons={
        <>
          <ButtonLinkedin linkedinUrl={signal.linkedin_url} />
          <ButtonTwitter twitterUrl={signal.twitter_url} />
          <ButtonEmail email={signal?.email} />
        </>
      }
      cardBoxes={
        !withReducedInfo && (
          <>
            {!!currentPositions?.length && (
              <PositionsCard
                title={`Current Position${
                  currentPositions.length > 1 ? "s" : "" ? "s" : ""
                }`}
                positions={currentPositions}
              />
            )}
            {!!pastPositions?.length && (
              <PositionsCard title="Past Positions" positions={pastPositions} />
            )}
            {signal.education && <EducationCard education={signal.education} />}
          </>
        )
      }
    />
  )
}

export const TagListWithOverflow = <T,>({
  items,
  maxNItems,
  overflowTo,
  RenderTag = (item) => item as string,
  formatValue = (item) => item as string,
  renderFlexDivider,
}: {
  items?: T[] | null
  maxNItems: number
  overflowTo?: string
  RenderTag?: (item: T) => React.ReactNode
  formatValue?: (item: T) => string
  renderFlexDivider?: boolean
}) => {
  const overflowCount = (items?.length ?? 0) - maxNItems

  if (isNullish(items) || items.length === 0) return null

  return (
    <>
      {items
        .slice(0, maxNItems) // * Only show the first N tags
        .map(RenderTag)}
      {items.length > maxNItems && (
        <Button
          as={RemixLink}
          to={overflowTo ?? ""}
          variant="link"
          minW="fit-content"
          whiteSpace="nowrap"
        >
          <Tooltip
            label={items?.slice(-overflowCount).map(formatValue).join(", ")}
            fontSize="xs"
          >
            <Text as="span">+{overflowCount}</Text>
          </Tooltip>
        </Button>
      )}
      {renderFlexDivider && <FlexDivider orientation="vertical" />}
    </>
  )
}

export const PositionsCard = ({
  title,
  positions,
}: {
  title?: string
  positions: Position[]
}) => {
  return (
    <Card p={4} display="flex" flexDirection="column" gap={2} maxW="500px">
      {title && (
        <Text as="h2" color="gray.800" fontSize="sm" fontWeight="bold">
          {title}
        </Text>
      )}

      <Flex gap={2}>
        {positions?.slice(0, 2).map((position, i) => (
          <Fragment key={position.specter_company_id + position.title}>
            {i > 0 && <FlexDivider orientation="vertical" />}
            <SimpleGrid templateColumns="auto auto" gap={2}>
              {position.domain ? (
                <CompanyLogo domain={position.domain} size={8} />
              ) : (
                <BoxIcon icon={HiBriefcase} size={8} />
              )}
              <Flex
                direction="column"
                justifyContent="center"
                fontSize="xs"
                overflow="hidden"
                flexShrink={1}
              >
                {position.company_linkedin_url ? (
                  <Link
                    color="brand.500"
                    {...(position.specter_company_id
                      ? {
                          as: RemixLink,
                          to: `/signals/company/feed/${position.specter_company_id}/f`,
                        }
                      : {
                          href: ensureAbsoluteLink(
                            position.company_linkedin_url
                          ),
                          target: "_blank",
                          to: "",
                        })}
                    // href={ensureAbsoluteLink(position.company_linkedin_url)}
                    // target="_blank"
                    onClick={(e) => e.stopPropagation()}
                    fontWeight="semibold"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                  >
                    {position.company_name}
                  </Link>
                ) : (
                  <Text
                    fontWeight="semibold"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                  >
                    {position.company_name}
                  </Text>
                )}
                <Text
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                >
                  {position.title}
                </Text>
              </Flex>
            </SimpleGrid>
          </Fragment>
        ))}
      </Flex>
    </Card>
  )
}

export const EducationCard = ({
  title = "Education",
  education,
}: {
  title?: string
  education: Education[]
}) => {
  return (
    <Card p={4} display="flex" flexDirection="column" gap={2} maxW="500px">
      {title && (
        <Text as="h2" color="gray.800" fontSize="sm" fontWeight="bold">
          {title}
        </Text>
      )}

      <Flex gap={2}>
        {education?.slice(0, 2).map((item, i) => (
          <Fragment key={`${item.school_id} + ${item.degree}`}>
            {i > 0 && <FlexDivider orientation="vertical" />}
            <SimpleGrid templateColumns="auto auto" gap={2}>
              {item.logo_url ? (
                <CompanyLogo source={item.logo_url} size={8} />
              ) : (
                <BoxIcon icon={HiLibrary} size={8} />
              )}
              <Flex
                direction="column"
                justifyContent="center"
                fontSize="xs"
                overflow="hidden"
                flexShrink={1}
              >
                {item.school_linkedin_url ? (
                  <Link
                    color="brand.500"
                    //   {...(talentSignal.PastCompany?.Company?.id
                    //     ? {
                    //         as: RemixLink,
                    //         to: `/signals/company/feed/${talentSignal.PastCompany.Company.id}/f`,
                    //       }
                    //     : {
                    //         href: talentSignal.PastCompanyWebsite ?? undefined,
                    //         target: "_blank",
                    //         to: "",
                    //       })}
                    href={ensureAbsoluteLink(item.school_linkedin_url)}
                    target="_blank"
                    onClick={(e) => e.stopPropagation()}
                    fontWeight="semibold"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                  >
                    {item.school_name}
                  </Link>
                ) : (
                  <Text
                    fontWeight="semibold"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                  >
                    {item.school_name}
                  </Text>
                )}
                <Text
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                >
                  {item.degree}
                </Text>
              </Flex>
            </SimpleGrid>
          </Fragment>
        ))}
      </Flex>
    </Card>
  )
}
