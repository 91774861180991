import { Box, Button, Checkbox, Flex, Heading, Text } from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import {
  Link as RemixLink,
  useLocation,
  useNavigate,
  useParams,
} from "@remix-run/react"
import { useState } from "react"
import {
  CompanySignalCard,
  ProductDetailItem,
  StrategicSignalCard,
  TalentSignalCard,
} from "~/components"
import { FeedDataError, PermissionsError } from "~/components/Errors"
import { FeedLoadingLogo, LoadingLogo } from "~/components/Logo/loading"
import { ProductSignalFeedItems } from "~/routes/__protected/api/signals/$product"
import {
  ListNameTag,
  useInfiniteSignalsQuery,
} from "~/routes/__protected/signals/$product"
import { getDeprecatedFields } from "~/utils/checkForBackwardsCompatibility"
import { hasAccessToFeed } from "~/utils/hasAccessToFeed"
import { useListDetailsQuery } from "~/utils/hooks/useListDetailsQuery"
import { useProduct } from "~/utils/hooks/useProduct"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"
import { useUserPermissions } from "~/utils/hooks/useUserPermissions"
import { JSONSafeParse } from "~/utils/JSONSafeParse"
import { isPermissionAccessError } from "~/utils/permissionAccessError"
import { hasLength } from "~/utils/values"
import { DeprecatedFieldsAlert } from "../DeprecatedFieldsAlert"
import { mapProductsToRouteName } from "../Filters/schemas"
import { FundingRoundSignalCard } from "../FundingRoundSignalCard"
import { InvestorSignalCard } from "../InvestorSignalCard"
import { PeopleSignalCard } from "../PeopleSignalCard"
import { SelectionMenu } from "../Table/SelectionMenu"

export const PRODUCT_SIGNAL_LABEL = {
  [SpecterProducts.company]: "Company",
  [SpecterProducts.talent]: "Talent",
  [SpecterProducts.stratintel]: "Strategic Intelligence",
  [SpecterProducts.investors]: "Investors",
  [SpecterProducts.fundingRounds]: "Funding Rounds",
  [SpecterProducts.acquisition]: "Acquisitions",
  [SpecterProducts.ipo]: "IPOs",
  [SpecterProducts.people]: "People",
} as const

function ProductSignalCard({
  signal,
  product,
}: {
  signal: any
  product: SpecterProducts
}) {
  switch (product) {
    case "company":
      return <CompanySignalCard key={signal.id} signal={signal} />
    case "talent":
      return <TalentSignalCard key={signal.id} signal={signal} />
    case "stratintel":
      return <StrategicSignalCard key={signal.id} signal={signal} />
    case "investors":
      return <InvestorSignalCard key={signal.id} signal={signal} />
    case "fundingRounds":
      return <FundingRoundSignalCard key={signal.id} signal={signal} />
    case "people":
      return <PeopleSignalCard key={signal.id} signal={signal} />
    default:
      return <></>
  }
}

const Feed = (): JSX.Element => {
  const product = useProduct()

  const [searchParams] = useSafeSearchParams()
  const signalsQuery = useInfiniteSignalsQuery(product)

  const userPermissions = useUserPermissions()

  const listId = searchParams.get("listId")
  const query = searchParams.get("query")

  const listQuery = useListDetailsQuery()

  const isEditableList =
    listId && !(listQuery.data?.isGlobalHub && !userPermissions.data?.isAdmin)

  const [selectedRows, setSelectedRows] = useState<
    ProductDetailItem<typeof product>[]
  >([])

  const toggleItem = (
    item: ProductDetailItem<typeof product>,
    checked: boolean
  ) => {
    if (checked) {
      setSelectedRows((prev) => [...prev, item])
    } else {
      setSelectedRows((prev) => prev.filter((i) => i.id !== item.id))
    }
  }

  if (
    signalsQuery.isLoading ||
    signalsQuery.isRefetching ||
    userPermissions.isLoading
  ) {
    return <FeedLoadingLogo />
  }

  if (!hasAccessToFeed(userPermissions.data, product)) {
    return <PermissionsError product={product} />
  }

  if (signalsQuery.isError) {
    if (isPermissionAccessError(signalsQuery.error)) {
      return <PermissionsError product={product} />
    }

    const query = JSONSafeParse<Record<string, any>>(searchParams.get("query"))
    const deprecatedFields = getDeprecatedFields(product, query)

    if (deprecatedFields.length > 0) {
      return (
        <DeprecatedFieldsAlert
          deprecatedFields={deprecatedFields}
          query={query}
        />
      )
    }

    return <FeedDataError />
  }

  const items = signalsQuery.data.pages.flatMap(
    // @ts-ignore
    (page) => page.items
  ) as unknown as ProductSignalFeedItems<typeof product>

  return (
    <>
      <SelectionMenu
        config={product}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
      <Flex
        alignSelf="stretch"
        alignItems="stretch"
        gap={3}
        flex={1}
        mt={4}
        flexDirection="column"
      >
        {!hasLength(items) && (
          <Flex direction="column" my={9} alignItems="center" gap={2}>
            <Heading
              as="h4"
              fontSize="xl"
              display="inline-flex"
              alignItems="center"
              gap={2}
            >
              {!listId ? (
                "No results found. 😞"
              ) : (
                <>
                  List <ListNameTag /> is empty
                  {query ? ", for those filters" : ""}.
                </>
              )}
            </Heading>
            <Text fontSize="sm" color="gray.400">
              {!listId
                ? "Perhaps try a different search or contact us at team@tryspecter.com."
                : `Add some signals to your list ${
                    query ? ", or change the filters" : "to get started"
                  }.`}
            </Text>
            {listId && (
              <Button
                variant="outline"
                fontSize="xs"
                as={RemixLink}
                to={`/signals/${mapProductsToRouteName(product)}/feed`}
                minW="fit-content"
              >
                Browse Specter feeds
              </Button>
            )}
          </Flex>
        )}

        {items.map((signal) => (
          <Flex key={signal.id}>
            {isEditableList && (
              <Checkbox
                pr={3}
                isChecked={selectedRows.some((i) => i.id === signal.id)}
                onChange={(e) =>
                  toggleItem(
                    signal as ProductDetailItem<typeof product>,
                    e.target.checked
                  )
                }
              />
            )}
            <Box flexGrow={1}>
              <ProductSignalCard signal={signal} product={product} />
            </Box>
          </Flex>
        ))}

        <Box ref={signalsQuery.observerRef}>
          {signalsQuery.hasNextPage && <LoadingLogo />}
        </Box>
      </Flex>
    </>
  )
}

export function useDetailPageGoBack() {
  const navigate = useNavigate()
  const { pathname, search } = useLocation()
  const params = useParams()

  const returnPath = pathname.slice(0, pathname.indexOf(params.id ?? ""))

  return () => {
    const query = new URLSearchParams(search)
    query.delete("tab")
    navigate(`${returnPath}${query.toString() ? `?${query}` : ""}`)
  }
}

export default Feed
