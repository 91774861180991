import { Flex } from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { LoaderArgs, redirect } from "@remix-run/node"
import { Outlet } from "@remix-run/react"

import {
  mapProductsToRouteName,
  PRODUCT_ROUTE_NAMES,
} from "~/components/Filters/schemas"

import { isOneOf } from "~/utils/isOneOf"
import { PAGE_NOT_FOUND } from "~/utils/responses/errors"

export enum ListType {
  savedSearch = "search",
  userList = "list",
}

export async function loader({ params }: LoaderArgs) {
  if (isOneOf(params.product, Object.keys(PRODUCT_ROUTE_NAMES))) return null

  if (isOneOf(params.product, Object.values(SpecterProducts)))
    throw redirect(`/user/lists/${mapProductsToRouteName(params.product)}`)

  throw PAGE_NOT_FOUND
}

const UserLists = (): JSX.Element => {
  return (
    <Flex
      as="main"
      direction="column"
      overflow="hidden"
      bgColor="white"
      borderBottomRadius="xl"
      flexGrow={1}
    >
      <Outlet />
    </Flex>
  )
}

export default UserLists
