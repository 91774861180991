import { Alert, AlertIcon, Flex, Tooltip } from "@chakra-ui/react"
import { FormikProps } from "formik"
import { HiOutlineQuestionMarkCircle } from "react-icons/hi2"
import { getSignalFieldTitle } from "~/utils/signal"

interface Props<T> {
  formik: FormikProps<T>
}

export function FormikErrors<T>({ formik }: Props<T>) {
  const errors = Object.entries(formik.errors)
    .map(([field, message]) => {
      const touched = formik.touched[field as keyof typeof formik.errors]

      return (
        (Array.isArray(touched) ? touched.every(Boolean) : touched) && (
          <Flex alignItems="center" gap={0.5} ml={1}>
            {getSignalFieldTitle(field)}
            <Tooltip label={<>{message}</>} shouldWrapChildren>
              <HiOutlineQuestionMarkCircle />
            </Tooltip>
          </Flex>
        )
      )
    })
    .filter(Boolean)

  if (errors.length <= 0) return null

  return (
    <Alert status="error" fontSize="xs" py={2}>
      <AlertIcon />
      Invalid filters:{" "}
      {errors.map((error, idx) => (
        <>
          {idx > 0 && ","}
          {error}
        </>
      ))}
    </Alert>
  )
}
