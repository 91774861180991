import { useQuery } from "@tanstack/react-query"
import { useMemo } from "react"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"
import invariant from "~/utils/invariant"
import { cacheKeys } from "../cacheKeys"
import { GetListDetailsById } from "../db/userLists"
import { useProduct } from "./useProduct"
import { getProductSignalSearchParams } from "./useProductFilters"

export const useListDetailsQuery = () => {
  const [searchParams] = useSafeSearchParams()
  const product = useProduct()
  let listId = searchParams.get("listId")

  const filters = useMemo(
    () => getProductSignalSearchParams(product, searchParams),
    [product, searchParams]
  )

  if ("listId" in filters && filters.listId) {
    listId = filters.listId
  }

  const listQuery = useQuery<GetListDetailsById>(
    cacheKeys.userListDetails(listId ?? ""),
    async () => {
      if (!listId) return null
      const req = await fetch(`/api/lists/${product}/${listId}/details`)

      invariant(req.ok)

      return await req.json()
    },
    {
      enabled: !!listId,
    }
  )

  return listQuery
}
