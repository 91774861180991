import { Values } from "~/utils/values"

export const STRATEGIC_SIGNAL_TYPE = {
  TALENT: "Talent",
  COMPANY: "Company",
} as const

export type StrategicSignalType = Values<typeof STRATEGIC_SIGNAL_TYPE>

export const allStrategicSignalType = Object.values<StrategicSignalType>(
  STRATEGIC_SIGNAL_TYPE
)
