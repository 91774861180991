import { Flex, Spinner, Text } from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import numeral from "numeral"
import pluralize from "pluralize"
import { getProductEntityName } from "~/utils/getProductName"
import { useSignalsQueryCount } from "~/utils/hooks/useSignalsQuery"

type Props = {
  product: SpecterProducts
  results: number
  isLoading?: boolean
}

export const SignalResultCount = ({
  product,
  results,
  isLoading,
}: Props): JSX.Element => {
  const entityName = pluralize(getProductEntityName(product), results)
    .toLowerCase()
    .replaceAll(/ipos/g, "IPOs")

  if (isLoading) {
    return (
      <Flex alignItems="center" gap={1} whiteSpace="nowrap">
        <Text color="gray.500" fontSize="xs">
          Showing
        </Text>
        <Spinner size="xs" />
        <Text color="gray.500" fontSize="xs">
          {entityName}
        </Text>
      </Flex>
    )
  }

  return (
    <Text color="gray.500" fontSize="xs" whiteSpace="nowrap">
      Showing{" "}
      <Text as="span" fontWeight="semibold">
        {results > 1_000_000
          ? `${Math.floor(results / 1_000_000)}M+`
          : numeral(results).format("0,0")}
      </Text>{" "}
      {entityName}
    </Text>
  )
}

export const ResultCount = ({
  product,
  countQuery,
}: {
  product: SpecterProducts
  countQuery: ReturnType<typeof useSignalsQueryCount>
}): JSX.Element => {
  const { data, isLoading } = countQuery

  return (
    <SignalResultCount
      product={product}
      results={data ? data.results : 0}
      isLoading={isLoading}
    />
  )
}
