import {
  Box,
  BoxProps,
  CSSObject,
  Flex,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"

import {
  CellClassParams,
  ColDefField,
  SelectionChangedEvent,
} from "ag-grid-community"
import { AgGridReact, AgGridReactProps } from "ag-grid-react"
import {
  memo,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"

import { FeedLoadingLogo } from "~/components/Logo/loading"
import {
  ExtraSortableFields,
  RenderText,
} from "~/components/Table/cellRenderers"
import {
  ColumnDef,
  COLUMNS_WITHOUT_ACTION,
  TABLE_CONFIG_DEFAULT_TABLE_VIEW,
  TableColumnData,
  TableViewConfigs,
} from "~/components/Table/columnDefs"
import { clipPathFrame } from "~/utils/clipPathFrame"
import { useLocalStorageRef } from "~/utils/hooks/useLocalStorage"
import { useProduct } from "~/utils/hooks/useProduct"
import { isOneOf } from "~/utils/isOneOf"
import { moveInArray } from "~/utils/moveInArray"
import { oneOf } from "~/utils/oneOf"
import { isNullish } from "~/utils/values"
import { SearchesAndListsProducts } from "../SignalCard"
import { calculateColumnDefs } from "./calculateColumnDefs"
import TableHeader, {
  SignalSort,
  signalSortFields,
  useSortValue,
} from "./TableHeader"

const TABLE_CONFIG_CLEANUP_VERSION_TOKEN = "0.4.0"

export type TableConfigs =
  | SpecterProducts
  | `saved-searches.${SearchesAndListsProducts}`
  | `user-lists.${SearchesAndListsProducts}`

export const MemoizedTable = memo(function MemoizedTableComponent<
  Config extends TableConfigs
>({
  config,
  rowData,
  // tableView,
  onChange,
  agGridRef,
  updateSelectedRows,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
  isLoading,
  styleOverrides,
  agGridProps = {},
  noSelection,
  suppressLastInfoRow,
}: {
  config: Config
  rowData: any[] | undefined
  // tableView?: number
  onChange?: () => void
  agGridRef?: RefObject<AgGridReact>
  updateSelectedRows?: (rows: any[]) => void
  hasNextPage?: boolean
  isFetchingNextPage?: boolean
  fetchNextPage?: () => void
  isLoading?: boolean
  styleOverrides?: CSSObject
  agGridProps?: Omit<AgGridReactProps, "columnDefs">
  noSelection?: boolean
  suppressLastInfoRow?: boolean
}) {
  const columnDefsRef = useRef<
    | {
        columnDefs: ColumnDef<Config>[]
        currentTableView: TableViewConfigs
      }
    | undefined
  >()

  // This ref is used to store the data when the table is loading the next page, or new sort/filters are applied
  const loadedData = useRef<{
    rowData: any[] | undefined
    config: Config
  }>()
  const lastLoadedPage = useRef<number | null>()
  const [loaded, setLoaded] = useState(false)

  const [getLsTableView, setLsTableView, clearLsTableView] =
    useLocalStorageRef<TableViewConfigs>(`${config}-current-table-view`)

  const [getTableConfigCleanupVersion, setTableConfigCleanupVersion] =
    useLocalStorageRef<string>(`${config}-table-config-cleanup-version`)

  // TODO: This will be used when we have the Table Views feature
  // const tableViewsQuery = useQuery(cacheKeys.tableViews(product), async () => {
  //   const req = await fetch("/api/user/table-views")

  //   invariant(req.ok)

  //   const data: TableView[] = await req.json()

  //   // If there's a table view, ignore previous settings
  //   if (data.some((tv) => tv.id === tableView)) {
  //     clearLsTableView()
  //   }

  //   return data
  // })

  const getCurrentTableView = useCallback(() => {
    return (
      getLsTableView() ??
      // TODO: Table Views feature
      // tableViewsQuery.data?.find((tv) => tv.id === tableView) ??
      TABLE_CONFIG_DEFAULT_TABLE_VIEW[config]
    )
  }, [getLsTableView, config])

  useEffect(() => {
    if (typeof window !== "undefined") {
      const newVersionCleanup =
        getTableConfigCleanupVersion() !== TABLE_CONFIG_CLEANUP_VERSION_TOKEN
      if (newVersionCleanup) {
        clearLsTableView()
        setTableConfigCleanupVersion(TABLE_CONFIG_CLEANUP_VERSION_TOKEN)
      }

      columnDefsRef.current = calculateColumnDefs(
        config,
        getCurrentTableView(),
        agGridRef?.current?.api
      )
    }
    setLoaded(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config])

  if (rowData) {
    loadedData.current = { rowData, config }
  }
  // If the product changes, clear the loaded data (or it'd fail on columnDefs)
  else if (config !== loadedData.current?.config) {
    loadedData.current = undefined
  }

  const newVersionCleanup =
    getTableConfigCleanupVersion() !== TABLE_CONFIG_CLEANUP_VERSION_TOKEN

  if (!columnDefsRef.current && !newVersionCleanup) {
    columnDefsRef.current = calculateColumnDefs(
      config,
      getCurrentTableView(),
      agGridRef?.current?.api
    )
  }

  return (
    <CustomAgTable
      // This is crucial to force the table to re-render when the product changes
      key={config}
      config={config}
      isLoaded={loaded}
      isLoading={isLoading ?? false}
      agGridRef={agGridRef}
      // * AgGrid Props *
      opacity={loaded && isLoading ? 0.5 : 1}
      suppressMenuHide
      styleOverrides={styleOverrides}
      // @ts-ignore -- columnDefs will accept string or JSX.Element
      columnDefs={columnDefsRef.current?.columnDefs}
      onColumnMoved={({ finished, column, toIndex }) => {
        if (
          !finished ||
          isNullish(toIndex) ||
          isNullish(column) ||
          isNullish(columnDefsRef.current)
        )
          return

        onChange?.()

        const newOrder = moveInArray(
          columnDefsRef.current!.currentTableView.columns,
          columnDefsRef.current!.currentTableView.columns.indexOf(
            column.getColId()
          ),
          toIndex
        )

        columnDefsRef.current = calculateColumnDefs(
          config,
          {
            ...columnDefsRef.current!.currentTableView,
            columns: newOrder,
          },
          agGridRef?.current?.api
        )

        setLsTableView(columnDefsRef.current.currentTableView)
      }}
      onColumnPinned={({ column, pinned }) => {
        if (!column) return

        onChange?.()

        const newColumnsPinned = {
          ...columnDefsRef.current!.currentTableView.pinned,
        }

        if (!isOneOf(pinned, ["left", "right"]))
          delete newColumnsPinned[column.getColId()]
        else newColumnsPinned[column.getColId()] = pinned

        columnDefsRef.current = calculateColumnDefs(
          config,
          {
            ...columnDefsRef.current!.currentTableView,
            pinned: newColumnsPinned,
          },
          agGridRef?.current?.api
        )

        setLsTableView(columnDefsRef.current.currentTableView)
      }}
      onColumnResized={({ column, finished }) => {
        if (!column || !finished) return

        onChange?.()

        const newColumnsWidth = {
          ...columnDefsRef.current!.currentTableView.widths,
        }

        newColumnsWidth[column.getColId()] = column.getActualWidth()

        columnDefsRef.current = calculateColumnDefs(
          config,
          {
            ...columnDefsRef.current!.currentTableView,
            widths: newColumnsWidth,
          },
          agGridRef?.current?.api
        )

        setLsTableView(columnDefsRef.current.currentTableView)
      }}
      onColumnVisible={({ column, columns, visible, source, api }) => {
        const columnsToChange = columns ?? (column ? [column] : [])

        // ! This event was being triggered unexpectedly when sorting a column after having made another column visible. So we add them back
        // ! This causes a flicker when sorting a column, if there were recently made visible columns
        if (source === "gridOptionsChanged") {
          api.setColumnsVisible(
            columnsToChange.map((c) => c.getColId()),
            true
          )

          return
        }

        const newColumns = [...columnDefsRef.current!.currentTableView.columns]

        if (!visible) {
          for (const col of columnsToChange) {
            newColumns.splice(newColumns.indexOf(col.getColId()), 1)
          }
        } else {
          for (const col of columnsToChange) {
            newColumns.push(col.getColId())
          }
        }

        const newColumnsUnique = [...new Set(newColumns)]

        columnDefsRef.current = calculateColumnDefs(
          config,
          {
            ...columnDefsRef.current!.currentTableView,
            columns: newColumnsUnique,
          },
          agGridRef?.current?.api
        )

        setLsTableView(columnDefsRef.current.currentTableView)
      }}
      maxConcurrentDatasourceRequests={1}
      // @ts-ignore
      getRowId={({ data }) => data.id}
      rowData={
        loadedData.current?.rowData
          ? [
              ...loadedData.current.rowData,
              ...(hasNextPage || !suppressLastInfoRow
                ? [
                    {
                      id: hasNextPage
                        ? "last-row-loading"
                        : "last-row-end-of-data",
                    },
                  ]
                : []),
            ].filter(Boolean)
          : // ? What is this doing?
            // .map((data) => ({ ...data, selection: false }))
            undefined
      }
      onRowDataUpdated={(params) => {
        // Auto-resize columns on first render
        if (loaded) return

        const allColumnIds = params.api
          .getColumns()
          ?.map((column) => {
            if (column.getColDef().suppressAutoSize) return ""
            return column.getId()
          })
          .filter(Boolean)

        if (!allColumnIds) return

        params.api.autoSizeColumns(allColumnIds)

        setLoaded(true)
      }}
      suppressColumnVirtualisation
      isFullWidthRow={(params) => {
        // Used isFullWidthRow to fetch next page when it gets to the end of the table
        // isFullWidthRow is called when a row is rendered within AgGrid virtualization
        if (
          params.rowNode.lastChild &&
          !isFetchingNextPage &&
          hasNextPage &&
          // ⬇️ This line assures the next page is only fetched once
          lastLoadedPage.current !== params.rowNode.rowIndex
        ) {
          fetchNextPage?.()
          lastLoadedPage.current = params.rowNode.rowIndex
        }

        // @ts-ignore
        if (params.rowNode.data?.id.toString().startsWith("last-row")) {
          return true
        }

        return false
      }}
      isRowSelectable={(rowNode) =>
        !noSelection && !rowNode.data?.id.toString().startsWith("last-row")
      }
      fullWidthCellRenderer={
        hasNextPage
          ? TableLoadingRow
          : !suppressLastInfoRow
          ? TableEndOfDataRow(loadedData.current?.rowData?.length ?? 0, config)
          : undefined
      }
      {...(updateSelectedRows && {
        rowSelection: "multiple",
        rowMultiSelectWithClick: true,
        onSelectionChanged: (event: SelectionChangedEvent) => {
          updateSelectedRows(event.api.getSelectedRows())
        },
      })}
      {...agGridProps}
    />
  )
})

const TableLoadingRow = () => {
  return (
    <Flex height="100%" alignItems="center" justifyContent="center">
      <Spinner size="xs" ml="0.75rem" color="gray.400" />
    </Flex>
  )
}

const TableEndOfDataRow = (total: number, config: TableConfigs) =>
  function EndOfDataRow() {
    const isSingular = total === 1

    const suffix =
      config === SpecterProducts.company
        ? `compan${isSingular ? "y" : "ies"}`
        : config.startsWith("saved-searches.")
        ? `search${isSingular ? "" : "es"}`
        : config.startsWith("user-lists.")
        ? `list${isSingular ? "" : "s"}`
        : `signal${isSingular ? "" : "s"}`

    return (
      <Flex height="100%" alignItems="center" justifyContent="center">
        <Text color="gray.400">
          Total: {total} {suffix}
        </Text>
      </Flex>
    )
  }

const TableLoadingOverlay = () => {
  return (
    <Flex height="100%" alignItems="center" justifyContent="center">
      <Flex bgColor="white" padding={4} boxShadow="md" alignItems="center">
        <Text>Loading</Text>
        <Spinner size="xs" ml="0.75rem" color="gray.400" />
      </Flex>
    </Flex>
  )
}

type SearchesTableColumnFields = ColDefField<
  TableColumnData<`saved-searches.${SearchesAndListsProducts}`>
>[]

type UserListsTableColumnFields = ColDefField<
  TableColumnData<`user-lists.${SearchesAndListsProducts}`>
>[]

export const getTableSortableFields = <Config extends TableConfigs>(
  config?: Config,
  product?: SearchesAndListsProducts
) => {
  if (!config) return []

  return (
    isOneOf(config, Object.values(SpecterProducts))
      ? signalSortFields(config)
      : config.startsWith("saved-searches.")
      ? ([
          "name",
          "createdAt",
          "modifiedAt",
          "countNewSignals",
          ...(product === SpecterProducts.company
            ? ([
                "countNewFundingHighlights",
                "countNewGrowthHighlights",
              ] as SearchesTableColumnFields)
            : []),
          "countAllSignals",
        ] satisfies SearchesTableColumnFields)
      : config.startsWith("user-lists.")
      ? ([
          "name",
          "createdAt",
          "modifiedAt",
          "_count",
          // TODO: add logic in backend to enable these sorts
          // ...(product === SpecterProducts.company
          //   ? ([
          //       "_countNewFundingSignals",
          //       "_countNewGrowthSignals",
          //     ] as UserListsTableColumnFields)
          //   : []),
        ] satisfies UserListsTableColumnFields)
      : []
  ) as Config extends SpecterProducts
    ? (keyof SignalSort<Config>)[]
    : Config extends `saved-searches.${SearchesAndListsProducts}`
    ? SearchesTableColumnFields
    : Config extends `user-lists.${SearchesAndListsProducts}`
    ? UserListsTableColumnFields
    : never //TODO: Define any - sortable fields for searches and lists
}

export const CustomAgTable = <Config extends TableConfigs>({
  agGridRef,
  config,
  containerProps = {},
  isLoaded = true,
  isLoading,
  columnDefs,
  styleOverrides,
  ...agGridProps
}: Omit<AgGridReactProps, "columnDefs"> & {
  agGridRef?: RefObject<AgGridReact>
  config: Config
  columnDefs: ColumnDef<Config>[]
  containerProps?: BoxProps
  isLoaded?: boolean
  isLoading?: boolean
  styleOverrides?: CSSObject
}) => {
  const tableTheme = useColorModeValue(
    "ag-theme-quartz",
    "ag-theme-alpine-dark"
  )

  const sort = useSortValue(config)
  const product = useProduct()

  const isColumnSorted = useCallback(
    (field: string): boolean => {
      const sortableField = oneOf(
        field,
        getTableSortableFields(config, product as SearchesAndListsProducts)
      )

      return !!sortableField && !!sort?.[sortableField as keyof typeof sort]
    },
    [config, sort, product]
  )

  return (
    <Box
      visibility={isLoaded ? "visible" : "hidden"}
      position="relative"
      display="grid" // This was the only way I could make the table show up...
      className={tableTheme}
      flexGrow={1}
      borderTopWidth={1}
      borderColor="gray.100"
      sx={{
        "--column-border-color": "#f5f5f5",
        "--ag-cell-horizontal-border":
          "var(--ag-row-border-width) var(--ag-row-border-style) var(--column-border-color)" /* Add left and right borders to each cell and use row border's properties  */,
        "--ag-header-column-separator-display": "block",
        "--ag-header-column-separator-color": "var(--column-border-color)",
        "--cell-sorted-color": "#fbfbff",
        "--cell-sorted-color-hover": "#dadaf4",
        "--ag-active-color": "#7e7dda",
        "--ag-row-hover-color": "#eaeaf6",
        "--ag-header-background-color": "#fff",
        "--ag-border-color": "#eee",
        "--ag-header-column-resize-handle-color": "transparent",
        "--ag-line-height": "38px",
        ".ag-header-cell-sorted": {
          backgroundColor: "var(--cell-sorted-color)",
          borderBottomWidth: "2px",
          borderBottomStyle: "solid",
          borderBottomColor: "var(--ag-active-color)",
        },
        ".ag-header-cell-sorted:hover": {
          backgroundColor: "var(--cell-sorted-color-hover) !important",
        },
        ".ag-root-wrapper": {
          border: "none",
        },
        ".ag-cell:hover::before": {
          content: "''",
          position: "absolute",
          inset: "0",
          bgColor: "gray.300",
          clipPath: clipPathFrame({ borderRadius: 5 }),
        },
        // ".ag-header-cell:not(.ag-column-first)": {
        //   padding: "0",
        // },
        ".ag-header-cell": {
          padding: "0",
        },
        ".ag-header-cell:hover": {
          // borderColor: "blue.400",
          backgroundColor: "var(--cell-sorted-color) !important",
        },
        ".ag-input-field-input": {
          width: "54px",
          height: "42px",
          position: "absolute",
          transform: "translate(-19px, -13px)",
        },
        ".ag-cell-focus:not(.ag-cell-range-selected):focus-within": {
          borderColor: "transparent",
        },
        ".ag-row-selected > .ag-cell": {
          backgroundColor: "var(--ag-selected-row-background-color) !important",
        },
        ".ag-row-hover > .ag-cell": {
          backgroundColor: `${
            agGridProps.rowSelection ? "var(--ag-row-hover-color)" : "white"
          } !important`,
        },
        ...styleOverrides,
      }}
    >
      <Box rounded="xl" overflow="hidden" {...containerProps}>
        <AgGridReact
          ref={agGridRef}
          // @ts-ignore -- columnDefs will accept string or JSX.Element
          columnDefs={columnDefs?.map((columnDef) => ({
            ...columnDef,
            cellStyle: (
              cellClassParam: CellClassParams<TableColumnData<Config>>
            ) => {
              if (!cellClassParam.data) return {}

              const { column } = cellClassParam
              const extraSortableFields: ExtraSortableFields<Config> =
                // @ts-ignore
                column.getColDef()?.["extraSortableFields"] ?? []

              const columnSortedStyles = (isColumnSorted(column.getColId()) ||
                extraSortableFields.some(({ field }) =>
                  isColumnSorted(field)
                )) && {
                backgroundColor: "var(--cell-sorted-color)",
              }

              const loadingStyles = isLoaded && isLoading && { opacity: 0.5 }

              const interactivityStyles = {
                cursor: COLUMNS_WITHOUT_ACTION.includes(columnDef.field)
                  ? undefined
                  : "pointer",
              }

              return {
                ...(typeof columnDef.cellStyle === "function"
                  ? columnDef.cellStyle(cellClassParam)
                  : typeof columnDef.cellStyle === "object"
                  ? columnDef.cellStyle
                  : {}),
                ...columnSortedStyles,
                ...loadingStyles,
                ...interactivityStyles,
              }
            },
          }))}
          defaultColDef={{
            resizable: true,
            cellRenderer: RenderText,
          }}
          components={{
            agColumnHeader: (props: any) => {
              return <TableHeader {...props} config={config} />
            },
          }}
          {...agGridProps}
        />
        <Box
          visibility={!isLoaded || isLoading ? "visible" : "hidden"}
          position="absolute"
          inset="0"
          pointerEvents="none"
          transform="translateY(-28px)"
        >
          {!isLoaded && <FeedLoadingLogo />}
          {isLoaded && isLoading && <TableLoadingOverlay />}
        </Box>
      </Box>
    </Box>
  )
}
