import { UserAction } from "~/utils/hooks/useIntegrations"
import { make_timeago } from "~/utils/timeago"
import { SpecterProducts } from "@prisma/client"
import { UseToastOptions } from "@chakra-ui/react"
import { AffinityErrorCode } from "~/utils/affinity/requests"

const timeago = make_timeago()

function getProductName(product: SpecterProducts) {
  switch (product) {
    case SpecterProducts.talent:
      return "Talent Signal"
    case SpecterProducts.stratintel:
      return "Strategic Signal"
    case SpecterProducts.company:
      return "Company"
  }
}

/**
 * Build the modal body message
 * @param product
 * @param alreadyAdded
 * @param currentUserId
 */
export function buildBodyMessage(
  product: SpecterProducts,
  alreadyAdded: UserAction | undefined,
  currentUserId?: string | undefined
) {
  const productName = getProductName(product)

  const defaultMessage = `Would you like to add this ${productName} to your list in Affinity?`

  if (!alreadyAdded || !currentUserId) {
    return defaultMessage
  }

  const humanCreatedAt: string | undefined = alreadyAdded.createdAt
    ? timeago.format(new Date(alreadyAdded.createdAt))
    : undefined

  if (alreadyAdded?.userId == currentUserId) {
    if (humanCreatedAt) {
      return `You've already added this ${productName} to Affinity, ${humanCreatedAt}. Would you like to add it again?`
    } else {
      return `You've already added this ${productName} to Affinity. Would you like to add it again?`
    }
  }

  if (alreadyAdded?.userId != currentUserId) {
    if (humanCreatedAt) {
      return `${alreadyAdded.firstName} ${alreadyAdded.lastName} already added this ${productName} to Affinity, ${humanCreatedAt}. Would you like to add it again?`
    } else {
      return `${alreadyAdded.firstName} ${alreadyAdded.lastName} already added this ${productName} to Affinity. Would you like to add it again?`
    }
  }

  return defaultMessage
}

export function affinityResponseToastOption(
  requestBody: Record<string, any>
): UseToastOptions {
  if ("errorCode" in requestBody) {
    switch (requestBody.errorCode as AffinityErrorCode) {
      case AffinityErrorCode.RATE_LIMIT_EXCEEDED:
        return {
          status: "warning",
          title: "Affinity Rate Limit",
          description:
            "You’ve hit your Affinity API Rate Limit, you can't push any more" +
            "data into Affinity until they've removed that limit. " +
            "Please contact Affinity Support.",
        }
      case AffinityErrorCode.OPPORTUNITY_NAME_NOT_UNIQUE:
        return {
          status: "warning",
          title: "There is already an Affinity Opportunities with that name",
          description:
            "Opportunities must have a unique name, either delete the signal or " +
            "change the name in Affinity and re-import",
        }
      case AffinityErrorCode.UNAUTHORISED:
        return {
          status: "warning",
          title: "Affinity API key not valid",
          description:
            "Your Affinity API key isn't valid, it could be that you've entered it " +
            "incorrectly, or a colleague has changed it. Please go to the " +
            "integrations section on the left-hand menu and reconfigure your " +
            "Affinity connection.",
        }
      case AffinityErrorCode.UNKNOWN:
        return {
          status: "error",
          title: "Failed to add signal to Affinity",
        }
    }
  }

  return {
    status: "error",
    title: "Failed to add signal to Affinity",
  }
}
