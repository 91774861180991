import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Flex,
  Tag,
} from "@chakra-ui/react"
import { getSignalFieldTitle } from "~/utils/signal"

interface Props {
  deprecatedFields: (string | number)[]
  query: Record<string, any>
}

export const DeprecatedFieldsAlert = ({
  deprecatedFields,
  query,
}: Props): JSX.Element => {
  return (
    <Alert status="warning" variant="top-accent">
      <AlertIcon />
      <Box>
        <AlertTitle>This search is outdated</AlertTitle>
        <AlertDescription>
          Some of the filters you're using are deprecated. Please update the
          following filters:{" "}
        </AlertDescription>
        <Flex flexWrap="wrap" gap={2}>
          {deprecatedFields.map((field) => {
            const value = query[field]
            return (
              <Tag key={field}>
                {getSignalFieldTitle(field as string)} (
                {JSON.stringify(value, null, 2)})
              </Tag>
            )
          })}
        </Flex>
      </Box>
    </Alert>
  )
}
