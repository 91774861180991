import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  ModalFooter,
  Spinner,
  Text,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { useMutation, useQuery } from "@tanstack/react-query"
import pluralize from "pluralize"
import { getProductEntityName } from "~/utils/getProductName"
import {
  getIntegrationName,
  integrationSupportsLists,
} from "~/utils/integrationApp"
import { ReturnButton } from "../ReturnButton"
import { ProductItem } from "../SignalCard"
import { FieldMapping } from "./FieldMapping"
import { HiCheck, HiOutlineSwitchHorizontal } from "react-icons/hi"
import { AddToList } from "./AddToList"
import { z } from "zod"
import { useIntegrationService } from "~/utils/hooks/useIntegrationService"
import { DataMatchingType } from "./DataMatching"

type Props<P extends SpecterProducts> = {
  product: P
  integrationKey: string
  signals: ProductItem<P>[]
  dataSource: string
  nextStep: () => void
  configLocked: boolean
  defaultFieldMapping?: any
  dataMatching: DataMatchingType
}

export const RecordMatching = <P extends SpecterProducts>({
  product,
  integrationKey,
  signals,
  dataSource,
  nextStep,
  configLocked,
  defaultFieldMapping,
  dataMatching,
}: Props<P>) => {
  const integrationService = useIntegrationService()

  const matchingQuery = useQuery({
    queryKey: [
      "recordMatching",
      dataSource,
      product,
      JSON.stringify(signals.map((s) => s.id)),
    ],
    staleTime: 0,
    queryFn: async () => {
      const res = await integrationService.post(`/match-records`, {
        signals,
        dataSource,
        product,
        integrationKey,
        dataMatching,
      })

      const result = z
        .array(
          z.object({
            signal: z.any(),
            match: z.any().nullable(),
          })
        )
        .parse(res)

      return result
    },
    enabled: dataSource !== null,
  })

  const unmatchedSignals = matchingQuery.data?.filter((r) => !r?.match) ?? []
  const matchedSignals = matchingQuery.data?.filter((r) => r?.match) ?? []
  const totalMatched = matchedSignals.length

  const goToCreateRecords = () => {
    createRecordsMutation.mutate()
    nextStep()
  }

  const createRecordsMutation = useMutation(
    async () => {
      const res = await integrationService.post(`/create-records`, {
        signals: unmatchedSignals,
        dataSource,
        product,
        integrationKey,
      })

      const result = z
        .array(
          z.object({
            signal: z.any(),
            match: z.any().nullable(),
          })
        )
        .parse(res)

      return result
    },
    {
      retry: 0,
    }
  )

  const createdMatchedSignals =
    createRecordsMutation.data?.filter((r) => r?.match) ?? []
  const allMatchedSignals = [...matchedSignals, ...createdMatchedSignals]

  return (
    <>
      <Flex h="full" flexDir="column">
        <Center flex={1}>
          <Box textAlign="center">
            {matchingQuery.isFetching && (
              <>
                <Spinner />
                <Text fontWeight="medium">
                  We're matching the selected{" "}
                  {pluralize(
                    getProductEntityName(product).toLowerCase(),
                    signals.length
                  )}{" "}
                  with your {getIntegrationName(integrationKey)} records.
                </Text>
                <Text fontWeight="medium">This may take a moment...</Text>
              </>
            )}

            {!matchingQuery.isFetching && totalMatched < signals.length && (
              <Box maxW={320}>
                <Text mb={2} fontWeight="medium">
                  {pluralize(
                    getProductEntityName(product).toLowerCase(),
                    unmatchedSignals.length,
                    true
                  )}{" "}
                  couldn't be matched to existing{" "}
                  {pluralize("Record", unmatchedSignals.length)}, do you want to
                  create as new {pluralize("Record", unmatchedSignals.length)}?
                </Text>
                <Text fontSize="sm" color="red.500" mb={4}>
                  Warning: this may create duplicate Records in certain
                  circumstances.
                </Text>
                <Flex gap={2} flexDir="column">
                  <Button
                    size="sm"
                    variant="solid"
                    onClick={nextStep}
                    isDisabled={signals.length <= 1}
                  >
                    No, Continue without{" "}
                    {pluralize("Record", unmatchedSignals.length)}
                  </Button>
                  <Button
                    size="sm"
                    variant="solid"
                    colorScheme="brand"
                    onClick={goToCreateRecords}
                  >
                    Yes, Create new{" "}
                    {pluralize("Record", unmatchedSignals.length)}
                  </Button>
                </Flex>
              </Box>
            )}

            {!matchingQuery.isFetching && totalMatched >= signals.length && (
              <Box maxW={320}>
                <Icon
                  as={HiOutlineSwitchHorizontal}
                  color="brand.500"
                  fontSize="4xl"
                />
                <Text fontWeight="medium">
                  All {pluralize(getProductEntityName(product).toLowerCase())}{" "}
                  match with existing Records in{" "}
                  {getIntegrationName(integrationKey)}
                </Text>
                <Text fontSize="sm" color="gray.600">
                  Continue to Enrich or Add{" "}
                  {pluralize("this", allMatchedSignals.length)}{" "}
                  {pluralize("Record", allMatchedSignals.length)} to a List.
                </Text>
              </Box>
            )}
          </Box>
        </Center>
        <ModalFooter>
          <ReturnButton
            isDisabled={
              matchingQuery.isFetching || totalMatched < signals.length
            }
            onClick={nextStep}
          >
            Next
          </ReturnButton>
        </ModalFooter>
      </Flex>

      {(createRecordsMutation.isLoading || createRecordsMutation.isSuccess) && (
        <Flex h="full" flexDir="column">
          <Center flex={1}>
            <Box textAlign="center">
              {createRecordsMutation.isLoading && (
                <>
                  <Spinner />
                  <Text fontWeight="medium">
                    We're creating {unmatchedSignals.length}{" "}
                    {pluralize("Record", unmatchedSignals.length)} in your{" "}
                    {getIntegrationName(integrationKey)}...
                  </Text>
                </>
              )}

              {createRecordsMutation.isSuccess && (
                <>
                  <Icon as={HiCheck} color="brand.500" fontSize="4xl" />
                  <Text fontWeight="medium">
                    {createdMatchedSignals.length}{" "}
                    {pluralize("Record", createdMatchedSignals.length)} created
                  </Text>
                  <Text fontSize="sm" color="gray.600">
                    Continue to Enrich or Add{" "}
                    {pluralize("this", allMatchedSignals.length)}{" "}
                    {pluralize("Record", allMatchedSignals.length)} to a List.
                  </Text>
                </>
              )}
            </Box>
          </Center>
          {createRecordsMutation.isSuccess && (
            <ModalFooter>
              <ReturnButton onClick={nextStep}>Next</ReturnButton>
            </ModalFooter>
          )}
        </Flex>
      )}

      {allMatchedSignals.length > 0 && (
        <>
          <FieldMapping
            product={product}
            dataSource={dataSource}
            integrationKey={integrationKey}
            // @ts-ignore-next-line
            matchedSignals={allMatchedSignals}
            nextStep={nextStep}
            configLocked={configLocked}
            defaultFieldMapping={defaultFieldMapping}
          />

          {integrationSupportsLists(integrationKey) && (
            <AddToList
              integrationKey={integrationKey}
              // @ts-ignore-next-line
              matchedSignals={allMatchedSignals}
              dataSource={dataSource}
              nextStep={nextStep}
            />
          )}
        </>
      )}
    </>
  )
}
