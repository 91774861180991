import { z } from "zod"
import { COMPANY_OPERATORS } from "~/consts/signals"
import {
  CompanyOperatorsFilter,
  companyOperatorsFilterSchema,
  dateBeforeAfterSchema_DEPRECATED,
  dateRangeSchema,
  dateRollingRangeSchema,
  GrowthFilter,
  growthFilterSchema,
  numericFilterRevampedSchema,
  numericFilterSchema,
  setFilterSchema,
  textFilterSchema,
  CurrentTenureFilter,
  currentTenureFilterSchema,
  LanguagesFilter,
  languagesFilterSchema,
} from "~/utils/signal"

const arrayFilterSchema = z.array(z.string()).nonempty()
type ArrayFilter = z.infer<typeof arrayFilterSchema>

type TextFilter = z.infer<typeof textFilterSchema>
const numericFilterInstance = numericFilterSchema({ allowNegatives: true })
const numericFilter = numericFilterInstance.options[0]
const numericRangeFilter = numericFilterInstance.options[1]
type NumericFilter = z.infer<typeof numericFilter>

const numericFilterRevampedInstance = numericFilterRevampedSchema()
type NumericFilterRevamped = z.infer<typeof numericFilterRevampedInstance>

type NumericRangeFilter = z.infer<typeof numericRangeFilter>
type DateBeforeAfterFilter_DEPRECATED = z.infer<
  typeof dateBeforeAfterSchema_DEPRECATED
>
type DateRangeFilter = z.infer<typeof dateRangeSchema>
type DateRollingRangeFilter = z.infer<typeof dateRollingRangeSchema>
const setFilterInstance = setFilterSchema()
type SetFilter = z.infer<typeof setFilterInstance>

export function isNumericFilter(value: any): value is NumericFilter {
  return numericFilter.safeParse(value).success
  // return (
  //   Array.isArray(value) &&
  //   value.length === 2 &&
  //   typeof value[0] === "string" &&
  //   Object.values(NUMERIC_OPERATORS).includes(value[0] as NumericFilter[0]) &&
  //   typeof value[1] === "number"
  // )
}

export function isNumericFilterRevamped(
  value: any
): value is NumericFilterRevamped {
  return numericFilterRevampedInstance.safeParse(value).success
}

export function isNumericRangeFilter(value: any): value is NumericRangeFilter {
  return numericRangeFilter.safeParse(value).success
  // return (
  //   Array.isArray(value) &&
  //   value.length === 2 &&
  //   typeof value[0] === "number" &&
  //   typeof value[1] === "number"
  // )
}

export function isTextFilter(value: any): value is TextFilter {
  return textFilterSchema.safeParse(value).success
  // return (
  //   Array.isArray(value) &&
  //   value.length === 2 &&
  //   typeof value[0] === "string" &&
  //   TEXT_OPERATORS.includes(value[0] as TextFilter[0]) &&
  //   Array.isArray(value[1])
  // )
}

export function isDateFilter(
  value: any
): value is DateBeforeAfterFilter_DEPRECATED {
  return dateBeforeAfterSchema_DEPRECATED.safeParse(value).success
  // return (
  //   // Date filter
  //   Array.isArray(value) &&
  //   value.length === 2 &&
  //   typeof value[0] === "string" &&
  //   isOneOf(value[0], DATE_OPERATORS) &&
  //   value[1].length >= 6 &&
  //   isDateValid(JSON.stringify(value[1]))
  // )
}

export function isDateRangeFilter(value: any): value is DateRangeFilter {
  return dateRangeSchema.safeParse(value).success
  // return (
  //   // Date Rangefilter
  //   Array.isArray(value) &&
  //   value.length === 2 &&
  //   ((value[0].length >= 6 && isDateValid(JSON.stringify(value[0]))) ||
  //     (value[0].length >= 6 && isDateValid(JSON.stringify(value[1]))))
  // )
}

export function isDateRollingRangeFilter(
  value: any
): value is DateRollingRangeFilter {
  return dateRollingRangeSchema.safeParse(value).success
  // return (
  //   Array.isArray(value) &&
  //   value.length === 2 &&
  //   typeof value[0] === "number" &&
  //   isOneOf(value[1], TIME_FRAME_OPTIONS)
  // )
}

export function isSetFilter(value: any): value is SetFilter {
  return setFilterInstance.safeParse(value).success
  // return (
  //   Array.isArray(value) &&
  //   value.length === 2 &&
  //   AND_OR_OPERATORS.includes(value[0]) &&
  //   Array.isArray(value[1])
  // )
}

export function isArrayFilter(value: any): value is ArrayFilter {
  return arrayFilterSchema.safeParse(value).success
  // return Array.isArray(value) && value.length > 0
}

export function isSingleValueFilter(value: any): value is string {
  return typeof value === "string" && value.length > 0
}

export function isBooleanFilter(value: any): value is boolean {
  return typeof value === "boolean"
}

const growthFilterInstance = growthFilterSchema()

export function isGrowthFilter(value: any): value is GrowthFilter {
  return growthFilterInstance.safeParse(value).success
}

const companyOperatorsFilterInstance = companyOperatorsFilterSchema({
  innerSchema: z.any(),
  operators: COMPANY_OPERATORS,
})

export function isCompanyOperatorsFilter(
  value: any
): value is CompanyOperatorsFilter {
  return companyOperatorsFilterInstance.safeParse(value).success
}

export function isLanguagesFilter(value: any): value is LanguagesFilter {
  return languagesFilterSchema.safeParse(value).success
}

export function isCurrentTenureFilter(
  value: any
): value is CurrentTenureFilter {
  return currentTenureFilterSchema.safeParse(value).success
}
