import { SpecterProducts } from "@prisma/client"
import * as R from "ramda"
import { getProductSignalSearchParams } from "./hooks/useProductFilters"

export const countAppliedSignalFilters = (
  product: SpecterProducts,
  searchParams: URLSearchParams
) => {
  const allFilters = getProductSignalSearchParams(product, searchParams)
  const appliedFilters = R.reject(R.isEmpty, allFilters)

  return Object.keys(appliedFilters).length
}
