import {
  Menu,
  MenuButton,
  IconButton,
  useDisclosure,
  Tooltip,
  MenuList,
  Image,
  Button,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { TbCube } from "react-icons/tb"
import { IntegrationMeta } from "~/utils/hooks/useIntegrationMeta"
import { useIntegrations } from "~/utils/hooks/useIntegrations"
import { getIntegrationLogo, getIntegrationName } from "~/utils/integrationApp"
import { IntegrationPushSignalModal } from "../IntegrationPushSignalModal"
import { MenuItem } from "../MenuItem"

interface IntegrationActionProps {
  integration: IntegrationMeta
  singleAction: boolean
  MenuListction?: boolean
  signalIds: string[]
  product: SpecterProducts
  showLabel?: boolean
  addedToIntegration?: boolean
}

const IntegrationAction = ({
  integration,
  singleAction = false,
  signalIds,
  product,
  showLabel = false,
  addedToIntegration,
}: IntegrationActionProps) => {
  const modalState = useDisclosure()
  const integrationName = getIntegrationName(integration.integrationKey)

  return (
    <>
      {singleAction && !showLabel && (
        <Tooltip label={`Add to ${integrationName}`} openDelay={300}>
          <IconButton
            variant="outline"
            colorScheme={addedToIntegration ? "green" : "gray"}
            bgColor={addedToIntegration ? "green.50" : undefined}
            aria-label={`Add to ${integrationName}`}
            icon={
              <Image
                w={4}
                src={getIntegrationLogo(integration.integrationKey)}
              />
            }
            onClick={() => {
              modalState.onOpen()
            }}
          />
        </Tooltip>
      )}

      {singleAction && showLabel && (
        <Button
          leftIcon={
            <Image w={4} src={getIntegrationLogo(integration.integrationKey)} />
          }
          onClick={() => {
            modalState.onOpen()
          }}
          colorScheme={addedToIntegration ? "green" : "gray"}
          bgColor={addedToIntegration ? "green.50" : undefined}
        >
          Add to {integrationName}
        </Button>
      )}

      {!singleAction && (
        <MenuItem
          py={1}
          onClick={() => {
            modalState.onOpen()
          }}
        >
          Add to {integrationName}
        </MenuItem>
      )}

      <IntegrationPushSignalModal
        product={product}
        isOpen={modalState.isOpen}
        onClose={modalState.onClose}
        integrationKey={integration.integrationKey}
        signalIds={signalIds}
      />
    </>
  )
}

interface Props {
  signalIds: string[]
  product: SpecterProducts
  showLabel?: boolean
  addedToIntegration?: boolean
}

export const IntegrationSignalButton = ({
  signalIds,
  product,
  showLabel = false,
  addedToIntegration,
}: Props) => {
  const integrations = useIntegrations()
  const connectedIntegrations = integrations.data?.integrations.integrationsMeta

  if ((connectedIntegrations?.length ?? 0) <= 0) {
    return null
  }

  if (connectedIntegrations && connectedIntegrations.length === 1) {
    return (
      <IntegrationAction
        singleAction
        integration={connectedIntegrations[0]}
        signalIds={signalIds}
        product={product}
        showLabel={showLabel}
        addedToIntegration={addedToIntegration}
      />
    )
  }

  return (
    <>
      <Menu>
        {showLabel && (
          <MenuButton
            as={Button}
            aria-label="Add to Integration"
            leftIcon={<TbCube />}
            variant="outline"
            bgColor={addedToIntegration ? "green.50" : undefined}
            colorScheme={addedToIntegration ? "green" : "gray"}
          >
            Add to CRM
          </MenuButton>
        )}

        {!showLabel && (
          <MenuButton
            as={IconButton}
            aria-label="Add to Integration"
            icon={<TbCube />}
            variant="outline"
            bgColor={addedToIntegration ? "green.50" : undefined}
            colorScheme={addedToIntegration ? "green" : "gray"}
          />
        )}

        <MenuList zIndex={999} p={2}>
          {connectedIntegrations?.map((item) => {
            return (
              <IntegrationAction
                key={item.integrationKey}
                integration={item}
                singleAction={false}
                signalIds={signalIds}
                product={product}
                addedToIntegration={addedToIntegration}
              />
            )
          })}
        </MenuList>
      </Menu>
    </>
  )
}
