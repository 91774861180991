import { Box, ButtonGroup, Flex, IconButton, Text } from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { FaXmark } from "react-icons/fa6"
import { isOneOf } from "~/utils/isOneOf"
import { ActionMenuSelection, ProductDetailItem } from "../SignalCard"
import { TableColumnData } from "./columnDefs"
import { TableConfigs } from "./MemoizedTable"

export const SelectionMenu = <Config extends TableConfigs>({
  config,
  selectedRows,
  setSelectedRows,
  onClearSelection,
}: {
  config: Config
  selectedRows: TableColumnData<Config>[]
  setSelectedRows: (rows: TableColumnData<Config>[]) => void
  onClearSelection?: () => void
}) => {
  return (
    <Box
      h="0px"
      overflow="visible"
      position="sticky"
      top="85vh"
      zIndex={10}
      pointerEvents="none"
      display={selectedRows.length === 0 ? "none" : "block"}
    >
      <Flex justifyContent="center">
        <Box
          p={1}
          bgColor="brand.50"
          shadow="lg"
          rounded="full"
          borderWidth={1}
          borderColor="brand.200"
          pointerEvents="all"
        >
          <Flex
            gap={2}
            rounded="full"
            alignItems="center"
            justifySelf="center"
            borderWidth={1}
            borderColor="brand.100"
            px={4}
            py={2}
          >
            <IconButton
              onClick={() => {
                setSelectedRows([])
                onClearSelection?.()
              }}
              aria-label="Deselect all"
              icon={<FaXmark />}
              size="xs"
              colorScheme="red"
              variant="solid"
              h={5}
              w={5}
              minW={5}
            />
            <Text
              color="gray.500"
              fontSize="xs"
              whiteSpace="nowrap"
              fontWeight="semibold"
            >
              {selectedRows.length} selected:
            </Text>
            <ButtonGroup
              spacing={1}
              size="xs"
              variant="outline"
              alignItems="center"
              justifyContent="flex-end"
            >
              <SelectionMenuActionButtons
                config={config}
                selectedRows={selectedRows}
              />
            </ButtonGroup>
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}

const SelectionMenuActionButtons = <Config extends TableConfigs>({
  config,
  selectedRows,
}: {
  config: Config
  selectedRows: TableColumnData<Config>[]
}) => {
  if (isOneOf(config, Object.values(SpecterProducts))) {
    return (
      <ActionMenuSelection
        product={config}
        signals={selectedRows as ProductDetailItem<typeof config>[]}
      />
    )
  }

  return null
}
