import {
  Modal,
  ModalCloseButton,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  Box,
  Flex,
  Text,
  Icon,
  Kbd,
  Spacer,
} from "@chakra-ui/react"
import { createContext, useContext, useEffect, useState } from "react"
import { HiArrowDown, HiArrowUp } from "react-icons/hi"

interface Props {
  title: string
  isOpen: boolean
  onClose: () => void
  children?: React.ReactNode
  actionButton?: () => React.ReactNode
  activeIndex: number
  setActiveIndex: (index: number) => void
}

const Context = createContext<{
  activeIndex: number
  setActiveIndex: (index: number) => void
  disableHoverSelection: boolean
  setDisableHoverSelection: (disable: boolean) => void
}>({
  activeIndex: 0,
  setActiveIndex: () => {},
  disableHoverSelection: false,
  setDisableHoverSelection: () => {},
})

export const useContextModal = () => useContext(Context)

const ModalInner = ({
  children,
  title,
  actionButton,
  activeIndex,
  setActiveIndex,
}: Props) => {
  const [disableHoverSelection, setDisableHoverSelection] = useState(false)

  return (
    <Context.Provider
      value={{
        activeIndex,
        setActiveIndex,
        disableHoverSelection,
        setDisableHoverSelection,
      }}
    >
      <Box
        rounded="xl"
        borderWidth={1}
        borderColor="gray.100"
        overflow="hidden"
        pos="relative"
      >
        <ModalCloseButton />
        <ModalHeader>{title}</ModalHeader>
        {children}

        <Flex
          bgColor="gray.50"
          borderTopWidth={1}
          borderColor="gray.100"
          py={2}
          px={5}
          gap={1}
          alignItems="center"
        >
          <Kbd>
            <Icon as={HiArrowUp} size="xs" />
          </Kbd>
          <Kbd>
            <Icon as={HiArrowDown} size="xs" />
          </Kbd>
          <Text fontSize="xx-small" fontWeight="medium" color="gray.600" ml={1}>
            Navigate
          </Text>
          <Spacer />
          {actionButton && actionButton()}
        </Flex>
      </Box>
    </Context.Provider>
  )
}

export const ContextModal = ({
  isOpen,
  onClose,
  children,
  title,
  actionButton,
  activeIndex,
  setActiveIndex,
}: Props) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowUp") {
        event.preventDefault()
        setActiveIndex(Math.max(0, activeIndex - 1))
      } else if (event.key === "ArrowDown") {
        event.preventDefault()
        setActiveIndex(activeIndex + 1)
      }
    }

    if (isOpen) {
      global.window.addEventListener("keydown", handleKeyDown)
    }

    return () => {
      global.window.removeEventListener("keydown", handleKeyDown)
    }
  }, [isOpen, activeIndex, setActiveIndex])

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW={800} p={1} rounded="2xl">
        {isOpen && (
          <ModalInner
            isOpen={isOpen}
            onClose={onClose}
            title={title}
            actionButton={actionButton}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          >
            {children}
          </ModalInner>
        )}
      </ModalContent>
    </Modal>
  )
}

interface ItemProps {
  children: React.ReactNode
  index: number
}

export const ContextModalItem = ({ children, index }: ItemProps) => {
  const {
    activeIndex,
    setActiveIndex,
    disableHoverSelection,
    setDisableHoverSelection,
  } = useContext(Context)

  return (
    <Flex
      bgColor={activeIndex === index ? "gray.100" : "transparent"}
      onMouseOver={() => {
        if (!disableHoverSelection) {
          setActiveIndex(index)
        }
      }}
      onClick={() => {
        setDisableHoverSelection(true)
        setActiveIndex(index)
      }}
      cursor="pointer"
      p={2}
      gap={2}
      rounded="lg"
      fontSize="xs"
      alignItems="center"
    >
      {children}
    </Flex>
  )
}
