import { getSignalFieldTitle } from "./signal"

export const getSignalFields = (
  data: any,
  parentLabel = "",
  parentValue = ""
): { label: string; value: string }[] => {
  if (typeof data !== "object" || data === null) return []

  return Object.entries(data).flatMap(([field, value]) => {
    const currentLabel = parentLabel
      ? `${parentLabel} > ${String(getSignalFieldTitle(field))}`
      : String(getSignalFieldTitle(field))
    const currentValue = parentValue ? `${parentValue}.${field}` : field

    if (Array.isArray(value) && value.length > 0) {
      // Look at first array item and recursively get its fields
      const arrayFields = getSignalFields(
        value[0],
        `${currentLabel} > Item #1`,
        `${currentValue}.[0]`
      )
      return [
        ...arrayFields,
        {
          label: `${currentLabel} > Aggregated`,
          value: `${currentValue}`,
        },
        ...(arrayFields.length === 0
          ? [
              {
                label: `${currentLabel} > Item #1`,
                value: `${currentValue}.[0]`,
              },
            ]
          : []),
      ]
    }

    if (typeof value === "object" && value !== null) {
      return getSignalFields(value, currentLabel, currentValue)
    }

    return [
      {
        label: currentLabel,
        value: currentValue,
      },
    ]
  })
}
