import { Flex, Heading, HStack, Text, VStack } from "@chakra-ui/react"
import { HiLibrary } from "react-icons/hi"
import { GetTalentSignalByID } from "~/utils/db/getTalentSignalByID"

import { BoxIcon } from "~/components/BoxIcon"
import { Card } from "~/components/Card"
import { isNullish } from "~/utils/values"
import { trim } from "ramda"

interface Props {
  talentSignal: GetTalentSignalByID
}

function Education({
  education,
  educationDate,
  pastEducation,
  pastEducationDate,
}: {
  education: string | null | undefined
  educationDate: string | null | undefined
  pastEducation: string | null | undefined
  pastEducationDate: string | null | undefined
}) {
  const [title, body] = education?.split(";").map(trim) ?? []
  const [pastTitle, pastBody] = pastEducation?.split(";").map(trim) ?? []

  return (
    <>
      <Heading size="sm" as="h2">
        Education
      </Heading>

      {isNullish(education) && isNullish(pastEducation) && (
        <Text fontSize="xs" color="gray.400">
          No education data found
        </Text>
      )}

      {!isNullish(education) && (
        <HStack alignItems="start">
          <BoxIcon icon={HiLibrary} />
          <Flex alignSelf="center" direction="column" py={1} gap={1}>
            {title && (
              <Text fontSize="sm" lineHeight={1} fontWeight="bold">
                {title}
              </Text>
            )}
            {body && (
              <Text fontSize="sm" lineHeight={1}>
                {body}
              </Text>
            )}
            {educationDate && (
              <Text fontSize="sm" color="gray.500" lineHeight={1}>
                {educationDate}
              </Text>
            )}
          </Flex>
        </HStack>
      )}

      {!isNullish(pastEducation) && (
        <HStack alignItems="start">
          <BoxIcon icon={HiLibrary} />
          <Flex alignSelf="center" direction="column" py={1} gap={1}>
            {pastTitle && (
              <Text fontSize="sm" lineHeight={1} fontWeight="bold">
                {pastTitle}
              </Text>
            )}
            {pastBody && (
              <Text fontSize="sm" lineHeight={1}>
                {pastBody}
              </Text>
            )}
            {pastEducationDate && (
              <Text fontSize="sm" color="gray.500" lineHeight={1}>
                {pastEducationDate}
              </Text>
            )}
          </Flex>
        </HStack>
      )}
    </>
  )
}

export const TalentSignalEducation = ({
  talentSignal,
}: Props): JSX.Element | null => {
  return (
    <VStack alignItems="stretch">
      <Education
        education={talentSignal.Education}
        educationDate={talentSignal.EducationDate}
        pastEducation={talentSignal.PastEducation}
        pastEducationDate={talentSignal.PastEducationDate}
      />
    </VStack>
  )
}

export const TalentSignalEducationSmallCard = ({
  talentSignal,
}: Props): JSX.Element | null => {
  if (isNullish(talentSignal.Education)) return null

  const [title, body] = talentSignal.Education?.split(";") ?? []

  return (
    <Card p={4} display="flex" flexDirection="column" gap={2} maxW={300}>
      <Text as="h2" color="gray.800" fontSize="sm" fontWeight="bold">
        Education
      </Text>

      <HStack alignItems="stretch">
        <BoxIcon icon={HiLibrary} size={8} />
        <Flex
          direction="column"
          justifyContent="center"
          fontSize="xs"
          overflow="hidden"
        >
          <Text
            fontWeight="semibold"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {title}
          </Text>

          <Text overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
            {body}
          </Text>
        </Flex>
      </HStack>
    </Card>
  )
}
