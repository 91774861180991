interface Params {
  location?: string | null
  region?: string | null
}

export const getSignalLocation = ({ location, region }: Params) => {
  if (!location) {
    return region
  }

  return [...new Set(location.split(", "))].join(", ")
}
