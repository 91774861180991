import numeral from "numeral"
import { Text } from "@chakra-ui/react"
import { getGrowthOperator } from "./utils"
import { getGrowthColor } from "~/utils/getGrowthColor"

/**
 * Format a percentage value into number that is suffixed with
 * '%' and is green is positive and red if negative.
 */
export function PercentageDelta({ value }: { value: number }) {
  return (
    <Text
      fontWeight="extrabold"
      fontSize="4xl"
      lineHeight={1}
      color={getGrowthColor(value)}
    >
      {getPercentageDelta(value)}
    </Text>
  )
}

export function getPercentageDelta(value: number, format?: string) {
  const formatting = format ?? "0.[00]"

  return `${getGrowthOperator(value)}${
    numeral(value).format(formatting) ?? "-"
  }%`
}
