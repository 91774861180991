import { z } from "zod"
import { FEED_PAGE_SIZE } from "~/consts/signals"

export type SortDirection = "asc" | "desc"
export interface SortingParameter {
  column: string
  direction: SortDirection
}

export const Pagination = z
  .object({
    page: z.number().default(0),
    limit: z.number().default(FEED_PAGE_SIZE),
  })
  .default({})

export type PaginationType = z.infer<typeof Pagination>
