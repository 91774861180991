import { SpecterProducts } from "@prisma/client"
import { AsyncInputProperty } from "~/routes/__protected/api/input-options.$product.$property"
import { prisma } from "~/utils/prisma.server"

export enum AutoCompleteCompanyProperties {
  name = "CompanyName",
  technologies = "technologies",
  awards = "awards",
  highlights = "highlights",
  investors = "investors",
}

export enum AutoCompleteFundingRoundsProperties {
  investors = "investors",
  company = "company",
}

const VALID_AUTOCOMPLETE_COLUMNS: Record<SpecterProducts, Object> = {
  [SpecterProducts.company]: AutoCompleteCompanyProperties,
  [SpecterProducts.talent]: {},
  [SpecterProducts.stratintel]: {},
  [SpecterProducts.investors]: {},
  [SpecterProducts.fundingRounds]: AutoCompleteFundingRoundsProperties,
  [SpecterProducts.acquisition]: {},
  [SpecterProducts.ipo]: {},
  [SpecterProducts.people]: {},
}

export function validProductProperty(
  product: SpecterProducts,
  property: string
): boolean {
  return (
    Object.values(VALID_AUTOCOMPLETE_COLUMNS[product])?.includes(property) ??
    false
  )
}

// async function _getCompanyNameOptions(searchTerm: string | null) {
//   const property = "CompanyName"

//   let results: { id: string; [property]: string | null }[]

//   if (!searchTerm) {
//     results = await prisma.companySignal.findMany({
//       select: {
//         id: true,
//         [property]: true,
//       },
//       take: 5,
//     })
//   } else {
//     results = await prisma.companySignal.findMany({
//       select: {
//         id: true,
//         [property]: true,
//       },
//       where: {
//         [property]: {
//           contains: searchTerm,
//           mode: "insensitive",
//         },
//       },
//       take: 5,
//     })
//   }

//   return results.map((r) => ({ id: r.id, value: r[property] }))
// }

async function getLocationOptions(searchTerm: string | null) {
  if (!searchTerm) {
    return []
  }

  const ilike = `${searchTerm}%`

  const locations = await prisma.$queryRaw<
    { key: string; geoid_start: number; geoid_end: number }[]
  >`
    SELECT distinct on (weight) key, geoid_start, geoid_end, weight 
    FROM geo_completions
    WHERE key ILIKE ${ilike}
    ORDER BY weight desc, LENGTH(key) DESC
    LIMIT 10;
  `

  // The bounds on the ranges have to come back as text otherwise they get rounded.
  const regionMappings = await prisma.$queryRaw<
    { region_name: string; range: string[][] }[]
  >`
      SELECT region_name, json_agg(ARRAY[lower(range)::text, upper(range)::text]) as range
      FROM (
               SELECT rm.region_name as region_name, unnest(rm.geo_range) AS range
               FROM region_mappings rm
               WHERE rm.region_name ILIKE ${ilike}
           )
      GROUP BY region_name;
  `

  // * We're not using the original regions anymore, we use the region mappings
  // * (which includes the old regions plus some handpicked ones).
  const groups = ["Countries", "States", "Cities"] as const

  return [
    {
      label: "Regions",
      options: regionMappings.map(({ region_name, range }) => ({
        value: `[${range
          .map((geo) => `"${geo.map((val) => BigInt(val)).join(":")}"`)
          .join(",")}]`,
        label: region_name,
      })),
    },
    ...groups.map((group, index) => {
      const options = locations
        .filter(({ key }) => key.split(", ").length === index + 2) // The +2 is intentional, and it's to match the splitted key length with the group
        .map(({ key, geoid_start, geoid_end }) => ({
          value: `${geoid_start}:${geoid_end}`,
          label: [...new Set(key.split(", "))].join(", "),
        }))

      return {
        label: group,
        options,
      }
    }),
  ]
}

async function getActiveTechnologyOptions(searchTerm: string | null) {
  let results: { id: number; technology: string }[]

  if (searchTerm) {
    const ilike = `${searchTerm}%`

    results = await prisma.$queryRaw<{ id: number; technology: string }[]>`
          SELECT id::int, technology
          FROM active_technologies
          WHERE technology ILIKE ${ilike};
      `
  } else {
    results = await prisma.$queryRaw<{ id: number; technology: string }[]>`
          SELECT id::int, technology
          FROM active_technologies;
      `
  }

  return results.map((r) => ({
    value: r.technology,
    label: r.technology,
  }))
}

async function getAwardsOptions(searchTerm: string | null) {
  type AwardsResult = { award: string; logo_url: string }[]
  let results: AwardsResult

  if (searchTerm) {
    const ilike = `${searchTerm}%`

    results = await prisma.$queryRaw<AwardsResult>`
          SELECT "Award Name" as award, "URL for Logo" as logo_url
          FROM company_awards_orgs
          WHERE "Award Name" ILIKE ${ilike}
          ORDER BY award ASC;
      `
  } else {
    results = await prisma.$queryRaw<AwardsResult>`
          SELECT "Award Name" as award, "URL for Logo" as logo_url
          FROM company_awards_orgs
          ORDER BY award ASC;
      `
  }

  return results.map((r) => ({
    value: r.award,
    label: r.award,
    logoUrl: r.logo_url,
  }))
}

async function getInvestorsOptions(searchTerm: string | null) {
  type InvestorsResult = {
    name: string
    id: string
    domain: string
    logo_url: string
  }[]
  let results: InvestorsResult = []

  if (searchTerm) {
    const ilike = `${searchTerm}%`

    results = await prisma.$queryRaw<InvestorsResult>`
      SELECT name, domain, specter_investor_id as id, logo_url
      FROM investor i 
      WHERE name ILIKE ${ilike}
      ORDER BY name ASC
      LIMIT 10
    `
  } else {
    results = await prisma.$queryRaw<InvestorsResult>`
      SELECT name, domain, specter_investor_id as id, logo_url
      FROM investor i
      ORDER BY rank ASC
      LIMIT 7
    `
  }

  return results.map((r) => ({
    value: r.id,
    label: r.name,
    domain: r.domain,
    logoUrl: r.logo_url,
  }))
}

async function getStockExchangeSymbolOptions(searchTerm: string | null) {
  if (!searchTerm) {
    return []
  }

  const options = await prisma.iPO.findMany({
    where: {
      stockExchangeSymbol: {
        startsWith: searchTerm,
      },
    },
    select: {
      stockExchangeSymbol: true,
    },
    distinct: ["stockExchangeSymbol"],
  })

  return options.map(({ stockExchangeSymbol }) => {
    return {
      label: stockExchangeSymbol ?? "",
      value: stockExchangeSymbol ?? "",
    }
  })
}

async function _getHighlightsOptions(searchTerm: string | null) {
  type HighlightsResult = { highlight: string }[]
  let results: HighlightsResult

  if (searchTerm) {
    results = await prisma.$queryRaw<HighlightsResult>`
          SELECT DISTINCT highlight
          FROM company_highlights, unnest(highlights) AS highlight
          WHERE highlight ILIKE ${searchTerm}
          ORDER BY highlight ASC;
      `
  } else {
    results = await prisma.$queryRaw<HighlightsResult>`
          SELECT DISTINCT highlight
          FROM company_highlights, unnest(highlights) AS highlight
          ORDER BY highlight ASC;
      `
  }

  return results.map(({ highlight }) => ({
    value: highlight,
    label: highlight,
  }))
}

async function getCompanyNameOptions(searchTerm: string | null) {
  type CompanyNameResult = {
    id: string
    name: string
    logo_url: string
    domain: string
  }[]
  let results: CompanyNameResult
  const LIMIT = 25

  if (searchTerm) {
    results = await prisma.$queryRaw<CompanyNameResult>`
          SELECT "Specter - ID" as id, "Company Name" as name, company_logo_url("Domain") as logo_url, "Domain" as domain
          FROM company_data cd INNER JOIN company_data_search cds ON cds.company_id = cd."Specter - ID" 
          WHERE cds.name_search @@ websearch_to_tsquery('simple', ${searchTerm}) 
          ORDER BY "Employee Count" DESC NULLS LAST
          LIMIT ${LIMIT};
      `
  } else {
    results = await prisma.$queryRaw<CompanyNameResult>`
          SELECT "Specter - ID" as id, "Company Name" as name, company_logo_url("Domain") as logo_url, "Domain" as domain
          FROM company_data cd 
          ORDER BY "Rank" ASC NULLS LAST
          LIMIT 7;
      `
  }

  return results.map((r) => ({
    value: r.id,
    label: r.name,
    domain: r.domain,
    logoUrl: r.logo_url,
  }))
}

async function getSignalSourceOptions(searchTerm: string | null) {
  type SourceResult = {
    specter_name: string
    external_specter_id: string | null
    domain: string | null
    logo_url: string | null
  }[]
  let results: SourceResult

  if (searchTerm) {
    const ilike = `%${searchTerm}%`

    results = await prisma.$queryRaw<SourceResult>`
          SELECT DISTINCT specter_name, external_specter_id , 
            COALESCE(company_logo_url(c.domain), i.logo_url, company_logo_url(i.domain)) as logo_url, 
            COALESCE(c.domain, i.domain) as domain
          FROM stratintelligence_source ss 
          LEFT JOIN companies c ON ss.entity_type = 'company' AND ss.entity_id = c.organization_id
          LEFT JOIN investor i ON ss.entity_type = 'investor' AND ss.entity_id = i.investor_id 
          WHERE specter_name ILIKE ${ilike}
          ORDER BY specter_name ASC; 
      `
  } else {
    // Show the top investors present in stratintel sources
    results = await prisma.$queryRaw<SourceResult>`
          SELECT DISTINCT specter_name, external_specter_id, 
            COALESCE(company_logo_url(c.domain), i.logo_url, company_logo_url(i.domain)) as logo_url, 
            COALESCE(c.domain, i.domain) as domain, i.rank 
          FROM stratintelligence_source ss
          LEFT JOIN companies c ON ss.entity_type = 'company' AND ss.entity_id = c.organization_id
          LEFT JOIN investor i ON ss.entity_type = 'investor' AND ss.entity_id = i.investor_id 
          ORDER BY i.rank asc nulls LAST
          LIMIT 7;
      `
  }

  return results.map(
    ({ specter_name, external_specter_id, domain, logo_url }) => ({
      // Some sources don't have an external id. Use the name as the option id in that case
      value: external_specter_id ?? specter_name,
      label: specter_name,
      domain,
      logoUrl: logo_url,
    })
  )
}

async function getLanguagesOptions(searchTerm: string | null) {
  type LanguagesResult = { id: number; name: string }[]
  let results: LanguagesResult

  if (searchTerm) {
    const ilike = `${searchTerm}%`

    results = await prisma.$queryRaw<LanguagesResult>`
          SELECT language_id as id, name
          FROM poc_people_db."language"
          WHERE name ILIKE ${ilike}
          ORDER BY language_id ASC;
      `
  } else {
    results = await prisma.$queryRaw<LanguagesResult>`
          SELECT language_id as id, name
          FROM poc_people_db."language"
          ORDER BY language_id ASC
          LIMIT 7;
      `
  }

  return results.map((r) => ({
    value: String(r.id),
    label: r.name,
  }))
}

async function getEducationOptions(searchTerm: string | null) {
  type EducationResult = { id: number; name: string; logo_url: string }[]
  let results: EducationResult

  if (searchTerm) {
    const ilike = `${searchTerm}%`

    results = await prisma.$queryRaw<EducationResult>`
          SELECT university_id as id, name, logo_url
          FROM poc_people_db.university
          WHERE name ILIKE ${ilike}
          ORDER BY
            CASE WHEN top_university THEN 1 ELSE 2 END,
            name ASC;
      `
  } else {
    results = await prisma.$queryRaw<EducationResult>`
          SELECT university_id as id, name, logo_url
          FROM poc_people_db.university
          ORDER BY
            CASE WHEN top_university THEN 1 ELSE 2 END,
            name ASC;
      `
  }

  return results.map((r) => ({
    value: String(r.id),
    label: r.name,
    logoUrl: r.logo_url,
  }))
}

async function getCertificationsOptions() {
  type CertificationResult = { certification: string }[]
  const results = await prisma.$queryRaw<CertificationResult>`
      SELECT unnest(enum_range(NULL::certifications_mapped)) as certification
      `

  return results.map(({ certification }) => ({
    value: certification,
    label: certification,
  }))
}

export type GetOptionsReturn = SimpleSelectOptions | GroupedSelectOptions

type SimpleSelectOptions = {
  value: number | string
  label: string
  [key: string]: any
}[]

type GroupedSelectOptions = {
  label: string
  options: SimpleSelectOptions
}[]

export const ASYNC_INPUT_FIELD_PROPS: {
  [key in AsyncInputProperty]: {
    getOptions: (searchTerm: string | null) => Promise<GetOptionsReturn>
    noOptionsMessage?: string
    hasOptionsByDefault?: boolean
    acceptFreeText?: boolean
    placeholderText?: string
  }
} = {
  active_technologies: {
    getOptions: getActiveTechnologyOptions,
    noOptionsMessage: "Type to see options — Java, Python, Oracle ...",
  },
  awards: {
    getOptions: getAwardsOptions,
    hasOptionsByDefault: true,
  },
  certifications_mapped: {
    getOptions: getCertificationsOptions,
    hasOptionsByDefault: true,
  },
  source: {
    getOptions: getSignalSourceOptions,
    placeholderText: "E.g. Sequoia Capital, Index Ventures...",
    hasOptionsByDefault: true,
    acceptFreeText: true,
  },
  company: {
    getOptions: getCompanyNameOptions,
    placeholderText: "E.g. Google, Microsoft...",
    hasOptionsByDefault: true,
  },
  investors: {
    getOptions: getInvestorsOptions,
    placeholderText: "E.g. Sequoia Capital, Sam Altman...",
    hasOptionsByDefault: true,
  },
  stock_exchange_symbol: {
    getOptions: getStockExchangeSymbolOptions,
  },
  location: {
    getOptions: getLocationOptions,
    placeholderText: "E.g. Japan, Boston, European Union...",
  },
  languages: {
    getOptions: getLanguagesOptions,
    placeholderText: "E.g. English, Spanish, French...",
    hasOptionsByDefault: true,
  },
  education: {
    getOptions: getEducationOptions,
    placeholderText: "E.g. Harvard, Stanford, Cambridge...",
  },
}

export async function getInputOptions(
  property: AsyncInputProperty,
  searchTerm: string | null
) {
  const { getOptions } = ASYNC_INPUT_FIELD_PROPS[property]

  return getOptions(searchTerm)
}
