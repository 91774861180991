import {
  Button,
  ButtonGroup,
  Flex,
  Icon,
  Image,
  Link,
  List,
  ListIcon,
  ListItem,
  Tag,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import { format } from "date-fns"
import numeral from "numeral"

import { GrowthStage, SpecterProducts } from "@prisma/client"

import {
  Card,
  CompanyLogo,
  FlexDivider,
  FlexOneLine,
  getStratintelHotness,
  LastFundingStatus,
  NotificationBadge,
  OperatingStatusInfo,
  SignalStatusCompany,
} from "~/components"
import { getPercentageDelta } from "~/components/CompanySignalDetailPage/PercentageDelta"
import {
  ActionMenuIcons,
  ButtonFacebook,
  ButtonG2,
  ButtonGooglePlay,
  ButtonInstagram,
  ButtonITunes,
  ButtonLinkedin,
  ButtonTwitter,
  ButtonWebsite,
  SignalCard,
  SignalCardProps,
} from "~/components/SignalCard"
import { CardBox, GrowthTag } from "~/components/SignalCard/CardBox"
import { CompanyGrowthBadge } from "~/components/SignalCard/GrowthBadge"
import { CompanyRankBadge } from "~/components/TagCompanyRank"
import { TagIndustry } from "~/components/TagIndustry"

import { Link as RemixLink, useLocation } from "@remix-run/react"
import { Fragment, useCallback } from "react"
import { BiAward } from "react-icons/bi"
import { HiFire, HiOutlineCube, HiStar } from "react-icons/hi2"
import {
  G2_LOGO_URL,
  getTrustPilotURL,
  TRUSTPILOT_COLOR,
  TRUSTPILOT_LOGO_URL,
} from "~/consts/data3rdParties"

import {
  COMPANY_HIGHLIGHTS_GROUPS,
  COMPANY_HIGHLIGHTS_PROPS,
} from "~/consts/signals"
import {
  CompanySignalFeedItem,
  CompanySignalMetric,
} from "~/utils/db/queries/company/types"
import { groupBy } from "~/utils/groupBy"
import { useCompanyDeliveryMonth } from "~/utils/hooks/useCompanyDeliveryMonth"
import { isDateValid } from "~/utils/isDateValid"
import { ObjectMap } from "~/utils/ObjectMap"
import { sortCompanyHighlights } from "~/utils/sortHighlights"
import { ensureAbsoluteLink, normalizeItunesAppUrl } from "~/utils/string/url"
import { isNullish, nonZero } from "~/utils/values"
import { RatingDistribution } from "../CompanySignalDetailPage/Tabs/Product"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"
import { CustomerFocusTag } from "../CustomerFocusTag"

function CompanyMetric({
  to,
  metric,
}: {
  to: string
  metric: CompanySignalMetric
}) {
  return (
    <CardBox
      to={to}
      title={metric.name}
      value={numeral(metric.value).format("0,[00]a")}
    >
      <GrowthTag
        formattedValue={getPercentageDelta(
          Math.ceil(metric.growth ?? 0),
          "0,[00]a"
        )}
        period={metric.periodDays}
        sign={metric.growth}
      />
    </CardBox>
  )
}

interface Props {
  signal: CompanySignalFeedItem
  signalCardProps?: Partial<SignalCardProps<"company">>
}

export const CompanySignalCard = ({
  signal,
  signalCardProps,
}: Props): JSX.Element => {
  const location = useLocation()

  const detailURL = `./${signal.id}${location.search}`
  const queryAppendChar = location.search ? "&" : "?"

  const formattedAwards = signal.awards
    ? ObjectMap(
        groupBy(signal.awards, (item) => item.award_year),
        ([year, data]) =>
          [year, groupBy(data, (item) => item.award_name)] as const
      )
    : null

  const NUM_INDUSTRIES_TO_SHOW = 2

  const IndustriesOverflow = useCallback(
    ({ overflowCount }: { overflowCount: number }) => (
      <Button
        as={RemixLink}
        to={detailURL}
        variant="link"
        minW="fit-content"
        whiteSpace="nowrap"
      >
        <Tooltip
          label={signal.industry?.slice(-overflowCount).join(", ")}
          fontSize="xs"
        >
          <Text as="span">+{overflowCount}</Text>
        </Tooltip>
      </Button>
    ),
    [detailURL, signal.industry]
  )

  const { data: currentMonth } = useCompanyDeliveryMonth()
  const [searchParams] = useSafeSearchParams()

  const { hotFactor, hotCount } = getStratintelHotness(
    signal.hot_companies,
    searchParams
  )

  return (
    <SignalCard
      {...signalCardProps}
      product="company"
      signal={signal}
      picture={<CompanyLogo domain={String(signal.domain)} size={16} />}
      title={signal.name}
      description={signal.descriptionShort}
      postDescription={
        formattedAwards?.length ? (
          <FlexOneLine
            gap={1}
            justifyContent="flex-end"
            overflowIndicator={(overflowingCount) => (
              <Button
                size="xs"
                fontSize="x-small"
                variant="link"
                as={RemixLink}
                to={`./${signal.id}${location.search}${queryAppendChar}tab=8`}
                alignSelf="center"
                onClick={(e) => e.stopPropagation()}
              >
                +{overflowingCount}
              </Button>
            )}
          >
            {formattedAwards.map(([year, awards]) => (
              <Fragment key={`award-year-${year}`}>
                {Object.entries(awards).map(([name, award]) => {
                  const logoUrl = award[0].logo_url

                  const newSearch = new URLSearchParams(location.search)
                  newSearch.set("tab", "8")
                  const to = `./${signal.id}?${newSearch.toString()}`

                  return (
                    <Tooltip
                      fontSize="xs"
                      key={`${name}_${year}`}
                      label={
                        <Flex direction="column" key={name}>
                          <Text fontWeight="semibold">
                            {name} - {year}
                          </Text>
                          <List>
                            {award.map(({ award_details }) => (
                              <ListItem key={award_details}>
                                <ListIcon as={BiAward} />
                                {award_details}
                              </ListItem>
                            ))}
                          </List>
                        </Flex>
                      }
                    >
                      <Tag
                        rounded="full"
                        display="inline-flex"
                        gap={1}
                        whiteSpace="nowrap"
                        fontSize="x-small"
                        h="fit-content"
                        as={RemixLink}
                        to={to}
                        onClick={(e) => e.stopPropagation()}
                        _focus={{
                          boxShadow: "none",
                        }}
                        _focusVisible={{
                          outline: "-webkit-focus-ring-color auto 1px",
                          outlineOffset: "1px",
                        }}
                        zIndex={0}
                      >
                        <Image src={logoUrl ?? ""} w="12px" />
                        {name}
                      </Tag>
                    </Tooltip>
                  )
                })}
              </Fragment>
            ))}
          </FlexOneLine>
        ) : undefined
      }
      postTitleOverflow={
        // Only show the overflow indicator if g2_data is not defined. If it is defined, the overflow indicator is shown in the postTitle, manually.
        !signal.g2_data
          ? (overflowingCount) => (
              <IndustriesOverflow overflowCount={overflowingCount} />
            )
          : undefined
      }
      topLeftBadge={
        !!signal.rank && (
          <CompanyRankBadge rank={signal.rank} month={currentMonth} />
        )
      }
      postTitle={[
        signal.b2x && (
          <>
            <CustomerFocusTag b2x={signal.b2x} />
            <FlexDivider orientation="vertical" />
          </>
        ),
        signal.industry &&
          [
            signal.industry
              .slice(0, NUM_INDUSTRIES_TO_SHOW) // * Only show the first N industries
              .map((i: string) => <TagIndustry key={i} label={i} />),
            signal.industry.length > NUM_INDUSTRIES_TO_SHOW && (
              <IndustriesOverflow
                key="industries_overflow"
                overflowCount={signal.industry.length - NUM_INDUSTRIES_TO_SHOW}
              />
            ),
          ].filter(Boolean),
        !!signal.g2_data?.length && (
          <Fragment key="g2_data">
            <FlexDivider orientation="vertical" />
            <Link
              href={
                signal.g2_data.reduce((prev, curr) =>
                  prev && (prev.reviews ?? 0) > (curr.reviews ?? 0)
                    ? prev
                    : curr
                ).g2_link
              }
              target="_blank"
              onClick={(e) => e.stopPropagation()}
              borderRadius="full"
              mr={1}
              zIndex={0}
            >
              <Image src={G2_LOGO_URL} boxSize="16px" />
            </Link>
            {signal.g2_data.map(
              ({
                product_name,
                product_logo,
                rating,
                reviews,
                star_distribution,
              }) => (
                <Tooltip
                  p={4}
                  isDisabled={isNullish(rating)}
                  label={
                    <RatingDistribution
                      starDistribution={star_distribution}
                      reviews={reviews ?? 0}
                    />
                  }
                  key={product_name}
                >
                  <Tag
                    rounded="full"
                    verticalAlign="text-bottom"
                    mr={1}
                    display="inline-flex"
                    alignItems="center"
                    gap={1}
                    as={RemixLink}
                    to={`${detailURL}${queryAppendChar}tab=6`}
                    onClick={(e) => e.stopPropagation()}
                    zIndex={0}
                  >
                    {product_logo ? (
                      <Image src={product_logo} w="12px" />
                    ) : (
                      <Icon as={HiOutlineCube} mb="1px" />
                    )}
                    {product_name}
                    {!isNullish(rating) && (
                      <Flex alignItems="center">
                        <Icon as={HiStar} color="yellow.500" mb="1px" />
                        {rating}
                      </Flex>
                    )}
                  </Tag>
                </Tooltip>
              )
            )}
          </Fragment>
        ),
        !isNullish(signal.trustpilot_data) && (
          <Fragment key="trustpilot_data">
            <FlexDivider orientation="vertical" />
            <Link
              href={getTrustPilotURL(signal.trustpilot_data.domain ?? "")}
              onClick={(e) => e.stopPropagation()}
              borderRadius="full"
              mr={1}
              target="_blank"
              zIndex={0}
            >
              <Image src={TRUSTPILOT_LOGO_URL} boxSize="16px" />
            </Link>
            <Tooltip
              p={4}
              isDisabled={isNullish(signal.trustpilot_data.review_aggregate)}
              label={
                <RatingDistribution
                  starDistribution={signal.trustpilot_data.review_aggregate}
                  reviews={signal.trustpilot_data.review_count ?? 0}
                  barColor={TRUSTPILOT_COLOR}
                />
              }
              key={signal.trustpilot_data.name}
            >
              <Tag
                rounded="full"
                verticalAlign="text-bottom"
                mr={1}
                display="inline-flex"
                alignItems="center"
                gap={1}
                as={RemixLink}
                to={`${detailURL}${queryAppendChar}tab=6`}
                onClick={(e) => e.stopPropagation()}
                zIndex={0}
              >
                {signal.trustpilot_data.logo_url ? (
                  <Image
                    src={signal.trustpilot_data.logo_url}
                    w="12px"
                    onError={(e) =>
                      signal.logoUrl && (e.currentTarget.src = signal.logoUrl)
                    }
                  />
                ) : (
                  <Icon as={HiOutlineCube} mb="1px" />
                )}
                {signal.trustpilot_data.name}
                {!isNullish(signal.trustpilot_data.review_aggregate) && (
                  <Flex alignItems="center">
                    <Icon as={HiStar} color="yellow.500" mb="1px" />
                    {signal.trustpilot_data.reviews_average}
                  </Flex>
                )}
              </Tag>
            </Tooltip>
          </Fragment>
        ),
        hotFactor && !!hotCount && (
          <Fragment key="hot_companies">
            <FlexDivider orientation="vertical" />
            <Tooltip
              label={`Increased Investor Interest\n${hotCount} Signals in the last ${hotFactor} days`}
              whiteSpace="pre"
            >
              <Tag
                display="inline-flex"
                alignItems="center"
                colorScheme="orange"
                fontSize="xs"
                fontWeight="semibold"
                verticalAlign="text-bottom"
                as={RemixLink}
                to={`${detailURL}${queryAppendChar}tab=1`}
                onClick={(e) => e.stopPropagation()}
                zIndex={0}
              >
                <Icon as={HiFire} color="orange.500" />
                {hotCount}
              </Tag>
            </Tooltip>
          </Fragment>
        ),

        /* {signal.hqRegion && (
            <Tooltip label={signal.hqRegion} openDelay={200}>
              <Text
                lineHeight={1}
                ml={3}
                position="relative"
                _before={{
                  content: '""',
                  position: "absolute",
                  top: 0.5,
                  bottom: 0.5,
                  left: -2,
                  transform: "translateX(-50%)",
                  width: "1px",
                  bg: "gray.200",
                }}
              >
                {
                  SIGNAL_REGION_FLAGS[
                    signal.hqRegion as keyof typeof SIGNAL_REGION_FLAGS
                  ]
                }
              </Text>
            </Tooltip>
          )} */
      ].filter(Boolean)}
      actionButtons={
        <>
          <ButtonWebsite websiteUrl={signal.domain} />
          <ButtonLinkedin
            linkedinUrl={ensureAbsoluteLink(signal.linkedinUrl)}
          />
          <ButtonTwitter twitterUrl={ensureAbsoluteLink(signal.twitterUrl)} />
          <ButtonInstagram
            instagramUrl={ensureAbsoluteLink(signal.instagramUrl)}
          />
          <ButtonFacebook
            facebookUrl={ensureAbsoluteLink(signal.facebookUrl)}
          />
          <ButtonITunes itunesUrl={normalizeItunesAppUrl(signal.itunesUrl)} />
          <ButtonGooglePlay
            googlePlayUrl={ensureAbsoluteLink(signal.googlePlayUrl)}
          />
          <ButtonG2 g2Product={signal.g2_data?.[0]} />
        </>
      }
      cardBoxes={
        <>
          {(signal.foundedYear || signal?.growthStage) && (
            <CardBox
              title="Founded"
              value={signal.foundedYear ?? "N/A"}
              to={`${detailURL}${queryAppendChar}tab=0`}
            >
              <CompanyGrowthBadge growthStage={signal.growthStage} />
            </CardBox>
          )}

          <CardBox
            title="Total Funding"
            to={`${detailURL}${queryAppendChar}tab=1`}
            value={
              signal.totalFundingAmount
                ? `$${numeral(signal.totalFundingAmount).format("0,0a")}`
                : "N/A"
            }
          >
            {signal.growthStage === GrowthStage.exit &&
            !isNullish(signal.operatingStatus) ? (
              <OperatingStatusInfo operatingStatus={signal.operatingStatus} />
            ) : (
              <LastFundingStatus
                lastFundingAmount={signal.lastFundingAmount}
                lastFundingDate={signal.lastFundingDate}
                totalFundingAmount={signal.totalFundingAmount}
                highlights={signal.highlights}
              />
            )}
          </CardBox>
          {(signal.lastFundingAmount || signal.lastFundingDate) && (
            <CardBox
              to={`${detailURL}${queryAppendChar}tab=1`}
              title="Last Funding"
              value={
                isDateValid(signal.lastFundingDate)
                  ? format(new Date(signal.lastFundingDate), "yyyy/MM/dd")
                  : "N/A"
              }
            >
              <SignalStatusCompany
                lastFundingDate={signal.lastFundingDate}
                lastFundingAmount={signal.lastFundingAmount}
                lastFundingType={signal.lastFundingType}
              />
            </CardBox>
          )}
          {signal.metrics
            .filter((m) => nonZero(m.value) && m.name !== "Social Followers")
            .map((metric) => {
              return (
                <CompanyMetric
                  key={metric.name}
                  to={`${detailURL}${queryAppendChar}tab=${getFieldTab(
                    metric.name
                  )}`}
                  metric={metric}
                />
              )
            })}
        </>
      }
      postActionMenuButtons={
        <ButtonGroup
          size="xs"
          variant="outline"
          display="grid"
          gridTemplateColumns={"repeat(3, 1fr)"}
          spacing={0}
          gap={1}
        >
          <ActionMenuIcons
            product={SpecterProducts.company}
            signal={signal}
            stretchButtons={false}
            addedToIntegration={signal.addedToIntegration}
          />
        </ButtonGroup>
      }
      postActionMenu={
        !!signal.highlights?.filter((h) => {
          const isNew = signal.newHighlights && signal.newHighlights.includes(h)

          const { hide } =
            COMPANY_HIGHLIGHTS_GROUPS[COMPANY_HIGHLIGHTS_PROPS[h].group]

          return COMPANY_HIGHLIGHTS_PROPS[h] !== undefined && (!hide || isNew)
        })?.length && (
          <Flex direction="column" gap={1}>
            {[
              ...new Set(
                sortCompanyHighlights(
                  signal.highlights.filter(
                    (h) => COMPANY_HIGHLIGHTS_PROPS[h] !== undefined
                  ),
                  signal.newHighlights
                )?.map((highlight) => COMPANY_HIGHLIGHTS_PROPS[highlight].group)
              ),
            ]
              .slice(0, 5)
              .map((group) => {
                const highlight = sortCompanyHighlights(
                  signal.highlights?.filter(
                    (h) => COMPANY_HIGHLIGHTS_PROPS[h] !== undefined
                  ) ?? [],
                  signal.newHighlights
                )?.find((h) => group === COMPANY_HIGHLIGHTS_PROPS[h].group)

                if (!highlight) return null

                const { label } = COMPANY_HIGHLIGHTS_PROPS[highlight]

                const { colorScheme, tab, hide } =
                  COMPANY_HIGHLIGHTS_GROUPS[group]

                const isNew =
                  signal.newHighlights &&
                  signal.newHighlights.includes(highlight)

                if (hide && !isNew) return null

                return (
                  <Card
                    key={highlight}
                    p={1}
                    px={2}
                    position="relative"
                    bgColor={`${colorScheme}.50`}
                    borderColor={`${colorScheme}.100`}
                    fontWeight="bold"
                    to={`${detailURL}${queryAppendChar}tab=${tab}`}
                  >
                    {isNew && (
                      <NotificationBadge
                        value="New"
                        color={`${colorScheme}.400`}
                      />
                    )}

                    <Text
                      as="h2"
                      color={`${colorScheme}.500`}
                      fontSize="x-small"
                      whiteSpace="nowrap"
                      flexGrow={1}
                    >
                      {label.replace(/Growth/g, "")}
                    </Text>
                  </Card>
                )
              })}
          </Flex>
        )
      }
    />
  )
}

const COMPANY_METRICS_FIELD_TAB = {
  "Employee Count": 2,
  "Web Visits": 3,
  "Social Followers": 5,
}
export const getFieldTab = (field: string) => {
  return (
    COMPANY_METRICS_FIELD_TAB[
      field as keyof typeof COMPANY_METRICS_FIELD_TAB
    ] ?? 0
  )
}

export const randomFromStringSeed = (text: string, range = 2, min = 0) => {
  return (
    (text
      .split("")
      .map((c) => c.charCodeAt(0))
      .reduce((a, b) => a + b, 0) %
      range) +
    min
  )
}
