export const getValueAtPathToString = (obj: any, path: string): string => {
  if (!obj || !path) return ""

  const parts = path.split(".")
  let current = obj

  for (const part of parts) {
    if (!current) return ""

    // Handle array index notation [n]
    if (part.startsWith("[") && part.endsWith("]")) {
      const index = parseInt(part.slice(1, -1))
      if (Array.isArray(current) && !isNaN(index)) {
        current = current[index]
      } else {
        return ""
      }
    } else {
      current = current[part]
    }
  }

  // Join array values with comma
  if (Array.isArray(current)) {
    return current.join(",")
  }

  return current?.toString() ?? ""
}
