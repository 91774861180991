import { Link } from "@chakra-ui/react"

import {
  SourceType,
  SpecterProducts,
  StratintelSignalSources,
} from "@prisma/client"
import { Link as RemixLink, useLocation } from "@remix-run/react"
import { Fragment } from "react"
import { StratintelFeedSignal } from "~/utils/db/strategicSignals"
import { mapProductsToRouteName } from "../Filters/schemas"
import { GetStrategicSignalByID } from "~/utils/db/getStrategicSignalByID"

const SIGNAL_SOURCE_TYPE_TO_PRODUCT: Record<SourceType, SpecterProducts> = {
  company: SpecterProducts.company,
  investor: SpecterProducts.investors,
}

export const makeSignalSourceLink = (
  sourceId: string,
  sourceType: SourceType
) =>
  `/signals/${mapProductsToRouteName(
    SIGNAL_SOURCE_TYPE_TO_PRODUCT[sourceType]
  )}/feed/${sourceId}/f`

const SignalSourceLink = ({
  sourceName,
  sourceId,
  sourceType,
}: StratintelSignalSources) => {
  // const location = useLocation()
  // const isEmbed = location.pathname.includes("/embed")

  return sourceId && sourceType ? (
    <Link
      color="brand.500"
      position="relative"
      zIndex={0}
      onClick={(e) => e.stopPropagation()}
      as={RemixLink}
      to={makeSignalSourceLink(sourceId, sourceType)}
      // target={isEmbed ? "_blank" : undefined}
      target="_blank"
    >
      {sourceName}
    </Link>
  ) : (
    <>{sourceName}</>
  )
}

interface Props {
  signal: Omit<
    StratintelFeedSignal | GetStrategicSignalByID,
    "lists" | "personalDetail" | "StratintelMulti"
  >
  nSources?: number
}

const SIGNAL_SOURCE_LIMIT = 6

export const StrategicSignalTitle = ({
  signal,
  nSources = SIGNAL_SOURCE_LIMIT,
}: Props): JSX.Element | null => {
  const location = useLocation()
  const isEmbed = location.pathname.includes("/embed")

  const { StratintelSignalSources, Company, Website, Name } = signal

  const signalSources = StratintelSignalSources ?? []
  const totalSources = signalSources.length ?? 0
  const firstSources = signalSources.slice(0, nSources)
  const lastSource = firstSources[firstSources.length - 1]

  // const suffix = signal.SignalType === "Company" ? "interested in" : "following"
  const suffix = "interested in"
  const join = totalSources <= 1 ? "is" : "are"

  const Sources = (): JSX.Element => {
    let nodes = []

    if (totalSources > 1) {
      nodes.push(
        ...firstSources
          .slice(0, firstSources.length - 1)
          .map((source, idx, arr) => (
            <Fragment key={source?.sourceId ?? idx}>
              <SignalSourceLink key={source.sourceName} {...source} />
              {idx !== arr.length - 1 ? ", " : ""}
            </Fragment>
          ))
      )
      nodes.push(" and ")

      if (totalSources > nSources) {
        nodes.push(`others`)
      } else {
        nodes.push(
          <SignalSourceLink key={lastSource.sourceId} {...lastSource} />
        )
      }
    } else {
      nodes.push(
        <SignalSourceLink
          key={signalSources[0]?.sourceId ?? "-"}
          {...signalSources[0]}
        />
      )
    }

    return <>{nodes}</>
  }

  const NameLink = (): JSX.Element => (
    <Link
      color="brand.500"
      position="relative"
      zIndex={0}
      onClick={(e) => e.stopPropagation()}
      target={isEmbed ? "_blank" : undefined}
      {...(Company && "Company" in Company && Company?.Company?.id
        ? {
            as: RemixLink,
            to: `/signals/company/feed/${Company.Company.id}/f?tab=1`,
          }
        : {
            href: Website ?? undefined,
            target: "_blank",
            to: "",
          })}
    >
      {Name}
    </Link>
  )

  return (
    <>
      <Sources /> {join} {suffix} <NameLink />
    </>
  )
}
