import { getAuth } from "@clerk/remix/ssr.server"
import { SpecterProducts } from "@prisma/client"
import { ActionFunction, LoaderFunction } from "@remix-run/node"
import { Params } from "@remix-run/react"
import { sentenceCase } from "sentence-case"
import { titleCase } from "title-case"
import { OG_DOMAIN } from "~/consts/users"
import { getInputOptions } from "~/utils/db/options"
import {
  SLACK_IMPORT_EVENTS_URL,
  SLACK_SEND_IMPORT_EVENTS,
} from "~/utils/env/server"
import invariant from "~/utils/invariant"
import { isOneOf } from "~/utils/isOneOf"

export const ASYNC_INPUT_FIELDS_TO_PROPERTY: Record<
  string,
  AsyncInputProperty
> = {
  ActiveTechnologies: "active_technologies",
  Awards: "awards",
  certifications_mapped: "certifications_mapped",
  SignalSource: "source",
  Company: "company",
  acquirer: "company",
  acquired: "company",
  PastCompany: "company",
  NewCompany: "company",
  Companies: "company",
  stockExchangeSymbol: "stock_exchange_symbol",
  Investors: "investors",
  CompanyInvestors: "investors",
  Location: "location",
  HQLocation: "location",
  CompanyHQLocation: "location",
  EducationLocation: "location",
  Languages: "languages",
  Education: "education",
} as const

export const ASYNC_INPUT_PROPERTIES = [
  "active_technologies",
  "awards",
  "certifications_mapped",
  "source",
  "company",
  "investors",
  "stock_exchange_symbol",
  "location",
  "languages",
  "education",
] as const

export type AsyncInputProperty = (typeof ASYNC_INPUT_PROPERTIES)[number]

function validate(params: Params<string>): {
  product: SpecterProducts
  property: AsyncInputProperty
} {
  invariant(params.product)
  invariant(params.property)

  invariant(
    isOneOf(params.product, Object.values(SpecterProducts)),
    `Invalid product: ${params.product}`
  )

  invariant(
    ASYNC_INPUT_PROPERTIES.includes(params.property as AsyncInputProperty),
    `Invalid property: ${params.property}`
  )

  return {
    product: params.product,
    property: params.property as AsyncInputProperty,
  }
}

// GET - Load options for an input field
export const loader: LoaderFunction = async ({ params, request }) => {
  const { property } = validate(params)

  const url = new URL(request.url)

  return getInputOptions(property, url.searchParams.get("q"))
}

// POST - Request specter to track a new option
export const action: ActionFunction = async (args) => {
  const { request, params } = args

  const { userId } = await getAuth(args)

  invariant(userId)

  const body = await request.json()

  const permission = await prisma.permissions.findFirst({
    where: {
      userId,
    },
    select: {
      email: true,
    },
  })

  const { product, property } = validate(params)

  const isOGUser = permission?.email?.includes(OG_DOMAIN)

  if (SLACK_SEND_IMPORT_EVENTS && !isOGUser)
    fetch(SLACK_IMPORT_EVENTS_URL, {
      method: "POST",
      body: JSON.stringify({
        text: `
        New Source Request:

        Product: ${product}
        From: ${userId} : ${permission?.email}
        Property: ${titleCase(sentenceCase(property))}

        Source Value requested: ${body.name}
        `,
      }),
    })

  return null
}
