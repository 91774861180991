import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { useMutation } from "@tanstack/react-query"
import { useFormik } from "formik"
import { z } from "zod"
import { toFormikValidationSchema } from "zod-formik-adapter"
import { CreateUserSavedSearch } from "~/routes/__protected/api/saved-searches/create"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"

import { useAnalytics } from "~/utils/hooks/useAnalytics"
import { useEnv } from "~/utils/hooks/useEnv"
import { useSavedSearchQuery } from "~/utils/hooks/useSavedSearchQuery"
import { JSONSafeParse } from "~/utils/JSONSafeParse"
import { relativeUrl } from "~/utils/string/url"
import { mapProductsToRouteName } from "../Filters/schemas"
import { ListAddManyModal } from "../ListAddManyModal"
import { createSearchQuery, createSearchSort } from "../UserSearchesPage/utils"
import { queryClient } from "~/utils/queryClient"
import { cacheKeys } from "~/utils/cacheKeys"

interface Props {
  product: SpecterProducts
}

const formSchema = z.object({
  name: z.string(),
})

const validationSchema = toFormikValidationSchema(formSchema)

type FormValues = z.infer<typeof formSchema>

export const SaveSearch = ({ product }: Props): JSX.Element => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  return (
    <>
      <Button
        onClick={onOpen}
        minW="fit-content"
        intercom-target="save-search"
        colorScheme="brand"
      >
        Save Search
      </Button>
      <SaveSearchModal product={product} isOpen={isOpen} onClose={onClose} />
    </>
  )
}

type SaveSearchModalProps = {
  product: SpecterProducts
  isOpen: boolean
  onClose: () => void
}

const SaveSearchModal = ({
  product,
  isOpen,
  onClose,
}: SaveSearchModalProps) => {
  const [searchParams, setSearchParams] = useSafeSearchParams()
  const toast = useToast()

  const analytics = useAnalytics()
  const env = useEnv()

  const saveSearchMutation = useMutation<
    CreateUserSavedSearch,
    unknown,
    FormValues
  >(
    async ({ name }) => {
      const query = JSONSafeParse(searchParams.get("query"))
      const sort = JSONSafeParse(searchParams.get("sort"))

      const countsQuery = queryClient.getQueryData(
        cacheKeys.signalsCount({ product, searchParams })
      ) as { results: number } | undefined

      const req = await fetch("/api/saved-searches/create", {
        method: "post",
        body: JSON.stringify({
          product,
          name,
          query,
          sort,
          cachedCount: countsQuery?.results,
        }),
      })

      return req.json()
    },
    {
      async onSuccess(data) {
        const toParams = {
          query: createSearchQuery(data.queries.query),
          sort: createSearchSort(data.sort),
          searchId: String(data.id),
        }

        const searchURL = `${env.APP_URL}/signals/${mapProductsToRouteName(
          product
        )}/feed`

        analytics.track("Saved Search", {
          product,
          name: data.name,
          query: toParams.query,
          sort: toParams.sort,
          searchURL: relativeUrl(searchURL, toParams),
        })

        setSearchParams(toParams)

        onClose()
        toast({
          status: "success",
          title: "Your saved search has been created",
        })

        // Don't await this as it could be long
        fetch(`/api/saved-searches/${data.id}/update-counts`, {
          method: "post",
        }).then(() => {
          queryClient.invalidateQueries(cacheKeys.userSavedSearches(product))
        })
      },
    }
  )

  const savedSearchQuery = useSavedSearchQuery()

  const formik = useFormik({
    validationSchema,
    initialValues: {
      name: "",
    },
    onSubmit(values) {
      saveSearchMutation.mutate(values)
    },
  })

  const addManyToList = useDisclosure()

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <form onSubmit={formik.handleSubmit}>
          <ModalHeader>Save Search</ModalHeader>
          <ModalBody display="flex" flexDirection="column" gap={8}>
            <FormControl
              isInvalid={!!formik.errors.name}
              isDisabled={!!savedSearchQuery.data}
            >
              <FormLabel htmlFor="name">Search Name</FormLabel>
              <Input
                id="name"
                value={savedSearchQuery.data?.name ?? formik.values.name}
                onChange={formik.handleChange}
                name="name"
              />
              <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter gap={2}>
            <Link
              fontSize="xs"
              mr="auto"
              color="brand.500"
              onClick={addManyToList.onOpen}
            >
              Save into a List
            </Link>
            <ListAddManyModal
              isOpen={addManyToList.isOpen}
              onClose={addManyToList.onClose}
              product={product}
            />

            <Button type="button" onClick={onClose} variant="outline">
              Cancel
            </Button>
            <Button
              colorScheme="brand"
              type="submit"
              disabled={
                !formik.isValid ||
                saveSearchMutation.isLoading ||
                !!savedSearchQuery.data
              }
              isLoading={saveSearchMutation.isLoading}
            >
              Save Search
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
