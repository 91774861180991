import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { HiGlobeAlt } from "react-icons/hi"
import { titleCase } from "title-case"
import { LoadingLogo } from "~/components/Logo/loading"
import { MenuItem } from "~/components/MenuItem"
import { ListType } from "~/routes/__protected/user/lists/$product"
import { cacheKeys } from "~/utils/cacheKeys"
import { useUserPermissions } from "~/utils/hooks/useUserPermissions"

interface Props {
  listType: ListType
  id: string | number
  product: SpecterProducts
  isGlobalHub: boolean
}

export function PromoteGlobalHub({
  listType,
  id,
  product,
  isGlobalHub,
}: Props): JSX.Element {
  const modal = useDisclosure()

  const queryClient = useQueryClient()
  const permissions = useUserPermissions()
  const toast = useToast()

  const promoteMutation = useMutation<unknown, unknown, boolean>(
    async (promote) => {
      const apiUrl =
        listType == ListType.userList
          ? `/api/lists/${product}/${id}/setGlobalHub`
          : `/api/saved-searches/${id}/setGlobalHub`

      await fetch(apiUrl, {
        method: "POST",
        body: JSON.stringify({
          isGlobalHub: promote,
        }),
      })
    },
    {
      async onSuccess() {
        modal.onClose()

        if (listType == ListType.userList) {
          await queryClient.invalidateQueries(cacheKeys.globalHubLists(product))
          await queryClient.invalidateQueries(cacheKeys.userLists({ product }))
        }

        if (listType == ListType.savedSearch) {
          await queryClient.invalidateQueries(
            cacheKeys.globalHubSearches(product)
          )
          await queryClient.invalidateQueries(
            cacheKeys.userSavedSearches(product)
          )
        }
        const title = `${titleCase(listType)} ${
          !isGlobalHub ? "promoted" : "removed from Global Hub"
        } successfully`

        toast({
          status: "success",
          title,
        })
      },
    }
  )

  const promoteBody = !isGlobalHub ? (
    <Text>
      Promoting this {listType} to the Global Hub will make it visible to all{" "}
      <i>admin</i> users, in the Global Hub section. After review within Global
      Hub, Admins can share the {listType} to all teams, selected teams, or
      individual users.
    </Text>
  ) : (
    <Text>
      Removing this {listType} from the Global Hub will make it visible to only
      the author and other users you have shared it with.
    </Text>
  )

  const modalTitle = !isGlobalHub
    ? `Promote ${titleCase(listType)} to Global Hub`
    : `Remove ${titleCase(listType)} from Global Hub`

  if (permissions.isLoading && !permissions.data) {
    return <LoadingLogo />
  }

  return (
    <>
      <MenuItem
        color={isGlobalHub ? "yellow.500" : undefined}
        icon={HiGlobeAlt}
        onClick={modal.onOpen}
        isLoading={promoteMutation.isLoading}
        whiteSpace="nowrap"
      >
        {isGlobalHub ? "De-Promote" : "Promote"}
      </MenuItem>

      <Modal isOpen={modal.isOpen} onClose={modal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>{modalTitle}</ModalHeader>
          <ModalBody>{promoteBody}</ModalBody>
          <ModalFooter gap={2}>
            <Button variant="outline" size="sm" onClick={modal.onClose}>
              Cancel
            </Button>
            <Button
              size="sm"
              colorScheme="brand"
              isLoading={promoteMutation.isLoading}
              onClick={() => promoteMutation.mutate(!isGlobalHub)}
            >
              {isGlobalHub ? "Remove" : "Promote"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
