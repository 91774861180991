import { ButtonGroup, Flex, Tag, Text } from "@chakra-ui/react"

import { SpecterProducts } from "@prisma/client"

import {
  Card,
  CompanyLogo,
  getOptionLabel,
  NotificationBadge,
} from "~/components"
import {
  ButtonLinkedin,
  ButtonTwitter,
  ButtonWebsite,
  SignalCard,
  SignalCardProps,
} from "~/components/SignalCard"
import { CardBox } from "~/components/SignalCard/CardBox"

import { useLocation } from "@remix-run/react"

import { INVESTOR_HIGHLIGHTS_PROPS } from "~/consts/signals"
import { GetInvestorByID } from "~/utils/db/getInvestorById"
import { getSignalLocation } from "~/utils/getSignalLocation"
import { useCompanyDeliveryMonth } from "~/utils/hooks/useCompanyDeliveryMonth"
import { ensureAbsoluteLink } from "~/utils/string/url"
import { CompanyRankBadge } from "../TagCompanyRank"
interface Props {
  signal: GetInvestorByID
  addedToList?: boolean
  signalCardProps?: Partial<SignalCardProps<"investors">>
}

export const InvestorSignalCard = ({
  signal,
  signalCardProps,
}: Props): JSX.Element => {
  const location = useLocation()

  const detailURL = `./${signal.id}${location.search}`
  const queryAppendChar = location.search ? "&" : "?"

  const { data: currentMonth } = useCompanyDeliveryMonth()

  return (
    <SignalCard
      {...signalCardProps}
      product={SpecterProducts.investors}
      signal={signal}
      picture={
        <CompanyLogo
          domain={String(signal.domain)}
          source={signal.logoUrl || undefined}
          size={16}
        />
      }
      title={signal.name}
      description={getSignalLocation({
        location: signal.HQLocation,
      })}
      // postDescription={"postDescription"}

      topLeftBadge={
        !!signal.rank && (
          <CompanyRankBadge rank={signal.rank} month={currentMonth} />
        )
      }
      postTitle={[
        signal.types?.map((type) => (
          <Tag key={type} colorScheme="brand" mr={1}>
            {getOptionLabel(type, "types", SpecterProducts.investors)}
          </Tag>
        )),
      ].filter(Boolean)}
      cardBoxes={
        <>
          {signal.nInvestments && (
            <CardBox
              title="Investments"
              value={signal.nInvestments ?? "N/A"}
              to={`${detailURL}${queryAppendChar}`}
            ></CardBox>
          )}
          {signal.nLeadInvestments && (
            <CardBox
              title="Lead Investments"
              value={signal.nLeadInvestments ?? "N/A"}
              to={`${detailURL}${queryAppendChar}`}
            ></CardBox>
          )}

          {signal.nExits && (
            <CardBox
              title="Exits"
              value={signal.nExits ?? "N/A"}
              to={`${detailURL}${queryAppendChar}`}
            ></CardBox>
          )}
          {signal.nFunds && (
            <CardBox
              title="Funds"
              value={signal.nFunds ?? "N/A"}
              to={`${detailURL}${queryAppendChar}`}
            ></CardBox>
          )}

          {/* <CardBox
            title="Total Funding"
            to={`${detailURL}${queryAppendChar}tab=1`}
            value={
              signal.totalFundingAmount
                ? `$${numeral(signal.totalFundingAmount).format("0,0a")}`
                : "N/A"
            }
          >
            {signal.growthStage === GrowthStage.exit &&
            !isNullish(signal.operatingStatus) ? (
              <OperatingStatusInfo operatingStatus={signal.operatingStatus} />
            ) : (
              <LastFundingStatus
                lastFundingAmount={signal.lastFundingAmount}
                lastFundingDate={signal.lastFundingDate}
                totalFundingAmount={signal.totalFundingAmount}
              />
            )}
          </CardBox>
          {(signal.lastFundingAmount || signal.lastFundingDate) && (
            <CardBox
              to={`${detailURL}${queryAppendChar}tab=1`}
              title="Last Funding"
              value={
                isDateValid(signal.lastFundingDate)
                  ? format(new Date(signal.lastFundingDate), "yyyy/MM/dd")
                  : "N/A"
              }
            >
              <SignalStatusCompany
                lastFundingDate={signal.lastFundingDate}
                lastFundingAmount={signal.lastFundingAmount}
                lastFundingType={signal.lastFundingType}
              />
            </CardBox>
          )} */}
        </>
      }
      postActionMenuButtons={
        <ButtonGroup
          size="xs"
          variant="outline"
          display="grid"
          gridTemplateColumns={"repeat(3, 1fr)"}
          spacing={0}
          gap={1}
        >
          <ButtonWebsite websiteUrl={signal.domain} />
          <ButtonLinkedin
            linkedinUrl={ensureAbsoluteLink(signal.linkedinUrl)}
          />
          <ButtonTwitter twitterUrl={ensureAbsoluteLink(signal.twitterUrl)} />
        </ButtonGroup>
      }
      postActionMenu={
        <Flex direction="column" gap={1}>
          {signal.InvestorHighlights?.slice(0, 4).map(
            ({ highlight, isNew }) => {
              if (!highlight) return null

              const { label, colorScheme, tab } =
                INVESTOR_HIGHLIGHTS_PROPS[highlight]

              return (
                <Card
                  key={highlight}
                  p={1}
                  px={2}
                  position="relative"
                  bgColor={`${colorScheme}.50`}
                  borderColor={`${colorScheme}.100`}
                  fontWeight="bold"
                  to={`${detailURL}${queryAppendChar}tab=${tab}`}
                >
                  {isNew && (
                    <NotificationBadge
                      value="New"
                      color={`${colorScheme}.400`}
                      fontSize="0.5rem"
                    />
                  )}

                  <Text
                    as="h2"
                    color={`${colorScheme}.500`}
                    fontSize="x-small"
                    whiteSpace="nowrap"
                    flexGrow={1}
                  >
                    {label}
                  </Text>
                </Card>
              )
            }
          )}
        </Flex>
      }
    />
  )
}
