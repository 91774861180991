import { prisma } from "~/utils/prisma.server"
import { Prisma } from "@prisma/client"

/**
 * Update company search entries for recently changed searches
 * @param updatedAt
 */
export async function updateSearchIndexAfterUpdatedAt(updatedAt: Date) {
  await prisma.$executeRaw(
    Prisma.sql`
        SELECT update_company_search("Specter - ID")
        FROM company_data
        WHERE updated_at > ${updatedAt}
    `
  )
}

export const getSearchPlaceholder = (pathname: string) => {
  if (pathname.includes("/signals/funding-rounds")) {
    return "Search Companies or Investors"
  }

  if (pathname.includes("/signals/investors")) {
    return "Search Investors"
  }

  if (pathname.includes("/signals/acquisition")) {
    return "Search Acquisitions"
  }

  if (pathname.includes("/signals/ipo")) {
    return "Search Companies"
  }

  if (pathname.includes("/signals/people")) {
    return "Search People"
  }

  if (pathname.includes("/signals/strategic")) {
    return "Search Companies or People"
  }

  return "Search Companies or Domains"
}

export const pathHasSearchInput = (pathname: string) =>
  pathname.includes("/signals/company") ||
  pathname.includes("/signals/funding-rounds") ||
  pathname.includes("/signals/investors") ||
  pathname.includes("/signals/acquisition") ||
  pathname.includes("/signals/ipo") ||
  pathname.includes("/signals/strategic") ||
  pathname.includes("/signals/people")
