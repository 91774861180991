import { LandscapeScope, SpecterProducts } from "@prisma/client"
import { useQuery } from "@tanstack/react-query"
import { z } from "zod"
import { EditableColumnsLandscapesResponse } from "~/routes/__protected/api/landscapes/editable-columns"
import { cacheKeys } from "../cacheKeys"

export const landscapeColumnsItemsSchema = z.object({
  id: z.number(),
  company: z
    .object({
      id: z.string(),
      CompanyName: z.string().nullable(),
      Domain: z.string(),
    })
    .passthrough()
    .optional()
    .nullable(),
  talentSignal: z
    .object({
      id: z.string(),
      Name: z.string(),
      ProfilePicture: z.string().nullable(),
    })
    .passthrough()
    .optional()
    .nullable(),
  strategicSignal: z
    .object({
      id: z.string(),
      Name: z.string(),
      Screenshot: z.string().nullable(),
    })
    .passthrough()
    .optional()
    .nullable(),
  ipo: z
    .object({
      id: z.string(),
      Name: z.string(),
    })
    .optional()
    .nullable(),
  acquisition: z
    .object({
      id: z.string(),
      companyName: z.string(),
    })
    .optional()
    .nullable(),
  investor: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .optional()
    .nullable(),
  fundingRound: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .optional()
    .nullable(),
})

const landscapeColumnsSchema = z.object({
  id: z.number(),
  name: z.string(),
  parentId: z.number().nullable(),
  LandscapeColumnsItems: z.array(landscapeColumnsItemsSchema),
})

export const landscapeColumnsWithChildrenSchema = landscapeColumnsSchema.extend(
  {
    LandscapeColumns: z.array(landscapeColumnsSchema).optional(),
  }
)

export const landscapeSchema = z.object({
  id: z.number(),
  name: z.string(),
  columns: z.array(landscapeColumnsWithChildrenSchema),
  description: z.string().nullable().optional(),
  scope: z.nativeEnum(LandscapeScope),
  userId: z.string(),
  icon: z.string().nullable().optional(),
  createdAt: z.coerce.date(),
  product: z.nativeEnum(SpecterProducts),
})

export type LandscapeType = z.infer<typeof landscapeSchema>
export type LandscapeColumnType = z.infer<
  typeof landscapeColumnsWithChildrenSchema
>

export const useLandscape = (id: string) => {
  return useQuery({
    queryKey: cacheKeys.landscapes(id),
    queryFn: async () => {
      const req = await fetch(`/api/landscapes/${id}`)

      if (!req.ok) {
        throw new Error("Failed to fetch landscape")
      }

      const data = await req.json()

      return landscapeSchema.parse(data)
    },
    staleTime: 1000 * 60 * 5,
  })
}

export const useLandscapesGlobal = () => {
  return useQuery({
    queryKey: cacheKeys.landscapesGlobal(),
    queryFn: async () => {
      const req = await fetch("/api/landscapes/global")

      if (!req.ok) {
        throw new Error("Failed to fetch landscapes")
      }

      const result = await req.json()

      return z.array(landscapeSchema).parse(result)
    },
  })
}

export const useLandscapesEditable = (
  product: SpecterProducts,
  enabled: boolean = true
) => {
  return useQuery<EditableColumnsLandscapesResponse>({
    enabled,
    queryKey: cacheKeys.landscapesEditable(product),
    queryFn: async () => {
      const response = await fetch(
        `/api/landscapes/editable-columns?product=${product}`
      )
      return response.json()
    },
  })
}
