import { Tag, Text, Tooltip } from "@chakra-ui/react"
import { format } from "date-fns"
import numeral from "numeral"
import { getRankColor } from "~/utils/companySignals"
import { isDateValid } from "~/utils/isDateValid"

export function TagCompanyRank({
  rank,
  month,
}: {
  rank: number
  month?: string
}) {
  return (
    <Tag
      bgColor={getRankColor(rank)}
      rounded="full"
      variant="solid"
      whiteSpace="nowrap"
      width="fit-content"
    >
      {month ? format(new Date(month), "MMMM") : ""} Rank:{" "}
      {numeral(rank).format("0,0")}
    </Tag>
  )
}

export const CompanyRankBadge = ({
  rank,
  month,
}: {
  rank: number | null
  month?: string | null
}) => {
  if (!rank) return null

  const validMonth = isDateValid(month) ? month : new Date().toISOString()

  return (
    <Tooltip
      label={`${
        month ? format(new Date(validMonth), "MMMM") : ""
      } Rank: ${numeral(rank).format("0,0")}`}
    >
      <Text
        p={1.5}
        bgColor={getRankColor(rank)}
        borderRadius="lg"
        fontSize="x-small"
        fontWeight="bold"
        color="white"
        boxShadow="md"
        border="1px solid white"
        minW="29px" // 29px is the height of the badge
        w="fit-content"
        textAlign="center"
      >
        #{numeral(rank).format("0,0")}
      </Text>
    </Tooltip>
  )
}
