import { useInfiniteQuery, useQuery } from "@tanstack/react-query"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"
import invariant from "~/utils/invariant"

import { SpecterProducts } from "@prisma/client"
import { mapProductsToRouteName } from "~/components/Filters/schemas"
import { FEED_PAGE_SIZE } from "~/consts/signals"
import { ProductSignalFeedItems } from "~/routes/__protected/api/signals/$product"
import { ProductSignalCount } from "~/routes/__protected/api/signals/$product/count"
import { AffinityContextValue } from "~/utils/context"
import { hasLength } from "~/utils/values"
import { cacheKeys } from "../cacheKeys"
import { PermissionAccessError } from "../permissionAccessError"
import { ViewMode } from "./useViewMode"

export const useSignalsQuery = <P extends SpecterProducts>(
  product: P,
  view: ViewMode = ViewMode.Table
) => {
  const [searchParams] = useSafeSearchParams()

  return useInfiniteQuery<{
    items: ProductSignalFeedItems<P>
    affinity: AffinityContextValue
    page: number
  }>(
    cacheKeys.signals(product, searchParams, view),
    async ({ pageParam = 0 }) => {
      const serverSearchParams = new URLSearchParams(searchParams)
      serverSearchParams.set("page", pageParam ?? 0)
      serverSearchParams.set("view", view)

      const req = await fetch(
        `/api/signals/${mapProductsToRouteName(product)}`,
        {
          method: "POST",
          body: JSON.stringify(
            Object.fromEntries(serverSearchParams.entries())
          ),
        }
      )

      if (req.status === 403) {
        throw PermissionAccessError
      }

      invariant(req.ok, `Failed to load ${product} signals`)

      const data = await req.json()

      // ! This was causing the routing flip-backs. This code was meant to populate the searchParams with missing params, when url was only identifiers (searchId p.e.)
      // if (Object.keys(data.filters).length > 0) {
      //   setSearchParams({
      //     ...Object.fromEntries(searchParams.entries()),
      //     query: JSON.stringify(data.filters),
      //     ...(!isEmpty(data.sort) &&
      //       !isNullish(data.sort) && {
      //         sort: JSON.stringify(data.sort),
      //       }),
      //   })
      // }

      return data
    },
    {
      getNextPageParam(result) {
        // No more pages if we haven't got items
        if (result.items) {
          if (!hasLength(result.items)) {
            // Needs to undefined as per
            //https://tanstack.com/query/v4/docs/react/guides/infinite-queries
            return undefined
          }

          // No more pages if the results are _less_ than the FEED_PAGE_SIZE
          if (result.items.length < FEED_PAGE_SIZE) {
            return undefined
          }
        }

        return result.page + 1
      },
      staleTime: 5 * 60 * 1000, // 5 minutes
      refetchOnMount: false,
    }
  )
}

export const useSignalsQueryCount = (product: SpecterProducts) => {
  const [searchParams] = useSafeSearchParams()

  return useQuery<ProductSignalCount>(
    cacheKeys.signalsCount({ product, searchParams }),
    async () => {
      const req = await fetch(
        `/api/signals/${mapProductsToRouteName(product)}/count`,
        {
          method: "POST",
          body: JSON.stringify(Object.fromEntries(searchParams.entries())),
        }
      )

      if (req.status === 403) {
        throw PermissionAccessError
      }

      invariant(req.ok, `Failed to load ${product} signals`)

      return req.json()
    },
    {
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  )
}
