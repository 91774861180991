import { Flex, Heading, SimpleGrid, Tag } from "@chakra-ui/react"

import { SpecterProducts } from "@prisma/client"
import { FormikProps } from "formik"
import { NEW_FEATURED_FILTER_SUB_SECTIONS } from "~/components"
import {
  FiltersSectionSchema,
  FiltersTabSchema,
  SignalFilters,
} from "../schemas"
import { AdvancedFilterItem, Dot } from "./AdvancedFilterItem"

type AdvancedFiltersProps<P extends SpecterProducts, Schema> = {
  title?: string | JSX.Element
  product: P
  formik: FormikProps<SignalFilters[P]>
  schema: Schema
}

export const AdvancedFilterSection = <
  P extends SpecterProducts,
  Schema extends FiltersSectionSchema
>({
  product,
  formik,
  title,
  schema,
}: AdvancedFiltersProps<P, Schema>) => {
  const isDirty = Object.keys(schema.shape).some(
    (key) => formik.values[key as keyof typeof formik.values] !== undefined
  )

  const isNew = NEW_FEATURED_FILTER_SUB_SECTIONS.includes(
    title?.toString() ?? ""
  )

  return (
    <Flex direction="column" gap={2}>
      <Heading
        size="xs"
        as="h3"
        fontWeight="semibold"
        // pt={4}
        display="flex"
        alignItems="center"
      >
        {title}
        {isNew && (
          <Tag colorScheme="brand" ml={2}>
            New
          </Tag>
        )}
        {isDirty && <Dot />}
      </Heading>
      <SimpleGrid
        templateColumns="repeat(auto-fill, minmax(300px, 1fr))"
        gap={6}
        py={1}
      >
        {Object.entries(schema.shape).map(([innerField, innerSchema]) => (
          <AdvancedFilterItem
            key={innerField}
            schema={innerSchema}
            value={formik.values}
            onChange={(values) => formik.setValues(values)}
            product={product}
            field={innerField}
            onBlur={formik.handleBlur}
          />
        ))}
      </SimpleGrid>
    </Flex>
  )
}

export const AdvancedFilterTab = <
  P extends SpecterProducts,
  Schema extends FiltersTabSchema
>({
  product,
  formik,
  schema,
}: AdvancedFiltersProps<P, Schema>) => {
  // TODO: Idea for creating a list of applied filters inside AdvancedFilters
  // const sectionFieldKeys = Object.values(schema.shape).flatMap((section) =>
  //   Object.keys(section.shape)
  // )

  // const sectionAppliedFields = Object.fromEntries(
  //   Object.entries(formik.values).filter(
  //     ([key, value]) => sectionFieldKeys.includes(key) && !isNullish(value)
  //   )
  // ) as typeof formik.values

  return (
    <Flex direction="column" gap={8}>
      {/* {!!Object.keys(sectionAppliedFields).length && (
          <Flex mt={4}>
            {Object.entries(sectionAppliedFields).map(([key, value], index) => (
              <Flex gap={2} key={key}>
                {index !== 0 && <FlexDivider orientation="vertical" my={0} />}
                <AppliedFilterItem
                  product={product}
                  field={key}
                  value={value as ValueOf<SignalFilters[P]>}
                  // TODO: Add onChange and onClickMoreItems if needed
                />
              </Flex>
            ))}
          </Flex>
        )} */}
      {Object.entries(schema.shape).map(([innerField, innerSchema]) => (
        <AdvancedFilterSection
          key={innerField}
          title={innerField}
          product={product}
          formik={formik}
          schema={innerSchema}
        />
      ))}
    </Flex>
  )
}
