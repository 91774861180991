import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Spacer,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { Outlet, useLocation } from "@remix-run/react"
import { useEffect, useMemo, useState } from "react"

import { LoaderArgs } from "@remix-run/node"
import * as Sentry from "@sentry/remix"
import { useMutation } from "@tanstack/react-query"
import { equals } from "ramda"
import {
  HiOutlineBars4,
  HiOutlineMagnifyingGlass,
  HiStar,
} from "react-icons/hi2"
import {
  ClientSide,
  PRODUCTS_WITH_LISTS_AND_SEARCHES,
  ResultCount,
  SaveSearch,
  SignalExport,
  SignalSortMenu,
} from "~/components"
import { AffinityFeedMenu } from "~/components/Affinity/AffinityFeedMenu"
import { FeedDataError } from "~/components/Errors"
import { AppliedFilters } from "~/components/Filters/AppliedFilters"
import { PRODUCT_ROUTE_NAMES } from "~/components/Filters/schemas"
import { cacheKeys } from "~/utils/cacheKeys"
import { getDeprecatedFields } from "~/utils/checkForBackwardsCompatibility"
import { countAppliedSignalFilters } from "~/utils/countAppliedSignalFilters"
import { hasAccessToFeed } from "~/utils/hasAccessToFeed"
import { useAnalytics } from "~/utils/hooks/useAnalytics"
import { useIntegrations } from "~/utils/hooks/useIntegrations"
import { useIntersectionObserver } from "~/utils/hooks/useIntersectionObserver"
import { useListDetailsQuery } from "~/utils/hooks/useListDetailsQuery"
import { useProduct } from "~/utils/hooks/useProduct"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"
import { useSavedSearchQuery } from "~/utils/hooks/useSavedSearchQuery"
import {
  useSignalsQuery,
  useSignalsQueryCount,
} from "~/utils/hooks/useSignalsQuery"
import { useUserPermissions } from "~/utils/hooks/useUserPermissions"
import { ViewMode } from "~/utils/hooks/useViewMode"
import invariant from "~/utils/invariant"
import { isOneOf } from "~/utils/isOneOf"
import { JSONSafeParse } from "~/utils/JSONSafeParse"
import { cleanProgmaticFiltersFromQuery } from "~/utils/progmaticFilters"
import { queryClient } from "~/utils/queryClient"
import { PAGE_NOT_FOUND } from "~/utils/responses/errors"
import { FAVOURITES_LIST_NAME } from "~/consts/lists"
import { HiGlobeAlt } from "react-icons/hi"
import { useUser } from "@clerk/remix"
import PeopleDBComingSoon from "~/components/PeopleDetailPage/Components/PeopleDBComingSoon"
import { useFeatureFlag } from "~/utils/hooks/useFeatureFlags"
import { IntegrationSearchButton } from "~/components/IntegrationSearchButton"
import { MdOutlineFilterList } from "react-icons/md"
import { AdvancedFiltersModal } from "~/components/Filters/AdvancedFilters/AdvancedFiltersModal"
import { HEADER_HEIGHT } from "~/components/Header"
import { SearchInput } from "~/components/SearchInput"
import { getSearchPlaceholder, pathHasSearchInput } from "~/utils/search"
import { SuggestedSearches } from "~/components/Filters/SuggestedSearches"

export async function loader({ params }: LoaderArgs) {
  if (!isOneOf(params.product, Object.keys(PRODUCT_ROUTE_NAMES)))
    throw PAGE_NOT_FOUND

  return null
}

export const useInfiniteSignalsQuery = (
  product: SpecterProducts,
  viewMode: ViewMode = ViewMode.Feed
) => {
  const signalsQuery = useSignalsQuery(product, viewMode)

  const observerRef = useIntersectionObserver<HTMLDivElement>(
    () =>
      !signalsQuery.isFetchingNextPage &&
      signalsQuery.hasNextPage &&
      signalsQuery.fetchNextPage()
  )

  return { ...signalsQuery, observerRef }
}

// First value is the default view mode
export const PRODUCT_VIEW_MODES: Record<
  SpecterProducts,
  [ViewMode, ...ViewMode[]]
> = {
  [SpecterProducts.company]: [ViewMode.Feed, ViewMode.Table],
  [SpecterProducts.talent]: [ViewMode.Feed, ViewMode.Table],
  [SpecterProducts.stratintel]: [ViewMode.Feed, ViewMode.Table],
  [SpecterProducts.investors]: [ViewMode.Feed, ViewMode.Table],
  [SpecterProducts.fundingRounds]: [ViewMode.Table],
  [SpecterProducts.acquisition]: [ViewMode.Table],
  [SpecterProducts.ipo]: [ViewMode.Table],
  [SpecterProducts.people]: [ViewMode.Feed, ViewMode.Table],
}

const SignalsPage = (): JSX.Element => {
  const analytics = useAnalytics()

  const toast = useToast()
  const { user } = useUser()
  const product = useProduct()
  const permissions = useUserPermissions()
  const [searchParams, setSearchParams] = useSafeSearchParams()
  const countQuery = useSignalsQueryCount(product)

  const integrationsQuery = useIntegrations()
  const disableLegacyAffinity =
    !!integrationsQuery.data?.integrations.integrationsMeta.find(
      (i) => i.integrationKey === "affinity"
    )

  const advancedFilters = useDisclosure()
  const savedSearchQuery = useSavedSearchQuery()
  const listQuery = useListDetailsQuery()

  const hasIntegrationSetup =
    (integrationsQuery.data?.integrations.integrationsMeta.length ?? 0) > 0

  const rootQuery = cleanProgmaticFiltersFromQuery(
    JSONSafeParse<Record<string, any>>(searchParams.get("query"))
  )
  const sort = searchParams.get("sort") ?? ""
  const searchId = searchParams.get("searchId")
  const searchTerm = searchParams.get("search") ?? ""
  const [showSearch, setShowSearch] = useState(!!searchTerm)
  const location = useLocation()

  const hasSearchInput = pathHasSearchInput(location.pathname)

  useEffect(() => {
    const deprecatedFields = getDeprecatedFields(product, rootQuery)
    if (deprecatedFields.length > 0 && !advancedFilters.isOpen) {
      advancedFilters.onOpen()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // * In case we decide to show for big searches:
  // * "This search doesn't fit the URL. Make sure to "Save Search" to be able to share with your team."
  // * This is the boolean for that:

  // const isBigSearch =
  //   Array.from(searchParams.entries()).length > 0 &&
  //   `?${searchParams.toString()}` !== location.search

  const totalAppliedFilters = useMemo(
    () => countAppliedSignalFilters(product, searchParams),
    [product, searchParams]
  )

  const clearFilters = () => {
    setSearchParams({})
  }

  const updateSavedSearch = useMutation(
    async () => {
      invariant(searchId, "No search to update")

      const res = await fetch(`/api/saved-searches/${searchId}`, {
        method: "post",
        body: JSON.stringify({
          query: JSON.stringify(rootQuery),
          sort,
        }),
      })

      invariant(res.ok, "Failed to edit saved search")

      analytics.track("User edited a Saved Search", {
        searchId,
        product,
        query: rootQuery,
        sort,
      })

      fetch(`/api/saved-searches/${searchId}/update-counts`, {
        method: "post",
      }).then(() => {
        queryClient.invalidateQueries(cacheKeys.userSavedSearch(searchId))
        queryClient.invalidateQueries(cacheKeys.userSavedSearches(product))
      })
    },
    {
      async onError() {
        toast({
          status: "error",
          title:
            "We were unable to update your Saved Search filter. Please try again later.",
        })
      },
      async onSuccess() {
        if (searchId) {
          await queryClient.invalidateQueries(
            cacheKeys.userSavedSearch(searchId)
          )
        }
        await queryClient.invalidateQueries(
          cacheKeys.userSavedSearches(product)
        )
        await queryClient.invalidateQueries(
          cacheKeys.globalHubSearches(product)
        )

        toast({
          status: "success",
          title: "Updated Saved Search filters",
        })
      },
    }
  )

  const showUpdateSearchButton =
    savedSearchQuery.data?.userId === user?.id &&
    savedSearchQuery.data &&
    typeof savedSearchQuery.data.queries.query === "object" &&
    !equals(
      cleanProgmaticFiltersFromQuery(savedSearchQuery.data.queries.query ?? {}),
      rootQuery
    )

  const isPeopleDBEnabled = useFeatureFlag("PEOPLE_DB_ENABLED")

  if (product === "people" && !isPeopleDBEnabled) {
    return <PeopleDBComingSoon />
  }

  return (
    <Flex as="main" direction="column" flexGrow={1}>
      <Flex
        bgColor="white"
        position="sticky"
        top={HEADER_HEIGHT}
        zIndex={110}
        py={1.5}
        px={6}
        borderBottomWidth={1}
        borderColor="gray.100"
        gap={1}
        alignItems="center"
      >
        {listQuery.isSuccess && listQuery.data !== null && <ListNameTag />}

        {savedSearchQuery.isSuccess && savedSearchQuery.data !== null && (
          <SearchNameTag />
        )}

        <Button
          onClick={() => advancedFilters.onOpen()}
          variant="dashed"
          leftIcon={<MdOutlineFilterList />}
        >
          Add Filter
        </Button>

        <SignalSortMenu product={product} />

        {totalAppliedFilters > 0 && (
          <Button
            onClick={clearFilters}
            colorScheme="red"
            variant="ghost"
            bgColor="red.50"
          >
            Clear Filters
          </Button>
        )}

        {showUpdateSearchButton && (
          <Button
            flex="none"
            isLoading={updateSavedSearch.isLoading}
            onClick={() => {
              updateSavedSearch.mutate()
            }}
            colorScheme="brand"
          >
            Update Search
          </Button>
        )}

        {!savedSearchQuery.data &&
          !listQuery.data &&
          totalAppliedFilters > 0 &&
          !savedSearchQuery.isInitialLoading &&
          !showUpdateSearchButton &&
          isOneOf(product, PRODUCTS_WITH_LISTS_AND_SEARCHES) && (
            <SaveSearch product={product} />
          )}

        {totalAppliedFilters <= 0 && (
          <>
            <Box w="1px" h={5} bgColor="gray.200" mx={1} />
            <SuggestedSearches product={product} />
          </>
        )}

        <Spacer />

        {!showSearch && hasSearchInput && (
          <IconButton
            aria-label="Search"
            icon={<HiOutlineMagnifyingGlass />}
            onClick={() => {
              setShowSearch(true)
            }}
            variant="outline"
          />
        )}

        {showSearch && (
          <SearchInput
            placeholder={getSearchPlaceholder(location.pathname)}
            onChange={(term) => {
              setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                search: term,
              })
            }}
            onClear={() => {
              setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                search: "",
              })
              setShowSearch(false)
            }}
            defaultValue={searchTerm}
          />
        )}

        {isOneOf(product, PRODUCTS_WITH_LISTS_AND_SEARCHES) &&
          hasAccessToFeed(permissions.data, product) &&
          hasIntegrationSetup && <IntegrationSearchButton />}

        {hasAccessToFeed(permissions.data, product) && (
          <>
            {totalAppliedFilters > 0 && !disableLegacyAffinity && (
              <AffinityFeedMenu product={product} />
            )}

            <SignalExport product={product} />
          </>
        )}
      </Flex>

      <Flex
        px={6}
        py={1.5}
        mb="-1px"
        bgColor="gray.50"
        borderBottomWidth={1}
        borderColor="gray.100"
        alignItems="flex-end"
      >
        {totalAppliedFilters > 0 && (
          <>
            <AppliedFilters
              product={product}
              advancedFilters={advancedFilters}
            />
            <Spacer />
          </>
        )}

        <ResultCount product={product} countQuery={countQuery} />
      </Flex>

      <ClientSide>
        <AdvancedFiltersModal
          product={product}
          isOpen={advancedFilters.isOpen}
          onClose={() => {
            advancedFilters.onClose()
          }}
        />
      </ClientSide>

      <Outlet />
    </Flex>
  )
}

export const ListNameTag = () => {
  const listQuery = useListDetailsQuery()

  if (!listQuery.data) return null

  return (
    <Flex
      alignItems="center"
      gap={1}
      flex="none"
      bgColor={
        listQuery.data.name === FAVOURITES_LIST_NAME ||
        listQuery.data.isGlobalHub
          ? "yellow.50"
          : "brand.50"
      }
      rounded="lg"
      borderWidth={1}
      borderStyle="dashed"
      borderColor={
        listQuery.data.name === FAVOURITES_LIST_NAME ||
        listQuery.data.isGlobalHub
          ? "yellow.500"
          : "brand.500"
      }
      px={2}
      py={1}
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      overflow="hidden"
      fontSize="xs"
      fontWeight="medium"
      color={
        listQuery.data.name === FAVOURITES_LIST_NAME ||
        listQuery.data.isGlobalHub
          ? "yellow.700"
          : "brand.500"
      }
    >
      <Icon
        as={
          listQuery.data.name === FAVOURITES_LIST_NAME
            ? HiStar
            : listQuery.data.isGlobalHub
            ? HiGlobeAlt
            : HiOutlineBars4
        }
      />
      {listQuery.data.name}
    </Flex>
  )
}

export const SearchNameTag = () => {
  const savedSearchQuery = useSavedSearchQuery()

  if (!savedSearchQuery.data) return null

  return (
    <Flex
      alignItems="center"
      gap={1}
      flex="none"
      bgColor={savedSearchQuery.data.isGlobalHub ? "yellow.50" : "brand.50"}
      rounded="lg"
      borderWidth={1}
      borderStyle="dashed"
      borderColor={
        savedSearchQuery.data.isGlobalHub ? "yellow.500" : "brand.500"
      }
      px={2}
      py={1}
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      overflow="hidden"
      fontSize="xs"
      fontWeight="medium"
      color={savedSearchQuery.data.isGlobalHub ? "yellow.700" : "brand.500"}
    >
      <Icon
        as={
          savedSearchQuery.data.isGlobalHub
            ? HiGlobeAlt
            : HiOutlineMagnifyingGlass
        }
      />
      {savedSearchQuery.data.name}
    </Flex>
  )
}

export const ErrorBoundary = ({ error }: { error: Error }) => {
  console.error(error)
  Sentry.captureException(error)
  return <FeedDataError />
}

export default SignalsPage
