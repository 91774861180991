import * as R from "ramda"

export const PermissionAccessError = {
  status: 403,
} as const

export const isPermissionAccessError = (
  err: unknown
): err is typeof PermissionAccessError => {
  return R.pathEq(["status"], 403)(err)
}
