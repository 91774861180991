import { Flex, FlexProps, Tag, Text, TextProps } from "@chakra-ui/react"
import { ReactText } from "react"
import { Card } from "../Card"
import { EnergyLine } from "../CompanySignalDetailPage/DetailValueCard"

interface Props extends FlexProps {
  icon?: React.ReactNode
  title?: string
  value?: ReactText
  children?: React.ReactNode
  to?: string
  suppressColon?: boolean
  withHighlight?: boolean
  valueProps?: TextProps
}

export const CardBox = ({
  icon,
  title,
  value,
  children,
  to,
  suppressColon,
  withHighlight,
  valueProps,
  ...props
}: Props): JSX.Element => {
  return (
    <Card
      to={to}
      {...((props.onClick || to) && { cursor: "pointer" })}
      display="flex"
      flexDirection="column"
      gap={1}
      p={{ base: 2, md: 4 }}
      position="relative"
      overflow="hidden"
      minW={150}
      {...props}
    >
      {withHighlight && <EnergyLine />}
      <Flex alignItems="center" gap={1}>
        {icon}
        <Text
          as="h2"
          color="gray.500"
          fontSize={{ base: "xs", lg: "sm" }}
          whiteSpace="nowrap"
          display="inline-flex"
          gap={1}
          alignItems="center"
        >
          {title ? `${title}${suppressColon ? "" : ":"} ` : ""}
          <Text
            as="span"
            color="gray.800"
            fontSize={{ base: "xs", lg: "sm" }}
            fontWeight="bold"
            {...valueProps}
          >
            {value}
          </Text>
        </Text>
      </Flex>
      {children && (
        <Flex alignItems="center" flexGrow={1}>
          {children}
        </Flex>
      )}
    </Card>
  )
}

type GrowthTagProps = {
  formattedValue: string
  period: number | null
  sign: "positive" | "negative" | "zero" | number | null
}
export const GrowthTag = ({ formattedValue, period, sign }: GrowthTagProps) => {
  const props = {
    positive: {
      color: "green",
      icon: "▲",
      fontSize: "12px",
    },
    negative: {
      color: "red",
      icon: "▼",
      fontSize: "10px",
    },
    zero: {
      color: "orange",
      icon: "•",
      fontSize: "12px",
    },
  }

  let signType: "positive" | "negative" | "zero" = "zero"

  if (typeof sign === "number") {
    if (sign > 0) {
      signType = "positive"
    } else if (sign < 0) {
      signType = "negative"
    } else {
      signType = "zero"
    }
  }

  const { color, icon, fontSize } = props[signType]

  return (
    <Tag borderRadius="full" py={1} px={2} display="flex" gap={2} fontSize="xs">
      <Text
        fontWeight="bold"
        whiteSpace="nowrap"
        pl={signType == "zero" ? "4px" : 0}
      >
        {formattedValue}
      </Text>
      <Text color={`${color}.500`} fontSize={fontSize}>
        {icon}
      </Text>
      <Text color={`gray.400`} whiteSpace="nowrap">
        ({period ?? 180} days)
      </Text>
    </Tag>
  )
}
