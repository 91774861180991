import { Input, InputProps } from "@chakra-ui/react"
import { format } from "date-fns"
import { isDateValid } from "~/utils/isDateValid"

type Props = {
  inputId: string
  value: string | Date | null
  onChange?: (value: Date | null) => void
  placeholder?: string
} & Omit<InputProps, "onChange" | "value">

export const DatePicker = ({
  inputId,
  value,
  onChange,
  placeholder,
  ...props
}: Props) => {
  const safeValue = isDateValid(value)
    ? format(new Date(value), "yyyy-MM-dd")
    : ""

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value === "") {
      onChange?.(null)
    } else {
      const date = new Date(e.currentTarget.value)
      onChange?.(date)
    }
  }

  return (
    <Input
      id={inputId}
      name={inputId}
      type="date"
      value={safeValue}
      placeholder={placeholder}
      onChange={handleChange}
      {...(!safeValue && { color: "gray.400" })}
      position="relative"
      css={{
        "&::-webkit-calendar-picker-indicator": {
          background: "transparent",
          color: "transparent",
          cursor: "pointer",
          position: "absolute",
          inset: 0,
          width: "auto",
          height: "auto",
        },
        "&:before": {
          content: !safeValue ? "attr(placeholder) !important" : '""',
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
        },
        ...(!safeValue && {
          "&::-webkit-datetime-edit": {
            display: "none",
          },
        }),
      }}
      {...props}
    />
  )
}
