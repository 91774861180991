import { Box, ButtonGroup, Flex, Heading, Text } from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { useLocation } from "@remix-run/react"

import { ReactNode, useMemo } from "react"
import { FlexDivider, FlexOneLine, FlexOneLineProps } from "~/components"
import { Card } from "~/components/Card"
import { ActionMenuIcons } from "~/components/SignalCard/ActionMenu"
import { isEmpty } from "~/utils/isEmpty"
import { CardBox } from "../CardBox"
import { ActionButtonProps } from "./Buttons"

export type SignalCardProps<P extends SpecterProducts> = {
  picture: ReactNode
  title?: ReactNode
  subtitle?: ReactNode
  description?: ReactNode
  postTitle?: ReactNode
  postTitleOverflow?: FlexOneLineProps["overflowIndicator"]
  preButtons?: ReactNode
  actionButtons?: ReactNode
  cardBoxes?: ReactNode
  avatarFullHeight?: boolean
  postDescription?: ReactNode
  postCardBoxes?: ReactNode
  topLeftBadge?: ReactNode
  postActionMenu?: ReactNode
  postActionMenuButtons?: ReactNode
  openInFullScreen?: boolean
  customLink?: string | null
  withReducedInfo?: boolean
} & ActionButtonProps<P>

export const SignalCard = <P extends SpecterProducts>({
  signal,
  product,
  picture,
  title,
  subtitle,
  description,
  postTitle,
  postTitleOverflow,
  preButtons,
  actionButtons,
  cardBoxes,
  avatarFullHeight,
  postDescription,
  postCardBoxes,
  topLeftBadge,
  postActionMenu,
  postActionMenuButtons,
  openInFullScreen,
  customLink,
  withReducedInfo,
}: SignalCardProps<P>) => {
  const location = useLocation()
  const detailURL = `./${signal.id}${location.search}${
    openInFullScreen ? "/f" : ""
  }`

  const cardBoxesContainer = useMemo(
    () =>
      cardBoxes && (
        <Flex alignItems="flex-end">
          {/* <Grid
            gap={2}
            alignItems="stretch"
            flexGrow={1}
            templateColumns="repeat(auto-fit, minmax(150px, auto))"
            templateRows="min-content"
            justifyContent="start"
          > */}
          {/* //! Haven't found the best way of handling this yet, but to avoid the boxes from overflowing the card, we'll use FlexOneLine for now*/}
          <FlexOneLine gap={2} flexWrap="wrap">
            {cardBoxes}
          </FlexOneLine>
          {/* </Grid> */}
          <Box ml="auto">{postCardBoxes}</Box>
        </Flex>
      ),
    [cardBoxes, postCardBoxes]
  )

  return (
    <CardBox>
      <Card
        display="flex"
        gap={3}
        to={customLink === null ? undefined : customLink ?? detailURL}
        position="relative"
      >
        <Flex direction="column" gap={2} flexGrow={1}>
          <Flex gap={4} alignItems="stretch">
            {picture}

            <Flex direction="column" gap={2} flexGrow={1}>
              <Flex
                justifyContent={"space-between"}
                alignItems={"center"}
                gap={2}
              >
                <FlexOneLine
                  gap={1}
                  alignItems="center"
                  overflowIndicator={postTitleOverflow}
                >
                  {!isEmpty(title) && (
                    <Heading as="h1" fontSize="md" fontWeight="bold" maxW={500}>
                      {title}
                    </Heading>
                  )}

                  {!isEmpty(title) && !isEmpty(postTitle) && (
                    <FlexDivider orientation="vertical" />
                  )}

                  {postTitle}
                </FlexOneLine>

                <ButtonGroup size="xs" variant="outline" spacing={1}>
                  {preButtons}

                  {!isEmpty(preButtons) && !isEmpty(actionButtons) && (
                    <FlexDivider orientation="vertical" />
                  )}

                  {actionButtons}

                  {!withReducedInfo &&
                    (product === SpecterProducts.talent ||
                      product === SpecterProducts.stratintel) && (
                      <>
                        <FlexDivider orientation="vertical" />
                        <ActionMenuIcons
                          product={product}
                          signal={signal}
                          stretchButtons={false}
                        />
                      </>
                    )}
                </ButtonGroup>
              </Flex>

              <Flex justifyContent="space-between" alignItems="center">
                <Flex direction="column" gap={2} flexGrow={1}>
                  {!isEmpty(subtitle) && (
                    <Heading
                      as="h2"
                      fontSize="sm"
                      fontWeight="semibold"
                      lineHeight={1.4}
                      noOfLines={1}
                    >
                      {subtitle}
                    </Heading>
                  )}
                  {!isEmpty(description) && (
                    <Text
                      fontSize="sm"
                      noOfLines={1}
                      color="gray.500"
                      maxW={500}
                    >
                      {description}
                    </Text>
                  )}
                </Flex>
                {postDescription}
              </Flex>
              {avatarFullHeight && cardBoxesContainer}
            </Flex>
          </Flex>
          {!avatarFullHeight && cardBoxesContainer}
        </Flex>

        {(postActionMenu || postActionMenuButtons) && (
          <>
            <FlexDivider orientation="vertical" />

            <Flex direction="column" gap={1.5} minW="8rem">
              {postActionMenuButtons}
              {postActionMenu}
            </Flex>
          </>
        )}

        {topLeftBadge && (
          <Box position="absolute" top={1.5} left={1.5}>
            {topLeftBadge}
          </Box>
        )}
      </Card>
    </CardBox>
  )
}

export * from "./ActionMenu"
export * from "./Buttons"
export * from "./CardBox"
export * from "./GrowthBadge"
