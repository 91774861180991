import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react"
import { useFormik } from "formik"
import { HiExternalLink, HiPencil } from "react-icons/hi"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { z } from "zod"
import { toFormikValidationSchema } from "zod-formik-adapter"
import { cacheKeys } from "~/utils/cacheKeys"
import { SpecterProducts } from "@prisma/client"
import { createSearchQuery } from "~/components/UserSearchesPage/utils"
import { generateURLFactory } from "~/components/UserSearchesPage/SavedSearchesTable"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"
import { Link as RemixLink } from "@remix-run/react"
import { useViewMode } from "~/utils/hooks/useViewMode"
import { MenuItem } from "../MenuItem"
import { SavedSearchesIndex } from "~/routes/__protected/api/saved-searches"

interface Props {
  savedSearch: Partial<SavedSearchesIndex["items"][number]>
  product: SpecterProducts
}

const formSchema = z.object({
  name: z.string(),
})

const validationSchema = toFormikValidationSchema(formSchema)

type FormValues = z.infer<typeof formSchema>

export const EditSavedSearch = ({
  savedSearch,
  product,
}: Props): JSX.Element => {
  const modal = useDisclosure()

  const queryClient = useQueryClient()
  const [, setSearchParams] = useSafeSearchParams()

  const toast = useToast()

  const viewMode = useViewMode()

  const { query, sort, id: searchId } = savedSearch
  const generateURL = generateURLFactory({
    setSearchParams,
    product,
    viewMode,
    query: query ?? {},
    sort,
    searchId,
  })

  const [to, onNavigate] = generateURL(createSearchQuery)

  const saveMutation = useMutation<Response, unknown, FormValues>(
    async (values) => {
      // NOTE: This is here because Saved Searches returns a different
      // set of data to what is returned on Global Hub edits.
      const isPublic =
        "visibility" in savedSearch
          ? savedSearch?.visibility?.type == "teamShare"
          : // @ts-ignore
            savedSearch.isPublic

      const res = await fetch(`/api/saved-searches/${savedSearch.id}`, {
        method: "post",
        body: JSON.stringify({
          ...values,
          isPublic: !!isPublic,
        }),
      })

      return res
    },
    {
      async onSuccess(res) {
        if (!res.ok) {
          throw new Error("Failed to update saved search")
        }

        modal.onClose()
        await queryClient.invalidateQueries(
          cacheKeys.userSavedSearches(product)
        )
        await queryClient.invalidateQueries(
          cacheKeys.globalHubSearches(product)
        )

        toast({
          status: "success",
          title: "Search edited successfully",
        })
      },
    }
  )

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      name: savedSearch.name ?? "",
    },
    onSubmit(values) {
      saveMutation.mutate(values)
    },
  })

  return (
    <>
      <MenuItem
        icon={HiPencil}
        onClick={modal.onOpen}
        isLoading={saveMutation.isLoading}
      >
        Edit
      </MenuItem>

      <Modal isOpen={modal.isOpen} onClose={modal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Edit Saved Search</ModalHeader>
          <form onSubmit={formik.handleSubmit}>
            <ModalBody>
              <VStack spacing={4} alignItems="start">
                <FormControl isInvalid={!!formik.errors.name}>
                  <FormLabel htmlFor="name">Search Name</FormLabel>
                  <Input
                    id="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    name="name"
                  />
                  <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                </FormControl>
                <Box>
                  <FormLabel htmlFor="edit">Edit Search</FormLabel>
                  <Link
                    as={RemixLink}
                    fontSize="10pt"
                    to={to}
                    onClick={() => {
                      // this will only be set if params are too big
                      onNavigate()
                    }}
                  >
                    Go to the feed and edit the Saved Search
                    <Icon as={HiExternalLink} ml={1} verticalAlign="middle" />
                  </Link>
                </Box>
              </VStack>
            </ModalBody>
            <ModalFooter gap={2}>
              <Button size="sm" variant="outline" onClick={modal.onClose}>
                Cancel
              </Button>
              <Button
                size="sm"
                colorScheme="brand"
                disabled={!formik.isValid || saveMutation.isLoading}
                isLoading={saveMutation.isLoading}
                type="submit"
              >
                Save Changes
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
}
