import { Box, Image, BoxProps } from "@chakra-ui/react"
import { IconType } from "react-icons"
import { HiBriefcase } from "react-icons/hi"
import { BoxIcon } from "~/components/BoxIcon"

interface Props extends BoxProps {
  domain?: string | null
  source?: string | null
  size?: number
  fallbackIcon?: IconType
}

export const CompanyLogo = ({
  domain,
  source,
  size = 10,
  fallbackIcon = HiBriefcase,
  ...props
}: Props): JSX.Element => {
  return (
    <Box
      w={size}
      h={size}
      flex="none"
      bgColor="white"
      rounded="lg"
      overflow="hidden"
      pos="relative"
      zIndex={0}
      {...props}
    >
      <Image
        w="100%"
        h="100%"
        objectFit="contain"
        pos="relative"
        zIndex={1}
        src={source || (domain && `/logo?domain=${domain}`) || undefined}
        alt={domain ?? "Company logo"}
        fallback={<BoxIcon icon={fallbackIcon} size={size} {...props} />}
      />
    </Box>
  )
}
