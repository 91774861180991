import { Queries, QueriesByUser, SpecterProducts } from "@prisma/client"
import { PRODUCT_VIEW_MODES } from "~/routes/__protected/signals/$product"
import { ViewMode } from "~/utils/hooks/useViewMode"
import { JSONSafeParse } from "~/utils/JSONSafeParse"
import { DATE_RANGE_OPTIONS } from "~/utils/signal"
import { mapProductsToRouteName } from "../Filters/schemas"

export const getSearchUrlFromProduct = (
  product: SpecterProducts,
  viewMode: ViewMode = PRODUCT_VIEW_MODES[product][0]
) => {
  return `/signals/${mapProductsToRouteName(product)}/${viewMode}`
}

export const createSearchQuery = (query: Queries["query"]): string => {
  if (query === null || typeof query !== "object") return ""

  return JSON.stringify(query)
}

export const createSearchQueryNew =
  (product: SpecterProducts) => (query: Queries["query"]) => {
    if (typeof query !== "object") return ""

    return createSearchQuery({
      ...query,
      ...(product === SpecterProducts.company
        ? { new: true }
        : { SignalDate: DATE_RANGE_OPTIONS.THIS_WEEK }),
    })
  }

export const createSearchQueryNewHighlights =
  (
    product: SpecterProducts,
    field:
      | "HasNewHighlights"
      | "HasNewFundingHighlights"
      | "HasNewGrowthHighlights"
  ) =>
  (query: Queries["query"]) => {
    if (typeof query !== "object") return ""

    return createSearchQuery({
      ...query,
      ...(product === SpecterProducts.company ? { [field]: true } : {}),
    })
  }

export const createSearchQueryAll = (query: Queries["query"]) => {
  if (typeof query !== "object") return ""

  return createSearchQuery(query)
}

export const createSearchSort = (sort: QueriesByUser["sort"]): string => {
  if (sort === null) return ""

  if (typeof sort === "string" && JSONSafeParse(sort)) return sort

  return JSON.stringify(sort)
}
