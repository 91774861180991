import { SpecterProducts } from "@prisma/client"
import { useSearchParams } from "@remix-run/react"
import { cacheKeys } from "~/utils/cacheKeys"
import { useIntegrations } from "~/utils/hooks/useIntegrations"
import {
  useSignalFavourite,
  useSignalFavourites,
} from "~/utils/hooks/useSignalFavourite"
import { ViewMode } from "~/utils/hooks/useViewMode"
import { isOneOf } from "~/utils/isOneOf"
import { queryClient } from "~/utils/queryClient"
import { IntegrationSignalButton } from "../IntegrationSignalButton"
import { ListRemoveModal } from "../ListRemoveModal"
import {
  ActionButtonProps,
  AddManyToListIconButton,
  AddToAffinityIconButton,
  AddToListButton,
  AddToListIconButton,
  ExportSignalsIconButton,
  FavouriteSignalIconButton,
  ProductDetailItem,
} from "./Buttons"

export type SearchesAndListsProducts =
  (typeof PRODUCTS_WITH_LISTS_AND_SEARCHES)[number]

export const PRODUCTS_WITH_LISTS_AND_SEARCHES = [
  SpecterProducts.company,
  SpecterProducts.talent,
  SpecterProducts.stratintel,
]

type Props<P extends SpecterProducts> = ActionButtonProps<P> & {
  containerRef?: React.RefObject<HTMLElement | null>
  stretchButtons?: boolean
  showAll?: boolean
  addedToIntegration?: boolean
}

export const ActionMenuIcons = <P extends SpecterProducts>({
  product,
  signal,
  stretchButtons = true,
  addedToIntegration,
}: Props<P>) => {
  const integrationsQuery = useIntegrations()

  const { toggleFavourite, isFavourite, isLoading } = useSignalFavourite(
    signal,
    product
  )

  const disableLegacyAffinity =
    integrationsQuery.data?.integrations.integrationsMeta.some(
      (i) => i.integrationKey === "affinity"
    ) ?? false

  const hasAffinityProductIntegration = {
    company: integrationsQuery.data?.integrations.hasAffinityCompanyList,
    talent: integrationsQuery.data?.integrations.hasAffinityTalentList,
    stratintel: integrationsQuery.data?.integrations.hasAffinityStrategicList,
    investors: false,
    fundingRounds: false,
    acquisition: false,
    ipo: false,
    people: false,
  }

  const legacyAffinitySetup = hasAffinityProductIntegration[product]

  return (
    <>
      {isOneOf(product, PRODUCTS_WITH_LISTS_AND_SEARCHES) && (
        <>
          <FavouriteSignalIconButton
            stretch={stretchButtons}
            toggleFavourite={toggleFavourite}
            isFavourite={isFavourite}
            isLoading={isLoading}
          />
          <AddToListIconButton
            stretch={stretchButtons}
            product={product}
            signal={signal}
          />
          <IntegrationSignalButton
            signalIds={[signal.id]}
            product={product}
            addedToIntegration={addedToIntegration}
          />
        </>
      )}

      {!disableLegacyAffinity && legacyAffinitySetup && (
        <AddToAffinityIconButton
          stretch={stretchButtons}
          product={product}
          signal={signal}
        />
      )}
    </>
  )
}

export const ActionMenu = <P extends SpecterProducts>({
  product,
  signal,
}: Props<P>) => {
  return (
    <>
      {isOneOf(product, [
        ...PRODUCTS_WITH_LISTS_AND_SEARCHES,
        SpecterProducts.fundingRounds,
        SpecterProducts.acquisition,
        SpecterProducts.ipo,
      ]) && (
        <>
          <AddToListButton product={product} signal={signal} />
          <IntegrationSignalButton
            signalIds={[signal.id]}
            product={product}
            showLabel
            addedToIntegration={
              "addedToIntegration" in signal ? signal.addedToIntegration : false
            }
          />
        </>
      )}
    </>
  )
}

export const ActionMenuSelection = <P extends SpecterProducts>({
  product,
  signals,
  stretchButtons = true,
}: {
  product: P
  signals: ProductDetailItem<P>[]
  stretchButtons?: boolean
}) => {
  const { toggleFavourite, isFavourite, isLoading } = useSignalFavourites(
    signals,
    product
  )

  const signalIds = signals.map((signal) => signal.id)

  const [searchParams] = useSearchParams()
  const listId = searchParams.get("listId")

  return (
    <>
      {listId && (
        <ListRemoveModal
          isSelectionMenu
          items={signalIds}
          product={product}
          listId={listId}
          onSuccess={async () => {
            await queryClient.invalidateQueries(
              cacheKeys.userLists({ product })
            )
            await queryClient.invalidateQueries(
              cacheKeys.signals(product, searchParams, ViewMode.Table)
            )
            await queryClient.invalidateQueries(
              cacheKeys.signalsCount({ product, searchParams })
            )
          }}
        />
      )}
      {isOneOf(product, PRODUCTS_WITH_LISTS_AND_SEARCHES) && (
        <>
          <FavouriteSignalIconButton
            stretch={stretchButtons}
            toggleFavourite={toggleFavourite}
            isFavourite={isFavourite}
            isLoading={isLoading}
          />
          <AddManyToListIconButton
            stretch={stretchButtons}
            product={product}
            signalIds={signalIds}
          />
          <IntegrationSignalButton signalIds={signalIds} product={product} />
        </>
      )}
      <ExportSignalsIconButton
        stretch={stretchButtons}
        product={product}
        signalIds={signalIds}
      />
    </>
  )
}
