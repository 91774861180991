// useElementSize.ts
import {
  MutableRefObject,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react"
import useResizeObserver from "@react-hook/resize-observer"

interface Size {
  width: number
  height: number
}

export default function useElementSize<T extends HTMLElement = HTMLDivElement>(
  roundTo: "floor" | "round" | "ceil" | "none" = "none"
): [MutableRefObject<T | null>, Size] {
  const target = useRef<T | null>(null)
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  })

  const setRoundedSize = useCallback(
    ({ width, height }: Size) => {
      if (roundTo === "none") return setSize({ width, height })

      setSize({
        width: Math[roundTo]?.(width) ?? width,
        height: Math[roundTo]?.(height) ?? height,
      })
    },
    [roundTo]
  )

  useLayoutEffect(() => {
    target.current && setRoundedSize(target.current.getBoundingClientRect())
  }, [setRoundedSize, target])

  useResizeObserver(target, (entry) => {
    const { inlineSize: width, blockSize: height } = entry.contentBoxSize[0]
    setRoundedSize({ width, height })
  })

  return [target, size]
}
