import {
  Button,
  MenuItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { useUser } from "@clerk/remix"
import { SpecterProducts } from "@prisma/client"
import { useMutation } from "@tanstack/react-query"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"

import { useAnalytics } from "~/utils/hooks/useAnalytics"
import { useEnv } from "~/utils/hooks/useEnv"
import invariant from "~/utils/invariant"
import { relativeUrl } from "~/utils/string/url"
import { mapProductsToRouteName } from "../Filters/schemas"
import { useIntegrations } from "~/utils/hooks/useIntegrations"

interface Props {
  product: SpecterProducts
}

export const AffinityImportSearch = ({
  product,
}: Props): JSX.Element | null => {
  const toast = useToast()
  const user = useUser()
  const env = useEnv()
  const [searchParams] = useSafeSearchParams()
  const integration = useIntegrations()
  const modal = useDisclosure()
  const analytics = useAnalytics()

  const affinityImportSearchMutation = useMutation(
    async () => {
      invariant(user.user?.id)

      const query = searchParams.get("query")

      invariant(query)

      await fetch(`/api/affinity/import-search`, {
        method: "POST",
        body: JSON.stringify({
          query,
          product,
        }),
      })

      const searchURL = relativeUrl(
        `${env.APP_URL}/signals/${mapProductsToRouteName(product)}/feed`,
        searchParams.toString()
      )

      analytics.track(
        "Add to Affinity",
        {
          product,
          type: "search",
          searchURL,
          query,
        },
        { exclude: ["intercom"] }
      )
    },
    {
      onMutate() {
        toast({
          status: "info",
          title: "This search is being added to Affinity",
          description: "This can take some time",
        })
      },
      onSuccess() {
        modal.onClose()
        toast({
          status: "success",
          title: "Signals imported",
          description: "Your signals have been imported into Affinity",
        })
      },
    }
  )

  if (
    (product === SpecterProducts.talent &&
      !integration.data?.integrations.hasAffinityTalentList) ||
    (product == SpecterProducts.stratintel &&
      !integration.data?.integrations.hasAffinityStrategicList) ||
    (product == SpecterProducts.company &&
      !integration.data?.integrations.hasAffinityCompanyList)
  ) {
    return null
  }

  return (
    <>
      <MenuItem
        onClick={modal.onOpen}
        disabled={affinityImportSearchMutation.isLoading}
        minW="fit-content"
      >
        Add to Affinity
      </MenuItem>

      <Modal isOpen={modal.isOpen} onClose={modal.onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add to Affinity</ModalHeader>
          <ModalBody>
            <Text fontSize="sm">
              Are you sure you want to add these signals to Affinity?
            </Text>
            <Text fontSize="sm" mt={3}>
              This import will take some time, it takes approximately 1 minute
              for every 40 signals you want to export. Try to refine your search
              by applying more filters, or use Export to Affinity which is much
              faster.
            </Text>
          </ModalBody>
          <ModalFooter gap={2}>
            <Button variant="outline" onClick={modal.onClose}>
              Cancel
            </Button>

            <Button
              colorScheme="brand"
              onClick={() => affinityImportSearchMutation.mutate()}
              disabled={affinityImportSearchMutation.isLoading}
              isLoading={affinityImportSearchMutation.isLoading}
            >
              Add to Affinity
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
