import { Seniority } from "@prisma/client"
import numeral from "numeral"
import { sentenceCase } from "sentence-case"
import { titleCase } from "title-case"
import { isNullish } from "../values"

export function formatSeniority(seniority: Seniority | null): string {
  if (isNullish(seniority)) return ""

  return titleCase(sentenceCase(seniority)).split(" ").join("-")
}

export function formatMoney(value: number, symbol = "$") {
  return numeral(value).format(`${symbol}0,0`)
}
