import { ListType } from "~/routes/__protected/user/lists/$product"
import { useLocalStorage } from "./useLocalStorage"

export const useHiddenSearchesLists = (type: ListType) => {
  const key =
    type === "list" ? "specter-hidden-lists" : "specter-hidden-searches"

  const [hiddenLS, setHiddenLS] = useLocalStorage<string[]>(key)

  let hidden = hiddenLS ?? []

  // Backwards compatibility for old pattern
  if (!Array.isArray(hidden)) {
    hidden = hiddenLS.toString().split(",")
    setHiddenLS(hidden)
  }

  const hide = (id: string) => setHiddenLS([...hidden, id])

  const unhide = (id: string) =>
    setHiddenLS(hidden.filter((hiddenId) => hiddenId !== id))

  return {
    hidden,
    hide,
    unhide,
  }
}
