import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { useRef } from "react"
import { HiTrash } from "react-icons/hi"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { cacheKeys } from "~/utils/cacheKeys"
import { MenuItem } from "~/components/MenuItem"

interface Props {
  listId: string
  product: SpecterProducts
}

export const ListDelete = ({ listId, product }: Props): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef(null)
  const queryClient = useQueryClient()

  const deleteMutation = useMutation(
    async () => {
      await fetch(`/api/lists/${product}/${listId}`, {
        method: "delete",
      })
    },
    {
      async onSuccess() {
        onClose()
        await queryClient.refetchQueries(cacheKeys.userLists({ product }))
      },
    }
  )

  return (
    <>
      <MenuItem
        icon={HiTrash}
        onClick={onOpen}
        isLoading={deleteMutation.isLoading}
      >
        Delete
      </MenuItem>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontWeight="bold">Delete List</AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this list?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} size="sm">
                Cancel
              </Button>
              <Button
                size="sm"
                colorScheme="red"
                variant="solid"
                ml={3}
                onClick={() => deleteMutation.mutate()}
                isLoading={deleteMutation.isLoading}
                disabled={deleteMutation.isLoading}
              >
                Yes, Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
