import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { HiDuplicate } from "react-icons/hi"
import { cacheKeys } from "~/utils/cacheKeys"
import { MenuItem } from "../MenuItem"

export const DuplicateSavedSearch = ({
  id,
  product,
}: {
  id: string
  product: SpecterProducts
}): JSX.Element => {
  const modalDisclosure = useDisclosure()
  const queryClient = useQueryClient()

  const { mutate: duplicateSavedSearch, isLoading } = useMutation({
    mutationFn: () => {
      return fetch(`/api/saved-searches/${id}/duplicate`, {
        method: "POST",
      })
    },
    async onSuccess() {
      await queryClient.invalidateQueries({
        queryKey: cacheKeys.userSavedSearches(product),
      })
      modalDisclosure.onClose()
    },
  })

  return (
    <>
      <MenuItem icon={HiDuplicate} onClick={modalDisclosure.onOpen}>
        Duplicate
      </MenuItem>

      <Modal isOpen={modalDisclosure.isOpen} onClose={modalDisclosure.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Duplicate Saved Search</ModalHeader>
          <ModalBody>
            <Text fontSize="sm" color="gray.500">
              This will create a new saved search with the same filters and
              parameters as the original saved search.
            </Text>
          </ModalBody>
          <ModalFooter gap={2} display="flex">
            <Button variant="outline" onClick={modalDisclosure.onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="brand"
              onClick={() => duplicateSavedSearch()}
              isLoading={isLoading}
              disabled={isLoading}
            >
              Duplicate
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
