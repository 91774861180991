import { ReturnButton } from "../ReturnButton"

import { getDataMatchingKey, getIntegrationName } from "~/utils/integrationApp"
import {
  Center,
  Box,
  Flex,
  Text,
  ModalFooter,
  Select,
  Icon,
} from "@chakra-ui/react"
import { SpecterProducts } from "@prisma/client"
import { ProductItem } from "../SignalCard"
import { useIntegrationDataMatchingFields } from "~/utils/hooks/useIntegrations"
import { getSignalFields } from "~/utils/getSignalFields"
import { HiMenuAlt4 } from "react-icons/hi"
import { getProductEntityName } from "~/utils/getProductName"
import pluralize from "pluralize"
import { useFormik } from "formik"
import { z } from "zod"
import { toFormikValidationSchema } from "zod-formik-adapter"
import { RecordMatching } from "./RecordMatching"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { cacheKeys } from "~/utils/cacheKeys"
import invariant from "~/utils/invariant"

export const dataMatchingSchema = z.object({
  specterField: z.string(),
  integrationField: z.string(),
})

export type DataMatchingType = z.infer<typeof dataMatchingSchema>

type Props<P extends SpecterProducts> = {
  product: P
  integrationKey: string
  nextStep: () => void
  signals: ProductItem<P>[]
  defaultDataSource?: string
  configLocked: boolean
  defaultFieldMapping?: any
  dataSource: string
  defaultDataMatching?: DataMatchingType
}

const validationSchema = toFormikValidationSchema(dataMatchingSchema)

export const DataMatching = <P extends SpecterProducts>({
  product,
  integrationKey,
  signals,
  dataSource,
  nextStep,
  configLocked,
  defaultFieldMapping,
  defaultDataMatching,
}: Props<P>) => {
  const fieldsQuery = useIntegrationDataMatchingFields(
    product,
    integrationKey,
    dataSource
  )
  const [firstSignal] = signals
  const internalFields = getSignalFields(firstSignal ?? {})

  const queryClient = useQueryClient()

  const updateMetaMutation = useMutation({
    mutationFn: async (values: z.infer<typeof dataMatchingSchema>) => {
      const dataMatchingKey = getDataMatchingKey(product)

      invariant(dataMatchingKey)

      const req = await fetch(`/api/integrations/${integrationKey}/meta`, {
        method: "POST",
        body: JSON.stringify({
          [dataMatchingKey]: values,
        }),
      })

      invariant(req.ok)
    },
    async onSuccess() {
      await queryClient.invalidateQueries({
        queryKey: cacheKeys.integrationMeta(integrationKey),
      })
      nextStep()
    },
  })

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: defaultDataMatching ?? {
      specterField: "",
      integrationField: "",
    },
    onSubmit: (values) => {
      updateMetaMutation.mutate(values)
    },
  })

  return (
    <>
      <Flex
        as="form"
        h="full"
        flexDir="column"
        onSubmit={(e) => {
          e.preventDefault()
          formik.handleSubmit()
        }}
      >
        <Center flex={1}>
          <Box textAlign="center">
            <Box maxW={380} mx="auto">
              <Text fontWeight="medium">
                Which fields should be used for matching?
              </Text>
              <Text fontSize="sm" color="gray.600" mb={4}>
                These are the fields that will be used to match Specter{" "}
                {pluralize(getProductEntityName(product))} with existing{" "}
                {getIntegrationName(integrationKey)} Records.
              </Text>
            </Box>

            <Flex align="center" gap={2}>
              <Select
                w="300px"
                flex="none"
                value={formik.values.specterField}
                onChange={(e) =>
                  formik.setFieldValue("specterField", e.target.value)
                }
                placeholder="Select a field..."
              >
                {internalFields.map((field) => (
                  <option key={field.value} value={field.value}>
                    {field.label}
                  </option>
                ))}
              </Select>
              <Icon as={HiMenuAlt4} />
              <Select
                w="300px"
                flex="none"
                value={formik.values.integrationField}
                onChange={(e) =>
                  formik.setFieldValue("integrationField", e.target.value)
                }
                placeholder="Select a field..."
              >
                {fieldsQuery.data?.map((field) => (
                  <option key={field.value} value={field.value}>
                    {field.label}
                  </option>
                ))}
              </Select>
            </Flex>
          </Box>
        </Center>
        <ModalFooter>
          <ReturnButton
            type="submit"
            isDisabled={!formik.isValid || updateMetaMutation.isLoading}
            isLoading={updateMetaMutation.isLoading}
          >
            Next
          </ReturnButton>
        </ModalFooter>
      </Flex>

      {updateMetaMutation.isSuccess && (
        <RecordMatching
          product={product}
          integrationKey={integrationKey}
          signals={signals}
          dataSource={dataSource}
          nextStep={nextStep}
          configLocked={configLocked}
          defaultFieldMapping={defaultFieldMapping}
          dataMatching={formik.values}
        />
      )}
    </>
  )
}
