import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  Box,
  ModalCloseButton,
  Spinner,
  Center,
  Text,
  Flex,
  Image,
} from "@chakra-ui/react"
import pluralize from "pluralize"
import { getProductEntityName } from "~/utils/getProductName"
import { useProduct } from "~/utils/hooks/useProduct"
import { useSignalsQuery } from "~/utils/hooks/useSignalsQuery"
import { ViewMode } from "~/utils/hooks/useViewMode"
import {
  formatSignalsForIntegration,
  getIntegrationColor,
  getIntegrationLogo,
  getIntegrationName,
} from "~/utils/integrationApp"
import { IntegrationPush } from "../IntegrationPush"
import { ProductItem } from "../SignalCard"

interface Props {
  isOpen: boolean
  onClose: () => void
  integrationKey: string
}

const Content = ({ integrationKey, onClose }: Props) => {
  const product = useProduct()
  const signalsQuery = useSignalsQuery(product, ViewMode.Table)

  if (signalsQuery.isLoading) {
    return (
      <Center h="calc(100vh - 300px)">
        <Box textAlign="center">
          <Spinner />
          <Text fontSize="md" fontWeight="medium" mb={2}>
            Fetching {pluralize(getProductEntityName(product))}...
          </Text>
        </Box>
      </Center>
    )
  }

  const signals =
    signalsQuery.data?.pages.flatMap(
      (page) => page?.items as ProductItem<typeof product>[]
    ) ?? []

  return (
    <IntegrationPush
      product={product}
      integrationKey={integrationKey}
      signals={formatSignalsForIntegration(signals, product)}
      onComplete={onClose}
    />
  )
}

export const IntegrationPushSearchModal = ({
  isOpen,
  onClose,
  integrationKey,
}: Props) => {
  const integrationName = getIntegrationName(integrationKey)
  const color = getIntegrationColor(integrationKey)

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW={800} p={1} rounded="2xl">
        <Box
          overflow="hidden"
          rounded="xl"
          borderWidth={1}
          borderColor="gray.100"
        >
          <ModalCloseButton color={color ? "white" : "inherit"} />
          <ModalHeader bgColor={color} borderBottomWidth={color ? 0 : 1}>
            <Flex alignItems="center" gap={2}>
              <Image
                w={4}
                src={getIntegrationLogo(integrationKey)}
                alt={integrationName}
              />
              <Text as="span" color={color ? "white" : "inherit"}>
                Add to {integrationName}
              </Text>
            </Flex>
          </ModalHeader>
          {isOpen && (
            <Content isOpen integrationKey={integrationKey} onClose={onClose} />
          )}
        </Box>
      </ModalContent>
    </Modal>
  )
}
