import { useQuery } from "@tanstack/react-query"
import { cacheKeys } from "../cacheKeys"
import invariant from "../invariant"
import { openai } from "../openai"

export const useAi = (prompt?: string) => {
  return useQuery({
    queryKey: cacheKeys.ai(prompt),
    queryFn: async () => {
      invariant(prompt, "Prompt is required")

      const result = await openai.chat.completions.create({
        model: "gpt-4o-mini",
        messages: [
          {
            role: "user",
            content: prompt,
          },
        ],
      })

      return result.choices[0].message.content
    },
    enabled: !!prompt,
  })
}
