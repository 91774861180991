import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { HiEye, HiEyeOff } from "react-icons/hi"
import { ListType } from "~/routes/__protected/user/lists/$product"
import { useHiddenSearchesLists } from "~/utils/hooks/useHiddenSearchesLists"
import { capitalize } from "~/utils/string/format"
import { MenuItem } from "../MenuItem"

type Props =
  | {
      type: ListType.userList
      id: string
    }
  | {
      type: ListType.savedSearch
      id: number
    }

export function HideListOrSearch({ id, type }: Props): JSX.Element {
  const modal = useDisclosure()
  const toast = useToast()

  const { hidden, hide, unhide } = useHiddenSearchesLists(type)

  const isHidden = hidden.includes(id.toString())
  const promoteBody = (
    <Text>
      If you don't want this {capitalize(type)} to appear in this page, you can
      hide it. You can unhide it at any time.
    </Text>
  )

  const action = isHidden ? "Unhide" : "Hide"
  const modalTitle = `${action} ${capitalize(type)}`

  return (
    <>
      <MenuItem icon={isHidden ? HiEye : HiEyeOff} onClick={modal.onOpen}>
        {action}
      </MenuItem>

      <Modal isOpen={modal.isOpen} onClose={modal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>{modalTitle}</ModalHeader>
          <ModalBody>{promoteBody}</ModalBody>
          <ModalFooter gap={2}>
            <Button variant="outline" size="sm" onClick={modal.onClose}>
              Cancel
            </Button>
            <Button
              size="sm"
              colorScheme="brand"
              onClick={() => {
                if (isHidden) unhide(id.toString())
                else hide(id.toString())

                modal.onClose()

                toast({
                  status: "success",
                  title: `${capitalize(type)} updated successfully`,
                })
              }}
            >
              {action}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
