import { useIntercom } from "react-use-intercom"
import { OG_DOMAIN } from "~/consts/users"
import { useEnv } from "./useEnv"
import { useJuneAnalytics } from "./useJuneAnalytics"
import { useUserPermissions } from "./useUserPermissions"

const AnalyticsPlatforms = ["june", "intercom"] as const
type AnalyticsPlatform = (typeof AnalyticsPlatforms)[number]

export const useAnalytics = () => {
  const userPermissions = useUserPermissions()

  const june = useJuneAnalytics()
  const intercom = useIntercom()

  const env = useEnv()

  const track = (
    event: string,
    properties: Record<string, any> = {},
    constraints?:
      | {
          exclude?: AnalyticsPlatform[]
          only?: never
        }
      | {
          exclude?: never
          only?: AnalyticsPlatform[]
        }
  ) => {
    const shouldTrack = (platform: AnalyticsPlatform) =>
      constraints?.exclude
        ? !constraints.exclude.includes(platform)
        : constraints?.only
        ? constraints.only.includes(platform)
        : true

    if (
      shouldTrack("june") &&
      !userPermissions.data?.email?.includes(OG_DOMAIN) // * Skip OG users
    ) {
      june?.track(event, properties, {
        context: { groupId: userPermissions.data?.organizationId },
      })
    }

    if (shouldTrack("intercom") && env.INTERCOM_ENABLED) {
      intercom.trackEvent(event, properties)
    }
  }

  return {
    track,
  }
}
