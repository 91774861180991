import { Button, ButtonProps } from "@chakra-ui/react"
import { HiChevronDown } from "react-icons/hi"

export const ReturnButton = ({ children, ...props }: ButtonProps) => {
  return (
    <Button
      size="sm"
      variant="solid"
      colorScheme="brand"
      {...props}
      rightIcon={<HiChevronDown />}
    >
      {children}
    </Button>
  )
}
