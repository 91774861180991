import { SpecterProducts } from "@prisma/client"
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  UseQueryResult,
} from "@tanstack/react-query"
import { useMemo } from "react"
import { GetUserLists } from "~/routes/__protected/api/lists"
import invariant from "~/utils/invariant"
import { cacheKeys } from "../cacheKeys"
import { hasLength } from "../values"
import { useSafeSearchParams } from "./useSafeSearchParams"

export const USER_LISTS_PAGE_SIZE = 25

export const useUserLists = <Infinite extends boolean = false>({
  product,
  signalId,
  isDashboard,
  infiniteQuery,
}: {
  product: SpecterProducts
  signalId?: string
  isDashboard?: boolean
  infiniteQuery?: Infinite
}) => {
  const [searchParams] = useSafeSearchParams()

  const listsQuery = useInfiniteQuery<GetUserLists>(
    cacheKeys.userLists({
      product,
      signalId,
      isDashboard,
      searchParams,
    }),
    async ({ pageParam = 0 }) => {
      const reqSearchParams = new URLSearchParams({
        product,
        ...(signalId && { signalId }),
        ...(isDashboard && { dashboard: "true" }),
        ...(pageParam && { page: pageParam }),
        ...(searchParams.has("sort") && {
          sort: searchParams.get("sort") ?? "",
        }),
        ...(searchParams.has("search") && {
          search: searchParams.get("search") ?? "",
        }),
      })

      const req = await fetch(`/api/lists?${reqSearchParams.toString()}`)

      invariant(req.ok, "Failed to load lists")

      return await req.json()
    },
    {
      getNextPageParam(result) {
        // No more pages if we haven't got items
        if (result.items) {
          if (!hasLength(result.items)) {
            // Needs to undefined as per
            //https://tanstack.com/query/v4/docs/react/guides/infinite-queries
            return undefined
          }

          // No more pages if the results are _less_ than the FEED_PAGE_SIZE
          if (result.items.length < USER_LISTS_PAGE_SIZE) {
            return undefined
          }
        }

        return result.page + 1
      },
      staleTime: 5 * 60 * 1000, // 5 minutes
      refetchOnMount: false,
    }
  )

  const rowDataFetched = useMemo(
    () =>
      listsQuery.data?.pages.flatMap(
        // @ts-ignore
        ({ items }) => items
      ) as unknown as GetUserLists["items"] | undefined,
    [listsQuery.data]
  )

  return {
    ...listsQuery,
    ...(!infiniteQuery && { data: rowDataFetched }),
  } as Infinite extends true
    ? UseInfiniteQueryResult<typeof rowDataFetched>
    : UseQueryResult<typeof rowDataFetched>
}
